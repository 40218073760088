import * as t from 'io-ts';

export const isMaximumLengthStringBrand =
  (l: number) =>
  (s: unknown): s is string =>
    typeof s === 'string' && s.trim().length !== 0 && s.trim().length <= l;

export const strictLength = (length: number) =>
  new t.Type(
    'MaximumLengthString',
    isMaximumLengthStringBrand(length),
    (u, c) =>
      isMaximumLengthStringBrand(length)(u)
        ? t.success(u)
        : t.failure(u, c, `Failed to decode MaximumLengthString from ${JSON.stringify(u)}`),
    t.identity,
  );
