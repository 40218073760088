import { Avatar, colors, Icon, SafeAreaView } from '@mero/components';
import { pipe } from 'fp-ts/function';
import { t } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, Vibration } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import CalendarScreen from '../screens/Authorized/CalendarScreen/CalendarScreen';
import CheckoutScreen from '../screens/Authorized/CheckoutScreen';
import EnableCheckoutScreen from '../screens/Authorized/CheckoutScreen/EnableCheckoutScreen';
import ClientsScreen from '../screens/Authorized/ClientsScreen';
import MenuScreen from '../screens/Authorized/MenuScreen';
import CashRegistryScreen from '../screens/Authorized/MenuScreen/screens/CashRegistryScreen/CashRegistryScreen';
import {
  CheckoutReportsScreen,
  CheckoutReportsScreenBottom,
} from '../screens/Authorized/MenuScreen/screens/PageReportsScreen/CheckoutReportsScreen';
import NotificationsScreen from '../screens/Authorized/NotificationsScreen';
import BrandProductsListScreen from '../screens/Authorized/ProductsScreen/BrandProductsListScreen';
import CategoryProductsListScreen from '../screens/Authorized/ProductsScreen/CategoryProductsListScreen';
import EnableProductsScreen from '../screens/Authorized/ProductsScreen/EnableProductsScreen';
import ProductSearchScreen from '../screens/Authorized/ProductsScreen/ProductSearchScreen';
import ProductsDashboardScreen from '../screens/Authorized/ProductsScreen/ProductsDashboardScreen';
import ProductsMenuScreen from '../screens/Authorized/ProductsScreen/ProductsMenuScreen';
import ServicesMobileScreen from '../screens/Authorized/ServicesScreen/ServicesMobileScreen';
import ServicesWebScreen from '../screens/Authorized/ServicesScreen/ServicesWebScreen';

import LargeScreenMenu from '../components/LargeScreenMenu';
import { BLACK, DARK_BLUE, RADICAL_RED, WHITE } from '@mero/components/lib/styles/colors';

import { styles } from '../navigation/styles';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, RouteProp } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { AuthContext } from '../contexts/AuthContext';
import { withBookingClientSelectContextProvider } from '../contexts/BookingClientSelectContext';
import {
  CheckoutDraftsCountContext,
  withCheckoutNotificationsCountContextProvider,
} from '../contexts/CheckoutDraftsCountContext';
import { CheckoutsContext } from '../contexts/CheckoutsContext';
import { CurrentBusinessContext, CurrentBusinessProps } from '../contexts/CurrentBusiness';
import { IntercomContext } from '../contexts/IntercomContext';
import { withSelectedProductsTabContext } from '../contexts/SelectedProductsTabContext';
import { UserNotificationsCountContext } from '../contexts/UserNotificationsCountContext';
import {
  HomeTabsParamsList,
  CalendarTabStackParamList,
  ClientsTabStackParamList,
  NotificationsTabStackParamList,
  MenuTabStackParamList,
  HomeDrawerParamsList,
  AuthorizedStackParamList,
  RootStackParamList,
  CheckoutTabStackParamList,
  ReportsTabStackParamList,
  ProductsTabStackParamList,
  ServicesDashboardStackParamList,
} from '../types';
import ProsDashboardStackNavigator from './ProsDashboardStackNavigator';

const BottomTab = createBottomTabNavigator<HomeTabsParamsList>();

type Props = CurrentBusinessProps & {
  navigation: CompositeNavigationProp<
    DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
    CompositeNavigationProp<
      StackNavigationProp<AuthorizedStackParamList, 'Home'>,
      StackNavigationProp<RootStackParamList, 'Authorized'>
    >
  >;
  route: RouteProp<HomeDrawerParamsList>;
};

function CalendarTabBarIcon({ color }: { color: string }) {
  return <Icon type="calendar_menu" color={color} disabled={true} />;
}

function ClientsTabBarIcon({ color }: { color: string }) {
  return <Icon type="clients_menu" color={color} disabled={true} />;
}

function NotificationsTabBarIcon({ color }: { color: string }) {
  return <Icon type="notifications_menu" color={color} disabled={true} />;
}

function CheckoutTabBarIcon({ color }: { color: string }) {
  return (
    <Svg width={24} height={24}>
      <G fill="none" data-name="Group 7145">
        <G stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
          <Path d="M6.416 12.997h6.386m-6.386 3.189h4.789m4.79-3.194h1.595m-1.596 3.194h1.595M2.434 2v20.394l2.386-1.591 2.42 1.6 2.386-1.6 2.395 1.591 2.386-1.591 2.393 1.591 2.387-1.591 2.394 1.591V2M16.8 22.404h0Zm-4.78 0h0ZM1 2h22" />
          <Path d="m9.732 7.683 1.634 1.634 2.909-2.906" data-name="layer1" />
        </G>
        <Path d="M0 0h24v24H0z" data-name="Rectangle 2701" />
      </G>
    </Svg>
  );
}

function ReportsTabBarIcon({ color }: { color: string }) {
  return (
    <Svg width={25.5} height={25.811}>
      <G
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        data-name="Group 7829"
      >
        <Path d="M18.417 1.061h3.619V4.68" />
        <Path d="m3.464 12.261 7.055-6.4 4.7 2.4 7.059-7.2m-1.568 24v-16h3.136v16" data-name="layer1" />
        <Path d="M14.076 25.061v-9.047h3.619v9.047" />
        <Path d="M7.805 25.061V13.3h3.619v11.761" data-name="layer1" />
        <Path d="M1.655 25.061v-5.428h3.619v5.428" data-name="layer2" />
        <Path d="M24.75 25.061h-24" data-name="layer1" />
      </G>
    </Svg>
  );
}

/**
 * https://icons.expo.fyi/
 */
function BottomTabNavigator({ navigation, route, page }: Props): React.ReactElement {
  const [checkoutDraftsCountState] = CheckoutDraftsCountContext.useContext();
  const [userNotificationsCountState] = UserNotificationsCountContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const [authState] = AuthContext.useContext();
  const [checkoutState] = CheckoutsContext.useContext();

  const { isPhone } = useMediaQueries();

  const unseenNotificationsCount =
    userNotificationsCountState.type === 'Loaded' && userNotificationsCountState.unseenCount > 0
      ? userNotificationsCountState.unseenCount
      : undefined;

  const draftsCount =
    checkoutDraftsCountState.type === 'Loaded' && checkoutDraftsCountState.draftsCount > 0
      ? checkoutDraftsCountState.draftsCount
      : undefined;

  const [intercomState] = IntercomContext.useContext();
  const unreadIntercomCount = intercomState.type === 'Ready' ? intercomState.unreadConversationCount : 0;

  const isWorker = React.useMemo(() => {
    if (authState.type !== 'Authorized' || pageState.type !== 'Loaded') {
      return false;
    }

    return (
      pageState.page.workers.some((worker) => worker.user._id === authState.user._id) ||
      page.permissions.proProfiles.canManageAllProfiles()
    );
  }, [pageState, authState]);

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: colors.WHITE, flexDirection: 'row' }}
      edges={
        isPhone ? ['left', 'right', 'bottom'] : Platform.OS === 'web' ? ['left', 'right'] : ['left', 'right', 'top']
      }
    >
      {isPhone ? (
        <BottomTab.Navigator
          initialRouteName="CalendarTab"
          safeAreaInsets={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          screenOptions={{
            headerShown: false,
            tabBarActiveTintColor: DARK_BLUE,
            tabBarInactiveTintColor: BLACK,
          }}
        >
          <BottomTab.Screen
            name="CalendarTab"
            component={CalendarStackNavigator}
            options={{
              tabBarIcon: CalendarTabBarIcon,
              tabBarLabel: 'Calendar',
            }}
          />
          {pageState.type === 'Loaded' && pageState.page.permissions.clients.canListAllClients() ? (
            <BottomTab.Screen
              name="ClientsTab"
              component={ClientsStackNavigator}
              options={{
                tabBarIcon: ClientsTabBarIcon,
                tabBarLabel: 'Clienți',
              }}
            />
          ) : null}
          {pageState.type === 'Loaded' &&
          pageState.page.permissions.checkout.canWriteAll() &&
          pageState.page.permissions.page.canManageMemberships() ? (
            <BottomTab.Screen
              name="CheckoutTab"
              component={CheckoutTabStackNavigator}
              options={{
                tabBarBadge:
                  checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled
                    ? draftsCount
                    : undefined,
                tabBarBadgeStyle: {
                  color: WHITE,
                  backgroundColor: RADICAL_RED,
                  borderColor: WHITE,
                  borderWidth: 1,
                },
                tabBarIcon: CheckoutTabBarIcon,
                tabBarLabel: 'Vânzări',
              }}
            />
          ) : null}
          <BottomTab.Screen
            name="CashRegistryScreen"
            component={CashRegistryScreen}
            options={{
              tabBarStyle: { display: 'none' },
              tabBarItemStyle: { display: 'none' },
              title: t('cashRegistry:title'),
            }}
          />

          <BottomTab.Screen
            name="NotificationsTab"
            component={NotificationsStackNavigator}
            options={{
              tabBarBadge: unseenNotificationsCount,
              tabBarBadgeStyle: {
                color: WHITE,
                backgroundColor: RADICAL_RED,
                borderColor: WHITE,
                borderWidth: 1,
              },
              tabBarIcon: NotificationsTabBarIcon,
              tabBarLabel: 'Notificări',
            }}
          />
          {pageState.type === 'Loaded' && pageState.page.permissions.products.canManageAll() ? (
            <BottomTab.Screen
              name="ProductsTab"
              component={ProductsStackNavigator}
              options={{
                tabBarStyle: { display: 'none' },
                tabBarItemStyle: { display: 'none' },
              }}
            />
          ) : null}
          <BottomTab.Screen
            name="ServicesTab"
            component={ServicesDashboardNavigator}
            options={{
              tabBarStyle: { display: 'none' },
              tabBarItemStyle: { display: 'none' },
            }}
          />
          <BottomTab.Screen
            name="ProsTab"
            component={ProsDashboardStackNavigator}
            options={{
              tabBarStyle: { display: 'none' },
              tabBarItemStyle: { display: 'none' },
            }}
          />
          <BottomTab.Screen
            name="MenuTab"
            component={MenuStackNavigator}
            options={{
              tabBarIcon: function MenuTabBarIcon() {
                return (
                  <Avatar
                    source={page.details.profilePhoto.small}
                    firstname={page.details.name}
                    lastname=""
                    size={26}
                  />
                );
              },
              tabBarLabel:
                page.details.name !== ''
                  ? page.details.name.length > 12
                    ? `${page.details.name.substring(0, 10)}…`
                    : page.details.name
                  : 'Meniu',
              tabBarBadge: unreadIntercomCount > 0 ? unreadIntercomCount : undefined,
              tabBarBadgeStyle: {
                color: WHITE,
                backgroundColor: RADICAL_RED,
                borderColor: WHITE,
                borderWidth: 1,
              },
            }}
            listeners={{
              tabLongPress: () => {
                navigation.navigate('SelectAccount');
                Vibration.vibrate(80);
              },
            }}
          />
        </BottomTab.Navigator>
      ) : (
        <>
          <LargeScreenMenu
            page={page}
            navigation={navigation}
            route={route}
            unseenNotificationsCount={unseenNotificationsCount}
            draftsCount={draftsCount}
          />
          <BottomTab.Navigator
            initialRouteName="CalendarTab"
            // tabBar={(props) => <LargeScreenMenu page={page} {...props} />}
            tabBar={() => null}
            screenOptions={{ headerShown: false }}
          >
            <BottomTab.Screen
              name="CalendarTab"
              component={CalendarStackNavigator}
              options={{ tabBarLabel: 'calendar_menu' }}
            />
            {pageState.type === 'Loaded' && pageState.page.permissions.clients.canListAllClients() ? (
              <BottomTab.Screen
                name="ClientsTab"
                component={ClientsStackNavigator}
                options={{ tabBarLabel: 'clients_menu' }}
              />
            ) : null}

            {pageState.type === 'Loaded' && pageState.page.permissions.products.canManageAll() ? (
              <BottomTab.Screen
                name="ProductsTab"
                component={ProductsStackNavigator}
                options={{
                  tabBarLabel: t('products:productsTitle'),
                }}
              />
            ) : null}
            <BottomTab.Screen
              name="ServicesTab"
              component={ServicesDashboardNavigator}
              options={{
                tabBarStyle: { display: 'none' },
                tabBarItemStyle: { display: 'none' },
              }}
            />
            <BottomTab.Screen
              name="ProsTab"
              component={ProsDashboardStackNavigator}
              options={{
                tabBarStyle: { display: 'none' },
                tabBarItemStyle: { display: 'none' },
              }}
            />
            {pageState.type === 'Loaded' &&
            pageState.page.permissions.checkout.canWriteAll() &&
            pageState.page.permissions.page.canManageMemberships() ? (
              <BottomTab.Screen
                name="CheckoutTab"
                component={CheckoutTabStackNavigator}
                options={{
                  tabBarLabel: 'checkout_menu',
                }}
              />
            ) : null}
            <BottomTab.Screen
              name="CashRegistryScreen"
              component={CashRegistryScreen}
              options={{
                tabBarStyle: { display: 'none' },
                tabBarItemStyle: { display: 'none' },
                title: t('cashRegistry:title'),
              }}
            />
            <BottomTab.Screen
              name="NotificationsTab"
              component={NotificationsStackNavigator}
              options={{
                tabBarLabel: 'notifications_menu',
                tabBarBadge: unseenNotificationsCount,
              }}
            />
            {pageState.type === 'Loaded' &&
            isWorker &&
            (page.permissions.statistics.canReadOwnStatistics() ||
              page.permissions.statistics.canReadAllStatistics()) ? (
              <BottomTab.Screen
                name="ReportsTab"
                component={ReportsStackNavigator}
                options={{
                  tabBarStyle: { display: 'none' },
                  tabBarItemStyle: { display: 'none' },
                  title: t('reports:title'),
                }}
              />
            ) : null}
            <BottomTab.Screen
              name="MenuTab"
              component={MenuStackNavigator}
              listeners={{
                tabLongPress: () => {
                  navigation.navigate('SelectAccount');
                  Vibration.vibrate(80);
                },
              }}
            />
          </BottomTab.Navigator>
        </>
      )}
    </SafeAreaView>
  );
}

export default pipe(BottomTabNavigator, withCheckoutNotificationsCountContextProvider);

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const CalendarStack = createStackNavigator<CalendarTabStackParamList>();

function CalendarStackNavigator() {
  const { t } = useTranslation('calendar');

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.WHITE }} edges={['top']}>
      <CalendarStack.Navigator initialRouteName="CalendarScreen">
        <CalendarStack.Screen
          name="CalendarScreen"
          component={CalendarScreen}
          options={{
            headerShown: false,
            title: t('title'),
          }}
        />
      </CalendarStack.Navigator>
    </SafeAreaView>
  );
}

const ClientsStack = createStackNavigator<ClientsTabStackParamList>();

function ClientsStackNavigator() {
  return (
    <ClientsStack.Navigator initialRouteName="ClientsScreen">
      <ClientsStack.Screen
        name="ClientsScreen"
        component={ClientsScreen}
        options={{
          headerShown: false,
          title: 'Clienți',
        }}
      />
    </ClientsStack.Navigator>
  );
}

const ServicesDashboardStack = createStackNavigator<ServicesDashboardStackParamList>();

const ServicesDashboardNavigator = pipe(function ServicesDashboardNavigator() {
  const { t } = useTranslation('services');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <ServicesDashboardStack.Navigator
      initialRouteName={isPhone ? 'ServicesMobile' : 'ServicesWeb'}
      screenOptions={{
        headerShown: false,
      }}
    >
      <ServicesDashboardStack.Screen
        name="ServicesWeb"
        component={ServicesWebScreen}
        options={{
          title: t('services'),
        }}
      />
      <ServicesDashboardStack.Screen
        name="ServicesMobile"
        component={ServicesMobileScreen}
        options={{
          title: t('services'),
          cardStyle: modalStyle,
        }}
      />
    </ServicesDashboardStack.Navigator>
  );
});

const ProductsStack = createStackNavigator<ProductsTabStackParamList>();

const ProductsStackNavigator = pipe(function ProductsNavigator() {
  const { t } = useTranslation('products');
  const { isPhone } = useMediaQueries();
  const [checkoutState] = CheckoutsContext.useContext();

  return (
    <ProductsStack.Navigator initialRouteName={isPhone ? 'ProductsMenuScreen' : 'ProductsDashboardScreen'}>
      <ProductsStack.Screen
        name="ProductsMenuScreen"
        //@ts-expect-error types are not correct
        component={
          checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled
            ? ProductsMenuScreen
            : EnableProductsScreen
        }
        options={{
          headerShown: false,
          title: t('productsTitle'),
        }}
      />
      <ProductsStack.Screen
        name="ProductsDashboardScreen"
        component={
          checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled
            ? ProductsDashboardScreen
            : EnableProductsScreen
        }
        options={{
          headerShown: false,
          title: t('productsTitle'),
        }}
      />
      <ProductsStack.Screen
        name="ProductSearchScreen"
        component={ProductSearchScreen}
        options={{
          headerShown: false,
          title: t('productsTitle'),
        }}
      />
      <ProductsStack.Screen
        name="BrandProductsListScreen"
        component={BrandProductsListScreen}
        options={{
          headerShown: false,
          title: t('productsTitle'),
        }}
      />

      <ProductsStack.Screen
        name="CategoryProductsListScreen"
        component={CategoryProductsListScreen}
        options={{
          headerShown: false,
          title: t('productsTitle'),
        }}
      />
    </ProductsStack.Navigator>
  );
}, withSelectedProductsTabContext);

const CheckoutTabStack = createStackNavigator<CheckoutTabStackParamList>();

const CheckoutTabStackNavigator = pipe(function CheckoutStackNavigator() {
  const { t } = useTranslation();
  const [checkoutState] = CheckoutsContext.useContext();

  return (
    <CheckoutTabStack.Navigator initialRouteName="CheckoutTabScreen">
      <CheckoutTabStack.Screen
        name="CheckoutTabScreen"
        component={
          checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled
            ? CheckoutScreen
            : EnableCheckoutScreen
        }
        options={{
          headerShown: false,
          title: t('checkout:sales'),
        }}
      />
    </CheckoutTabStack.Navigator>
  );
}, withBookingClientSelectContextProvider);

const NotificationsStack = createStackNavigator<NotificationsTabStackParamList>();

function NotificationsStackNavigator() {
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.WHITE }} edges={['top']}>
      <NotificationsStack.Navigator initialRouteName="NotificationsScreen">
        <NotificationsStack.Screen
          name="NotificationsScreen"
          component={NotificationsScreen}
          options={{
            headerShown: false,
            title: 'Notificări',
          }}
        />
      </NotificationsStack.Navigator>
    </SafeAreaView>
  );
}

const ReportsStack = createStackNavigator<ReportsTabStackParamList>();

function ReportsStackNavigator() {
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.WHITE }} edges={['top']}>
      <ReportsStack.Navigator initialRouteName="ReportsScreen">
        <ReportsStack.Screen
          name="ReportsScreen"
          component={CheckoutReportsScreenBottom}
          options={{
            headerShown: false,
            title: 'Rapoarte',
          }}
        />
      </ReportsStack.Navigator>
    </SafeAreaView>
  );
}

const MenuStack = createStackNavigator<MenuTabStackParamList>();

function MenuStackNavigator() {
  return (
    <MenuStack.Navigator initialRouteName="MenuScreen" screenOptions={{ headerShown: false }}>
      <MenuStack.Screen name="MenuScreen" component={MenuScreen} options={{ title: 'Meniu' }} />
      <MenuStack.Screen name="PageReportsScreen" component={CheckoutReportsScreen} options={{ title: 'Meniu' }} />
    </MenuStack.Navigator>
  );
}
