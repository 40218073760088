import * as MeroApi from '@mero/api-sdk';
import { colors, Column, H2s, Row, SimpleListItem, SmallBody, Spacer, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { AuthContext } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList } from '../../../types';
import { styles } from './styles';

type Props = {
  navigation: CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'SelectAccount'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >;
};

function PageItem({
  page,
  onPress,
  selected,
  separator,
}: {
  page: MeroApi.pages.PageDetails;
  onPress: () => void;
  selected: boolean;
  separator: boolean;
}): React.ReactElement {
  const pageNameParts = (page.name ?? '').split(/\s+/g);
  return (
    <>
      <TouchableOpacity delayPressIn={0} onPress={onPress}>
        <SimpleListItem
          icon={selected ? 'checked' : 'unchecked'}
          title={page.name}
          subtitle={page.location?.address}
          image={{
            src: page.profilePhoto.medium,
            firstname: pageNameParts[0],
            lastname: pageNameParts[1],
          }}
          alignContent="middle"
        />
      </TouchableOpacity>
      {!!separator && <View key={`${page._id}-separator`} style={{ height: 1, backgroundColor: '#E0E0E0' }} />}
    </>
  );
}

export default function SelectAccountScreen({ navigation }: Props): React.ReactElement | null {
  const { t } = useTranslation('settings');

  const [state, { setCurrentPage }] = CurrentBusinessContext.useContext();
  const [, { signOut }] = AuthContext.useContext();

  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });

  if (state.type !== 'Loaded' && state.type !== 'NoAccess') {
    return null;
  }

  const currentPage = state.type === 'Loaded' ? state.page : { details: state.page };
  const allPages = state.allPages;

  const onPageSelected = (page: MeroApi.pages.PageDetails) => {
    if (page._id !== currentPage.details._id) {
      setCurrentPage(page._id);
    }
    goBack();
  };

  return (
    <View style={styles.container}>
      <Pressable style={styles.stretchContainer} onPress={goBack} />
      <Column style={styles.listContainer}>
        <Row alignItems="center" justifyContent="space-between">
          <H2s>{t('selectLocation')}</H2s>
          <TouchableOpacity onPress={() => navigation.navigate('CreateLocation')}>
            <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE }}>
              {t('addLocation')}
            </SmallBody>
          </TouchableOpacity>
        </Row>
        {allPages.map((page, idx) => (
          <PageItem
            key={page._id}
            page={page}
            onPress={() => {
              onPageSelected(page);
            }}
            selected={page._id === currentPage.details._id}
            separator={idx < allPages.length - 1}
          />
        ))}
        <Spacer size={40} />
        <TouchableOpacity onPress={signOut}>
          <Title style={{ textAlign: 'center', color: colors.DARK_BLUE }}>{t('logout')}</Title>
        </TouchableOpacity>
      </Column>
    </View>
  );
}
