import { PageId } from '@mero/api-sdk/dist/pages';
import { SubscriptionInfo } from '@mero/api-sdk/dist/payments';
import { Invoice } from '@mero/api-sdk/dist/payments/invoice';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import SplashScreen from '../../screens/SplashScreen';

import log from '../../utils/log';
import { promiseTimeout } from '../../utils/promise';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

export type SubscriptionContextState =
  | {
      readonly type: 'New';
      readonly subscriptionInfo?: SubscriptionInfo;
      readonly invoiceArchive: Invoice[];
    }
  | {
      readonly type: 'Loading';
      readonly subscriptionInfo?: SubscriptionInfo;
      readonly invoiceArchive: Invoice[];
    }
  | {
      readonly type: 'Loaded';
      readonly subscriptionInfo: SubscriptionInfo;
      readonly invoiceArchive: Invoice[];
    }
  | {
      readonly type: 'Failed';
      readonly subscriptionInfo?: SubscriptionInfo;
      readonly invoiceArchive: Invoice[];
      readonly error: unknown;
    };

const defaultState = (): SubscriptionContextState => ({
  type: 'New',
  invoiceArchive: [],
});

export const SubscriptionContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        subscriptionInfo: SubscriptionInfo;
        invoiceArchive: Invoice[];
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          subscriptionInfo: result.subscriptionInfo,
          invoiceArchive: result.invoiceArchive,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setResult: (
      state,
      result: {
        subscriptionInfo: SubscriptionInfo;
        invoiceArchive: Invoice[];
      },
    ) => {
      return {
        ...state,
        subscriptionInfo: result.subscriptionInfo,
        invoiceArchive: result.invoiceArchive,
      };
    },
    setFailed: (
      _,
      payload: {
        error: unknown;
        subscriptionInfo?: SubscriptionInfo;
        invoiceArchive: Invoice[];
      },
    ) => {
      return {
        type: 'Failed',
        subscriptionInfo: payload.subscriptionInfo,
        invoiceArchive: payload.invoiceArchive,
        error: payload.error,
      };
    },
    mutate: (s, fn: (s: SubscriptionContextState) => SubscriptionContextState): SubscriptionContextState => fn(s),
  },
  (dispatch) => {
    const reload = async ({ pageId, silent = false }: { pageId: PageId; silent?: boolean }) => {
      try {
        log.debug('Start reloading Subscription');

        const [subscriptionInfo] = await Promise.all([meroApi.payments.getPageSubscription(pageId)]);

        if (subscriptionInfo) {
          const invoiceArchive = await meroApi.payments.getSubscriptionInvoices(subscriptionInfo._id);

          silent
            ? dispatch.setResult({
                subscriptionInfo: subscriptionInfo,
                invoiceArchive,
              })
            : dispatch.trySetResult({
                subscriptionInfo: subscriptionInfo,
                invoiceArchive,
              });
        } else {
          log.debug('No subscription info');
          dispatch.setFailed({
            error: new Error('No subscription info'),
            subscriptionInfo: defaultState().subscriptionInfo,
            invoiceArchive: defaultState().invoiceArchive,
          });
        }
      } catch (error) {
        dispatch.setFailed({
          error: error,
          subscriptionInfo: defaultState().subscriptionInfo,
          invoiceArchive: defaultState().invoiceArchive,
        });
        log.exception(error);
      }
    };

    return {
      reloadAsync: async (payload: { pageId: PageId; withoutDelay?: boolean }): Promise<void> => {
        if (!payload.withoutDelay) {
          await promiseTimeout(10000);
        }
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload({ pageId: payload.pageId, silent: true }).catch(log.exception);

            return state;
          }

          return state;
        });
      },

      reload: (payload: { pageId: PageId }): void => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload(payload).catch(log.exception);

            return {
              type: 'Loading',
              subscriptionInfo: state.subscriptionInfo,
              invoiceArchive: state.invoiceArchive,
            };
          }

          return state;
        });
      },
    };
  },
);

export type SubscriptionInfoProps = {
  subscriptionInfo: SubscriptionInfo;
  invoiceArchive: Invoice[];
};

type PropsWithSubscriptionInfo<P> = P & SubscriptionInfoProps;

export function withSubscriptionInfo<P extends SubscriptionInfoProps>(
  Component: React.ComponentType<P>,
  FailedComponent: React.ComponentType<P> = () => null,
): React.FunctionComponent<Omit<P, keyof SubscriptionInfoProps>> {
  return function SubscriptionContextComponent(props) {
    const [state] = SubscriptionContext.useContext();

    switch (state.type) {
      case 'New': {
        return <SplashScreen />;
      }
      case 'Loading': {
        return <SplashScreen />;
      }
      case 'Loaded': {
        // FIXME: find a type safe way to exclude a property for generic type argument
        const subscriptionInfo: SubscriptionInfo = state.subscriptionInfo;
        const invoiceArchive: Invoice[] = state.invoiceArchive;

        // @ts-expect-error
        const allProps: PropsWithSubscriptionInfo<P> = {
          ...props,
          subscriptionInfo: subscriptionInfo,
          invoiceArchive: invoiceArchive,
        };

        return <Component {...allProps} />;
      }
      case 'Failed': {
        // @ts-expect-error
        const allProps: P = { ...props };

        return <FailedComponent {...allProps} />;
      }
    }
  };
}

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [, { reload }] = SubscriptionContext.useContext();

  const pageId = currentBusinessState.type === 'Loaded' ? currentBusinessState.page.details._id : undefined;

  React.useEffect(() => {
    if (pageId) {
      reload({ pageId });
    }
  }, [pageId]);

  return <>{children}</>;
};

export const withSubscriptionContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSubscriptionContextProvider(props: P) {
    return (
      <SubscriptionContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </SubscriptionContext.Provider>
    );
  };
};
