import { AnalyticsStatsNew } from '@mero/api-sdk/dist/analytics/analytics2';
import { CheckoutSaleOwnerSalesReportTotalsByType } from '@mero/api-sdk/dist/checkoutReports/checkoutReportsApi/checkoutSaleOwnerSalesReportTotalsByType';
import { FormCard, Column, H2s, Label, Line, Row, SmallBody, Spacer, colors, Title } from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import Svg, { Circle, G, Path } from 'react-native-svg';

import H3s from '@mero/components/lib/components/Text/H3s';

import { scaledToString } from '../../../../../../utils/scaled';

const AppointmentsIcon = () => (
  <Svg width={40} height={40}>
    <G data-name="Group 6006" transform="translate(-235 -484)">
      <Circle data-name="Ellipse 15" cx={20} cy={20} r={20} transform="translate(235 484)" fill="#52577f" />
      <Path data-name="Path 8323" d="M243 492h24v24h-24Z" fill="none" />
      <Path
        data-name="Path 8325"
        d="M262 496h-1v-2h-2v2h-8v-2h-2v2h-1a1.991 1.991 0 0 0-1.99 2l-.01 14a2 2 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2v-14a2.006 2.006 0 0 0-2-2Zm0 16h-14v-10h14Zm0-12h-14v-2h14Zm-7 5h5v5h-5Z"
        fill="#fff"
      />
    </G>
  </Svg>
);

const MoneyIcon = () => (
  <Svg width={40} height={40}>
    <G data-name="Group 6006">
      <Path data-name="Path 8328" d="M20 0A20 20 0 1 1 0 20 20 20 0 0 1 20 0Z" fill="#2dce89" />
      <Path data-name="Path 8326" d="M8 8h24v24H8Z" fill="none" />
      <Path
        data-name="Path 8327"
        d="M14.5 18h-2v7h2Zm6 0h-2v7h2Zm8.5 9H10v2h19Zm-2.5-9h-2v7h2Zm-7-6.74L24.71 14H14.29l5.21-2.74m0-2.26L10 14v2h19v-2Z"
        fill="#fff"
      />
    </G>
  </Svg>
);

const MIN_WIDTH = 343;

export type Props = {
  isFutureDate: boolean;
  analytics: AnalyticsStatsNew;
  prosDetails: CheckoutSaleOwnerSalesReportTotalsByType<MeroUnits.Any>;
};
const Summary: React.FC<Props> = ({ analytics, prosDetails, isFutureDate }) => {
  const { t } = useTranslation('reports');
  const [width, setWidth] = React.useState(0);

  const formWidth = React.useMemo(() => {
    const calculatedWidth = width / 3;
    if (calculatedWidth < MIN_WIDTH + 24) {
      return MIN_WIDTH;
    }

    return calculatedWidth - 24;
  }, [width]);

  return (
    <ScrollView style={{ flex: 1, zIndex: -1 }}>
      <View
        style={{ flex: 1 }}
        onLayout={(ev) => {
          setWidth(ev.nativeEvent.layout.width);
        }}
      >
        <FormCard paddings="none" dropShaddow rounded style={{ paddingLeft: 24, paddingBottom: 64, zIndex: -1 }}>
          <Row style={{ flex: 1, flexWrap: 'wrap' }}>
            <FormCard rounded paddings="none" dropShaddow style={{ width: formWidth, marginTop: 16, marginRight: 24 }}>
              <Spacer size={16} />
              <Row style={{ paddingHorizontal: 16 }}>
                <Column style={{ flex: 1 }}>
                  <H2s>{analytics.bookings.totalCount}</H2s>
                  <Label>{t('allAppointments')}</Label>
                </Column>
              </Row>
              <Spacer size={16} />
              <Column style={{ paddingHorizontal: 16 }}>
                <Line />
              </Column>
              <Spacer size={22} />
              <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                  {t('finalAppointments')}
                </SmallBody>
                <Row style={{ flex: 1 }}>
                  <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                    {analytics.bookings.finishedStats.count}
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.finishedStats.percent}%
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.finishedStats.income} {t('currency').toLowerCase()}
                  </SmallBody>
                </Row>
              </Row>
              <Spacer size={16} />
              <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                  {t('canceledAppointments')}
                </SmallBody>
                <Row style={{ flex: 1 }}>
                  <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                    {analytics.bookings.cancelledStats.count}
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.cancelledStats.percent}%
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.cancelledStats.income} {t('currency').toLowerCase()}
                  </SmallBody>
                </Row>
              </Row>
              <Spacer size={16} />
              <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                  {t('missingAppointments')}
                </SmallBody>
                <Row style={{ flex: 1 }}>
                  <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                    {analytics.bookings.noShowStats.count}
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.noShowStats.percent}%
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.noShowStats.income} {t('currency').toLowerCase()}
                  </SmallBody>
                </Row>
              </Row>
              <Spacer size={16} />
              <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                  {t('unconfirmedAppointments')}
                </SmallBody>
                <Row style={{ flex: 1 }}>
                  <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                    {analytics.bookings.pendingStats.count}
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.pendingStats.percent}%
                  </SmallBody>
                  <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                    {analytics.bookings.pendingStats.income} {t('currency').toLowerCase()}
                  </SmallBody>
                </Row>
              </Row>
              <Spacer size={16} />
              {isFutureDate && (
                <>
                  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', paddingLeft: 6, width: '32%' }}>
                      {t('futureAppointments')}
                    </SmallBody>
                    <Row style={{ flex: 1 }}>
                      <SmallBody style={{ width: '20%', textAlign: 'right' }}>
                        {analytics.bookings.futureStats.count}
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.futureStats.percent}%
                      </SmallBody>
                      <SmallBody style={{ width: '40%', textAlign: 'right' }}>
                        {analytics.bookings.futureStats.income} {t('currency').toLowerCase()}
                      </SmallBody>
                    </Row>
                  </Row>
                  <Spacer size={16} />
                </>
              )}
              <Line />
              <Column style={{ backgroundColor: colors.ALABASTER }}>
                <Spacer size={16} />
                <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                  <SmallBody style={{ flex: 1, fontFamily: 'open-sans-semibold', paddingLeft: 6 }}>
                    {t('rangeValue')}
                  </SmallBody>
                  <Row>
                    <SmallBody style={{ textAlign: 'right' }}>
                      {analytics.clients.averageBookingValue} {t('currency').toLowerCase()}
                    </SmallBody>
                  </Row>
                </Row>
                <Spacer size={16} />
              </Column>
            </FormCard>
            <Spacer size={24} />
            <FormCard rounded paddings="none" dropShaddow style={{ width: formWidth, marginTop: 16, marginRight: 24 }}>
              <Spacer size={16} />
              <Column style={{ paddingHorizontal: 16 }}>
                <Title>{t('revenue')}</Title>
                <Spacer size={4} />
                <H2s style={{ fontSize: 24 }}>
                  {scaledToString(prosDetails.all.total.amount)} {t(prosDetails.all.total.unit)}
                </H2s>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <Row>
                  <Column style={{ flex: 2 }}>
                    <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>{t('services')}</Label>
                    <Spacer size={4} />
                    <H3s>
                      {scaledToString(prosDetails.services.total.amount)} {t(prosDetails.services.total.unit)}
                    </H3s>
                  </Column>
                  <Column style={{ flex: 2 }}>
                    <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>{t('membership')}</Label>
                    <Spacer size={4} />
                    <H3s>
                      {scaledToString(prosDetails.memberships.total.amount)} {t(prosDetails.memberships.total.unit)}
                    </H3s>
                  </Column>
                </Row>
                <Spacer size={16} />
                <Row justifyContent="space-between">
                  <Column style={{ flex: 2 }}>
                    <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                      {t('products:products')}
                    </Label>
                    <Spacer size={4} />
                    <H3s>
                      {scaledToString(prosDetails.products.total.amount)} {t(prosDetails.products.total.unit)}
                    </H3s>
                  </Column>
                  <Column style={{ flex: 2 }}>
                    <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>{t('amounts')}</Label>
                    <Spacer size={4} />
                    <H3s>
                      {scaledToString(prosDetails.amounts.total.amount)} {t(prosDetails.amounts.total.unit)}
                    </H3s>
                  </Column>
                  {/*<Column style={{flex: 2}}>*/}
                  {/*  <Label style={{fontFamily: 'open-sans-semibold', color: colors.COMET}}>*/}
                  {/*    {t('products')}*/}
                  {/*  </Label>*/}
                  {/*  <Spacer size={4}/>*/}
                  {/*  <H3s>*/}
                  {/*    {scaledToString(prosDetails.products.total.amount)} {t(prosDetails.products.total.unit)}*/}
                  {/*  </H3s>*/}
                  {/*</Column>*/}
                </Row>
              </Column>
              <Spacer size={16} />
              <Column style={{ flex: 1 }} />
              <Line />
              <Column style={{ paddingHorizontal: 16, backgroundColor: colors.ALABASTER }}>
                <Spacer size={16} />
                <Row justifyContent="space-between">
                  {/*<Column style={{ flex: 2 }}>*/}
                  {/*  <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>*/}
                  {/*    {t('giftCards')}*/}
                  {/*  </Label>*/}
                  {/*  <Spacer size={4} />*/}
                  {/*  <H3s>*/}
                  {/*    {scaledToString(EMPTY_SCALAR)} {t(prosDetails.all.total.unit)}*/}
                  {/*  </H3s>*/}
                  {/*</Column>*/}
                  <Column style={{ flex: 2 }}>
                    <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                      {t('revenueMissed')}
                    </Label>
                    <Spacer size={4} />
                    <H3s>
                      {analytics.bookings.cancelledStats.income + analytics.bookings.noShowStats.income}{' '}
                      {t(prosDetails.all.total.unit)}
                    </H3s>
                  </Column>
                  <Column style={{ flex: 2 }}>
                    <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                      {t('revenueFuture')}
                    </Label>
                    <Spacer size={4} />
                    <H3s>
                      {analytics.bookings.futureStats.income} {t(prosDetails.all.total.unit)}
                    </H3s>
                  </Column>
                </Row>
                <Spacer size={16} />
              </Column>
            </FormCard>
            {/*<Spacer size={24} />*/}
            {/*<FormCard rounded paddings="none" dropShaddow>*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Row style={{ paddingHorizontal: 16 }}>*/}
            {/*    <Column style={{ flex: 1 }}>*/}
            {/*      <H2s>{analytics.totalIncome}%</H2s>*/}
            {/*      <Label>{t('occupancy')}</Label>*/}
            {/*    </Column>*/}
            {/*    <CalendarIcon />*/}
            {/*  </Row>*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Column style={{ paddingHorizontal: 16 }}>*/}
            {/*    <Line />*/}
            {/*  </Column>*/}
            {/*  <Spacer size={22} />*/}
            {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
            {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('allocatedTime')}</SmallBody>*/}
            {/*    <Row style={{ flex: 2 }}>*/}
            {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
            {/*    </Row>*/}
            {/*  </Row>*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
            {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('reservedTime')}</SmallBody>*/}
            {/*    <Row style={{ flex: 2 }}>*/}
            {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>{analytics.totalNewClients}%</SmallBody>*/}
            {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
            {/*    </Row>*/}
            {/*  </Row>*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
            {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('blockedTime')}</SmallBody>*/}
            {/*    <Row style={{ flex: 2 }}>*/}
            {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>{analytics.totalNewClients}%</SmallBody>*/}
            {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
            {/*    </Row>*/}
            {/*  </Row>*/}
            {/*  <Spacer size={16} />*/}
            {/*  <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>*/}
            {/*    <SmallBody style={{ flex: 2, fontFamily: 'open-sans-semibold' }}>{t('unreservedTime')}</SmallBody>*/}
            {/*    <Row style={{ flex: 2 }}>*/}
            {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>{analytics.totalNewClients}%</SmallBody>*/}
            {/*      <SmallBody style={{ flex: 3, textAlign: 'right' }}>48h 10m</SmallBody>*/}
            {/*    </Row>*/}
            {/*  </Row>*/}
            {/*  <Spacer size={32} />*/}
            {/*</FormCard>*/}
            <Spacer size={48} />
            <FormCard rounded paddings="none" dropShaddow style={{ width: formWidth, marginTop: 16, marginRight: 24 }}>
              <Spacer size={16} />
              <Column style={{ paddingHorizontal: 16 }}>
                <Title>{t('clients')}</Title>
                <Spacer size={4} />
                <H2s style={{ fontSize: 24 }}>
                  {analytics.clients.newClientStats.count +
                    analytics.clients.newFromProClientStats.count +
                    analytics.clients.existingClientStats.count}
                </H2s>
                <Spacer size={16} />
                <Line />
                <Spacer size={16} />
                <Row>
                  <Column style={{ flex: 2 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('newClientsOnline')}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>{analytics.clients.newClientStats.count}</H3s>
                  </Column>
                  <Column style={{ flex: 2 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('newClientsOffline')}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>{analytics.clients.newFromProClientStats.count}</H3s>
                  </Column>
                </Row>
                <Spacer size={16} />
                <Row justifyContent="space-between">
                  <Column style={{ flex: 2 }}>
                    <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.COMET, fontSize: 12 }}>
                      {t('returnedClients')}
                    </SmallBody>
                    <Spacer size={4} />
                    <H3s>{analytics.clients.existingClientStats.count}</H3s>
                  </Column>
                  <Column style={{ flex: 2 }}></Column>
                </Row>
              </Column>
              <Spacer size={16} />
            </FormCard>
            <FormCard rounded paddings="none" dropShaddow style={{ width: formWidth, marginTop: 16, marginRight: 24 }}>
              <Spacer size={16} />
              <Title style={{ paddingHorizontal: 16 }}>{t('top10Services')}</Title>
              <Spacer size={24} />
              <Row style={{ paddingHorizontal: 16, alignItems: 'center' }}>
                <Label style={{ fontFamily: 'open-sans-semibold', flex: 2, color: '#52577F' }}>{t('service')}</Label>
                <Row style={{ flex: 2 }}>
                  <Label style={{ fontFamily: 'open-sans-semibold', flex: 2, textAlign: 'right', color: '#52577F' }}>
                    {t('reservations')}
                  </Label>
                  <Label style={{ fontFamily: 'open-sans-semibold', flex: 2, textAlign: 'right', color: '#52577F' }}>
                    {t('value')}
                  </Label>
                </Row>
              </Row>
              <Spacer size={12} />
              <Column style={{ paddingHorizontal: 16 }}>
                {analytics.services.map((service, index) => (
                  <React.Fragment key={index}>
                    <Row key={index} style={{ alignItems: 'flex-start' }}>
                      <Row style={{ flex: 2, paddingRight: 12 }}>
                        <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{index + 1}. </SmallBody>
                        <SmallBody style={{ fontFamily: 'open-sans-semibold' }}>{service.name}</SmallBody>
                      </Row>
                      <Row style={{ flex: 2 }}>
                        <SmallBody style={{ flex: 2, textAlign: 'right' }}>{service.count}</SmallBody>
                        <SmallBody style={{ flex: 2, textAlign: 'right' }}>
                          {service.income} {t('currency').toLowerCase()}
                        </SmallBody>
                      </Row>
                    </Row>
                    <Spacer size={16} />
                  </React.Fragment>
                ))}
              </Column>
              <Spacer size={48} />
            </FormCard>
          </Row>
        </FormCard>
      </View>
    </ScrollView>
  );
};

export default Summary;
