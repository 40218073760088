import { Body, colors, Column, H1, MeroHeader, Text, Title } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import useGoBack from '../../../../../hooks/useGoBack';

import { IntercomContext } from '../../../../../contexts/IntercomContext';

const GiftCardsDetailsScreen: React.FC = ({}) => {
  const { t } = useTranslation('giftCards');

  const [, { openChat }] = IntercomContext.useContext();

  const goBack = useGoBack();

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('giftCards')} />
      <ScrollView style={{ paddingHorizontal: 24, flex: 1 }}>
        <H1 style={{ paddingTop: 16 }}>{t('giftCards')}</H1>
        <Column style={{ paddingTop: 16 }}>
          <Title>{t('q1Title')}</Title>
          <Body style={{ paddingTop: 6 }}>{t('q1Answer')}</Body>
        </Column>
        <Column style={{ paddingTop: 24 }}>
          <Title>{t('q2Title')}</Title>
          <Body style={{ paddingTop: 6 }}>{t('q2Answer')}</Body>
        </Column>
        <Column style={{ paddingTop: 24 }}>
          <Title>{t('q3Title')}</Title>
          <Body style={{ paddingTop: 6 }}>
            <Body style={{ paddingTop: 6 }}>
              <Trans ns={'giftCards'} t={t} i18nKey="q3Answer">
                0<Body style={{ textAlignVertical: 'center', fontFamily: 'open-sans-italic' }}>1</Body>
              </Trans>
            </Body>
          </Body>
        </Column>
        <Column style={{ paddingTop: 24 }}>
          <Title>{t('q4Title')}</Title>
          <Body style={{ paddingTop: 6 }}>{t('q4Answer')}</Body>
        </Column>
        <Column style={{ paddingTop: 24 }}>
          <Title>{t('q5Title')}</Title>
          <Body style={{ paddingTop: 6 }}>
            <Trans ns={'giftCards'} t={t} i18nKey="q5Answer">
              0
              <Text link semibold style={{ textAlignVertical: 'center' }} onPress={onContactUsPressed}>
                1
              </Text>
            </Trans>
          </Body>
        </Column>
      </ScrollView>
    </ModalScreenContainer>
  );
};

export default GiftCardsDetailsScreen;
