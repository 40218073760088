import { MeroSmsPageStatus } from '@mero/api-sdk';
import { PageId, PageMemberPreview, RootPageRole } from '@mero/api-sdk/dist/pages';
import {
  PageNotificationSettings,
  PageSmsNotificationSettings,
} from '@mero/api-sdk/dist/pages/page-notification-settings';
import {
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  Icon,
  Line,
  MeroHeader,
  Row,
  SafeAreaView,
  Select,
  SmallBody,
  Spacer,
  Title,
  styles as meroStyles,
  useToast,
} from '@mero/components';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { PageNotificationsContext } from '../../../../../contexts/PageNotificationsContext';
import { NotificationsSettingsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { styles } from '../PageGiftCardsSettingsScreen/BillingDetails.styles';
import DisableSmsDialog from './DisableSmsDialog';
import EnableSmsDialog from './EnableSmsDialog';

type Props = StackScreenProps<NotificationsSettingsStackParamList, 'NotificationsSettings'>;

const MarkedText: React.FC<{ color?: string; backgroundColor?: string } & React.PropsWithChildren> = ({
  color = colors.COMET,
  backgroundColor = colors.ATHENS_GRAY,
  children,
}) => (
  <Column style={{ flexDirection: 'row' }}>
    <Column style={{ backgroundColor, alignSelf: 'center', borderRadius: 4 }}>
      <SmallBody
        style={{
          color,
          paddingVertical: 4,
          paddingHorizontal: 8,
          fontSize: 12,
          fontFamily: 'open-sans-semibold',
        }}
      >
        {children}
      </SmallBody>
    </Column>
  </Column>
);

const REMINDERS_LIMIT = 2;

const units = [
  {
    unit: 'h',
    value: 2,
  },
  {
    unit: 'h',
    value: 24,
  },
  {
    unit: 'd',
    value: 2,
  },
  {
    unit: 'd',
    value: 3,
  },
  {
    unit: 'w',
    value: 1,
  },
  {
    unit: 'w',
    value: 2,
  },
] as const;

type Status = PageSmsNotificationSettings['type'];

const PageNotificationsSettingsScreen: React.FC<Props> = ({ navigation }) => {
  const [pageState] = CurrentBusinessContext.useContext();
  const [notificationsState, { updateState }] = PageNotificationsContext.useContext();

  const { t } = useTranslation('notifications');

  const goBack = useGoBack();
  const { isPhone } = useMediaQueries();
  const toast = useToast();

  const [smsStatus, setSmsStatus] = React.useState<Status | undefined>(undefined);

  const [clientAppointmentReminders, setClientAppointmentReminders] = React.useState<number[]>([]);

  const [meroSmsStatus, setMeroSmsStatus] = React.useState<MeroSmsPageStatus['status']>('Active');

  const [showEnableMeroSms, setShowEnableMeroSms] = React.useState(false);
  const [showEnableSms, setShowEnableSms] = React.useState(false);
  const [showDisableSms, setShowDisableSms] = React.useState(false);
  const [appointmentMadeByPage, setAppointmentMadeByPage] = React.useState(false);
  const [appointmentModified, setAppointmentModified] = React.useState(false);
  const [appointmentReviewReminder, setAppointmentReviewReminder] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const block = React.useRef(false);
  const savedState = React.useRef<PageSmsNotificationSettings | undefined>(undefined);
  const responseState = React.useRef<PageNotificationSettings | undefined>(undefined);

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      getNotificationSettings(pageState.page.details._id);
      getMeroSmsStatus(pageState.page.details._id);
    }
  }, [pageState.type]);

  const getNotificationSettings = async (pageId: PageId) => {
    try {
      const [notificationSettings, members] = await Promise.all([
        meroApi.pages.getPageNotificationSettings({ pageId }),
        meroApi.pages.getUsersForGlobalManualSms({ pageId }),
      ]);
      setSmsStatus(notificationSettings.sms.type);
      setAppointmentMadeByPage(notificationSettings.appointmentMadeByPage ?? false);
      setAppointmentModified(notificationSettings.appointmentModified ?? false);
      setAppointmentReviewReminder(notificationSettings.appointmentReviewReminder);

      savedState.current = notificationSettings.sms;
      responseState.current = notificationSettings;

      if (notificationSettings.sms.type === 'ManualSms') {
        updateState(notificationSettings.sms.sender);
      } else {
        const user = members.find((m) => m.roles.some((r) => r.id === RootPageRole.value)) ?? members[0];
        setSelectedSender(user);
        updateState({ type: 'global', userId: user.user._id });
      }

      const reminders = notificationSettings.clientAppointmentReminders.map((reminder) =>
        units.findIndex((unit) => unit.unit === reminder.unit && unit.value === reminder.value),
      );

      setClientAppointmentReminders(reminders);
    } catch (error) {
      log.error('Failed to get notification settings', error);
    }
  };

  const getMeroSmsStatus = async (pageId: PageId) => {
    try {
      const status = await meroApi.notifications.getMeroSmsStatusForPage(pageId);

      setMeroSmsStatus(status.status);
    } catch (error) {
      log.error('Failed to get mero sms status', error);
    }
  };

  const toggleSmsStatus = (status: Status) => () => {
    if (status === smsStatus) {
      setSmsStatus('Off');
    } else {
      setSmsStatus(status);
    }
  };

  const downloadMeroSms = async () => {
    try {
      await WebBrowser.openBrowserAsync(config.meroSmsUrl);
    } catch (error) {
      log.error(`Failed to open browser`, { error });
    }
  };

  const intervals = React.useMemo(() => {
    return [
      {
        label: t('2h'),
        value: 0,
      },
      {
        label: t('24h'),
        value: 1,
      },
      {
        label: t('2d'),
        value: 2,
      },
      {
        label: t('3d'),
        value: 3,
      },
      {
        label: t('1w'),
        value: 4,
      },
      {
        label: t('2w'),
        value: 5,
      },
    ];
  }, []);

  const updateNotificationSettings = async ({
    pageId,
    sms,
    appointmentReviewReminder,
    appointmentMadeByPage,
    appointmentModified,
  }: {
    pageId: PageId;
    sms: PageSmsNotificationSettings;
    appointmentReviewReminder: boolean;
    appointmentMadeByPage: boolean;
    appointmentModified: boolean;
  }) => {
    setIsLoading(true);
    try {
      await meroApi.pages.updatePageNotificationSettings({
        pageId,
        settings: {
          sms,
          clientAppointmentReminders: clientAppointmentReminders.map((reminder) => units[reminder]),
          meroSmsSendForInvitedWorkers: Boolean(responseState.current?.meroSmsSendForInvitedWorkers),
          appointmentReviewReminder,
          appointmentMadeByPage,
          appointmentModified,
        },
      });
      await getNotificationSettings(pageId);
      toast.show({
        type: 'success',
        text: t('updateSuccess'),
      });
    } catch (error: any) {
      log.error('Failed to update notification settings', error);
      toast.show({
        type: 'error',
        text: error.message || t('updateError'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onEnableMeroSms = () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    updateNotificationSettings({
      pageId: pageState.page.details._id,
      sms: {
        type: 'MeroSmsApp',
      },
      appointmentMadeByPage,
      appointmentModified,
      appointmentReviewReminder,
    });
    setShowEnableMeroSms(false);
  };

  const onCancelEnableMeroSms = () => {
    setShowEnableMeroSms(false);
  };

  const onEnableSms = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    updateNotificationSettings({
      pageId: pageState.page.details._id,
      sms: {
        type: 'MeroCloudSms',
      },
      appointmentMadeByPage: appointmentMadeByPage,
      appointmentModified: appointmentModified,
      appointmentReviewReminder,
    });

    setShowEnableSms(false);
  };

  const onCancelEnableSms = () => {
    setShowEnableSms(false);
  };

  const onDisableSms = () => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    updateNotificationSettings({
      pageId: pageState.page.details._id,
      sms: {
        type: 'Off',
      },
      appointmentMadeByPage: appointmentMadeByPage,
      appointmentModified: appointmentModified,
      appointmentReviewReminder,
    });

    setShowDisableSms(false);
  };

  const onCancelDisableSms = () => {
    setShowDisableSms(false);
  };

  const saveChanges = async () => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    if (savedState.current?.type !== 'MeroCloudSms' && smsStatus === 'MeroCloudSms') {
      setShowEnableSms(true);
      return;
    }

    if (savedState.current?.type !== 'Off' && smsStatus === 'Off') {
      setShowDisableSms(true);
      return;
    }

    try {
      await updateNotificationSettings({
        pageId: pageState.page.details._id,
        sms:
          smsStatus !== 'ManualSms' ? { type: smsStatus || 'Off' } : { type: 'ManualSms', sender: notificationsState },
        appointmentMadeByPage: appointmentMadeByPage,
        appointmentModified: appointmentModified,
        appointmentReviewReminder,
      });

      await getMeroSmsStatus(pageState.page.details._id);
    } catch (error) {
      log.error('Failed to update notification settings', error);
    }
  };

  const addReminder = () => {
    if (clientAppointmentReminders.length >= REMINDERS_LIMIT) {
      return;
    }

    // use an option that isn't already used
    // to avoid accidental duplicates
    const unusedIntervals = intervals.filter((i) => !clientAppointmentReminders.includes(i.value));
    const nextOption = unusedIntervals[0]?.value ?? 0;

    setClientAppointmentReminders((value) => [...value, nextOption]);
  };

  const changeReminder = (index: number) => (value: number) => {
    setClientAppointmentReminders((reminders) => {
      return reminders.map((reminder, i) => (i === index ? value : reminder));
    });
  };

  const removeReminder = (index: number) => () => {
    setClientAppointmentReminders((value) => value.filter((_, i) => i !== index));
  };

  /**
   * Filter intervals for a given reminder option.
   * Will remove ones already selected by other options.
   */
  const getUnusedIntervals = (currentValue: number) => {
    const usedOptions = [...clientAppointmentReminders];
    return intervals.filter((i) => (i.value === currentValue ? true : !usedOptions.includes(i.value)));
  };

  const [selectedSender, setSelectedSender] = React.useState<PageMemberPreview | undefined>();

  React.useEffect(() => {
    const loadMembers = async () => {
      if (pageState.type !== 'Loaded') {
        return undefined;
      }

      if (notificationsState.type === 'individual') {
        setSelectedSender(undefined);
      } else {
        const members = await meroApi.pages.getUsersForGlobalManualSms({ pageId: pageState.page.details._id });
        setSelectedSender(members.find((m) => m.user._id === notificationsState.userId));
      }
    };

    loadMembers().catch((error) => log.error('Failed to load members', error));
  }, [pageState, notificationsState]);

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        <MeroHeader
          canGoBack
          onBack={goBack}
          title={t('notifications')}
          RightComponent={
            <TouchableOpacity
              style={{ justifyContent: 'center', height: 50, marginRight: 16 }}
              onPress={() => navigation.navigate('NotificationsMessagesFormat')}
            >
              <SmallBody style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>
                {t('customMessages')}
              </SmallBody>
            </TouchableOpacity>
          }
        />
        <ScrollView style={{ flex: 1, paddingHorizontal: 16, marginBottom: 96 }}>
          <H1 style={{ paddingTop: 16, paddingHorizontal: 8 }}>{t('notificationsSettings')}</H1>
          <Spacer size={16} />
          {smsStatus === 'Off' ? (
            <Column
              style={{
                backgroundColor: '#FEF6EB',
                padding: 16,
                borderRadius: 4,
              }}
            >
              <Row style={{ alignItems: 'center' }}>
                <Column>
                  <Icon type="warn" color="#FBAC40" />
                </Column>
                <Column>
                  <SmallBody style={{ fontFamily: 'open-sans-bold', paddingLeft: 8 }}>
                    {t('smsNotificationDisabled')}
                  </SmallBody>
                </Column>
              </Row>
              <Column style={{ marginLeft: 32 }}>
                <SmallBody>{t('smsNotificationDisabledDetails')}</SmallBody>
              </Column>
            </Column>
          ) : null}
          <Spacer size={16} />
          <FormCard dropShaddow rounded paddings="none">
            <H3s style={{ padding: 16 }}>{t('selectNotification')}</H3s>
            <Line />
            <Row style={{ paddingHorizontal: 16, paddingTop: 24 }}>
              <Column>
                <Checkbox
                  value={smsStatus === 'MeroCloudSms'}
                  color="blue"
                  disabled={false}
                  onValueChange={toggleSmsStatus('MeroCloudSms')}
                />
              </Column>
              <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={toggleSmsStatus('MeroCloudSms')}>
                <Title>{t('titleSms')}</Title>
                <Spacer size={4} />
                <MarkedText>{t('smsCost').toUpperCase()}</MarkedText>
                <Spacer size={8} />
                <SmallBody style={{ color: colors.BLACK }}>
                  <Trans ns={'notifications'} t={t} i18nKey="smsCostDetails">
                    0
                  </Trans>
                </SmallBody>
              </TouchableOpacity>
            </Row>
            <Spacer size={16} />
            <Column style={{ paddingHorizontal: 16 }}>
              <Line />
            </Column>
            <Spacer size={16} />
            <Row style={{ paddingHorizontal: 16 }}>
              <Column>
                <Checkbox
                  value={smsStatus === 'MeroSmsApp'}
                  color="blue"
                  disabled={false}
                  onValueChange={toggleSmsStatus('MeroSmsApp')}
                />
              </Column>
              <Column style={{ paddingLeft: 12, flex: 1 }}>
                <TouchableOpacity onPress={toggleSmsStatus('MeroSmsApp')}>
                  <Title>{t('androidSmsApp')}</Title>
                  <Spacer size={4} />
                  {savedState.current?.type === 'MeroSmsApp' && smsStatus === 'MeroSmsApp' ? (
                    meroSmsStatus === 'Active' ? (
                      <MarkedText color={colors.SHAMROCK} backgroundColor="#E9FAF3">
                        {t('androidNotificationsEnabled').toUpperCase()}
                      </MarkedText>
                    ) : meroSmsStatus === 'Offline' ? (
                      <MarkedText color={colors.RADICAL_RED} backgroundColor="#FFE1E1">
                        {t('androidNotificationsOffline').toUpperCase()}
                      </MarkedText>
                    ) : (
                      <MarkedText color={colors.RADICAL_RED} backgroundColor="#FFE1E1">
                        {t('androidNotificationsDisabled').toUpperCase()}
                      </MarkedText>
                    )
                  ) : (
                    <MarkedText>{t('androidNotificationsUnchecked').toUpperCase()}</MarkedText>
                  )}
                  <Spacer size={8} />
                  <SmallBody style={{ color: colors.BLACK }}>{t('androidSmsAppDetails')}</SmallBody>
                </TouchableOpacity>
                <Spacer size={16} />
                <TouchableOpacity
                  onPress={downloadMeroSms}
                  style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <SmallBody style={{ color: colors.DARK_BLUE, fontFamily: 'open-sans-semibold' }}>
                    {t('androidSmsAppStep1')}
                  </SmallBody>
                  <Icon type="next" color={colors.DARK_BLUE} />
                </TouchableOpacity>
                <Spacer size={4} />
              </Column>
            </Row>
            <Spacer size={16} />
            <Column style={{ paddingHorizontal: 16 }}>
              <Line />
            </Column>
            <Spacer size={16} />
            <Row style={{ paddingHorizontal: 16 }}>
              <Column>
                <Checkbox
                  value={smsStatus === 'ManualSms'}
                  color="blue"
                  disabled={false}
                  onValueChange={toggleSmsStatus('ManualSms')}
                />
              </Column>
              <TouchableOpacity style={{ paddingLeft: 12, flex: 1 }} onPress={toggleSmsStatus('ManualSms')}>
                <Title>{t('semiAutomaticTitle')}</Title>
                <Spacer size={4} />
                <SmallBody style={{ color: colors.BLACK }}>{t('semiAutomaticDetails')}</SmallBody>

                <Spacer size={12} />
                <TouchableOpacity
                  style={{
                    paddingVertical: 12,
                    paddingLeft: 12,
                    paddingRight: 8,
                    borderWidth: 1,
                    borderColor: colors.GEYSER,
                    borderRadius: 4,
                  }}
                  disabled={smsStatus !== 'ManualSms'}
                  onPress={() => {
                    navigation.navigate('NotificationsSettingsManualMethod');
                  }}
                >
                  <Row>
                    <Column style={{ flex: 1 }}>
                      <Title style={{ color: colors.BLACK }}>
                        {selectedSender ? t('semiAutomaticGlobal') : t('semiAutomaticIndividual')}
                      </Title>
                      {selectedSender ? (
                        <>
                          <SmallBody style={{ color: colors.BLACK }}>
                            {selectedSender.user.profile.firstname} {selectedSender.user.profile.lastname}
                          </SmallBody>
                          <SmallBody style={{ color: colors.COMET }}>{selectedSender.user.phone}</SmallBody>
                        </>
                      ) : (
                        <SmallBody style={{ color: colors.COMET }}>{t('semiAutomaticIndividualDescription')}</SmallBody>
                      )}
                    </Column>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </Row>
                </TouchableOpacity>
              </TouchableOpacity>
            </Row>
            <Spacer size={24} />
          </FormCard>
          <Spacer size={24} />
          <FormCard dropShaddow rounded paddings="none">
            <H3s style={{ padding: 16 }}>{t('smsNotifications')}</H3s>
            <Line />
            <Spacer size={24} />
            <Column style={{ paddingHorizontal: 16 }}>
              <Row style={{ paddingTop: 8 }}>
                <Column>
                  <Checkbox value={true} disabled={true} />
                </Column>
                <Column style={{ paddingLeft: 12, flex: 1 }}>
                  <Body>{t('smsNotifications1')}</Body>
                </Column>
              </Row>
              <Spacer size={16} />
              <Row>
                <Column>
                  <Checkbox value={true} disabled={true} />
                </Column>
                <Column style={{ paddingLeft: 12, flex: 1 }}>
                  <Body>{t('smsNotifications2')}</Body>
                </Column>
              </Row>
              <Spacer size={16} />
              <Row>
                <Column>
                  <Checkbox value={true} disabled={true} />
                </Column>
                <Column style={{ paddingLeft: 12, flex: 1 }}>
                  <Body>{t('smsNotifications3')}</Body>
                  {clientAppointmentReminders.map((reminder, index) => (
                    <>
                      <Spacer size={8} />
                      <Row
                        style={{
                          flex: 1,
                          alignItems: 'center',
                          ...(clientAppointmentReminders.length > 1
                            ? { justifyContent: 'center', paddingHorizontal: 16 }
                            : {}),
                        }}
                      >
                        <Select
                          items={getUnusedIntervals(reminder)}
                          value={reminder}
                          onChange={changeReminder(index)}
                        />
                        {clientAppointmentReminders.length > 1 && (
                          <TouchableOpacity style={{ paddingLeft: 8 }} onPress={removeReminder(index)}>
                            <Icon type="close" color={colors.DARK_BLUE} />
                          </TouchableOpacity>
                        )}
                      </Row>
                    </>
                  ))}
                  {clientAppointmentReminders.length < REMINDERS_LIMIT && (
                    <>
                      <Spacer size={16} />
                      <SmallBody
                        onPress={addReminder}
                        style={{ color: colors.DARK_BLUE, fontFamily: 'open-sans-semibold' }}
                      >
                        Adaugă încă o notificare de reamintire
                      </SmallBody>
                    </>
                  )}
                  <Spacer size={24} />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Checkbox value={appointmentMadeByPage} disabled={false} onValueChange={setAppointmentMadeByPage} />
                </Column>
                <TouchableOpacity
                  style={{ paddingLeft: 12, flex: 1 }}
                  onPress={() => setAppointmentMadeByPage((prev) => !prev)}
                >
                  <Body>{t('smsNotifications4')}</Body>
                </TouchableOpacity>
              </Row>
              <Spacer size={16} />
              <Row>
                <Column>
                  <Checkbox value={appointmentModified} disabled={false} onValueChange={setAppointmentModified} />
                </Column>
                <TouchableOpacity
                  style={{ paddingLeft: 12, flex: 1 }}
                  onPress={() => setAppointmentModified((prev) => !prev)}
                >
                  <Body>{t('smsNotifications5')}</Body>
                </TouchableOpacity>
              </Row>
              <Spacer size={16} />
              <Row>
                <Column>
                  <Checkbox
                    value={appointmentReviewReminder}
                    disabled={false}
                    onValueChange={setAppointmentReviewReminder}
                  />
                </Column>
                <TouchableOpacity
                  style={{ paddingLeft: 12, flex: 1 }}
                  onPress={() => setAppointmentReviewReminder((prev) => !prev)}
                >
                  <Body>{t('smsNotifications6')}</Body>
                </TouchableOpacity>
              </Row>
              <Spacer size={32} />
            </Column>
          </FormCard>
        </ScrollView>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            <Button disabled={block.current || isLoading} text="Salvează modificări" onClick={saveChanges} />
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
      {showEnableSms ? <EnableSmsDialog onSuccess={onEnableSms} onCancel={onCancelEnableSms} /> : null}
      {showDisableSms ? <DisableSmsDialog onSuccess={onDisableSms} onCancel={onCancelDisableSms} /> : null}
    </>
  );
};

export default PageNotificationsSettingsScreen;
