import { Order } from '@mero/api-sdk/dist/payments/order';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  Icon,
  Label,
  Line,
  MeroHeader,
  Row,
  sizes,
  SmallBody,
  Spacer,
  Title,
  useToast,
} from '@mero/components';
import { flow, pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Linking, ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { images } from '../../../../../constants/images';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { BillingDetailsProps, withBillingDetails } from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusinessProps, CurrentBusiness } from '../../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import {
  SubscriptionContext,
  SubscriptionInfoProps,
  withSubscriptionInfo,
} from '../../../../../contexts/SubscriptionContext';
import { SubscriptionStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { nameGenerator } from '../../../../../utils/string';
import ConfirmCancelAutoCharge from './ConfirmCancelAutoCharge';
import ProformaDetails from './ProformaDetails';
import SubscriptionValidity from './SubscriptionValidity';

type Props = StackScreenProps<SubscriptionStackParamList, 'Subscription'> &
  CurrentBusinessProps &
  SubscriptionInfoProps &
  BillingDetailsProps & {
    // pass
  };

const PageSubscriptionSettingsScreen: React.FC<Props> = ({
  page,
  subscriptionInfo,
  invoiceArchive,
  billingDetails,
  navigation,
}: Props) => {
  const { t } = useTranslation('subscription');
  const goBack = useGoBack();
  const toast = useToast();
  const isFocused = useIsFocused();

  const [, { reload, reloadAsync }] = SubscriptionContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();
  const [pendingPaymentRetrial, setPendingPaymentRetrial] = React.useState(1);

  const [orders, setOrders] = React.useState<Order[]>([]);
  const [showCancelAutoCharge, setShowCancelAutoCharge] = React.useState(false);
  const [pendingPayment, setPendingPayment] = React.useState(false);

  const goToBillingDetails = React.useCallback(() => {
    navigation.navigate('BillingDetailsList');
  }, [navigation]);

  const downloadInvoice = (url: string) => async () => {
    try {
      await Linking.openURL(url);
    } catch (error) {
      toast.show({
        type: 'error',
        text: t('downloadInvoiceError'),
      });
    }
  };

  const cancelAutomaticRenewal = async () => {
    try {
      await meroApi.payments.setSubscriptionAutoCharge({
        subscriptionId: subscriptionInfo._id,
        autoCharge: false,
      });

      reload({ pageId: page.details._id });
    } catch (error) {
      log.error('Error canceling automatic renewal', error);
      toast.show({
        type: 'error',
        text: t('cancelAutomaticRenewalError'),
      });
    }
  };

  const getPendingOrders = async () => {
    try {
      const response = await meroApi.payments.listPendingOrders(subscriptionInfo._id);
      setOrders(response);
    } catch (error) {
      toast.show({
        type: 'error',
        text: t('errorGettingPendingOrders'),
      });
    }
  };

  const checkPaymentStatus = async () => {
    if (orders.length === 0) {
      setPendingPayment(false);
      return;
    }

    const lastSuccessfulPayment = await AppStorage.getLastPaymentId();
    const orderId = orders[0]._id;

    setPendingPayment(orderId === lastSuccessfulPayment);
  };

  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  React.useEffect(() => {
    checkPaymentStatus();
  }, [orders, isFocused]);

  React.useEffect(() => {
    if (isFocused) {
      reloadAsync({ pageId: page.details._id });
    }
    getPendingOrders();
  }, [isFocused]);

  React.useEffect(() => {
    let timeout = 0;

    if (pendingPayment && pendingPaymentRetrial < 5) {
      timeout = window.setTimeout(() => {
        getPendingOrders();
        setPendingPaymentRetrial((prev) => prev + 1);
      }, 250 * pendingPaymentRetrial);
    } else if (!pendingPayment && pendingPaymentRetrial > 1) {
      reloadAsync({ pageId: page.details._id, withoutDelay: true });
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [pendingPayment, pendingPaymentRetrial]);

  const subscriptionBillingDetails = React.useMemo(
    () => billingDetails.find((b) => b.usage?.includes('MeroSubscription')),
    [billingDetails],
  );

  const payWithCreditCard = async () => {
    if (!order) {
      return;
    }
    if (!subscriptionBillingDetails) {
      return navigation.navigate('BillingDetailsList', { then: 'payWithCard', meroCompany: order.meroCompany });
    } else {
      return navigation.navigate('CardPayment', { meroCompany: order.meroCompany });
    }
  };

  const seeAltPaymentMethods = () => {
    if (!order) {
      return;
    }

    if (!subscriptionBillingDetails) {
      return navigation.navigate('BillingDetailsList', { then: 'choosePaymentMethod', meroCompany: order.meroCompany });
    }

    navigation.navigate('PaymentMethod', { meroCompany: order.meroCompany });
  };

  const order = orders.length > 0 ? orders[0] : undefined;
  const now = new Date();
  const isAutoChargeOn = subscriptionInfo.autoCharge ?? false;
  const paymentExpires = subscriptionInfo.paymentExpires;
  const isExpired = paymentExpires.getTime() < now.getTime();
  const isAboutToExpire = React.useMemo(
    () => !isExpired && paymentExpires.getTime() - now.getTime() < 48 * 3600 * 1000,
    [isExpired, paymentExpires, now],
  );
  const isPositiveTotal = (order?.total ?? 0) > 0;

  const getLabel = () => {
    if (pendingPayment) {
      return (
        <Column
          style={{
            backgroundColor: colors.YELLOW_ORANGE,
            paddingHorizontal: 16,
            paddingVertical: 4,
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
          }}
        >
          <Label style={{ color: colors.WHITE }}>{t('pendingPayment')}</Label>
        </Column>
      );
    }

    if (isExpired && subscriptionInfo.autoChargeError) {
      return (
        <Column
          style={{
            backgroundColor: colors.RADICAL_RED,
            paddingHorizontal: 16,
            paddingVertical: 4,
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
          }}
        >
          <Label style={{ color: colors.WHITE }}>
            {t('automaticRenewalError')}
            {subscriptionInfo.autoChargeError ? `: ${subscriptionInfo.autoChargeError}` : ''}
          </Label>
        </Column>
      );
    }

    if (!isExpired && isPositiveTotal && isAboutToExpire && !isAutoChargeOn) {
      return (
        <Column
          style={{
            backgroundColor: colors.OUTRAGEOUS_ORANGE,
            paddingHorizontal: 16,
            paddingVertical: 4,
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
          }}
        >
          <Label style={{ color: colors.WHITE }}>{t('payToAvoidAccessRestrictions')}</Label>
        </Column>
      );
    }

    return null;
  };

  // <SubscriptionSettings />
  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('subscriptionAndBilling')} />
      <Column style={{ paddingTop: 16, flex: 1 }}>
        <H1 style={{ paddingHorizontal: 24 }}>{t('subscriptionAndBilling')}</H1>
        <ScrollView style={{ marginTop: 32, borderRadius: 6, flex: 1 }}>
          <SubscriptionValidity subscriptionInfo={subscriptionInfo} now={now} onContactUsPressed={onContactUsPressed} />

          {isPositiveTotal && (
            <>
              <Spacer size={sizes[24]} />

              <FormCard
                dropShaddow
                paddings="none"
                style={{ marginLeft: sizes[16], marginRight: sizes[16], padding: 0 }}
                rounded
              >
                {getLabel()}

                <Spacer size={sizes[16]} />

                <Column>
                  {order ? <ProformaDetails order={order} subscriptionInfo={subscriptionInfo} now={now} /> : null}
                </Column>
              </FormCard>

              {/*{pendingPayment || (subscriptionInfo.autoCharge && !subscriptionInfo.autoChargeError) ? null : (*/}
              {/*  <>*/}
              {/*    <Line />*/}
              {/*    <Spacer size={sizes[16]} />*/}
              {/*  </>*/}
              {/*)}*/}

              {pendingPayment ? null : subscriptionInfo.autoCharge && !subscriptionInfo.autoChargeError ? null : (
                <>
                  <Spacer size={32} />
                  <Column style={{ paddingHorizontal: 16 }}>
                    <Button text={t('payOnline')} onPress={payWithCreditCard} disabled={order?.total === 0} />
                    <Spacer size={8} />
                    <Button
                      onPress={seeAltPaymentMethods}
                      backgroundColor={colors.ALABASTER}
                      color={colors.DARK_BLUE}
                      text={t('otherPaymentMethods')}
                    />
                  </Column>
                </>
              )}
            </>
          )}

          {subscriptionInfo.autoCharge && !subscriptionInfo.autoChargeError ? (
            <Column style={{ paddingHorizontal: 16, paddingTop: 24 }}>
              <Button
                onPress={() => setShowCancelAutoCharge(true)}
                backgroundColor={colors.ALABASTER}
                color={colors.DARK_BLUE}
                text={t('cancelAutoRenewal')}
              />
            </Column>
          ) : null}

          <Column style={{ backgroundColor: colors.WHITE, marginTop: 46, paddingHorizontal: 24, flex: 1 }}>
            <H1 style={{ paddingTop: 32, paddingBottom: 24 }}>{t('billing')}</H1>
            {subscriptionBillingDetails ? (
              <TouchableOpacity
                style={{
                  marginBottom: 24,
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 12,
                  paddingLeft: 12,
                  paddingRight: 8,
                  flexDirection: 'row',
                }}
                onPress={goToBillingDetails}
              >
                <Column style={{ flex: 1 }}>
                  <Title>{t('billingInfo')}</Title>
                  <SmallBody>
                    {subscriptionBillingDetails.billing.type === 'Company'
                      ? subscriptionBillingDetails.billing.name
                      : nameGenerator(subscriptionBillingDetails.billing, t('noName'))}
                  </SmallBody>
                  <SmallBody style={{ color: colors.COMET }}>
                    {subscriptionBillingDetails.billing.type === 'Company'
                      ? subscriptionBillingDetails.billing.contact.email
                      : subscriptionBillingDetails.billing.email}
                  </SmallBody>
                </Column>
                <Icon type="arrow-right" />
              </TouchableOpacity>
            ) : (
              <Column
                style={{
                  marginBottom: 24,
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 12,
                  paddingLeft: 12,
                  paddingRight: 8,
                }}
              >
                <TouchableOpacity onPress={goToBillingDetails}>
                  <Row style={{ alignItems: 'center' }}>
                    <Title style={{ flex: 1 }}>{t('billingInfo')}</Title>
                    <Icon type="arrow-right" />
                  </Row>
                  <Body style={{ flex: 1, color: colors.COMET }}>{t('missingBillingInfo')}</Body>
                </TouchableOpacity>
              </Column>
            )}
            <Line />
            {invoiceArchive.length > 0 ? (
              invoiceArchive.map((invoice) => (
                <React.Fragment key={invoice._id}>
                  <TouchableOpacity style={{ paddingVertical: 16, flexDirection: 'row', alignItems: 'center' }}>
                    <Column style={{ padding: 4 }}>
                      <Image source={images.invoice} style={{ width: 24, height: 24 }} />
                    </Column>
                    <Title style={{ paddingLeft: 16, flex: 1 }}>
                      Fact. {invoice.tracking.number} / {DateTime.fromJSDate(invoice.createdAt).toFormat('dd.LL.yyyy')}
                    </Title>
                    <Button
                      onPress={downloadInvoice(invoice.downloadUrl)}
                      backgroundColor={colors.WHITE}
                      color={colors.DARK_BLUE}
                      expand={false}
                      text={t('download')}
                    />
                  </TouchableOpacity>
                  <Line />
                </React.Fragment>
              ))
            ) : (
              <Column style={{ paddingTop: 32, alignItems: 'center', paddingBottom: 32 }}>
                <H2s style={{ textAlign: 'center' }}>{t('noBills')}</H2s>
                <Body style={{ textAlign: 'center' }}>{t('noBillsText')}</Body>
              </Column>
            )}
          </Column>
        </ScrollView>
      </Column>
      {showCancelAutoCharge && (
        <ConfirmCancelAutoCharge
          onConfirm={flow(() => setShowCancelAutoCharge(false), cancelAutomaticRenewal)}
          onDismiss={() => setShowCancelAutoCharge(false)}
        />
      )}
    </ModalScreenContainer>
  );
};

export default pipe(PageSubscriptionSettingsScreen, withBillingDetails, withSubscriptionInfo, CurrentBusiness);
