import { colors, Icon } from '@mero/components';
import * as React from 'react';
import { View } from 'react-native';

type Props = {
  readonly hasGiftCards: boolean | undefined;
  readonly hasDiscounts: boolean | undefined;
};

const DiscountIcons: React.FC<Props> = ({ hasGiftCards = false, hasDiscounts = false }) => {
  return (
    <>
      {hasGiftCards && (
        <View style={{ marginRight: 4 }}>
          <Icon type="calendar-gift-card" color={colors.BLACK} />
        </View>
      )}
      {hasDiscounts && (
        <View style={{ marginRight: 4 }}>
          <Icon type="calendar-discount" color={colors.BLACK} />
        </View>
      )}
    </>
  );
};

export default DiscountIcons;
