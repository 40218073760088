import * as MeroApi from '@mero/api-sdk';
import { Price } from '@mero/api-sdk/dist/services';
import { colors, Column, Icon, Line, Row, SmallBody, Spacer, Title } from '@mero/components';
import * as luxon from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles } from './styles';

export type Props = {
  /**
   * Appointment to display.
   */
  appointment: MeroApi.calendar.UserAppointment;
  compact?: boolean;
};

export const priceSumFormatter = (price: MeroApi.services.Price) => {
  if (price.type === 'fixed') {
    return `${price.promo ?? price.fixed} lei`;
  } else if (price.type === 'range') {
    if (typeof price.rangePromo?.from !== 'undefined' && typeof price.rangePromo?.to !== 'undefined') {
      if (price.rangePromo.from === price.rangePromo.to) return `${price.rangePromo.from} lei`;
      return `${price.rangePromo.from} - ${price.rangePromo.to} lei`;
    }
    if (typeof price.range.from === 'undefined' || typeof price.range.to === 'undefined') return 'preț variabil';
    if (price.range.from === price.range.to) return `${price.range.from} lei`;
    return `${price.range.from} - ${price.range.to} lei`;
  }
  return `preț variabil`;
};

export const priceAfterDiscounts = (price: Price, discounts = 0): Price => {
  switch (price.type) {
    case 'fixed':
      return {
        ...price,
        ...(price.promo ? { promo: price.promo - discounts > 0 ? price.promo - discounts : 0 } : {}),
        fixed: price.fixed - discounts > 0 ? price.fixed - discounts : 0,
      };
    case 'range':
      return {
        ...price,
        ...(price.range && price.range.from && price.range.to
          ? {
              range: {
                from: price.range.from - discounts > 0 ? price.range.from - discounts : 0,
                to: price.range.to - discounts > 0 ? price.range.to - discounts : 0,
              },
            }
          : {}),
        ...(price.rangePromo && price.rangePromo.from && price.rangePromo.to
          ? {
              rangePromo: {
                from: price.rangePromo.from - discounts > 0 ? price.rangePromo.from - discounts : 0,
                to: price.rangePromo.to - discounts > 0 ? price.rangePromo.to - discounts : 0,
              },
            }
          : {}),
      };
    case 'hidden':
      return price;
  }
};
/**
 * Componet used to display summary of an user appointment.
 * Summary includes services names, price, worker name and times
 */
export default function UserAppointmentSummary({ appointment, compact = true }: Props): React.ReactElement {
  const [servicesText, setServicesText] = React.useState('');
  const [workerNameText, setWorkerNameText] = React.useState('');
  const [appointmentDateText, setAppointmentDateText] = React.useState('');
  const [priceText, setPriceText] = React.useState('');
  const [discountedPricePaid, setDiscountedPricePaid] = React.useState(0);

  // init method to extract info strings from appointment
  React.useEffect(() => {
    let servicesNames = appointment.bookedServices[0]?.name ?? '';
    if (appointment.bookedServices.length > 1) {
      servicesNames += ` (+ alte ${appointment.bookedServices.length - 1})`;
    }
    setServicesText(servicesNames);
    setWorkerNameText(`${appointment.worker.firstname} ${appointment.worker.lastname ?? ''}`);

    const from = luxon.DateTime.fromJSDate(appointment.start).setZone(appointment.timezone);
    const to = luxon.DateTime.fromJSDate(appointment.end).setZone(appointment.timezone);
    setAppointmentDateText(`${from.toFormat('HH:mm')} - ${to.toFormat('HH:mm')} (${to.diff(from).toFormat('mm')} min)`);

    const discounts =
      (appointment.giftCards?.reduce((acc, { price }) => acc + price, 0) ?? 0) +
      (appointment.discounts?.reduce((acc, { fixedAmount }) => acc + fixedAmount, 0) ?? 0);

    // compute total price
    const totalPrice = appointment.bookedServices
      .map((sv) => sv.price)
      .reduce((sum, price) => MeroApi.services.price.sum(sum, price));

    setPriceText(priceSumFormatter(totalPrice));

    if (totalPrice.type === 'fixed') {
      setDiscountedPricePaid(Math.max(totalPrice.fixed - discounts, 0));
    }
  }, []);

  const hasGiftCards = (appointment.giftCards?.length ?? 0) > 0;
  const hasDiscounts = (appointment.discounts?.length ?? 0) > 0;

  return (
    <>
      <View style={[styles.container]}>
        {!compact && (hasGiftCards || hasDiscounts) && (
          <Column>
            <Icon color={colors.COMET} type="checked-outline" />
          </Column>
        )}

        {/* info */}
        <View style={[styles.infoContainer, { paddingLeft: compact || (!hasGiftCards && !hasDiscounts) ? 0 : 8 }]}>
          <Title>{servicesText}</Title>

          <SmallBody>{workerNameText}</SmallBody>
          <Spacer size="6" />
          <SmallBody style={styles.dateLabel}>{appointmentDateText}</SmallBody>
        </View>

        {/* prices */}
        <View style={[styles.priceContainer]}>
          {compact && (hasGiftCards || hasDiscounts) ? (
            <>
              <Title>{discountedPricePaid} lei</Title>
              <SmallBody style={{ color: colors.COMET, fontFamily: 'open-sans', textDecorationLine: 'line-through' }}>
                {priceText}
              </SmallBody>
            </>
          ) : (
            <Title>{priceText}</Title>
          )}
        </View>
      </View>
      {!compact && hasGiftCards && (
        <>
          {appointment.giftCards?.map((giftCard) => (
            <Row key={giftCard.code} style={styles.giftCard}>
              <Column>
                <Icon color={colors.COMET} type="gift" />
              </Column>
              <Column style={styles.giftCardText}>
                <Title>Card cadou {giftCard.displayCode.toUpperCase()}</Title>
              </Column>
              <Column>
                <Title>-{giftCard.price} lei</Title>
              </Column>
            </Row>
          ))}
        </>
      )}
      {!compact && hasDiscounts && (
        <>
          {appointment.discounts?.map((discount) => (
            <Row key={discount.code} style={styles.discount}>
              <Column>
                <Icon color={colors.COMET} type="discount" />
              </Column>
              <Column style={styles.discountText}>
                <Title>Cod reducere {discount.code.toUpperCase()}</Title>
              </Column>
              <Column>
                <Title>-{discount.fixedAmount} lei</Title>
              </Column>
            </Row>
          ))}
        </>
      )}
      {!compact && (hasGiftCards || hasDiscounts) && (
        <>
          <Spacer size={16} />
          <Line />
          <Row style={{ paddingTop: 16 }}>
            <Title style={{ fontFamily: 'open-sans-bold', flex: 1 }}>Total</Title>
            <Title style={{ fontFamily: 'open-sans-bold' }}>{discountedPricePaid} lei</Title>
          </Row>
        </>
      )}
    </>
  );
}
