import {
  Body,
  Button,
  colors,
  Column,
  H1,
  Label,
  Row,
  Spacer,
  styles as meroStyles,
  useShowError,
} from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Image, ScrollView } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import ActiveIntervalView from '../MenuScreen/screens/PageReportsScreen/ActiveIntervalView';

import { IntercomContext } from '../../../contexts/IntercomContext';

const ListIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6633" transform="translate(-126 -558)">
      <Circle cx={12} cy={12} r={12} fill="#2dce89" data-name="Ellipse 11" transform="translate(126 558)" />
      <G data-name="Group 5794">
        <Path fill="#fff" d="m135.407 575.743-1.385-1.385 4.569-4.489-4.569-4.485 1.385-1.384 5.954 5.869Z" />
        <Path fill="none" d="M126 558h24v24h-24z" data-name="Rectangle 2" />
      </G>
    </G>
  </Svg>
);

type Props = {
  onEnableCheckout: () => void;
};

const EnableCheckoutDesktop: React.FC<Props> = ({ onEnableCheckout }) => {
  const { t } = useTranslation('checkout');

  const [, { openChat }] = IntercomContext.useContext();
  const onContactUsPressed = React.useCallback(() => {
    openChat();
  }, [openChat]);

  return (
    <Column style={{ flex: 1 }}>
      <Row
        style={{
          height: 91,
          backgroundColor: colors.WHITE,
          shadowColor: colors.BLACK,
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.16,
          shadowRadius: 16,
          elevation: 16,
          zIndex: 1,
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <H1 style={{ paddingHorizontal: 24 }}>{t('sales')}</H1>
      </Row>
      <ScrollView
        style={{ paddingHorizontal: 16 }}
        contentContainerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        <Column style={{ flex: 1, paddingTop: 96, paddingLeft: 96 }}>
          <Row alignItems="center">
            <Column style={{ paddingRight: 32, flex: 2 }}>
              <Column
                style={{
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                  backgroundColor: '#E9FAF3',
                  borderRadius: 4,
                  alignSelf: 'flex-start',
                }}
              >
                <Label style={[meroStyles.text.semibold, { color: colors.SHAMROCK }]}>{t('enableCheckoutLabel')}</Label>
              </Column>
              <Spacer size={4} />
              <H1>{t('enableCheckoutHeader')}</H1>
              <Spacer size={24} />
              <Row>
                <ListIcon />
                <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint1')}</Body>
              </Row>
              <Spacer size={12} />
              <Row>
                <ListIcon />
                <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint2')}</Body>
              </Row>
              <Spacer size={12} />
              <Row>
                <ListIcon />
                <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint3')}</Body>
              </Row>
              <Spacer size={12} />
              <Row>
                <ListIcon />
                <Body style={{ paddingLeft: 12, flex: 1 }}>{t('enableCheckoutPoint4')}</Body>
              </Row>
              <Spacer size={48} />
              <Column>
                <Button text={t('enableCheckoutButton')} onClick={onEnableCheckout} />
              </Column>
              <Spacer size={24} />
              <Body>
                <Trans ns={'checkout'} t={t} i18nKey="enableCheckoutFooter">
                  0
                  <Body style={[{ color: colors.DARK_BLUE }, meroStyles.text.semibold]} onPress={onContactUsPressed}>
                    1
                  </Body>
                </Trans>
              </Body>
            </Column>
            <Column style={{ flex: 2 }}>
              <Image
                style={{ width: 403, height: 372 }}
                source={require('../../../assets/images/membership_web.png')}
              />
            </Column>
          </Row>
        </Column>
      </ScrollView>
    </Column>
  );
};

export default EnableCheckoutDesktop;
