import { ApiError, DefinedString, apiError, RecurrenceRule } from '@mero/api-sdk';
import { AppointmentId, AppointmentStatus, CalendarId } from '@mero/api-sdk/dist/calendar';
import { PageId } from '@mero/api-sdk/dist/pages';
import { ServiceId } from '@mero/api-sdk/dist/services';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import * as t from 'io-ts';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

type BookingUpdateContextState =
  | {
      type: 'Ready';
    }
  | {
      type: 'Updating';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'Updated';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'Failed';
      error?: ApiError<unknown>;
      isOverride: boolean;
    };

const defaultState = (): BookingUpdateContextState => ({ type: 'Ready' });

export const BookingUpdateContext = createModelContext(
  defaultState(),
  {
    reset: () => ({
      type: 'Ready',
    }),
    setUpdating: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId }) => ({
      type: 'Updating',
      ...payload,
    }),
    setUpdated: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId }) => ({
      type: 'Updated',
      ...payload,
    }),
    setFailed: (_, payload: { error?: ApiError<unknown>; isOverride: boolean }) => ({
      type: 'Failed',
      ...payload,
    }),
  },
  (dispatch) => {
    return {
      updateBooking: async (payload: {
        calendarId: CalendarId;
        appointmentId: AppointmentId;
        occurrenceIndex: number;
        start: Date;
        end: Date;
        recurrenceRule?: RecurrenceRule.Any;
        bookedServiceIds: ServiceId[];
        note?: DefinedString;
        override: boolean;
        onlyOnce: boolean;
        pageId: PageId;
        workerId: WorkerId;
        /**
         * Updates appointment status
         */
        status?: AppointmentStatus;
      }): Promise<void> => {
        try {
          dispatch.setUpdating({
            calendarId: payload.calendarId,
            appointmentId: payload.appointmentId,
          });

          const { status, ...updatePayload } = payload;

          await meroApi.calendar.updateAppointment({
            ...updatePayload,
            recurrenceRule: payload.recurrenceRule ?? undefined,
            note: payload.note ?? undefined,
            recurrent: payload.recurrenceRule !== undefined,
          });

          if (status !== undefined) {
            await meroApi.calendar.updateCalendarAppointmentStatus({
              calendarId: payload.calendarId,
              entryId: payload.appointmentId,
              newStatus: status,
              occurrenceIndex: payload.occurrenceIndex,
            });
          }

          dispatch.setUpdated({
            calendarId: payload.calendarId,
            appointmentId: payload.appointmentId,
          });
        } catch (e) {
          if (apiError(t.unknown).is(e)) {
            if (e.code === 20) {
              // booking override
              dispatch.setFailed({
                error: e,
                isOverride: true,
              });
            } else {
              log.exception(e);
              dispatch.setFailed({
                error: e,
                isOverride: false,
              });
            }
          } else {
            log.exception(e);
          }
        }
      },
      reset: dispatch.reset,
    };
  },
);

export const withBookingUpdateContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithBookingUpdateContextProvider(props: P) {
    return (
      <BookingUpdateContext.Provider>
        <Content {...props} />
      </BookingUpdateContext.Provider>
    );
  };
};
