import { CalendarEntryDetails, FeedbackId, MembershipPurchaseId, One } from '@mero/api-sdk';
import { AppointmentId, AppointmentStatus, CalendarEntry, CalendarId } from '@mero/api-sdk/dist/calendar';
import { AppointmentHistoryRecord } from '@mero/api-sdk/dist/calendar/appointment-history-record';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout';
import { CheckoutTransactionPreview } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview';
import { AnyDraft } from '@mero/api-sdk/dist/checkout/checkoutTransactionPreview/checkoutTransactionPreview';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { ClientFeedbackId } from '@mero/api-sdk/dist/pro/clientFeedback/clientFeedbackId';
import { Price, price, ServiceId, WorkerService } from '@mero/api-sdk/dist/services';
import {
  Button,
  DismissKeyboard,
  FormCard,
  Icon,
  Column,
  Row,
  Line,
  SafeAreaView,
  SimpleListItem,
  Spacer,
  Body,
  H1,
  H3s,
  Title,
  colors,
  styles as meroStyles,
  SmallBody,
  Label,
  H2s,
  useToast,
  Header,
  sizes,
} from '@mero/components';
import { MeroUnits } from '@mero/shared-sdk';
import { capitalize } from '@mero/shared-sdk/dist/common';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, View, Platform, TouchableOpacity } from 'react-native';
import Svg, { Circle, SvgProps, G, Path } from 'react-native-svg';

import {
  priceAfterDiscounts,
  priceSumFormatter,
} from '../../screens/Authorized/ClientDetailsScreen/components/UserAppointmentList/UserAppointmentSummary';
import Review from '../../screens/Authorized/MenuScreen/screens/PageReviewsScreen/Review';

import AppointmentStatusView from '@mero/components/lib/components/AppointmentStatus';
import RecurrenceText from '@mero/components/lib/components/Mero/RecurrenceText';
import TextSkeleton from '@mero/components/lib/components/TextSkeleton';
import { formatDurationInMinutes } from '@mero/components/lib/utils/format';
import { formatTimeDiff } from '@mero/shared-components/dist/utils/format';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { useMediaQueries } from '../../hooks/useMediaQueries';

import { meroApi } from '../../contexts/AuthContext';
import { BookedServicePreview } from '../../contexts/BookingFormContext';
import { CheckoutsContext } from '../../contexts/CheckoutsContext';
import { ClientFeedbackContext } from '../../contexts/ClientFeedbackContext';
import { CurrentBusinessContext } from '../../contexts/CurrentBusiness';
import { AuthorizedStackParamList } from '../../types';
import log, { logCatch } from '../../utils/log';
import { national } from '../../utils/phone';
import { scaledToString } from '../../utils/scaled';
import BookedServiceWithWorkerListItem from '../BookedServiceWithWorkerListItem';
import ClientListItem, { ClientListItemProps } from '../ClientListItem';
import ModalScreenContainer from '../ModalScreenContainer';
import StarRating from '../StarRating';
import BookingOptionsMenu from './BookingOptionsMenu';
import FeedbackOptionsModal from './FeedbackOptionsModal';
import { styles } from './styles';

const CheckoutIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6972">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2671" />
    </G>
    <G fill="none" data-name="Group 7500">
      <G stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.3}>
        <Path d="M7.813 12.748h4.789m-4.789 2.395h3.59m3.593-2.4h1.2m-1.2 2.4h1.2M4.826 4.5v15.3l1.79-1.193 1.815 1.2 1.789-1.2 1.8 1.193 1.789-1.193 1.8 1.193 1.79-1.193 1.795 1.193V4.5m-3.585 15.3h0Zm-3.585 0h0ZM3.75 4.5h16.5" />
        <Path d="m10.3 8.762 1.226 1.226 2.18-2.179" data-name="layer1" />
      </G>
      <Path d="M3 3h18v18H3z" data-name="Rectangle 2701" />
    </G>
  </Svg>
);

const HistoryIcon = () => (
  <Svg width={32} height={32} data-name="Group 5901">
    <Circle cx={16} cy={16} r={16} fill="#e9ecef" />
    <G data-name="Group 6846">
      <Path fill="none" d="M7 7h18v18H7z" data-name="Rectangle 2638" />
    </G>
    <G data-name="Group 6849">
      <G data-name="Group 6848">
        <G data-name="Group 6847">
          <Path
            fill="#52577f"
            d="M22.75 14.59h-5.085l2.055-2.115a5.279 5.279 0 0 0-7.41-.075 5.156 5.156 0 0 0 0 7.343 5.265 5.265 0 0 0 7.41 0 4.885 4.885 0 0 0 1.53-3.668h1.5a7.006 7.006 0 0 1-1.98 4.717 6.79 6.79 0 0 1-9.54 0 6.63 6.63 0 0 1-.015-9.435 6.74 6.74 0 0 1 9.488 0L22.75 9.25ZM16.375 13v3.188L19 17.75l-.54.908-3.21-1.908V13Z"
            data-name="Path 9066"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export type BookingDetailsData =
  | {
      /**
       * Data to show while loading the full appointment info
       */
      type: 'Loading';
      status?: AppointmentStatus;
      start?: Date;
      totalDuration?: number;
      totalPrice?: Price;
    }
  | {
      /**
       * Appointment loaded
       */
      type: 'Loaded';
      appointment: CalendarEntryDetails.Appointment;
    };

export type MembershipItem = { _id: ServiceId; quantity: number; type: 'Service' };

export type MembershipServices = {
  _id: MembershipPurchaseId;
  name: string;
  items: MembershipItem[];
};

export type BookingDetailsScreenProps = {
  readonly onBackPress?: () => void;
  readonly onClosePress?: () => void;
  readonly onClientPress?: () => void;
  readonly onAcceptAppointment?: () => void;
  readonly onRejectAppointment?: () => void;
  readonly onEditAppointment?: () => void;
  readonly onBookAgain?: () => void;
  readonly onCancelByClient?: () => void;
  readonly onMarkAsNoShow?: () => void;
  readonly onDeleteAppointment?: () => void;
  readonly navigateClientFeedback: (params: { selectedScore?: number; clientFeedbackId?: ClientFeedbackId }) => void;
  readonly data: BookingDetailsData;
  readonly now: Date;
  readonly calendarId: CalendarId;
};

const BookingDetailsScreen: React.FC<BookingDetailsScreenProps> = ({
  onBackPress,
  onClosePress,
  onClientPress,
  onAcceptAppointment,
  onRejectAppointment,
  onEditAppointment,
  onBookAgain,
  onCancelByClient,
  onMarkAsNoShow,
  onDeleteAppointment,
  navigateClientFeedback,
  data,
  now,
  calendarId,
}: BookingDetailsScreenProps) => {
  const isLoading = data.type === 'Loading';
  const { isPhone, isDesktop } = useMediaQueries();
  const { t } = useTranslation('booking');
  const navigation = useNavigation<NavigationProp<AuthorizedStackParamList>>();
  const isFocus = useIsFocused();

  const [pageState] = CurrentBusinessContext.useContext();
  const [history, setHistory] = React.useState<AppointmentHistoryRecord[]>([]);
  const [isLoadingHistory, setIsLoadingHistory] = React.useState(false);
  const [usableMemberships, setUsableMemberships] = React.useState<MembershipServices[]>([]);
  const [totalMemberships, setTotalMemberships] = React.useState(0);

  const [clientFeedbackState, { reload: reloadFeedback }] = ClientFeedbackContext.useContext();
  const [showFeedbackOptionsModal, setShowFeedbackOptionsModal] = React.useState(false);
  const [score, setScore] = React.useState(0);

  React.useEffect(() => {
    // navigate to client feedback form after score is changed
    navigateClientFeedback({ selectedScore: score });
  }, [score]);

  // update local review
  React.useEffect(() => {
    if (isFocus) {
      reloadFeedback();
      setScore(0);
    }
  }, [isFocus]);

  type ServiceWithWorker = {
    service: BookedServicePreview;
    worker: CalendarEntryDetails.Appointment['payload']['worker'];
    workerServices: WorkerService[];
    start: Date;
  };

  type ServicesAcc = {
    start: Date;
    services: ServiceWithWorker[];
  };

  type ViewData = {
    start: Date;
    status: AppointmentStatus | undefined;
    inPast: boolean;
    recurrence: CalendarEntry.Recurrence.Any | undefined;
    clientUser: CalendarEntryDetails.Appointment['payload']['user'] | undefined;
    client: CalendarEntryDetails.Appointment['payload']['client'] | undefined;
    isBoost: boolean;
    hideBoostDetails: boolean;
    services: ServiceWithWorker[];
    note: string | undefined;
    totalDurationStr: string | undefined;
    totalStr: string | undefined;
    giftCards: Array<{ code: string; price: number }> | undefined;
    discounts: Array<{ code: string; price: number }> | undefined;
    bookingPageId?: PageId;
  };

  const {
    start,
    status,
    inPast,
    recurrence,
    clientUser,
    client,
    isBoost,
    hideBoostDetails,
    services,
    note,
    totalDurationStr,
    totalStr,
    giftCards,
    discounts,
    bookingPageId,
  } = React.useMemo((): ViewData => {
    switch (data.type) {
      case 'Loading': {
        const start = data.start ?? now;
        return {
          start: start,
          status: data.status,
          inPast: start < now,
          recurrence: undefined,
          clientUser: undefined,
          client: undefined,
          isBoost: false,
          hideBoostDetails: false,
          services: [],
          note: undefined,
          totalDurationStr: data.totalDuration !== undefined ? formatDurationInMinutes(data.totalDuration) : undefined,
          totalStr: data.totalPrice !== undefined ? priceSumFormatter(data.totalPrice) : undefined,
          giftCards: [],
          discounts: [],
        };
      }
      case 'Loaded': {
        const { start, end, payload } = data.appointment;
        setIsLoadingHistory(true);
        meroApi.calendar.fetchAppointmentHistory({ appointmentId: data.appointment._id }).then((history) => {
          setHistory(history);
          setIsLoadingHistory(false);
        });
        const {
          status,
          user,
          client,
          worker,
          bookedServices,
          note,
          giftCards,
          discounts,
          clientBoostStatus,
          hideBoostClientDetails,
        } = payload;
        const inPast = start < now;
        const { services } = bookedServices.reduce(
          ({ start, services }: ServicesAcc, service): ServicesAcc => ({
            start: new Date(start.getTime() + service.durationInMinutes * 60000),
            services: services.concat([
              {
                service,
                worker,
                start,
                workerServices:
                  pageState.type === 'Loaded'
                    ? pageState.page.workers.filter((w) => w._id === worker._id).flatMap((w) => w.services)
                    : [],
              },
            ]),
          }),
          { start, services: [] },
        );
        const totalDurationInMinutes = Math.floor((end.getTime() - start.getTime()) / 60000);
        const totalDurationStr = formatDurationInMinutes(totalDurationInMinutes);

        const totalServices = bookedServices.map((s) => s.price).reduce(price.sum);
        const totalDiscounts =
          (giftCards?.reduce((sum, card) => sum + card.price, 0) ?? 0) +
          (discounts?.reduce((sum, discount) => sum + discount.fixedAmount, 0) ?? 0);
        const totalStr = priceSumFormatter(priceAfterDiscounts(totalServices, totalDiscounts)) ?? t('hiddenPrice');

        const isBoost = clientBoostStatus?.isBoostClient ?? false;
        return {
          start: start,
          status: status,
          inPast: inPast,
          recurrence: data.appointment,
          clientUser: user,
          client: client,
          isBoost: isBoost,
          hideBoostDetails: isBoost && (hideBoostClientDetails ?? false),
          services,
          note,
          totalDurationStr,
          totalStr,
          giftCards: giftCards ?? [],
          discounts: discounts ? discounts.map((d) => ({ code: d.code, price: d.fixedAmount })) : [],
          bookingPageId: data.appointment.payload.page._id,
        };
      }
    }
  }, [data]);

  const [optionsVisible, setOptionsVisible] = React.useState(false);
  const [checkouts, setCheckouts] = React.useState<CheckoutTransactionPreview.Any[]>([]);
  const [checkoutState] = CheckoutsContext.useContext();
  const toast = useToast();

  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);
  const startDateTime = React.useMemo(
    () => DateTime.fromJSDate(start, { zone: timeZone }),
    [start.getTime(), timeZone],
  );

  const showOptions = React.useCallback(() => {
    setOptionsVisible(true);
  }, [setOptionsVisible]);

  const hideOptions = React.useCallback(() => {
    setOptionsVisible(false);
  }, [setOptionsVisible]);

  /**
   * Wrap callback to dismiss options menu first
   */
  const dismissingOpts = (fn: (() => void) | undefined): (() => void) | undefined => {
    if (fn !== undefined) {
      return () => {
        hideOptions();
        fn();
      };
    } else {
      return undefined;
    }
  };

  const hasClientAccess = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return pageState.page.permissions.clients.canViewClientDetails();
    }
    return false;
  }, [pageState.type]);

  const canManageOwnBooking = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return pageState.page.permissions.bookings.canWriteOwnBookings();
    }
    return false;
  }, [pageState.type]);

  const canMarkANoShow = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return pageState.page.permissions.bookings.canMarkOwnAsNoShow() && onMarkAsNoShow !== undefined;
    }
    return false;
  }, [pageState.type, onMarkAsNoShow]);

  const clientP = React.useMemo((): ClientListItemProps['client'] | undefined => {
    if (client || clientUser) {
      return {
        firstname: client?.firstname ?? clientUser?.profile.firstname,
        lastname: client?.lastname ?? clientUser?.profile.lastname,
        isBlocked: client?.isBlocked,
        isWarned: client?.isWarned,
        isFavourite: client?.isFavourite,
        phone: hasClientAccess ? clientUser?.phone : undefined,
        photo: clientUser?.profile.photo,
        isBoost: isBoost,
        hideBoostDetails: hideBoostDetails,
      };
    } else {
      return undefined;
    }
  }, [clientUser, client, hasClientAccess, isBoost, hideBoostDetails]);

  const showDeleteWarning = React.useCallback(() => {
    toast.show({
      type: 'error',
      text: t('withCheckoutError'),
    });
  }, []);

  const generateHistoryText = React.useCallback((history: AppointmentHistoryRecord) => {
    switch (history.type) {
      case 'appointmentCreatedByClient':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              date: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('dd MMMM yyyy'),
              hour: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('HH:mm'),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            0<Title>1</Title>2<Title>3</Title>4<Title>5</Title>
          </Trans>
        );
      case 'appointmentCreatedByPro':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              date: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('dd MMMM yyyy'),
              hour: DateTime.fromJSDate(history.payload.start).setLocale('ro').toFormat('HH:mm'),
              worker: `${history.payload.worker.firstname} ${history.payload.worker.lastname}`,
            }}
          >
            <Title>0</Title>1<Title>2</Title>3<Title>4</Title>5<Title>6</Title>
          </Trans>
        );
      case 'appointmentCancelledByClient':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.client.firstname} ${history.payload.client.lastname}`,
              reason: history.payload.reason ? t('withReason', { reason: history.payload.reason }) : t('noReason'),
            }}
          >
            0<Title>1</Title>
          </Trans>
        );
      case 'appointmentCancelledByPro':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              reason: history.payload.reason ? t('withReason', { reason: history.payload.reason }) : t('noReason'),
            }}
          >
            <Title>0</Title>1<Title>2</Title>
          </Trans>
        );
      case 'appointmentModifiedByPro':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
              startDate: DateTime.fromJSDate(history.payload.start.from).setLocale('ro').toFormat('ccc dd MMMM yyyy'),
              endDate: DateTime.fromJSDate(history.payload.start.to).setLocale('ro').toFormat('ccc dd MMMM yyyy'),
              startHour: DateTime.fromJSDate(history.payload.start.from).setLocale('ro').toFormat('HH:mm'),
              endHour: DateTime.fromJSDate(history.payload.start.to).setLocale('ro').toFormat('HH:mm'),
            }}
          >
            <Title>0</Title>1<Title>2</Title>3<Title>4</Title>5<Title>6</Title>7<Title>8</Title>
          </Trans>
        );
      case 'appointmentConfirmed':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <Title>0</Title>1
          </Trans>
        );
      case 'appointmentRefused':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <Title>0</Title>1
          </Trans>
        );
      case 'appointmentMarkedAsNoShow':
        return (
          <Trans
            ns="booking"
            t={t}
            i18nKey={history.type}
            values={{
              name: `${history.payload.byUser.firstname} ${history.payload.byUser.lastname}`,
              phone: national(history.payload.byUser.phone),
            }}
          >
            <Title>0</Title>1<Title>2</Title>
          </Trans>
        );
    }
  }, []);

  const getCheckoutInfo = React.useCallback(
    async (payload: { pageId: PageId; appointmentId: AppointmentId; occurrenceIndex: number | undefined }) => {
      try {
        const checkout = await meroApi.checkout.listAppointmentTransactions({
          ...payload,
        });

        setCheckouts(checkout.data.filter((c) => c.status !== 'Deleted'));
      } catch (e) {
        log.error('Failed to fetch checkout info', e);
      }
    },
    [],
  );

  const deleteFeedback = async () => {
    if (pageState.type !== 'Loaded' || clientFeedbackState.type !== 'Loaded' || !clientFeedbackState.clientFeedback) {
      return;
    }
    try {
      await meroApi.pro.clientFeedback.deleteClientFeedback({
        clientFeedbackId: clientFeedbackState.clientFeedback?._id,
        pageId: pageState.page.details._id,
      });
      toast.show({
        type: 'success',
        text: t('clientFeedbackDeleted'),
      });
      reloadFeedback();
    } catch (e) {
      log.error('Failed to delete client feedback', e);
    }
  };

  const hasFinishedCheckout = React.useMemo(() => checkouts.some((c) => c.status === 'Finished'), [checkouts]);
  const hasDraftCheckout = React.useMemo(() => checkouts.some((c) => c.status === 'Draft'), [checkouts]);
  const checkoutEnabled = checkoutState.type === 'Loaded' && checkoutState.pageSettings.checkoutEnabled;
  const isRestrictedCheckoutEnabled =
    checkoutState.type === 'LoadedRestricted' && checkoutState.restrictedPageSettings.checkoutEnabled;
  const latestDraft = React.useMemo(() => {
    const draftsSorted = checkouts
      .filter((c): c is AnyDraft => c.status === 'Draft')
      .sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

    return draftsSorted[0];
  }, [checkouts]);
  const finishedCheckout = React.useMemo(
    () => checkouts.find((c) => c.status === 'Finished'),
    [checkouts, hasFinishedCheckout],
  );
  const worker = React.useMemo(() => services.map((s) => s.worker).find((w) => w !== undefined), [services]);

  React.useEffect(() => {
    if (data.type === 'Loaded' && pageState.type === 'Loaded' && (checkoutEnabled || isRestrictedCheckoutEnabled)) {
      getCheckoutInfo({
        pageId: pageState.page.details._id,
        appointmentId: data.appointment._id,
        occurrenceIndex: data.appointment.occurrenceIndex,
      });
    }
  }, [data.type, pageState.type, checkoutEnabled, isRestrictedCheckoutEnabled]);

  const showCheckoutButton = checkoutEnabled && !hasFinishedCheckout;

  React.useEffect(() => {
    if (
      isFocus &&
      (checkoutEnabled || isRestrictedCheckoutEnabled) &&
      pageState.type === 'Loaded' &&
      data.type === 'Loaded'
    ) {
      getCheckoutInfo({
        pageId: pageState.page.details._id,
        appointmentId: data.appointment._id,
        occurrenceIndex: data.appointment.occurrenceIndex,
      });
    }
  }, [isFocus, checkoutEnabled, isRestrictedCheckoutEnabled, pageState.type, data.type]);

  const getMemberships = React.useCallback(
    async (pageId: PageId, clientId: ClientId, services: ServiceWithWorker[]) => {
      try {
        const memberships = await meroApi.memberships
          .getPurchasedMembershipsAvailableForTransaction({
            page: {
              _id: pageId,
            },
            client: {
              _id: clientId,
            },
            items: services.map((service) => ({
              type: 'Service',
              service: {
                _id: service.service._id,
              },
              quantity: 1 as One,
            })) as NonEmptyArray<CreateCheckoutTransactionItem.Service<MeroUnits.Any>>,
          })
          .catch(logCatch('getPurchasedMembershipsAvailableForTransaction'));
        setUsableMemberships(
          memberships.map((m) => ({
            _id: m.membership._id,
            name: m.membership.name,
            items: m.items
              .map((i) =>
                i.type === 'Service'
                  ? {
                      _id: i.service._id,
                      quantity: i.availableQuantity.type === 'Unlimited' ? Infinity : i.availableQuantity.remaining,
                      type: 'Service',
                    }
                  : undefined,
              )
              .filter((i): i is MembershipItem => i !== undefined),
          })),
        );
      } catch (e) {
        log.error('Failed to fetch memberships', e);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (
      pageState.type === 'Loaded' &&
      bookingPageId === pageState.page.details._id &&
      client?._id &&
      services.length > 0
    ) {
      getMemberships(pageState.page.details._id, client._id, services);
    }
  }, [pageState.type === 'Loaded' && pageState.page.details._id, bookingPageId, client?._id, services.length]);
  return (
    <ModalScreenContainer edges={['right', 'left']}>
      <Column
        style={[
          hasFinishedCheckout && { backgroundColor: colors.SHAMROCK },
          isDesktop && { borderTopLeftRadius: 6, borderTopRightRadius: 6 },
        ]}
      >
        <SafeAreaView edges={['top']} />
        <Header
          CenterComponent={
            hasFinishedCheckout
              ? () => <Title style={{ color: colors.WHITE }}>{t('appointmentDetails')}</Title>
              : () => <Title style={{ color: colors.BLACK }}>{t('appointmentDetails')}</Title>
          }
          RightComponent={
            onClosePress !== undefined
              ? () => (
                  <TouchableOpacity onPress={onClosePress} style={{ paddingRight: 16 }}>
                    <Icon
                      type="close"
                      disabled={true}
                      size={32}
                      color={hasFinishedCheckout ? colors.WHITE : colors.BLACK}
                    />
                  </TouchableOpacity>
                )
              : undefined
          }
          LeftComponent={
            onBackPress !== undefined
              ? () => (
                  <TouchableOpacity onPress={onBackPress} style={{ paddingRight: 16 }}>
                    <Icon
                      type="back"
                      disabled={true}
                      size={32}
                      color={hasFinishedCheckout ? colors.WHITE : colors.BLACK}
                    />
                  </TouchableOpacity>
                )
              : undefined
          }
        />
      </Column>
      <ScrollView style={{ flex: 1 }}>
        <DismissKeyboard>
          {hasFinishedCheckout && finishedCheckout && data.type === 'Loaded' ? (
            <>
              <Column style={{ backgroundColor: colors.SHAMROCK }}>
                <Spacer size="12" />
                <TouchableOpacity
                  style={{ paddingHorizontal: 24 }}
                  onPress={() => {
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutStack',
                      params: {
                        screen: 'ProceedDetailsScreen',
                        params: {
                          checkoutTransactionId: finishedCheckout._id,
                          appointmentId: data.appointment._id,
                          occurrenceIndex: data.appointment.occurrenceIndex,
                          backMode: 'one',
                        },
                      },
                    });
                  }}
                >
                  <Label
                    style={{
                      color: colors.WHITE,
                      fontFamily: 'open-sans-semibold',
                    }}
                  >
                    {t(`checkout:${finishedCheckout.status}`)}
                  </Label>
                  <Row style={{ flex: 1 }}>
                    <Column style={{ flex: 1 }}>
                      <H2s style={{ color: colors.WHITE }}>
                        {finishedCheckout.paymentTypes.length === 0
                          ? t('checkout:addProtocol')
                          : t('checkout:totalPayed')}
                      </H2s>
                      <Spacer size={6} />
                      <SmallBody style={{ color: colors.WHITE }}>
                        {DateTime.fromJSDate(finishedCheckout.createdAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
                      </SmallBody>
                    </Column>
                    <Column>
                      <H2s style={{ color: colors.WHITE, textAlign: 'right' }}>
                        {scaledToString(finishedCheckout.total.total.amount)} {t(finishedCheckout.total.total.unit)}
                      </H2s>
                      <Spacer size={6} />
                    </Column>
                    <Column style={{ paddingLeft: 8 }}>
                      <Spacer size="4" />
                      <Icon type="next" color={colors.WHITE} />
                    </Column>
                  </Row>
                </TouchableOpacity>
                <Spacer size="16" />
              </Column>
              <Spacer size="16" />
            </>
          ) : null}
          <View style={styles.hrPadding}>
            {status ? (
              <AppointmentStatusView status={status} inPast={inPast} />
            ) : isLoading ? (
              <TextSkeleton height="label" width="short" />
            ) : (
              <Spacer size="16" />
            )}
            <Spacer size="4" />
            <H1>{capitalize(startDateTime.toFormat('EEE d LLL - H:mm', { locale: 'ro' }))}</H1>
            <Spacer size="8" />
            {recurrence ? (
              <RecurrenceText recurrenceRule={recurrence.recurrent ? recurrence.recurrenceRule : undefined} dot />
            ) : isLoading ? (
              <TextSkeleton width="short" />
            ) : null}

            <Spacer size="16" />
          </View>
          {checkouts.length > 0 && !hasFinishedCheckout && checkoutEnabled && (
            <>
              <Spacer size="16" color={colors.ALABASTER} />
              {checkouts.map((checkoutInfo, index) => (
                <View style={styles.hrPadding} key={checkoutInfo._id}>
                  <Spacer size="16" />
                  <TouchableOpacity
                    style={{ flexDirection: 'row' }}
                    onPress={() => {
                      checkoutInfo.status === 'Draft'
                        ? navigation.navigate('CombineCheckout', {
                            screen: 'CheckoutStack',
                            params: {
                              screen: 'AddProceedScreen',
                              params: {
                                type: 'Booking',
                                transactionId: checkoutInfo._id,
                              },
                            },
                          })
                        : navigation.navigate('CombineCheckout', {
                            screen: 'CheckoutStack',
                            params: {
                              screen: 'ProceedDetailsScreen',
                              params: { checkoutTransactionId: checkoutInfo._id, backMode: 'one' },
                            },
                          });
                    }}
                  >
                    <Row style={{ flex: 1 }}>
                      <Column style={{ flex: 1, paddingRight: 16 }}>
                        <Label
                          style={{
                            color: checkoutInfo.status === 'Finished' ? colors.SHAMROCK : colors.COMET,
                            fontFamily: 'open-sans-semibold',
                          }}
                        >
                          {t(`checkout:${checkoutInfo.status}`)}
                        </Label>
                        <H2s>
                          {scaledToString(checkoutInfo.total.total.amount)} {t(checkoutInfo.total.total.unit)}
                        </H2s>
                        <Spacer size={2} />
                        <SmallBody>
                          {checkoutInfo.client
                            ? !checkoutInfo.client.user.profile.firstname && !checkoutInfo.client?.user.profile.lastname
                              ? t('noName')
                              : checkoutInfo.client.user.profile.firstname
                            : t('noClient')}{' '}
                          {checkoutInfo.client?.user.profile.lastname}
                        </SmallBody>
                        <Spacer size={6} />
                        <SmallBody style={{ color: colors.COMET }}>
                          {DateTime.fromJSDate(checkoutInfo.createdAt).toFormat('dd MMM. yyyy, HH:mm').toLowerCase()}
                        </SmallBody>
                      </Column>
                    </Row>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </TouchableOpacity>
                  <Spacer size="16" />
                  {index !== checkouts.length - 1 && <Line />}
                </View>
              ))}
            </>
          )}
          <Spacer size="16" color={colors.ALABASTER} />
          <View style={styles.hrPadding}>
            <Spacer size="16" />
            {isLoading ? (
              <>
                <TextSkeleton height="title" width="short" />
                <SimpleListItem
                  iconPosition="right"
                  image={{
                    firstname: '',
                    lastname: '',
                  }}
                  alignContent="top"
                >
                  <TextSkeleton width="long" />
                  <Spacer size="12" />
                  <TextSkeleton width="medium" />
                </SimpleListItem>
              </>
            ) : (
              <>
                <H3s>Client</H3s>
                {clientP ? (
                  <ClientListItem
                    onPress={hasClientAccess ? onClientPress : undefined}
                    icon={hasClientAccess ? 'next' : undefined}
                    client={clientP}
                  >
                    {/*{usableMemberships.length > 0 ? (*/}
                    {/*  <>*/}
                    {/*    <Spacer size={4} />*/}
                    {/*    <FlashyLabel*/}
                    {/*      text={t('usableMembership', {*/}
                    {/*        count: 10,*/}
                    {/*        total: usableMemberships.length,*/}
                    {/*      })}*/}
                    {/*      type={'info'}*/}
                    {/*    />*/}
                    {/*  </>*/}
                    {/*) : null}*/}
                  </ClientListItem>
                ) : (
                  <>
                    <Spacer size="16" />
                    <Body>{t('noClientSelected')}</Body>
                    <Spacer size="16" />
                  </>
                )}
              </>
            )}
          </View>

          {/* Client review exists (read-only)*/}
          {clientFeedbackState.type === 'Loaded' && clientFeedbackState.clientFeedback && (
            <View style={{ padding: sizes[24] }}>
              <Row
                style={{
                  flex: 1,
                  padding: sizes[16],
                  backgroundColor: '#F7F7FA',
                  borderRadius: 8,
                  justifyContent: 'space-between',
                }}
              >
                <Review
                  id={clientFeedbackState.clientFeedback._id.toString() as FeedbackId}
                  canAddReply={false}
                  firstname={clientFeedbackState.clientFeedback.addedByUser?.firstname ?? ''}
                  lastname={clientFeedbackState.clientFeedback.addedByUser?.lastname ?? ''}
                  image={clientFeedbackState.clientFeedback.addedByUser?.photo?.small}
                  score={clientFeedbackState.clientFeedback.feedback.score}
                  review={clientFeedbackState.clientFeedback.feedback.review}
                />

                {/* The review can be updated by this user */}
                {clientFeedbackState.canCreateOrUpdateClientFeedback && (
                  <TouchableOpacity
                    onPress={() => {
                      setShowFeedbackOptionsModal(true);
                    }}
                  >
                    <Icon type="options-dots" color={colors.DARK_BLUE} />
                  </TouchableOpacity>
                )}
              </Row>
            </View>
          )}

          {/* Client can create a review */}
          {clientFeedbackState.type === 'Loaded' &&
            clientFeedbackState.canCreateOrUpdateClientFeedback &&
            !clientFeedbackState.clientFeedback && (
              <View style={{ paddingHorizontal: sizes[24], paddingBottom: sizes[24], paddingTop: sizes[8] }}>
                <Column
                  style={{
                    flex: 1,
                    backgroundColor: '#F7F7FA',
                    paddingHorizontal: sizes[32],
                    paddingVertical: sizes[16],
                    alignItems: 'center',
                    borderRadius: 8,
                  }}
                >
                  <SmallBody
                    style={[
                      meroStyles.text.semibold,
                      { textTransform: 'uppercase', color: colors.COMET, fontSize: 12 },
                    ]}
                  >
                    {t('clickStarToAddReview')}
                  </SmallBody>
                  <Spacer size={12} />
                  <StarRating score={score} setScore={setScore} gap={24} />
                </Column>
              </View>
            )}

          <Spacer size="16" color={colors.ALABASTER} />
          <View style={styles.hrPadding}>
            <Spacer size="16" />
            {isLoading ? (
              <>
                <TextSkeleton width="short" />
                <Spacer size="24" />
                <TextSkeleton width="long" />
                <Spacer size="12" />
                <TextSkeleton width="medium" />
                <Spacer size="16" />
              </>
            ) : (
              <>
                <H3s>Servicii</H3s>
                {services.map(({ service, worker, workerServices, start: serviceStart }, idx) => (
                  <BookedServiceWithWorkerListItem
                    key={`${idx}`}
                    clientId={client?._id}
                    type="valid"
                    service={service}
                    worker={worker}
                    workerServices={workerServices}
                    startTime={serviceStart}
                    timeZone={timeZone}
                    style={{ simpleListItem: { paddingBottom: 0 } }}
                    memberships={usableMemberships.filter((m) => m.items.some((i) => i._id === service._id))}
                  />
                ))}
                {giftCards?.map((giftCard) => (
                  <React.Fragment key={giftCard.code}>
                    <Line />
                    <Row style={styles.giftCard}>
                      <Column>
                        <Icon color={colors.COMET} type="gift" />
                      </Column>
                      <Column style={styles.giftCardText}>
                        <Title>
                          {t('giftCard')} {giftCard.code.toUpperCase()}
                        </Title>
                      </Column>
                      <Column>
                        <Title>-{giftCard.price} lei</Title>
                      </Column>
                    </Row>
                  </React.Fragment>
                ))}
                {discounts?.map((discount) => (
                  <React.Fragment key={discount.code}>
                    <Line />
                    <Row style={styles.discount}>
                      <Column>
                        <Icon color={colors.COMET} type="discount" />
                      </Column>
                      <Column style={styles.discountText}>
                        <Title>
                          {t('discountCode')} {discount.code.toUpperCase()}
                        </Title>
                      </Column>
                      <Column>
                        <Title>
                          -{discount.price} {t('ron')}
                        </Title>
                      </Column>
                    </Row>
                  </React.Fragment>
                ))}
              </>
            )}
          </View>

          {isLoading ? (
            <>
              <Spacer size="16" color={colors.ALABASTER} />
              <View style={styles.hrPadding}>
                <Spacer size="16" />
                <TextSkeleton width="short" />
                <Spacer size="8" />
                <TextSkeleton width="long" />
                <Spacer size="16" />
              </View>
              <Spacer size="16" color={colors.ALABASTER} />
            </>
          ) : note ? (
            <>
              <Spacer size="16" color={colors.ALABASTER} />
              <View style={styles.hrPadding}>
                <Spacer size="16" />
                <Title>Observații</Title>
                <Spacer size="8" />
                <Body>{note}</Body>
                <Spacer size="16" />
              </View>
              <Spacer size="16" color={colors.ALABASTER} />
            </>
          ) : null}
          {history.length === 0 ? null : (
            <>
              <Spacer size={16} color={colors.ALABASTER} />
              <FormCard paddings="none">
                <Spacer size="24" />
                <H3s style={{ paddingHorizontal: 24 }}>{t('historyAppointment')}</H3s>
                <Spacer size="16" />
                {history.map((action, idx) => (
                  <Row
                    key={idx}
                    style={{
                      padding: 16,
                      ...(idx < 2 ? { borderBottomColor: colors.ATHENS_GRAY, borderBottomWidth: 1 } : {}),
                    }}
                  >
                    <Column>
                      <HistoryIcon />
                    </Column>
                    <Column style={{ flex: 1, paddingLeft: 12 }}>
                      <Body>{generateHistoryText(action)}</Body>
                      <Spacer size="6" />
                      <SmallBody style={{ fontSize: 12, color: colors.COMET }}>
                        {formatTimeDiff(action.date, new Date())}
                      </SmallBody>
                    </Column>
                  </Row>
                ))}
              </FormCard>
            </>
          )}
        </DismissKeyboard>
      </ScrollView>
      {/* Elements dropping shadows seems to draw over the ones with position absolute on Android */}
      <FormCard dropShaddow={Platform.OS !== 'android' || !optionsVisible} style={!isPhone && styles.modalBorderBottom}>
        <SafeAreaView edges={['bottom']} mode="padding">
          <Row>
            <Column style={{ flex: 1 }}>
              {totalDurationStr !== undefined ? (
                <Row>
                  <Body style={meroStyles.text.semibold}>{t('duration')}: </Body>
                  <Body>{totalDurationStr}</Body>
                </Row>
              ) : null}
            </Column>
            <Column style={{ flex: 1 }}>
              {totalStr !== undefined ? (
                <Row style={{ justifyContent: 'flex-end' }}>
                  <Body style={meroStyles.text.semibold}>{t('totalAppointment')}: </Body>
                  <Body>{totalStr}</Body>
                </Row>
              ) : null}
            </Column>
          </Row>
          {canManageOwnBooking || canMarkANoShow ? (
            <>
              <Spacer size="16" />
              {isPhone ? (
                showCheckoutButton ? (
                  <Row>
                    <Column style={{ flex: 2, paddingRight: 8 }}>
                      <Button
                        backgroundColor={colors.SKY_BLUE}
                        color={colors.DARK_BLUE}
                        text={t('appointmentOptionsMin')}
                        onPress={showOptions}
                        disabled={isLoading}
                      />
                    </Column>
                    <Column style={{ flex: 2, paddingLeft: 8 }}>
                      <Button
                        // LeftComponent={CheckoutIcon}
                        text={t('checkout')}
                        onPress={() =>
                          latestDraft
                            ? navigation.navigate('CombineCheckout', {
                                screen: 'CheckoutStack',
                                params: {
                                  screen: 'AddProceedScreen',
                                  params: {
                                    type: 'Booking',
                                    transactionId: latestDraft._id,
                                  },
                                },
                              })
                            : navigation.navigate('CombineCheckout', {
                                screen: 'CheckoutStack',
                                params: {
                                  screen: 'AddProceedScreen',
                                  params: {
                                    type: 'Booking',
                                    ...(data.type === 'Loaded'
                                      ? {
                                          appointmentId: data.appointment._id,
                                          calendarId,
                                          occurrenceIndex: data.appointment.occurrenceIndex,
                                        }
                                      : {}),
                                  },
                                },
                              })
                        }
                      />
                    </Column>
                  </Row>
                ) : (
                  <Button text={t('appointmentOptions')} onPress={showOptions} disabled={isLoading} />
                )
              ) : showCheckoutButton ? (
                <Row>
                  <Column style={{ flex: 2, paddingRight: 8 }}>
                    <Button
                      backgroundColor={colors.SKY_BLUE}
                      color={colors.DARK_BLUE}
                      text={t('appointmentOptionsMin')}
                      onPress={showOptions}
                      disabled={isLoading}
                    />
                  </Column>
                  <Column style={{ flex: 2, paddingLeft: 8 }}>
                    <Button
                      // LeftComponent={CheckoutIcon}
                      text={t('checkout')}
                      onPress={() =>
                        latestDraft
                          ? navigation.navigate('CombineCheckout', {
                              screen: 'CheckoutStack',
                              params: {
                                screen: 'AddProceedScreen',
                                params: {
                                  type: 'Booking',
                                  transactionId: latestDraft._id,
                                },
                              },
                            })
                          : navigation.navigate('CombineCheckout', {
                              screen: 'CheckoutStack',
                              params: {
                                screen: 'AddProceedScreen',
                                params: {
                                  type: 'Booking',
                                  ...(data.type === 'Loaded'
                                    ? {
                                        appointmentId: data.appointment._id,
                                        calendarId,
                                        occurrenceIndex: data.appointment.occurrenceIndex,
                                      }
                                    : {}),
                                },
                              },
                            })
                      }
                    />
                  </Column>
                </Row>
              ) : (
                <Button
                  expand={false}
                  containerStyle={{ alignSelf: 'center' }}
                  text={t('appointmentOptions')}
                  onPress={showOptions}
                  disabled={isLoading}
                />
              )}
            </>
          ) : null}
        </SafeAreaView>
      </FormCard>
      {optionsVisible ? (
        <BookingOptionsMenu
          onDismiss={hideOptions}
          onAcceptAppointment={dismissingOpts(onAcceptAppointment)}
          onRejectAppointment={dismissingOpts(onRejectAppointment)}
          onEditAppointment={dismissingOpts(onEditAppointment)}
          onBookAgain={dismissingOpts(onBookAgain)}
          onCanceledByClient={dismissingOpts(onCancelByClient)}
          onMarkAsNoShow={dismissingOpts(onMarkAsNoShow)}
          onDeleteAppointment={hasFinishedCheckout && !inPast ? showDeleteWarning : dismissingOpts(onDeleteAppointment)}
        />
      ) : null}

      {showFeedbackOptionsModal && (
        <FeedbackOptionsModal
          onDismiss={() => setShowFeedbackOptionsModal(false)}
          onDelete={deleteFeedback}
          onEdit={() =>
            navigateClientFeedback({
              clientFeedbackId:
                clientFeedbackState.type === 'Loaded' ? clientFeedbackState.clientFeedback?._id : undefined,
            })
          }
        />
      )}
    </ModalScreenContainer>
  );
};

export default React.memo(BookingDetailsScreen);
