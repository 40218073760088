import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ProductBrandId } from '@mero/api-sdk';
import {
  Body,
  colors,
  Column,
  FormCard,
  H2s,
  HSpacer,
  Icon,
  Line,
  Row,
  Spacer,
  Button,
  H1,
  H3s,
  styles as meroStyles,
  Avatar,
} from '@mero/components';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, TouchableOpacity, View } from 'react-native';

import { useClickOutsideWeb } from '../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import ProductsTable from './ProductsTable';
import { styles } from './styles';

type Props = {
  navigateProduct: () => void;
  navigateBrand: (brandId?: ProductBrandId) => void;
  navigateDeleteBrand: (brandId: ProductBrandId) => void;
  navigateBrandProducts: (brandId: ProductBrandId) => void;
};

export const getBrandTitle = (brandName: string, t: TFunction, search?: string): ReactJSXElement => {
  const title = brandName;

  if (search) {
    return (
      <Row>
        <H1 style={{ fontFamily: 'open-sans-semibold', fontSize: 20 }}>Rezultate pentru</H1>
        <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20, fontStyle: 'italic' }}>{` “${search}“ `}</H1>
        <H1 style={{ fontFamily: 'open-sans-semibold', fontSize: 20 }}> în </H1>
        <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>{title}</H1>
      </Row>
    );
  }
  return <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>{title}</H1>;
};

const BrandsContent: React.FC<Props> = ({
  navigateProduct,
  navigateBrand,
  navigateDeleteBrand,
  navigateBrandProducts,
}) => {
  const { t } = useTranslation('products');
  const { isPhone, isDesktop } = useMediaQueries();
  const optionsMenuRef = React.useRef<View>(null);

  const [state, { update }] = ProductsContext.useContext();
  const [searchState, { search }] = SearchProductsContext.useContext();

  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);

  React.useEffect(() => {
    if (isDesktop && state.type === 'Loaded' && state.brands.length > 0) {
      update({ selectedBrand: state.brands[0] });
    }
  }, []);

  React.useEffect(() => {
    if (!state.selectedBrand || isPhone) {
      return;
    }
    search({
      query: {
        brandId: state.selectedBrand._id,
        ...(searchState.query.search ? { search: searchState.query.search } : {}),
      },
    });
  }, [state.selectedBrand]);

  useClickOutsideWeb({
    ref: optionsMenuRef,
    isVisible: showOptionsMenu,
    onClickOutside() {
      setShowOptionsMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  const optionsMenu = () => (
    <View ref={optionsMenuRef}>
      <Column style={styles.optionsMenu}>
        <TouchableOpacity
          style={styles.optionsMenuItem}
          delayPressIn={0}
          onPress={() => {
            state.selectedBrand && navigateBrand(state.selectedBrand._id);
          }}
        >
          <Body style={meroStyles.text.semibold}>{t('editBrand')}</Body>
        </TouchableOpacity>
        <Line />

        <TouchableOpacity
          style={styles.optionsMenuItem}
          delayPressIn={0}
          onPress={() => {
            state.selectedBrand && navigateDeleteBrand(state.selectedBrand._id);
          }}
        >
          <Body style={[meroStyles.text.semibold, { color: colors.RADICAL_RED }]}>{t('deleteBrand')}</Body>
        </TouchableOpacity>
        <Line />
      </Column>
    </View>
  );

  if (state.type !== 'Loaded') {
    return;
  }

  return (
    <>
      <>
        <Spacer size={16} />

        {isDesktop && state.brands.length > 0 && (
          <>
            <FormCard dropShaddow paddings="none" style={{ flex: 1 }} rounded>
              <Row style={{ flex: 1 }}>
                <ScrollView style={{ maxWidth: 313, marginVertical: 24 }}>
                  <FormCard paddings="none" style={{ marginHorizontal: 24 }} dropShaddow rounded>
                    {state.brands.map((brand, index) => (
                      <View key={brand._id}>
                        <TouchableOpacity
                          onPress={() =>
                            update({
                              selectedBrand: {
                                _id: brand._id as ProductBrandId,
                                name: brand.name,
                                pageId: brand.pageId,
                              },
                            })
                          }
                        >
                          <Row
                            style={[
                              index === 0 && { borderTopRightRadius: 5, borderTopLeftRadius: 5 },
                              state.selectedBrand?.name === brand.name && {
                                backgroundColor: colors.ATHENS_GRAY,
                              },
                              { padding: 16, justifyContent: 'space-between', alignItems: 'center' },
                            ]}
                          >
                            <Row style={{ marginVertical: 2.5, width: '85%' }}>
                              <Avatar size={32} source={brand.image?.thumbnail} firstname={brand.name} lastname={''} />
                              <HSpacer left={12} />
                              <Body style={[{ alignSelf: 'center', flex: 1, flexWrap: 'wrap' }]}>
                                {brand.name} ({searchState.counters.brands[brand._id] ?? 0})
                              </Body>
                            </Row>

                            {state.selectedBrand?.name === brand.name && <Icon type="arrow-right" size={24}></Icon>}
                          </Row>
                        </TouchableOpacity>
                        {index !== state.brands.length - 1 && <Line />}
                      </View>
                    ))}
                  </FormCard>
                </ScrollView>

                {state.selectedBrand && (
                  <Column style={{ flex: 1, paddingVertical: 24, paddingRight: 24 }}>
                    <Row style={{ justifyContent: 'space-between' }}>
                      {state.selectedBrand && getBrandTitle(state.selectedBrand.name, t, searchState.query.search)}

                      {!searchState.query.search && (
                        <TouchableOpacity onPress={() => setShowOptionsMenu((prev) => !prev)}>
                          <Icon type="options-dots" />
                        </TouchableOpacity>
                      )}
                    </Row>
                    <Column style={{ position: 'relative', zIndex: 1 }}>{showOptionsMenu && optionsMenu()}</Column>

                    <Spacer size={12} />
                    <ProductsTable navigateProduct={navigateProduct} />
                  </Column>
                )}
              </Row>
            </FormCard>
            <Spacer size={24} />
          </>
        )}

        {isPhone && state.brands.length > 0 && (
          <ScrollView style={{ paddingHorizontal: 16 }}>
            <FormCard dropShaddow paddings="none" rounded>
              {state.brands.map((brand, index) => (
                <View key={brand._id}>
                  <Column key={brand._id} style={{ paddingHorizontal: 16, paddingVertical: 20 }}>
                    <TouchableOpacity
                      onPress={() => {
                        update({
                          selectedBrand: {
                            _id: brand._id as ProductBrandId,
                            name: brand.name,
                            pageId: brand.pageId,
                          },
                        });
                        navigateBrandProducts(brand._id as ProductBrandId);
                      }}
                    >
                      <Row style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Row>
                          <Avatar size={32} source={brand.image?.thumbnail} firstname={brand.name} lastname={''} />
                          <HSpacer left={12} />
                          <H3s style={[meroStyles.text.semibold, { alignSelf: 'center' }]}>
                            {brand.name} ({searchState.counters.brands[brand._id] ?? 0})
                          </H3s>
                        </Row>

                        <Icon type="arrow-right" />
                      </Row>
                    </TouchableOpacity>
                  </Column>
                  {index !== state.brands.length - 1 && (
                    <Column style={{ paddingHorizontal: 16 }}>
                      <Line />
                    </Column>
                  )}
                </View>
              ))}
            </FormCard>
            <Spacer size={163} />
          </ScrollView>
        )}
      </>

      {state.brands.length === 0 && (
        <Column style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <H2s>Niciun brand</H2s>
          <Body style={{ textAlign: 'center', paddingHorizontal: 40, paddingTop: 8 }}>
            Brandurile adăugate vor apărea aici.
          </Body>
          <Column style={{ paddingTop: 24 }}>
            <Button
              backgroundColor={colors.DARK_BLUE}
              color={colors.ALABASTER}
              size="medium"
              withShadow
              expand={false}
              text="Adaugă brand"
              padding={36}
              onClick={navigateBrand}
            />
          </Column>
        </Column>
      )}
    </>
  );
};

export default BrandsContent;
