import { CalendarEntryDetails, FullnameFromString, One, PortionPercent, RecurrenceRule } from '@mero/api-sdk';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { PageId } from '@mero/api-sdk/dist/pages';
import { price, Zero, HasServiceId, ServiceId } from '@mero/api-sdk/dist/services';
import { UserId } from '@mero/api-sdk/dist/users';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import { colors, SelectButton, sizes, SmallBody, styles as meroStyles, Switch } from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { UndefinedString, DefinedString, capitalize, StrictPhoneNumberParsed } from '@mero/shared-sdk/dist/common';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { uniqBy } from 'lodash';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Keyboard, ScrollView, TouchableOpacity, Platform } from 'react-native';

import { priceSumFormatter } from '../../screens/Authorized/ClientDetailsScreen/components/UserAppointmentList/UserAppointmentSummary';

import AvoidKeyboard from '@mero/components/lib/components/AvoidKeyboard';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import HSpacer from '@mero/components/lib/components/HSpacer';
import Icon from '@mero/components/lib/components/Icon';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import Column from '@mero/components/lib/components/Layout/Column';
import Row from '@mero/components/lib/components/Layout/Row';
import RecurrenceText from '@mero/components/lib/components/Mero/RecurrenceText';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import SimpleListItem from '@mero/components/lib/components/SimpleListItem';
import Spacer from '@mero/components/lib/components/Spacer';
import Body from '@mero/components/lib/components/Text/Body';
import H3s from '@mero/components/lib/components/Text/H3s';
import Title from '@mero/components/lib/components/Text/Title';
import TypeSafeTextInput, { ValueIO } from '@mero/components/lib/components/TypeSafeTextInput';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';
import { formatDurationInMinutes, formatPriceText } from '@mero/components/lib/utils/format';

import { useInputState } from '../../hooks/useInputState';
import { useMediaQueries } from '../../hooks/useMediaQueries';

import { meroApi } from '../../contexts/AuthContext';
import { BookedServicePreview, BookingClient } from '../../contexts/BookingFormContext';
import { CurrentBusinessContext } from '../../contexts/CurrentBusiness';
import log, { logCatch } from '../../utils/log';
import BookedServiceWithWorkerListItem, { BookedServiceWithWorkerItem } from '../BookedServiceWithWorkerListItem';
import { MembershipItem, MembershipServices } from '../BookingDetailsScreen';
import ClientListItem from '../ClientListItem';
import DayTimeSelect from '../DayTimeSelect';
import ModalScreenContainer from '../ModalScreenContainer';
import RecurrenceRuleEditScreen from '../RecurrenceRuleEditScreen';
import { default as RecurrenceRuleOptionsScreen } from '../RecurrenceRuleOptionsScreen';
import SearchInputButton from '../SearchInputButton';
import SelectDateTimeModal from '../SelectDateTimeModal';
import SelectedPerformerItem from '../SelectedPerformerItem';
import SelectedServiceItem from '../SelectedServiceItem';
import { styles } from './styles';

const OptionalNotes = t.union([DefinedString, UndefinedString], 'OptionalNotes');
type OptionalNotes = t.TypeOf<typeof OptionalNotes>;

const TimeSelectStep = 5;

export type Props = {
  readonly mode: 'add' | 'edit';

  /**
   * Booking start time
   */
  readonly start: Date;
  /**
   * Callback executed when user selects new day time
   */
  readonly onStartChanged?: (date: Date) => void;

  /**
   * Booking end time
   */
  readonly end: Date;
  /**
   * Callback executed when user selects new booking end time
   */
  readonly onEndChanged?: (date: Date) => void;

  readonly client?: BookingClient;
  readonly onClientChanged?: (client: BookingClient) => void;
  readonly onSelectClientPress?: () => void;
  readonly onChangeClientPress?: () => void;
  readonly onRemoveClientPress?: () => void;

  readonly services?: BookedServiceWithWorkerItem[];

  readonly performer?: SavedWorker;

  /**
   * When given - users will be able to add services up to this limit
   * If there are more services in `servicesLimit` field - those will not be removed,
   * you just won't be able to add more ("add service" button will be hidden)
   */
  readonly servicesLimit?: number;

  readonly onAddServicePress?: () => void;
  readonly onRemoveServicePress?: (service: HasServiceId, index: number) => void;

  readonly onSelectWorkerPress?: () => void;

  readonly recurrenceRule?: RecurrenceRule.Any;
  readonly onRecurrenceRuleChanged?: (newRule: RecurrenceRule.Any | undefined) => void;

  readonly notes?: OptionalNotes;
  readonly onNotesChanged?: (notes: OptionalNotes) => void;

  readonly onRemoveBookingPress?: () => void;

  /**
   * Page close button pressed
   */
  readonly onClosePress?: () => void;
  readonly onBackPress?: () => void;

  readonly onSaveBooking?: () => void;

  readonly editableStatus?: CalendarEntryDetails.AppointmentEditableStatus.Any;

  readonly fromWaitingList: boolean;
};

const AddBookingScreen: React.FC<Props> = ({
  mode,
  start: inStart,
  onStartChanged,
  end,
  onEndChanged,
  client,
  onClientChanged,
  onSelectClientPress,
  onChangeClientPress,
  onRemoveClientPress,
  services,
  servicesLimit,
  performer,
  onAddServicePress,
  onRemoveServicePress,
  onSelectWorkerPress,
  recurrenceRule,
  onRecurrenceRuleChanged,
  notes,
  onNotesChanged,
  onRemoveBookingPress,
  onClosePress,
  onBackPress,
  onSaveBooking,
  editableStatus,
  fromWaitingList,
}: Props) => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const { isPhone } = useMediaQueries();
  const { t: tt } = useTranslation('booking');

  const [pageState] = CurrentBusinessContext.useContext();

  // display errors only after first submit
  const [showErrors, setShowErrors] = React.useState(false);
  const [usableMemberships, setUsableMemberships] = React.useState<MembershipServices[]>([]);

  // ScrollView reference, for field auto scrolling
  const scrollRef = React.useRef<ScrollView>(null);
  // Target ScrollView scroll value (scroll to focused field to be visible)
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  /**
   * Client name (full name, must)
   */
  const [clientName, setClientName] = useInputState({
    input: client?.type === 'new' ? client.fullname ?? '' : '',
    decoded: FullnameFromString.decode(client?.type === 'new' ? client.fullname ?? '' : ''),
  });

  /**
   * Client phone number
   */
  const [clientPhoneNumber, setClientPhoneNumber] = useInputState({
    input: client?.type === 'new' ? client.phone ?? '' : '',
    decoded: StrictPhoneNumberParsed.decode(client?.type === 'new' ? client.phone ?? '' : ''),
  });

  const clientFormIsEmpty = clientName.input.trim() === '' && clientPhoneNumber.input.trim() === '';

  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);

  // Round start time to minutes
  const start = React.useMemo(
    () => new Date(Math.floor(inStart.getTime() / (TimeSelectStep * 60 * 1000)) * (TimeSelectStep * 60 * 1000)),
    [inStart.getTime(), TimeSelectStep],
  );

  const startDateTime = React.useMemo(() => DateTime.fromJSDate(start, { zone: timeZone }), [start, timeZone]);
  const endDateTime = React.useMemo(() => DateTime.fromJSDate(end, { zone: timeZone }), [end, timeZone]);
  const endDateEnd = React.useMemo(() => startDateTime.plus({ hours: 12 }), [startDateTime]);
  const differenceInDays = React.useMemo(() => endDateTime.diff(startDateTime, 'days').days, [start, end]);

  const [now] = React.useState(new Date());
  const [showSelectStartDateScreen, setShowSelectStartDateScreen] = React.useState(false);

  type ServicesAcc = {
    start: Date;
    nonEmptyServices: BookedServiceWithWorkerItem[];
  };

  const nonEmptyServices = ((services?.length ?? 0) > 0 ? services : undefined)?.reduce(
    ({ start, nonEmptyServices }: ServicesAcc, row): ServicesAcc => ({
      start: new Date(
        start.getTime() +
          (t.number.is(row.service.durationInMinutes)
            ? row.service.durationInMinutes
            : row.service.durationInMinutes.from) *
            60000,
      ),
      nonEmptyServices: nonEmptyServices.concat([{ ...row, startTime: start }]),
    }),
    { start: start, nonEmptyServices: [] },
  )?.nonEmptyServices;
  const servicesIsValid = nonEmptyServices !== undefined;

  const canAddMoreServices = servicesLimit === undefined || (nonEmptyServices?.length ?? 0) < servicesLimit;

  const [notesValue, setNotesValue] = useInputState({
    input: notes ?? '',
    decoded: OptionalNotes.decode(notes),
  });
  const notesArePresent = notesValue.input !== '';
  // User requested to add notes
  const [showNotesClicked, setShowNotesClicked] = React.useState<boolean>(false);
  const notesInputVisible = notesArePresent || showNotesClicked;

  // Service duration calculus
  const totalDurationInMinutes = Math.floor((end.getTime() - start.getTime()) / 60000);
  const total = nonEmptyServices?.map((row) => row.service.price).reduce(price.sum, Zero);
  const totalStr = total !== undefined ? priceSumFormatter(total) : undefined;

  const formIsValid = servicesIsValid && notesValue.isValid && performer !== undefined;

  const recurrenceRuleIsPresent = recurrenceRule !== undefined;
  // Recurrence rule options select modal
  const [showRecurrenceOptions, setShowRecurrenceOptions] = React.useState(false);
  // Custom recurrence rule edit modal
  const [showRecurrenceEditForm, setShowRecurrenceEditForm] = React.useState(false);

  const showSubmitButton = !(
    (isKeyboardOpen && Platform.OS === 'android') ||
    showRecurrenceOptions ||
    showSelectStartDateScreen ||
    showRecurrenceEditForm
  );

  const canEdit = React.useMemo((): CalendarEntryDetails.AppointmentEditableStatus.Editable['fields'] => {
    if (mode === 'edit') {
      const status = editableStatus ?? CalendarEntryDetails.AppointmentEditableStatus.NON_EDITABLE;
      if (status.type === 'Editable' && status.validUntil > now) {
        return status.fields;
      } else {
        return {
          start: false,
          end: false,
          client: false,
          worker: false,
          notes: false,
          services: false,
          recurrenceRule: false,
        };
      }
    } else {
      return {
        start: true,
        end: true,
        client: true,
        worker: true,
        notes: true,
        services: true,
        recurrenceRule: true,
      };
    }
  }, [now, mode, editableStatus]);

  const setClientCallback = React.useCallback(
    (client: BookingClient) => {
      if (onClientChanged) {
        onClientChanged(client);
      }
    },
    [onClientChanged],
  );

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const scrollTo = (y: number): void => {
    setScrollToY(y);
  };

  const setStartDateCallback = React.useCallback(
    (date: Date) => {
      setShowSelectStartDateScreen(false);
      if (onStartChanged) {
        const newDate = DateTime.fromJSDate(date)
          .setZone(timeZone) // WARNING: the date should be in same timezone
          .toUTC()
          .toJSDate();
        onStartChanged(newDate);
      }
    },
    [startDateTime, setShowSelectStartDateScreen, timeZone, onStartChanged],
  );

  const setEndTimeCallback = React.useCallback(
    (end: DateTime) => {
      if (onEndChanged) {
        onEndChanged(end.toJSDate());
      }
    },
    [onEndChanged],
  );

  const recurrenceOptionSelectedCallback = React.useCallback(
    (newRule: RecurrenceRule.Any | undefined | 'custom') => {
      setShowRecurrenceOptions(false);
      if (newRule === 'custom') {
        setShowRecurrenceEditForm(true);
      } else {
        if (onRecurrenceRuleChanged) {
          onRecurrenceRuleChanged(newRule);
        }
      }
    },
    [onRecurrenceRuleChanged, setShowRecurrenceEditForm],
  );

  const editRecurrenceRuleCallback = React.useCallback(() => {
    if (RecurrenceRule.Predefined.is(recurrenceRule)) {
      setShowRecurrenceOptions(true);
    } else {
      setShowRecurrenceEditForm(true);
    }
  }, [recurrenceRule, setShowRecurrenceOptions, setShowRecurrenceEditForm]);

  const setNotesCallback = React.useCallback(
    (value: ValueIO<OptionalNotes>) => {
      setNotesValue(value);
      if (E.isRight(value.decoded) && onNotesChanged) {
        onNotesChanged(value.decoded.right);
      }
    },
    [setNotesValue, onNotesChanged],
  );

  const saveBookingCallback = React.useCallback(() => {
    if (formIsValid && onSaveBooking) {
      onSaveBooking();
    } else {
      setShowErrors(true);
    }
  }, [setShowErrors, formIsValid, onSaveBooking]);

  React.useEffect(() => {
    if (clientName.isValid && clientPhoneNumber.isValid) {
      setClientCallback({
        type: 'new',
        isValid: true,
        isEmpty: false,
        fullname: clientName.input,
        firstname: clientName.value.firstname,
        lastname: clientName.value.lastname,
        phone: clientPhoneNumber.value,
      });
    } else if (clientFormIsEmpty) {
      setClientCallback({
        type: 'new',
        isValid: true,
        isEmpty: true,
      });
    } else {
      setClientCallback({
        type: 'new',
        isValid: false,
        isEmpty: false,
        fullname: clientName.input,
        phone: clientPhoneNumber.input,
      });
    }
  }, [clientName, clientPhoneNumber]);

  React.useEffect(() => {
    if (client?.type === 'new') {
      if (client.fullname !== clientName.input) {
        setClientName({
          input: client.fullname ?? '',
          decoded: FullnameFromString.decode(client.fullname ?? ''),
        });
      }

      if (client.phone !== clientPhoneNumber.input) {
        setClientPhoneNumber({
          input: client.phone ?? '',
          decoded: StrictPhoneNumberParsed.decode(client.phone ?? ''),
        });
      }
    }
  }, [client?.type === 'new' && client.fullname, client?.type === 'new' && client.phone]);

  // Scroll to requested position
  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  // Have to reset notes as the form is loaded asynchronously and when notesValue is first inited it's empty
  React.useEffect(() => {
    const decoded = OptionalNotes.decode(notes);
    setNotesValue({
      input: notes ?? '',
      decoded: decoded,
    });
    // Keep notes input visible even if notes were removed (to keep it consistent with the case when add notes button was clicked)
    if (E.isRight(decoded) && decoded.right) {
      setShowNotesClicked(true);
    }
  }, [notes]);

  const getMemberships = React.useCallback(
    async (pageId: PageId, clientId: ClientId, services: BookedServicePreview[]) => {
      try {
        const memberships = await meroApi.memberships
          .getPurchasedMembershipsAvailableForTransaction({
            page: {
              _id: pageId,
            },
            client: {
              _id: clientId,
            },
            items: services.map((service) => ({
              type: 'Service',
              service: {
                _id: service._id,
              },
              quantity: 1 as One,
            })) as NonEmptyArray<CreateCheckoutTransactionItem.Service<MeroUnits.Any>>,
          })
          .catch(logCatch('getPurchasedMembershipsAvailableForTransaction'));
        setUsableMemberships(
          memberships.map((m) => ({
            _id: m.membership._id,
            name: m.membership.name,
            items: m.items
              .map((i) =>
                i.type === 'Service'
                  ? {
                      _id: i.service._id,
                      quantity: i.availableQuantity.type === 'Unlimited' ? Infinity : i.availableQuantity.remaining,
                      type: 'Service',
                    }
                  : undefined,
              )
              .filter((i): i is MembershipItem => i !== undefined),
          })),
        );
      } catch (e) {
        log.error('Failed to fetch memberships', e);
      }
    },
    [],
  );

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && client?.type === 'existing' && nonEmptyServices && nonEmptyServices.length > 0) {
      getMemberships(
        pageState.page.details._id,
        client.client._id,
        nonEmptyServices.flatMap((r) => r.service),
      );
    }
  }, [
    pageState.type === 'Loaded' && pageState.page.details._id,
    client?.type === 'existing' && client.client._id,
    nonEmptyServices?.length,
  ]);

  const canWriteAllBookings = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return pageState.page.permissions.bookings.canWriteAllBookings();
    }

    return false;
  }, [pageState.type, canEdit]);
  const hasClientAccess = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      return pageState.page.permissions.clients.canViewClientDetails();
    }
    return false;
  }, [pageState.type]);

  const clientDetails = React.useMemo(() => {
    if (hasClientAccess) {
      return client;
    }

    return client?.type === 'existing' && !hasClientAccess
      ? { ...client, client: { ...client.client, phone: undefined } }
      : client;
  }, [hasClientAccess, client]);

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']}>
      <MeroHeader
        canGoBack={onBackPress !== undefined}
        onBack={onBackPress}
        canClose={onClosePress !== undefined}
        onClose={onClosePress}
        title={mode === 'add' ? tt('newAppointment') : tt('changeAppointment')}
      />
      <SafeAreaView edges={['bottom']} style={{ flex: 1, flexDirection: 'column' }}>
        <AvoidKeyboard
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 120,
          }}
        >
          <ScrollView style={{ flex: 1, flexGrow: 1 }} ref={scrollRef} onScrollEndDrag={dismissKeyboardCallback}>
            <View style={styles.hrPadding}>
              <Spacer size="16" />
              {mode === 'add' ? (
                clientDetails?.type === 'existing' ? (
                  <ClientListItem
                    client={clientDetails.client}
                    onPress={onChangeClientPress}
                    icon={onRemoveClientPress !== undefined ? 'close' : undefined}
                    onIconPress={onRemoveClientPress}
                    disabled={fromWaitingList}
                  />
                ) : fromWaitingList ? (
                  <SimpleListItem
                    iconColor={colors.DARK_BLUE}
                    iconPosition="right"
                    image={{
                      firstname: clientPhoneNumber.input,
                    }}
                  >
                    <Title numberOfLines={1} ellipsizeMode="tail">
                      {`${clientName.input}`.trim() || 'Fără nume'}
                    </Title>
                    <Spacer size="xxs" />

                    {clientPhoneNumber.input ? (
                      <SmallBody style={{ color: colors.COMET }} numberOfLines={1} ellipsizeMode="tail">
                        {clientPhoneNumber.input}
                      </SmallBody>
                    ) : null}
                  </SimpleListItem>
                ) : (
                  <>
                    <H3s style={{ flex: 1 }}>{tt('selectClient')}</H3s>
                    <Spacer size={16} />
                    <Switch
                      height={sizes[32]}
                      textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                      buttons={ClientTabsOptions.map((tab) => ({ ...tab, label: tt(tab.label) }))}
                      defaultValue={client?.type === 'none' ? 'existing' : 'new'}
                      onChange={(value) => {
                        if (value === 'existing') {
                          setClientCallback({
                            type: 'none',
                          });
                        } else if (value === 'new') {
                          setClientCallback({
                            type: 'new',
                            isValid: true,
                            isEmpty: true,
                          });
                        }
                      }}
                    />

                    {client?.type === 'none' && (
                      <>
                        <Spacer size="16" />
                        <SearchInputButton placeholder={tt('searchClient')} onPress={onSelectClientPress} />
                        {/*<Spacer size="8" />*/}
                        {/*<SmallBody style={{ color: colors.COMET }}>{tt('selectClientInfo')}</SmallBody>*/}
                        <Spacer size="16" />
                      </>
                    )}

                    {client?.type === 'new' && (
                      <>
                        <Spacer size="16" />
                        <TypeSafeTextInput
                          codec={FullnameFromString}
                          value={clientName.input}
                          onChange={setClientName}
                          placeholder={tt('clientName')}
                          showError={!clientFormIsEmpty && showErrors}
                          textContentType="name"
                        />
                        <Spacer size="8" />
                        <TypeSafeTextInput
                          codec={StrictPhoneNumberParsed}
                          value={clientPhoneNumber.input}
                          onChange={setClientPhoneNumber}
                          placeholder={tt('addPhoneNumber')}
                          showError={!clientFormIsEmpty && showErrors}
                          keyboardType="phone-pad"
                          textContentType="telephoneNumber"
                        />
                        <Spacer size="8" />
                        <SmallBody style={{ color: colors.COMET }}>{tt('addPhoneTo')}</SmallBody>
                        <Spacer size="16" />
                      </>
                    )}
                  </>
                )
              ) : clientDetails?.type === 'existing' ? (
                <ClientListItem client={clientDetails.client}>
                  {/*{usableMemberships.length > 0 ? (*/}
                  {/*  <>*/}
                  {/*    <Spacer size={4} />*/}
                  {/*    <FlashyLabel*/}
                  {/*      text={tt('usableMembership', {*/}
                  {/*        count: 10,*/}
                  {/*        total: usableMemberships.length,*/}
                  {/*      })}*/}
                  {/*      type={'info'}*/}
                  {/*    />*/}
                  {/*  </>*/}
                  {/*) : null}*/}
                </ClientListItem>
              ) : (
                <>
                  <Body>{tt('noClientSelected')}</Body>
                  <Spacer size="16" />
                </>
              )}
            </View>
            <Spacer size="16" color={colors.ALABASTER} />
            <View style={styles.hrPadding}>
              <Spacer size="16" />
              <Row>
                <H3s style={{ flex: 1 }}>{tt('service')}</H3s>
                {canAddMoreServices && (services?.length ?? 0 > 0) && canEdit.services ? (
                  <TouchableOpacity
                    onPress={onAddServicePress}
                    disabled={onAddServicePress === undefined}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Icon type="plus-screw-small" />
                    <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link]}>
                      Adaugă încă un serviciu
                    </SmallBody>
                  </TouchableOpacity>
                ) : null}
              </Row>
              <Spacer size="16" />
              {nonEmptyServices !== undefined ? (
                nonEmptyServices.map((row, idx) => (
                  <BookedServiceWithWorkerListItem
                    key={`service-at-${idx}`}
                    clientId={client?.type === 'existing' ? client.client._id : undefined}
                    type={row.type}
                    service={row.service}
                    worker={row.worker}
                    workerServices={row.workerServices}
                    startTime={row.startTime}
                    icon={canEdit.services ? 'remove' : undefined}
                    onIconPress={() => {
                      if (onRemoveServicePress) {
                        onRemoveServicePress(row.service, idx);
                      }
                    }}
                    hasBorder
                    timeZone={timeZone}
                    style={{
                      container: { marginBottom: 16 },
                      simpleListItem: { paddingLeft: sizes[12], paddingBottom: 0 },
                      warningLabel: { paddingLeft: sizes[12] },
                      memberships: { paddingLeft: sizes[12] },
                    }}
                    memberships={usableMemberships.filter((m) => m.items.some((i) => i._id === row.service._id))}
                  />
                ))
              ) : canAddMoreServices && canEdit.services ? (
                <>
                  <SelectedServiceItem
                    onPress={onAddServicePress}
                    hasError={
                      (services?.length ?? 0) === 0 && showErrors && !servicesIsValid
                        ? { message: tt('selectService') }
                        : undefined
                    }
                  />
                  <Spacer size="16" />
                </>
              ) : null}

              <SelectedPerformerItem
                disabled={!(canEdit.worker && canWriteAllBookings)}
                selected={performer}
                onPress={onSelectWorkerPress}
                hasError={performer === undefined && showErrors ? { message: tt('selectAPro') } : undefined}
              />
              {/* <Spacer size="16" />
              <TouchableOpacity disabled={true} style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Icon type="gift-card-small" />
                <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link]}>Adaugă card cadou</SmallBody>
              </TouchableOpacity> */}
              <Spacer size="24" />
            </View>

            <Spacer size="16" color={colors.ALABASTER} />

            <View style={styles.hrPadding}>
              <Spacer size="16" />
              <Row>
                <Column flex={1}>
                  <InputWithLabel label={tt('dateHour')}>
                    <SelectButton
                      text={capitalize(startDateTime.toFormat('d LLL, HH:mm', { locale: 'ro' }))}
                      onPress={() => {
                        setShowSelectStartDateScreen(true);
                      }}
                      disabled={!canEdit.start}
                    />
                  </InputWithLabel>
                </Column>
                <HSpacer left={8} right={8} />
                <Column flex={1}>
                  <InputWithLabel label={tt('until')}>
                    <DayTimeSelect
                      start={startDateTime}
                      end={endDateEnd}
                      value={endDateTime}
                      timeZone={timeZone}
                      step={TimeSelectStep}
                      onChange={setEndTimeCallback}
                      showDuration
                      disabled={!canEdit.end}
                    />
                  </InputWithLabel>
                </Column>
              </Row>

              <Spacer size="24" />

              {notesInputVisible ? (
                <>
                  <InputWithLabel
                    label={tt('observations')}
                    isError={showErrors && !notesValue.isValid}
                    errorText={tt('errorObservations')}
                  >
                    <TypeSafeTextInput
                      codec={OptionalNotes}
                      value={notesValue.input}
                      onChange={setNotesCallback}
                      placeholder={tt('onlyForPro')}
                      multiline
                      numberOfLines={3}
                      onFocus={() => scrollTo(411)}
                      editable={canEdit.notes}
                    />
                  </InputWithLabel>
                  <Spacer size="16" />
                </>
              ) : null}

              {recurrenceRuleIsPresent && (
                <>
                  <InputWithLabel label={tt('repeat')}>
                    <TouchableOpacity onPress={editRecurrenceRuleCallback} disabled={!canEdit.recurrenceRule}>
                      <SimpleListItem icon="arrow-right" iconPosition="right" style={{ paddingTop: 8 }}>
                        <RecurrenceText recurrenceRule={recurrenceRule} />
                      </SimpleListItem>
                    </TouchableOpacity>
                  </InputWithLabel>
                  <Spacer size="12" />
                </>
              )}

              <Row>
                {!notesInputVisible && canEdit.notes && (
                  <>
                    <TouchableOpacity
                      onPress={() => {
                        setShowNotesClicked(true);
                      }}
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Icon type="plus-screw-small" />
                      <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link]}>Observații</SmallBody>
                    </TouchableOpacity>
                    <HSpacer left={16} right={16} />
                  </>
                )}
                {!recurrenceRuleIsPresent && canEdit.recurrenceRule && (
                  <>
                    <TouchableOpacity
                      onPress={editRecurrenceRuleCallback}
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Icon type="recurring" />
                      <SmallBody style={[meroStyles.text.semibold, meroStyles.text.link]}>Repetare</SmallBody>
                    </TouchableOpacity>
                  </>
                )}
              </Row>
            </View>

            {mode === 'edit' && onRemoveBookingPress !== undefined ? (
              <>
                <Spacer size="16" />
                <Button
                  size="large"
                  text={tt('deleteAppointment')}
                  backgroundColor="transparent"
                  color={colors.RADICAL_RED}
                  onClick={onRemoveBookingPress}
                />
              </>
            ) : null}
            <Spacer size={48} />
          </ScrollView>
        </AvoidKeyboard>
      </SafeAreaView>

      {/* Elements dropping shadows seems to draw over the ones with position absolute on Android */}
      {showSubmitButton ? (
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            {totalDurationInMinutes !== undefined || totalStr !== undefined ? (
              <>
                <Row>
                  <Column style={{ flex: 1 }}>
                    {totalDurationInMinutes !== undefined ? (
                      <Row>
                        <Body style={meroStyles.text.semibold}>{tt('duration')}: </Body>
                        <Body>{formatDurationInMinutes(totalDurationInMinutes)}</Body>
                      </Row>
                    ) : null}
                  </Column>
                  <Column style={{ flex: 1 }}>
                    {totalStr !== undefined ? (
                      <Row style={{ justifyContent: 'flex-end' }}>
                        <Body style={meroStyles.text.semibold}>{tt('total')}: </Body>
                        <Body>{totalStr}</Body>
                      </Row>
                    ) : null}
                  </Column>
                </Row>
                <Spacer size="16" />
              </>
            ) : null}
            {isPhone ? (
              <Button
                text={mode === 'add' ? tt('saveAppointment') : tt('saveChanges')}
                onClick={saveBookingCallback}
                disabled={onSaveBooking === undefined}
              />
            ) : (
              <Button
                expand={false}
                containerStyle={{ alignSelf: 'center' }}
                text={mode === 'add' ? tt('saveAppointment') : tt('saveChanges')}
                onClick={saveBookingCallback}
                disabled={onSaveBooking === undefined}
              />
            )}
          </SafeAreaView>
        </FormCard>
      ) : null}

      {showSelectStartDateScreen
        ? Platform.select({
            // ios: (
            //   <DateTimePickerModal
            //     isVisible
            //     date={start}
            //     mode="datetime"
            //     is24Hour={true}
            //     minuteInterval={TimeSelectStep}
            //     themeVariant="light"
            //     onConfirm={setStartDateCallback}
            //     onCancel={() => {
            //       setShowSelectStartDateScreen(false);
            //     }}
            //     locale="ro_RO"
            //   />
            // ),
            default: (
              <View style={styles.modalScreen}>
                <SelectDateTimeModal
                  selected={start}
                  minDate={now}
                  onDateSelected={setStartDateCallback}
                  onClosePress={() => {
                    setShowSelectStartDateScreen(false);
                  }}
                  timeZone={timeZone}
                  timeStepMinutes={TimeSelectStep}
                />
              </View>
            ),
          })
        : null}

      {showRecurrenceOptions ? (
        <RecurrenceRuleOptionsScreen
          recurrenceRule={recurrenceRule}
          onOptionSelected={recurrenceOptionSelectedCallback}
          onDismiss={() => {
            setShowRecurrenceOptions(false);
          }}
          differenceInDays={differenceInDays}
        />
      ) : null}

      {showRecurrenceEditForm ? (
        <RecurrenceRuleEditScreen
          recurrenceRule={recurrenceRule}
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
          onBackPressed={() => {
            setShowRecurrenceEditForm(false);
          }}
          onSave={(newRule) => {
            setShowRecurrenceEditForm(false);
            if (onRecurrenceRuleChanged) {
              onRecurrenceRuleChanged(newRule);
            }
          }}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

const ClientTabs = {
  existing: { label: 'existingClient', value: 'existing' },
  new: { label: 'newClient', value: 'new' },
} as const;

export type ClientTabs = keyof typeof ClientTabs;

const ClientTabsOptions = [ClientTabs.existing, ClientTabs.new];

export default AddBookingScreen;
