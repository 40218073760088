import { PageId } from '@mero/api-sdk/dist/pages';
import { colors, Column } from '@mero/components';
import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import { useIsFocused } from '@react-navigation/native';

import { useMediaQueries } from '../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../contexts/AuthContext';
import { promiseDebounce } from '../../../../utils/functions';
import log from '../../../../utils/log';

const PendingApprovalsMobileIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G data-name="Group 6793">
      <G data-name="Group 6793">
        <Path fill="none" d="M4 4h24v24H4z" data-name="Rectangle 1517" />
        <G
          fill="none"
          stroke={props.color ?? '#080de0'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          transform="translate(3 3)"
        >
          <Circle cx={11} cy={11} r={11} transform="translate(2 2)" />
          <Circle cx={1.1} cy={1.1} r={1.1} transform="translate(11.9 11.9)" />
          <Path d="m13.719 13.833 4.414 2.838M13 4.933V11.9" data-name="layer1" />
        </G>
      </G>
      <Path fill="none" d="M0 0h32v32H0z" data-name="Rectangle 2629" />
    </G>
  </Svg>
);

export type Props = {
  onPress: () => void;
  pageId: PageId;
  color?: string;
};

export const PendingApprovalsButton: React.FC<Props> = ({ onPress, pageId, color }) => {
  const [hasPendingApprovals, setHasPendingApprovals] = React.useState(false);
  const isFocused = useIsFocused();
  const initialRender = React.useRef(true);
  const { isPhone } = useMediaQueries();

  React.useEffect(() => {
    initialRender.current = false;
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      promiseDebounce(
        'getWorkerWaitingList',
        () =>
          meroApi.calendar
            .fetchPagedWaitingList({ pageId, limit: 1 })
            .then((wl) => setHasPendingApprovals(wl.data.length > 0))
            .catch(log.error),
        1000,
      );
    }
  }, [isFocused]);

  return (
    <TouchableOpacity onPress={onPress} style={{ position: 'relative', zIndex: 1 }}>
      <Column
        style={
          !isPhone && {
            width: 43,
            height: 43,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: colors.GEYSER,
            borderWidth: 1,
            borderRadius: 43,
          }
        }
      >
        <PendingApprovalsMobileIcon color={color} />
      </Column>
      {hasPendingApprovals && (
        <Column
          style={{
            position: 'absolute',
            top: isPhone ? 4 : 2,
            right: isPhone ? 4 : 2,
            width: 8,
            height: 8,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: colors.WHITE,
            backgroundColor: colors.RADICAL_RED,
          }}
        />
      )}
    </TouchableOpacity>
  );
};
