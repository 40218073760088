import { Button, Spacer, H1, styles as meroStyles, Body } from '@mero/components';
import * as React from 'react';

import ModalScreenContainer from '../../components/ModalScreenContainer';

import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AuthContext } from '../../contexts/AuthContext';
import { RootStackParamList } from '../../types';

type Props = {
  readonly navigation: StackNavigationProp<RootStackParamList, 'ImpersonateScreen'>;
  readonly route: RouteProp<RootStackParamList, 'ImpersonateScreen'>;
};

const ImpersonateScreen: React.FC<Props> = ({ navigation, route }): React.ReactElement => {
  const [, authDispatch] = AuthContext.useContext();

  const token = route.params?.token;

  const goHome = React.useCallback(() => {
    navigation.replace('Index');
  }, [navigation]);

  React.useEffect(() => {
    let isMounted = true;

    if (token) {
      authDispatch.signInWith(token).then(() => {
        if (isMounted) {
          goHome();
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [token]);

  return (
    <ModalScreenContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
      <H1 style={meroStyles.text.alignCenter}>Autentificare Implicita</H1>
      <Spacer size="m" />
      {!token && <Body style={meroStyles.text.alignCenter}>Tokenul nu a fost specificat</Body>}
      <Spacer size="m" />
      <Button text="Acasă" size="small" onClick={goHome} />
    </ModalScreenContainer>
  );
};

export default ImpersonateScreen;
