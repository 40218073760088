import { SavedPageBillingDetails } from '@mero/api-sdk';
import { PageId } from '@mero/api-sdk/dist/pages';
import { BillingInfo } from '@mero/api-sdk/dist/payments/billing-info';
import { PageMonthlyInvoiceArchive } from '@mero/api-sdk/dist/payments/monthly-invoice-archive';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  H2s,
  Icon,
  InputWithLabel,
  Line,
  Row,
  Select,
  SmallBody,
  Spacer,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { reloadAsync } from 'expo-updates';
import * as E from 'fp-ts/Either';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Linking, ScrollView, TouchableOpacity } from 'react-native';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { images } from '../../../../../constants/images';

import { meroApi } from '../../../../../contexts/AuthContext';
import { BillingDetailsContext } from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { OnlinePaymentsContext } from '../../../../../contexts/OnlinePaymentsContext';
import { PageOnlinePaymentsSettingsStackParamList } from '../../../../../types';
import { nameGenerator } from '../../../../../utils/string';
import DisableOnlinePaymentDialog from './DisableOnlinePaymentDialog';

const ManageOnlinePayment = () => {
  const { t } = useTranslation('onlinePayments');
  const showError = useShowError();
  const navigation = useNavigation<NavigationProp<PageOnlinePaymentsSettingsStackParamList>>();
  const toast = useToast();

  const [pageState] = CurrentBusinessContext.useContext();
  const [billingDetailsState] = BillingDetailsContext.useContext();

  const [showDisableDialog, setShowDisableDialog] = React.useState(false);
  const [onlinePaymentInvoices, setOnlinePaymentInvoices] = React.useState<PageMonthlyInvoiceArchive[]>([]);
  const [onlinePaymentsState, { reloadAsync }] = OnlinePaymentsContext.useContext();

  const billingDetails = React.useMemo(() => {
    return billingDetailsState.billingDetails.find((billingDetail) => billingDetail.usage?.includes('UserPayments'));
  }, [billingDetailsState.billingDetails]);

  const goToBillingDetails = () => {
    navigation.navigate('BillingDetailsList');
  };
  const downloadInvoice = (url: string) => async () => {
    try {
      await Linking.openURL(url);
    } catch (error) {
      showError(error, t('downloadInvoiceError'));
    }
  };

  const getInvoices = async (pageId: PageId) => {
    try {
      if (pageState.type !== 'Loaded') {
        return;
      }
      const invoices = await meroApi.payments.getPageMonthlyInvoiceArchives({ pageId });
      setOnlinePaymentInvoices(invoices.result);
    } catch (error) {
      showError(error, t('getInvoicesError'));
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      getInvoices(pageState.page.details._id);
    }
  }, [pageState.type]);

  const toggleDisableDialog = () => {
    setShowDisableDialog(!showDisableDialog);
  };

  const changeCustomerType = async (value: 'New' | 'All') => {
    if (
      pageState.type !== 'Loaded' ||
      onlinePaymentsState.type !== 'Loaded' ||
      onlinePaymentsState.settings.requireAdvancePayment.type === 'Off'
    ) {
      return;
    }
    try {
      await meroApi.pages.updatePageOnlinePaymentsSettings({
        pageId: pageState.page.details._id,
        settings: {
          requireAdvancePayment: {
            ...onlinePaymentsState.settings.requireAdvancePayment,
            applyToCustomers: value,
          },
          advancePaymentTos: onlinePaymentsState.settings?.advancePaymentTos,
        },
      });
      await reloadAsync({ pageId: pageState.page.details._id });
      toast.show({ type: 'success', text: t('changesSaved') });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <ScrollView>
        <Spacer size={32} />
        <FormCard dropShaddow paddings="inputs" rounded style={{ marginHorizontal: 16 }}>
          {billingDetails ? (
            <TouchableOpacity
              style={{
                marginBottom: 16,
                borderWidth: 1,
                borderRadius: 6,
                borderColor: colors.GEYSER,
                paddingVertical: 12,
                paddingLeft: 12,
                paddingRight: 8,
                flexDirection: 'row',
              }}
              onPress={goToBillingDetails}
            >
              <Column style={{ flex: 1 }}>
                <Title>{t('billingInfo')}</Title>
                <SmallBody>
                  {billingDetails.billing.type === 'Company'
                    ? billingDetails.billing.name
                    : nameGenerator(billingDetails.billing, t('noName'))}
                </SmallBody>
                <SmallBody style={{ color: colors.COMET }}>
                  {billingDetails.billing.type === 'Company'
                    ? billingDetails.billing.contact.email
                    : billingDetails.billing.email}
                </SmallBody>
              </Column>
              <Icon type="arrow-right" />
            </TouchableOpacity>
          ) : (
            <Column
              style={{
                marginBottom: 16,
                borderWidth: 1,
                borderRadius: 6,
                borderColor: colors.GEYSER,
                paddingVertical: 12,
                paddingLeft: 12,
                paddingRight: 8,
              }}
            >
              <TouchableOpacity onPress={goToBillingDetails}>
                <Row style={{ alignItems: 'center' }}>
                  <Title style={{ flex: 1 }}>{t('billingInfo')}</Title>
                  <Icon type="arrow-right" />
                </Row>
                <Body style={{ flex: 1, color: colors.COMET }}>{t('missingBillingInfo')}</Body>
              </TouchableOpacity>
            </Column>
          )}
          {/*<Column>*/}
          {/*  <Title>{t('vat')}</Title>*/}
          {/*  <Row style={{ paddingTop: 8, alignItems: 'center' }}>*/}
          {/*    <Body>{t('defaultVatValue')}</Body>*/}
          {/*  </Row>*/}
          {/*</Column>*/}
          <TouchableOpacity
            style={{
              marginBottom: 16,
              borderWidth: 1,
              borderRadius: 6,
              borderColor: colors.GEYSER,
              paddingVertical: 12,
              paddingLeft: 12,
              paddingRight: 8,
              flexDirection: 'row',
            }}
            onPress={() => navigation.navigate('PageOnlinePaymentsAdvancedPayment')}
          >
            <Column style={{ flex: 1 }}>
              <Title>{t('advancedPayment')}</Title>
              <Body style={{ flex: 1, color: colors.COMET }}>
                {onlinePaymentsState.settings?.requireAdvancePayment.type === 'AllServices'
                  ? t('allServicesAdvancedPayment')
                  : onlinePaymentsState.settings?.requireAdvancePayment.type === 'SpecificServices'
                  ? t('someServicesAdvancedPayment')
                  : t('noAdvancedPayment')}
              </Body>
            </Column>
            <Icon type="next" color={colors.DARK_BLUE} />
          </TouchableOpacity>
          {onlinePaymentsState.settings && onlinePaymentsState.settings.requireAdvancePayment.type !== 'Off' && (
            <>
              <TouchableOpacity
                style={{
                  marginBottom: 16,
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 12,
                  paddingLeft: 12,
                  paddingRight: 8,
                  flexDirection: 'row',
                }}
                onPress={() => navigation.navigate('PageOnlinePaymentsTermsAndConditions')}
              >
                <Column style={{ flex: 1 }}>
                  <Title>{t('termsAndConditions')}</Title>
                  <Body style={{ flex: 1, color: colors.COMET }} numberOfLines={1} ellipsizeMode="tail">
                    {t('termsAndConditionsDescription')}
                  </Body>
                </Column>
                <Icon type="next" color={colors.DARK_BLUE} />
              </TouchableOpacity>
            </>
          )}
          {onlinePaymentsState.settings && onlinePaymentsState.settings.requireAdvancePayment.type !== 'Off' && (
            <>
              <InputWithLabel label={t('minPercentage')}>
                <Select
                  value={onlinePaymentsState.settings?.requireAdvancePayment.applyToCustomers}
                  onChange={changeCustomerType}
                  items={[
                    { label: t('allCustomers'), value: 'All' },
                    { label: t('newCustomers'), value: 'New' },
                  ]}
                />
              </InputWithLabel>
            </>
          )}
        </FormCard>
        <Spacer size={32} />
        <Button
          onClick={toggleDisableDialog}
          backgroundColor={colors.ALABASTER}
          color={colors.RADICAL_RED}
          size="small"
          text={t('deactivateOnlinePayment')}
        />
        <Spacer size={48} />
        <Column style={{ backgroundColor: colors.WHITE, paddingHorizontal: 24, flex: 1 }}>
          <H1 style={{ paddingTop: 32, paddingBottom: 24 }}>{t('bills')}</H1>
          {onlinePaymentInvoices.length > 0 ? (
            onlinePaymentInvoices.map((invoice) => (
              <React.Fragment key={invoice._id}>
                <TouchableOpacity style={{ paddingVertical: 16, flexDirection: 'row', alignItems: 'center' }}>
                  <Column style={{ padding: 4 }}>
                    <Image source={images.invoice} style={{ width: 24, height: 24 }} />
                  </Column>
                  <Title style={{ paddingLeft: 16, flex: 1 }}>
                    {DateTime.fromJSDate(invoice.createdAt).setLocale('ro').toFormat('LLLL yyyy')}
                  </Title>
                  <Button
                    onClick={downloadInvoice(invoice.downloadUrl)}
                    backgroundColor={colors.WHITE}
                    color={colors.DARK_BLUE}
                    expand={false}
                    text={t('download')}
                  />
                </TouchableOpacity>
                <Line />
              </React.Fragment>
            ))
          ) : (
            <Column style={{ alignItems: 'center', paddingBottom: 32 }}>
              <H2s style={{ textAlign: 'center' }}>{t('noBills')}</H2s>
              <Body style={{ textAlign: 'center' }}>{t('noBillsText')}</Body>
            </Column>
          )}
        </Column>
      </ScrollView>
      {showDisableDialog && (
        <DisableOnlinePaymentDialog onSuccess={toggleDisableDialog} onCancel={toggleDisableDialog} />
      )}
    </>
  );
};

export default ManageOnlinePayment;
