import { PageId, WorkerId } from '@mero/api-sdk';
import { GroupWithServices, GroupedWorkerServices, ServiceGroup, ServiceId } from '@mero/api-sdk/dist/services';
import {
  Body,
  Button,
  Checkbox,
  Column,
  FormCard,
  H1,
  H2,
  H2s,
  HSpacer,
  Line,
  MeroHeader,
  ModalOverlay,
  Row,
  SafeAreaView,
  SearchTextInput,
  SmallBody,
  Spacer,
  Title,
  colors,
  styles as meroStyles,
  useShowError,
  useToast,
} from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, ScrollView, TouchableOpacity, View } from 'react-native';
import { debounce } from 'throttle-debounce';

import {
  buildServiceSearchIndex,
  formatDuration,
} from '../MenuScreen/screens/ProsDashboardScreen/AddProServicesScreen';
import { getPrice } from '../MenuScreen/screens/ProsDashboardScreen/ProsSortScreen';

import MobileWebModalWrapper from '../../../components/MobileWebModalWrapper';
import ModalScreenContainer from '../../../components/ModalScreenContainer';

import { RouteProp, useIsFocused } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../contexts/CurrentBusiness';
import { ServicesContext } from '../../../contexts/ServicesContext';
import { SubscriptionContext } from '../../../contexts/SubscriptionContext';
import { AuthorizedStackParamList } from '../../../types';
import log from '../../../utils/log';
import DeleteServicesDialog from './DeleteServicesDialog';
import UpdateServicesCategoryDialog from './UpdateServicesCategoryDialog';
import UpdateServicesGroupDialog from './UpdateServicesGroupDialog';
import { styles } from './styles';

type Props = {
  readonly route: RouteProp<AuthorizedStackParamList, 'ServicesBulkOperations'>;
};

const ServicesBulkOperationsScreen: React.FC<Props> = ({ route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('services');
  const isFocused = useIsFocused();
  const { isPhone } = useMediaQueries();
  const showError = useShowError();
  const toast = useToast();
  const workerId = route.params.workerId;

  const [{ services }, { reload }] = ServicesContext.useContext();
  const [pageState, { getPageWorkersAndServices }] = CurrentBusinessContext.useContext();
  const [{ subscriptionInfo }] = SubscriptionContext.useContext();
  const shouldForceManualApproval = subscriptionInfo?.shouldForceManualApproval;

  const [showOptionsMenu, setShowOptionsMenu] = React.useState(false);
  const [showCategoryDialog, setShowCategoryDialog] = React.useState(false);
  const [showGroupDialog, setShowGroupDialog] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

  const [selectedServices, setSelectedServices] = React.useState<Record<ServiceId, boolean>>({});
  const [foundServices, setFoundServices] = React.useState<GroupWithServices[]>([]);
  const [groupedServices, setGroupedServices] = React.useState<GroupedWorkerServices | undefined>();

  const getGroupedServices = async (pageId: PageId, workerId: WorkerId) => {
    try {
      const groupedServices = await meroApi.pages.getWorkerGroupedServices({
        pageId: pageId,
        workerId: workerId,
      });

      setGroupedServices(groupedServices);
    } catch (e) {
      log.error('Error while loading worker grouped services', e);
    }
  };
  const groupedWithOthers = React.useMemo(() => {
    if (groupedServices) {
      return [
        ...groupedServices.grouped.filter((group) => group.services.length > 0),
        ...(groupedServices.others.length === 0
          ? []
          : [{ group: { name: 'Alte servicii', _id: '1' } as ServiceGroup, services: groupedServices.others }]),
      ];
    }

    return [
      ...services.grouped,
      ...(services.others.length === 0
        ? []
        : [{ group: { name: 'Alte servicii', _id: '1' } as ServiceGroup, services: services.others }]),
    ];
  }, [services, groupedServices]);

  const [query, setQuery] = React.useState('');
  const [debouncedQuery, setDebouncedQuery] = React.useState('');

  const index = React.useMemo(() => buildServiceSearchIndex(groupedWithOthers), [groupedWithOthers]);

  const debounceFunc = React.useMemo(
    () =>
      debounce(100, (q: string) => {
        setDebouncedQuery(q);
      }),
    [],
  );

  React.useEffect(() => {
    debounceFunc(query);
  }, [query]);

  React.useEffect(() => {
    setFoundServices(index.search(debouncedQuery));
  }, [debouncedQuery, index]);

  React.useEffect(() => {
    if (pageState.type === 'Loaded' && isFocused) {
      reload({ pageId: pageState.page.details._id });
      if (workerId) {
        getGroupedServices(pageState.page.details._id, workerId);
      }
    }
  }, [pageState.type, isFocused]);

  const selectedServicesCount = React.useMemo(
    () => Object.values(selectedServices).filter((v) => v).length,
    [selectedServices],
  );

  const totalServicesCount = React.useMemo(
    () => foundServices.reduce((acc, group) => acc + group.services.length, 0),
    [foundServices],
  );

  const selectedServiceIds = React.useMemo(() => {
    const serviceIds: ServiceId[] = [];
    Object.entries(selectedServices).forEach(([serviceId, isSelected]) => {
      if (isSelected) {
        serviceIds.push(serviceId as ServiceId);
      }
    });
    return serviceIds;
  }, [selectedServices]);

  const toggleServices = (servicesIds: ServiceId[], newValue?: boolean) => {
    const newSelectedServices = { ...selectedServices };
    servicesIds.forEach((serviceId) => {
      newSelectedServices[serviceId] = newValue ?? !newSelectedServices[serviceId];
    });
    setSelectedServices(newSelectedServices);
  };

  const bulkUpdatePageServicesVisibility = async (isPrivate: boolean) => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { totalCount, updatedCount } = await meroApi.pages.bulkUpdatePageServicesVisibility({
        pageId: pageState.page.details._id,
        serviceIds: selectedServiceIds,
        isPrivate: isPrivate,
        workerId: workerId,
      });

      reload({ pageId: pageState.page.details._id });
      getPageWorkersAndServices(pageState.page.details._id);
      goBack();
      toast.show({
        type: 'success',
        text: t('updateServicesSuccess', { totalCount, updatedCount }),
      });
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(t('errorUnknown'));
    }
  };

  const bulkUpdatePageServicesAutomaticApproval = async (automaticApproval: boolean) => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { totalCount, updatedCount } = await meroApi.pages.bulkUpdatePageServicesAutomaticApproval({
        pageId: pageState.page.details._id,
        serviceIds: selectedServiceIds,
        automaticApproval: automaticApproval,
        workerId: workerId,
      });

      reload({ pageId: pageState.page.details._id });
      getPageWorkersAndServices(pageState.page.details._id);
      goBack();
      toast.show({
        type: 'success',
        text: t('updateServicesSuccess', { totalCount, updatedCount }),
      });
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(t('errorUnknown'));
    }
  };

  const resetDefaultBulkOperation = async (workerId: WorkerId) => {
    if (pageState.type !== 'Loaded') {
      return;
    }

    try {
      const { totalCount, updatedCount } = await meroApi.pages.bulkResetWorkerServices({
        pageId: pageState.page.details._id,
        serviceIds: selectedServiceIds,
        workerId: workerId,
      });

      reload({ pageId: pageState.page.details._id });
      getPageWorkersAndServices(pageState.page.details._id);
      goBack();
      toast.show({
        type: 'success',
        text: t('updateServicesSuccess', { totalCount, updatedCount }),
      });
    } catch (e) {
      log.error('Failed while changing page visibility bulk operation', e);
      showError(t('errorUnknown'));
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canClose onClose={goBack} title={t('selectServices')} />
      <ScrollView style={{ flex: 1 }}>
        <Spacer size={16} />
        <Column style={{ marginHorizontal: 24 }}>
          <H1>{t('selectServices')}</H1>

          <Spacer size={16} />
          <SearchTextInput placeholder={t('searchByName')} value={query} onChange={setQuery} />

          <Spacer size={24} />
          <Row style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Body style={[meroStyles.text.semibold, { color: colors.COMET }]}>
              {t('selectedServices', { count: selectedServicesCount })}
            </Body>
            <TouchableOpacity
              onPress={() =>
                toggleServices(
                  foundServices.flatMap((s) => s.services.flatMap((s) => s._id)),
                  totalServicesCount !== selectedServicesCount,
                )
              }
            >
              <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>
                {totalServicesCount !== selectedServicesCount ? t('selectAll') : t('unselectAll')}
              </Body>
            </TouchableOpacity>
          </Row>
        </Column>

        <Spacer size={24} />
        {foundServices.length > 0 ? (
          <FormCard paddings="none" style={{ paddingVertical: 8, marginHorizontal: 16 }}>
            {foundServices.map(({ group, services }, index) => {
              const allServicesSelected =
                services.length !== 0 && services.every((service) => selectedServices[service._id]);
              const servicesIds = services.map((service) => service._id);

              return (
                <View key={group._id}>
                  {index !== 0 && <Line />}
                  <Column style={{ paddingHorizontal: 16 }}>
                    <Spacer size={24} />
                    <TouchableOpacity onPress={() => toggleServices(servicesIds, !allServicesSelected)}>
                      <Row>
                        <Checkbox
                          value={allServicesSelected}
                          onValueChange={() => toggleServices(servicesIds, !allServicesSelected)}
                        />
                        <HSpacer left={16} />
                        <H2 style={{ fontFamily: 'open-sans-bold', fontSize: 17 }}>{group.name}</H2>
                      </Row>
                    </TouchableOpacity>
                    {services.map((service, index) => (
                      <View key={service._id}>
                        {index !== 0 && <Line />}
                        <TouchableOpacity
                          key={service._id}
                          style={{ marginVertical: 16 }}
                          onPress={() => toggleServices([service._id])}
                        >
                          <Row>
                            <Checkbox
                              value={selectedServices[service._id]}
                              onValueChange={() => toggleServices([service._id])}
                            />
                            <HSpacer left={16} />
                            <Column style={{ flex: 1 }}>
                              <Body style={meroStyles.text.semibold}>{service.name}</Body>
                              <SmallBody style={meroStyles.text.hint}>
                                {formatDuration(service.durationInMinutes)},{' '}
                                {service.price.type === 'fixed' && service.price?.promo ? (
                                  <>
                                    <SmallBody style={meroStyles.text.hint}>
                                      {service.price.promo} lei{' '}
                                      <SmallBody
                                        style={{
                                          textDecorationLine: 'line-through',
                                          textDecorationStyle: 'solid',
                                        }}
                                      >
                                        ({service.price.fixed} lei)
                                      </SmallBody>
                                    </SmallBody>
                                  </>
                                ) : (
                                  <SmallBody style={meroStyles.text.hint}>{getPrice(service.price)}</SmallBody>
                                )}
                              </SmallBody>
                            </Column>
                          </Row>
                        </TouchableOpacity>
                      </View>
                    ))}
                    {services.length === 0 && <Spacer size={16} />}
                    <Spacer size={8} />
                  </Column>
                </View>
              );
            })}
          </FormCard>
        ) : (
          <>
            <H2s style={meroStyles.text.alignCenter}>{t('noResultsFor')}</H2s>
            <H2s style={meroStyles.text.alignCenter}>&quot;{query}&quot;</H2s>
            <Spacer size="8" />
            <Body style={meroStyles.text.alignCenter}>{t('checkSearch')}</Body>
          </>
        )}
        <Spacer size={144} />
      </ScrollView>

      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={false} text={t('chooseBulkOperation')} onPress={() => setShowOptionsMenu(true)} />
          ) : (
            <Button
              disabled={selectedServicesCount === 0}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('chooseBulkOperation')}
              onPress={() => setShowOptionsMenu(true)}
            />
          )}
        </SafeAreaView>
      </FormCard>

      {showCategoryDialog && <UpdateServicesCategoryDialog serviceIds={selectedServiceIds} onClose={goBack} />}

      {showGroupDialog && <UpdateServicesGroupDialog serviceIds={selectedServiceIds} onClose={goBack} />}

      {showDeleteConfirmation && (
        <DeleteServicesDialog onClose={() => setShowDeleteConfirmation(false)} serviceIds={selectedServiceIds} />
      )}

      {showOptionsMenu && (
        <ModalOverlay>
          <MobileWebModalWrapper position="bottom">
            <Pressable style={styles.optionsStretchContainer} onPress={() => setShowOptionsMenu(false)} />
            <Column style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom]}>
              <SafeAreaView edges={['bottom']}>
                <Column style={styles.optionsMenuItem}>
                  <H2 style={{ fontFamily: 'open-sans-bold', fontSize: 17 }}>{t('chooseBulkOperation')}</H2>
                  <Spacer size={4} />
                  <Body style={[meroStyles.text.hint, { color: colors.COMET }]}>
                    {t('selectedServices', { count: selectedServicesCount })}
                  </Body>
                </Column>
                {!workerId && (
                  <>
                    <Line />
                    <TouchableOpacity
                      style={styles.optionsMenuItem}
                      delayPressIn={0}
                      onPress={() => {
                        setShowOptionsMenu(false);
                        setShowGroupDialog(true);
                      }}
                    >
                      <Title>{t('updateGroupBulkOperation')}</Title>
                    </TouchableOpacity>
                  </>
                )}
                {!workerId && (
                  <>
                    <Line />
                    <TouchableOpacity
                      style={styles.optionsMenuItem}
                      delayPressIn={0}
                      onPress={() => {
                        setShowOptionsMenu(false);
                        setShowCategoryDialog(true);
                      }}
                    >
                      <Title>{t('updateCategoryBulkOperation')}</Title>
                    </TouchableOpacity>
                  </>
                )}
                <Line />
                <TouchableOpacity
                  style={styles.optionsMenuItem}
                  delayPressIn={0}
                  onPress={() => {
                    bulkUpdatePageServicesVisibility(false);
                  }}
                >
                  <Title>{t('listProfileOperation')}</Title>
                </TouchableOpacity>
                <Line />
                <TouchableOpacity
                  style={styles.optionsMenuItem}
                  delayPressIn={0}
                  onPress={() => {
                    bulkUpdatePageServicesVisibility(true);
                  }}
                >
                  <Title>{t('unlistProfileOperation')}</Title>
                </TouchableOpacity>
                <Line />
                <TouchableOpacity
                  disabled={shouldForceManualApproval}
                  style={styles.optionsMenuItem}
                  delayPressIn={0}
                  onPress={() => {
                    bulkUpdatePageServicesAutomaticApproval(false);
                  }}
                >
                  <Title style={[shouldForceManualApproval && { color: colors.COMET }]}>
                    {t('disableAutomaticApprovalOperation')}
                  </Title>
                </TouchableOpacity>
                <Line />
                <TouchableOpacity
                  disabled={shouldForceManualApproval}
                  style={styles.optionsMenuItem}
                  delayPressIn={0}
                  onPress={() => {
                    bulkUpdatePageServicesAutomaticApproval(true);
                  }}
                >
                  <Title style={[shouldForceManualApproval && { color: colors.COMET }]}>
                    {t('enableAutomaticApprovalOperation')}
                  </Title>
                </TouchableOpacity>
                {workerId ? (
                  <>
                    <Line />
                    <TouchableOpacity
                      style={styles.optionsMenuItem}
                      delayPressIn={0}
                      onPress={() => {
                        resetDefaultBulkOperation(workerId);
                      }}
                    >
                      <Title>{t('resetDefaultBulkOperation')}</Title>
                    </TouchableOpacity>
                  </>
                ) : (
                  <>
                    <Line />
                    <TouchableOpacity
                      style={styles.optionsMenuItem}
                      delayPressIn={0}
                      onPress={() => {
                        setShowOptionsMenu(false);
                        setShowDeleteConfirmation(true);
                      }}
                    >
                      <Title style={{ color: colors.RADICAL_RED }}>{t('deleteOperation')}</Title>
                    </TouchableOpacity>
                  </>
                )}
              </SafeAreaView>
            </Column>
          </MobileWebModalWrapper>
        </ModalOverlay>
      )}
    </ModalScreenContainer>
  );
};

export default ServicesBulkOperationsScreen;
