import { BillingInfo } from '@mero/api-sdk/dist/payments/billing-info';
import { Body, Button, colors, FormCard, Spacer, Title, useShowError } from '@mero/components';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Platform, ScrollView } from 'react-native';

import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import { BillingDetailsContext } from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../../../contexts/IntercomContext';
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext';
import { PageOnlinePaymentsSettingsStackParamList } from '../../../../../types';
import { styles } from '../ProsDashboardScreen/AddNewProScreen.styles';

const ActivateOnlinePayment = () => {
  const { t } = useTranslation('onlinePayments');
  const { isPhone } = useMediaQueries();
  const showError = useShowError();
  const navigation = useNavigation<NavigationProp<PageOnlinePaymentsSettingsStackParamList>>();

  const [pageState] = CurrentBusinessContext.useContext();
  const [, { openChat }] = IntercomContext.useContext();
  const [{ billingDetails }] = BillingDetailsContext.useContext();

  const block = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const activeSelectedBillingDetails = React.useMemo(
    () => billingDetails.find((b) => b.usage?.includes('UserPayments')),
    [billingDetails],
  );

  const saveChanges = async () => {
    try {
      if (block.current || pageState.type !== 'Loaded') {
        return;
      }
      block.current = true;
      setIsLoading(true);

      if (activeSelectedBillingDetails) {
        const redirectUri =
          Platform.OS === 'web'
            ? `${window.location.origin}/page/current/online-payments`
            : config.stripeRedirectUri.onlinePayments;

        const oauth = await meroApi.payments.getStripeConnectOauthLink({
          pageId: pageState.page.details._id,
          redirectUri,
        });

        if (Platform.OS === 'web') {
          window.location.href = oauth.result;
        } else {
          navigation.navigate('PageOnlinePaymentsStripe', { oauthUrl: oauth.result, title: t('stripe') });
        }
      } else {
        navigation.navigate('BillingDetailsList');
      }
    } catch (error) {
      showError(error, t('activateError'));
    } finally {
      setIsLoading(false);
      block.current = false;
    }
  };

  return (
    <>
      <ScrollView style={{ paddingHorizontal: 16, flex: 1 }}>
        <Spacer size={6} />
        <Body style={{ paddingHorizontal: 8 }}>{t('description')}</Body>
        <Spacer size={8} />
        <FormCard dropShaddow paddings="none" rounded style={{ paddingHorizontal: 16, paddingVertical: 24 }}>
          <Title>{t('q1Title')}</Title>
          <Spacer size={6} />
          <Body>
            <Trans i18nKey="q1Description" t={t} />
          </Body>
          <Spacer size={24} />
          <Title>{t('q2Title')}</Title>
          <Spacer size={6} />
          <Body>
            <Trans i18nKey="q2Description" t={t} />
          </Body>
          <Spacer size={24} />
          <Title>{t('q3Title')}</Title>
          <Spacer size={6} />
          <Body>
            <Trans i18nKey="q3Description" t={t}>
              1<Title>2</Title>3
            </Trans>
          </Body>
          <Spacer size={24} />
          <Title>{t('q4Title')}</Title>
          <Spacer size={6} />
          <Body>
            <Trans i18nKey="q4Description" t={t}>
              1<Title>2</Title>3
            </Trans>
          </Body>
          <Spacer size={24} />
          <Title>{t('q5Title')}</Title>
          <Spacer size={6} />
          <Body>
            <Trans i18nKey="q5Description" t={t}>
              1
              <Title style={{ color: colors.DARK_BLUE }} onPress={openChat}>
                2
              </Title>
              3
            </Trans>
          </Body>
        </FormCard>
        <Spacer size={96} />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button disabled={block.current || isLoading} text={t('activateOnlinePayment')} onClick={saveChanges} />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={t('activateOnlinePayment')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </>
  );
};

export default ActivateOnlinePayment;
