import { isDefined } from '@mero/api-sdk';
import { CheckoutUserPreview } from '@mero/api-sdk/dist/checkout/checkoutUserPreview';
import { MergedService, mergeServices, SavedService } from '@mero/api-sdk/dist/services';
import { useShowError } from '@mero/components';
import { One } from '@mero/shared-sdk/dist/numbers/numbers';
import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ServicesGroup } from '../../../components/GroupedServicesList';
import SelectServiceScreenView from '../../../components/SelectServiceScreen';

import { StackScreenProps } from '@react-navigation/stack';

import { useEscPressWeb } from '../../../hooks/useEscPressWeb';
import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutFormContext, ItemService } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList, CheckoutSubStackParamList } from '../../../types';
import { getPriceValue } from '../../../utils/number';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<CheckoutSubStackParamList & RootStackParamList & AuthorizedStackParamList, 'SelectServiceScreen'>;

export const serviceToCheckoutItem = (
  service: Pick<SavedService, '_id' | 'name' | 'durationInMinutes' | 'price'>,
  user: CheckoutUserPreview,
): Omit<ItemService, 'type'> => ({
  service: {
    _id: service._id,
    name: service.name,
    durationInMinutes: service.durationInMinutes,
    groupIds: undefined,
    price:
      service.price.type === 'hidden'
        ? { type: 'Hidden' }
        : service.price.type === 'fixed'
        ? {
            type: 'Fixed',
            fixed: {
              amount: {
                value: service.price.promo ?? service.price.fixed,
              },
              unit: 'RON',
            },
          }
        : {
            type: 'Range',
            range: {
              from: {
                amount: {
                  value: service.price.rangePromo?.from ?? service.price.range.from ?? 0,
                },
                unit: 'RON',
              },
              to: {
                amount: {
                  value: service.price.rangePromo?.to ?? service.price.range.to ?? 0,
                },
                unit: 'RON',
              },
            },
          },
  },
  quantity: 1 as One,
  total: {
    amount: {
      amount: {
        value: getPriceValue(service.price),
      },
      unit: 'RON',
    },
    vatStatus: {
      type: 'Included',
      rate: undefined,
    },
  },
  saleOwner: user,
});

const SelectServiceScreen: React.FC<Props> = ({ page, authorization, route }) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  useEscPressWeb({
    onPress: goBack,
  });
  const showError = useShowError();
  const { workerId } = route.params ?? {};

  const [, { setItem }] = CheckoutFormContext.useContext();

  const [services, setServices] = React.useState<ServicesGroup[]>([]);

  const selectServiceCallback = React.useCallback(
    (mergedService: MergedService) => {
      const service = page.details.services.find((s) => s._id === mergedService._id);

      if (!service) {
        return;
      }

      const worker = workerId ? page.workers.find((w) => w._id === workerId) : undefined;
      const member =
        (worker
          ? page.members.find((m) => m.user._id === worker?.user._id)
          : page.members.find((m) => m.user._id === authorization.user._id)) ?? page.members[0];

      const user = {
        _id: member.user._id,
        phone: member.user.phone ?? '074000000',
        profile: {
          firstname: member.user.profile.firstname,
          lastname: member.user.profile.lastname,
          photo: member.user.profile.photo,
        },
      };

      setItem({
        type: 'Service',
        ...serviceToCheckoutItem(service, user),
      });
      goBack();
    },
    [goBack],
  );

  const getServices = React.useCallback(async () => {
    try {
      const groupedServices = await meroApi.pages.getGroupedServices(page.details._id);
      const services = groupedServices.grouped
        .map((group) => ({
          group: group.group,
          services: group.services.map((service) => mergeServices(service, page.details.services)).filter(isDefined),
        }))
        .filter((group) => group.services.length > 0);

      setServices(
        groupedServices.others.length > 0
          ? [
              ...services,
              {
                group: {
                  name: 'Alte Servicii',
                },
                services: groupedServices.others
                  .map((service) => mergeServices(service, page.details.services))
                  .filter(isDefined),
              },
            ]
          : services,
      );
    } catch (e) {
      showError(e);
    }
  }, [page.details._id]);

  React.useEffect(() => {
    getServices();
  }, [page]);

  return (
    services.length > 0 && (
      <SelectServiceScreenView services={services} onBackPressed={goBack} onServiceSelected={selectServiceCallback} />
    )
  );
};

export default pipe(SelectServiceScreen, CurrentBusiness, Authorized);
