import { RoIBANish, RoRegistrationNumber } from '@mero/api-sdk';
import { PageGiftCardSettings } from '@mero/api-sdk/dist/pages/page-gift-card-settings';
import { PageMonthlyInvoiceArchive } from '@mero/api-sdk/dist/payments/monthly-invoice-archive';
import {
  Body,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  Icon,
  Line,
  ListItem,
  MeroHeader,
  Row,
  SmallBody,
  Spacer,
  Text,
  TextInput,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { capitalize } from '@mero/shared-components';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Image, Linking, Platform, ScrollView, TouchableOpacity } from 'react-native';

import { FlashyLabel } from '../../../../../components/FlashyLabel';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';
import { useSafeInput } from '../../../../../hooks/useSafeInput';

import { images } from '../../../../../constants/images';

import { AppStorage } from '../../../../../app-storage';
import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import { BillingDetailsProps, withBillingDetails } from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, GiftCardsStackParamList, RootStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { nameGenerator } from '../../../../../utils/string';
import { styles } from './PageBillingDetailsList.style';

type CheckInputProps = {
  label: string;
  checked: boolean;
  disabled?: boolean;
};

const CheckInput: React.FC<CheckInputProps> = ({ label, checked = false, disabled = false }) => {
  return (
    <ListItem
      LeftComponent={() => (
        <Icon
          type={checked ? 'checked' : 'unchecked'}
          disabled={disabled}
          size={24}
          color={disabled ? '#A8AABE' : undefined}
        />
      )}
      leftContainerStyle={{ justifyContent: 'flex-start', width: 24 }}
      centerContainerStyle={{ justifyContent: 'flex-start', marginRight: 16, marginLeft: 16 }}
    >
      <Title>{label}</Title>
    </ListItem>
  );
};

type Props = StackScreenProps<RootStackParamList & GiftCardsStackParamList, 'GiftCards'> &
  CurrentBusinessProps &
  BillingDetailsProps & {
    // pass
  };

const PageGiftCardsSettingsScreen: React.FC<Props> = ({
  navigation,
  page,
  billingDetails,
  tempSelectedBillingDetails,
}) => {
  const [, { updateGiftCardSettings, updatePageDetails }] = CurrentBusinessContext.useContext();

  const { t } = useTranslation('giftCards');
  const goBack = useGoBack();
  const isFocused = useIsFocused();
  const toast = useToast();
  const { isPhone } = useMediaQueries();
  const showError = useShowError();

  const stripeConnectionInProgress = React.useRef(false);

  const giftcardBillingDetails = React.useMemo(
    () => tempSelectedBillingDetails ?? billingDetails.find((b) => b.usage?.includes('MeroGiftCards')),
    [billingDetails, tempSelectedBillingDetails],
  );

  const [iban, setIban] = useSafeInput(RoIBANish.JSON)();

  const [isSaving, setIsSaving] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [invoices, setInvoices] = React.useState<PageMonthlyInvoiceArchive[]>([]);
  const [giftCardSettings, setGiftCardSettings] = React.useState<PageGiftCardSettings>({
    perSalonEnabled: false,
    universalEnabled: false,
  });

  React.useEffect(() => {
    setGiftCardSettings(page.details.giftCardSettings);
  }, [page.details.giftCardSettings]);

  React.useEffect(() => {
    if (isFocused) {
      getInvoices();
    }
  }, [isFocused]);

  React.useEffect(() => {
    const getIban = async () => {
      try {
        const billing = await meroApi.pro.pageBillingDetails.getPageMeroGiftCardsBilling(page.details._id);
        setIban(billing?.settlementAccount);
      } catch (error) {
        log.error('Failed to load iban', error);
      }
    };

    getIban();
  }, [page.details._id]);

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      const params = new URLSearchParams(window.location.search);
      if (params.has('code')) {
        const code = params.get('code');
        if (code) {
          connectStripeWeb(code);
        }
      }
    } else {
      connectStripeApp();
    }
  }, [page.details._id]);

  const onViewDetailsPresses = () => {
    if (Platform.OS === 'web') {
      Linking.openURL(config.giftCardUrl);
    } else {
      navigation.navigate('Authorized', {
        screen: 'CustomWebview',
        params: {
          url: config.giftCardUrl,
          title: t('giftCards'),
        },
      });
    }
  };

  const getInvoices = async () => {
    try {
      const response = await meroApi.payments.getPageMonthlyInvoiceArchives({ pageId: page.details._id });
      setInvoices(response.result);
    } catch (error) {
      log.error('Failed to load invoices', error);
      toast.show({
        type: 'error',
        text: t('monthlyInvoicesError'),
      });
    }
  };

  const downloadInvoice = (url: string) => async () => {
    try {
      await Linking.openURL(url);
    } catch (error) {
      toast.show({
        type: 'error',
        text: t('downloadInvoiceError'),
      });
    }
  };

  const connectStripeWeb = async (code: string) => {
    try {
      await meroApi.payments.connectStripeAccount({
        pageId: page.details._id,
        code,
      });

      setGiftCardSettings({
        ...giftCardSettings,
        perSalonEnabled: true,
      });
      await meroApi.pages.updatePageGiftCardSettings({
        pageId: page.details._id,
        settings: {
          universalEnabled: true,
          perSalonEnabled: true,
        },
      });
      updatePageDetails(page.details._id);
    } catch (error) {
      log.error('Failed to connect stripe', error);
      toast.show({
        type: 'error',
        text: t('connectStripeError'),
      });
    }
  };

  const connectStripeApp = async () => {
    try {
      const code = await AppStorage.getStripeCode();
      if (!code) {
        return;
      }
      stripeConnectionInProgress.current = true;
      await meroApi.payments.connectStripeAccount({
        pageId: page.details._id,
        code,
      });

      setGiftCardSettings({
        ...giftCardSettings,
        perSalonEnabled: true,
      });
      await meroApi.pages.updatePageGiftCardSettings({
        pageId: page.details._id,
        settings: {
          universalEnabled: true,
          perSalonEnabled: true,
        },
      });
      await AppStorage.deleteStripeCode();
      updatePageDetails(page.details._id);
    } catch (error) {
      log.error('Failed to connect stripe', error);
      toast.show({
        type: 'error',
        text: t('connectStripeError'),
      });
    } finally {
      stripeConnectionInProgress.current = false;
    }
  };

  const goToStripe = async () => {
    try {
      // const redirectUri = 'https://pro1.dev.mero.ro/drawer/page/gift-cards?hideLayout=true';
      const redirectUri =
        Platform.OS === 'web'
          ? `${window.location.origin}/page/current/gift-cards`
          : config.stripeRedirectUri.giftCards;

      const oauth = await meroApi.payments.getStripeConnectOauthLink({
        pageId: page.details._id,
        redirectUri,
      });

      if (Platform.OS === 'web') {
        window.location.href = oauth.result;
      } else {
        navigation.navigate('Stripe', { oauthUrl: oauth.result, title: t('stripe') });
      }
    } catch (error) {
      toast.show({
        type: 'error',
        text: t('goToStripeError'),
      });
    }
  };

  const toggleSalonGiftCard = () => {
    if (giftCardSettings.perSalonEnabled) {
      setGiftCardSettings({
        ...giftCardSettings,
        perSalonEnabled: false,
      });
      return;
    }

    if (!page.details.hasStripeAccountConnected) {
      goToStripe();
      return;
    }

    setGiftCardSettings({
      ...giftCardSettings,
      perSalonEnabled: true,
      universalEnabled: true,
    });
  };

  const goToBilling = () => {
    navigation.navigate('BillingDetailsList');
  };

  const toggleUniversalGiftCard = () => {
    setGiftCardSettings({
      ...giftCardSettings,
      universalEnabled: !giftCardSettings.universalEnabled,
    });
  };

  const saveChanges = async () => {
    try {
      if (giftCardSettings.universalEnabled && (!iban.isValid || !giftcardBillingDetails)) {
        setShowErrors(true);
        return;
      }

      if (iban.isValid && giftcardBillingDetails) {
        await meroApi.pro.pageBillingDetails.setPageMeroGiftCardsBilling({
          pageId: page.details._id,
          billingDetailsId: giftcardBillingDetails._id,
          settlementAccount: iban.value,
        });
      }

      await meroApi.pages.updatePageGiftCardSettings({
        pageId: page.details._id,
        settings: {
          ...giftCardSettings,
        },
      });

      updateGiftCardSettings(page.details._id);

      toast.show({
        type: 'success',
        text: t('changesSaved'),
      });
    } catch (error) {
      showError(error);
    }
  };

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('giftCards')} />
      <ScrollView style={{ flex: 1 }}>
        <Column style={{ paddingHorizontal: 16 }}>
          <Column
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 32,
              paddingBottom: 16,
            }}
          >
            <Image source={images.giftCard} style={[Platform.OS === 'web' && { width: 120, height: 120 }]} />
          </Column>
          <Column style={{ paddingBottom: 36 }}>
            <H1 style={{ textAlign: 'center' }}>{t('giftCards')}</H1>
          </Column>
          <FormCard paddings="none" style={{ paddingHorizontal: 16, paddingVertical: 24 }} rounded dropShaddow>
            <TouchableOpacity onPress={toggleSalonGiftCard} disabled={!page.details.hasStripeAccountConnected}>
              <CheckInput
                label={t('salonGiftCardTitle')}
                checked={giftCardSettings.perSalonEnabled}
                disabled={!page.details.hasStripeAccountConnected}
              />
              <Column style={{ paddingLeft: 40 }}>
                <Spacer size={4} />
                <FlashyLabel type="info" text={t('salonGiftCardFees')} />
                <Spacer size={8} />
                <SmallBody>
                  <Trans ns={'giftCards'} t={t} i18nKey="salonGiftCardSubtitle">
                    0
                  </Trans>
                </SmallBody>
              </Column>
            </TouchableOpacity>
            {!page.details.hasStripeAccountConnected && (
              <>
                <Spacer size={24} />
                <Button
                  color={colors.DARK_BLUE}
                  backgroundColor={colors.SKY_BLUE}
                  text={t('enableCardPayment')}
                  onPress={goToStripe}
                />
              </>
            )}
          </FormCard>
          <Spacer size={16} />
          <FormCard paddings="none" style={{ paddingVertical: 24 }} rounded dropShaddow>
            <TouchableOpacity
              style={{ paddingHorizontal: 16 }}
              disabled={giftCardSettings.perSalonEnabled}
              onPress={toggleUniversalGiftCard}
            >
              <CheckInput
                label={t('generalGiftCardTitle')}
                checked={giftCardSettings.universalEnabled}
                disabled={giftCardSettings.perSalonEnabled}
              />
              <Column style={{ paddingLeft: 40 }}>
                <Spacer size={4} />
                <FlashyLabel type="info" text={t('salonGiftCardFees')} />
                <Spacer size={8} />
                <SmallBody>
                  <Trans ns={'giftCards'} t={t} i18nKey="generalGiftCardSubtitle">
                    0
                  </Trans>
                </SmallBody>
              </Column>
            </TouchableOpacity>
            <Spacer size={16} />
            <Line />
            <Spacer size={24} />
            <Column style={{ paddingHorizontal: 16 }}>
              {giftcardBillingDetails ? (
                <>
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      borderRadius: 6,
                      borderColor: colors.GEYSER,
                      paddingVertical: 12,
                      paddingLeft: 12,
                      paddingRight: 8,
                      flexDirection: 'row',
                    }}
                    onPress={goToBilling}
                  >
                    <Column style={{ flex: 1 }}>
                      <Title>{t('billingInfo')}</Title>
                      <SmallBody>
                        {giftcardBillingDetails.billing.type === 'Company'
                          ? giftcardBillingDetails.billing.name
                          : nameGenerator(giftcardBillingDetails.billing, t('noName'))}
                      </SmallBody>
                      <SmallBody style={{ color: colors.COMET }}>
                        {giftcardBillingDetails.billing.type === 'Company'
                          ? giftcardBillingDetails.billing.contact.email
                          : giftcardBillingDetails.billing.email}
                      </SmallBody>
                    </Column>
                    <Icon type="arrow-right" />
                  </TouchableOpacity>
                  <Spacer size="16" />
                  <InputWithLabel label={t('iban')} isError={showErrors && !iban.isValid} errorText={t('ibanError')}>
                    <TextInput
                      value={iban.value}
                      onChange={setIban}
                      placeholder={t('ibanPlaceholder')}
                      isError={showErrors && !iban.isValid}
                    />
                  </InputWithLabel>
                </>
              ) : (
                <Column
                  style={{
                    borderWidth: 1,
                    borderRadius: 6,
                    borderColor: colors.GEYSER,
                    paddingVertical: 12,
                    paddingLeft: 12,
                    paddingRight: 8,
                  }}
                >
                  <TouchableOpacity onPress={goToBilling}>
                    <>
                      <Row style={{ alignItems: 'center' }}>
                        <Title style={{ flex: 1 }}>{t('billingInfo')}</Title>
                        <Icon type="arrow-right" />
                      </Row>
                      <Body style={{ flex: 1, color: colors.COMET }}>{t('missingBillingInfo')}</Body>
                    </>
                  </TouchableOpacity>
                  {showErrors && !giftcardBillingDetails && (
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('billingInfoError')}</SmallBody>
                  )}
                </Column>
              )}
            </Column>
          </FormCard>
          <Spacer size={24} />
          <Body style={{ paddingHorizontal: 16, textAlign: 'center' }}>
            <Trans ns={'giftCards'} t={t} i18nKey="giftCardsText">
              0
              <Text link semibold onPress={onViewDetailsPresses}>
                1
              </Text>
            </Trans>
          </Body>
        </Column>

        <Column style={{ marginTop: 48, paddingHorizontal: 24, backgroundColor: colors.WHITE, paddingBottom: 64 }}>
          <H1 style={{ paddingTop: 32, paddingBottom: 24 }}>{t('bills')}</H1>
          {invoices.map((invoice) => (
            <React.Fragment key={invoice._id}>
              <TouchableOpacity style={{ paddingVertical: 16, flexDirection: 'row', alignItems: 'center' }}>
                <Column style={{ padding: 4 }}>
                  <Image source={images.invoice} style={{ width: 24, height: 24 }} />
                </Column>
                <Title style={{ paddingLeft: 16, flex: 1 }}>
                  {capitalize(DateTime.fromJSDate(invoice.createdAt).setLocale('ro').toFormat('LLLL yyyy'))}
                </Title>
                <Button
                  onPress={downloadInvoice(invoice.downloadUrl)}
                  backgroundColor={colors.WHITE}
                  color={colors.DARK_BLUE}
                  expand={false}
                  text={t('download')}
                />
              </TouchableOpacity>
              <Line />
            </React.Fragment>
          ))}
          {invoices.length === 0 && <Body style={{ textAlign: 'center', paddingTop: 20 }}>{t('noBills')}</Body>}
        </Column>
        <Spacer size={96} />
      </ScrollView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
      >
        <SafeAreaView edges={['bottom']}>
          <Button text={t('saveChanges')} onPress={saveChanges} disabled={isSaving} />
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default pipe(PageGiftCardsSettingsScreen, withBillingDetails, CurrentBusiness);
