import { pipe } from 'fp-ts/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import PageBillingDetailsList from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageBillingDetailsList';
import PageBillingDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageBillingDetailsScreen';
import PageOnlinePaymentsAdvancedPayment from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsAdvancedPayment';
import PageOnlinePaymentsBillingDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsBillingDetailsScreen';
import PageOnlinePaymentsServiceSettings from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsServiceSettings';
import PageOnlinePaymentsSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsSettingsScreen';
import PageOnlinePaymentsStripeScreen from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsStripeScreen';
import PageOnlinePaymentsTermsAndConditions from '../screens/Authorized/MenuScreen/screens/PageOnlinePaymentsSettingsScreen/PageOnlinePaymentsTermsAndConditions';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withBillingDetailsContextProvider } from '../contexts/CheckoutSettingsContext';
import { withOnlinePaymentsContextProvider } from '../contexts/OnlinePaymentsContext';
import { withSubscriptionContextProvider } from '../contexts/SubscriptionContext';
import { PageOnlinePaymentsSettingsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<PageOnlinePaymentsSettingsStackParamList>();

function PageOnlinePaymentsSettingsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('onlinePayments');
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;

  return (
    <Stack.Navigator
      initialRouteName="PageOnlinePaymentsSettings"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="PageOnlinePaymentsSettings"
        component={PageOnlinePaymentsSettingsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetailsList"
        component={PageBillingDetailsList}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetails"
        component={PageBillingDetailsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsBillingDetails"
        component={PageOnlinePaymentsBillingDetailsScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsStripe"
        component={PageOnlinePaymentsStripeScreen}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsAdvancedPayment"
        component={PageOnlinePaymentsAdvancedPayment}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsServiceSettings"
        component={PageOnlinePaymentsServiceSettings}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="PageOnlinePaymentsTermsAndConditions"
        component={PageOnlinePaymentsTermsAndConditions}
        options={{ title: t('title'), cardStyle: modalStyle }}
      />
    </Stack.Navigator>
  );
}

export default pipe(
  PageOnlinePaymentsSettingsStackNavigator,
  withBillingDetailsContextProvider('Company'),
  // withSubscriptionContextProvider,
  withOnlinePaymentsContextProvider,
);
