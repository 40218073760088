import {
  RecurrenceRule,
  RecurrenceEndCriteria,
  RecurrenceTimeUnit,
  capitalize,
  Numbers,
  PositiveInt,
  positiveIntRange,
} from '@mero/api-sdk';
import { colors, styles as meroStyles } from '@mero/components';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Keyboard, ScrollView, StyleProp, ViewStyle, StyleSheet, TouchableOpacity } from 'react-native';

import AvoidKeyboard from '@mero/components/lib/components/AvoidKeyboard';
import Button from '@mero/components/lib/components/Button';
import FormCard from '@mero/components/lib/components/FormCard';
import HSpacer from '@mero/components/lib/components/HSpacer';
import Icon from '@mero/components/lib/components/Icon';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import Column from '@mero/components/lib/components/Layout/Column';
import Row from '@mero/components/lib/components/Layout/Row';
import Line from '@mero/components/lib/components/Line';
import ListItem from '@mero/components/lib/components/ListItem';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Select, { SelectItem } from '@mero/components/lib/components/Select';
import SelectButton from '@mero/components/lib/components/SelectButton';
import Spacer from '@mero/components/lib/components/Spacer';
import Body from '@mero/components/lib/components/Text/Body';
import H1 from '@mero/components/lib/components/Text/H1';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import SelectDateScreen from '../SelectDateScreen';
import { styles } from './styles';

type StopOptionProps = {
  title: string;
  checked?: boolean;
};

const StopOption: React.FC<StopOptionProps> = ({ title, checked = false }: StopOptionProps) => {
  return (
    <ListItem
      style={{ paddingTop: 16, paddingBottom: 16 }}
      LeftComponent={() => <Icon type={checked ? 'checked' : 'unchecked'} disabled={true} size={24} />}
      leftContainerStyle={{ justifyContent: 'flex-start', width: 24 }}
      centerContainerStyle={{ justifyContent: 'flex-start', marginRight: 16, marginLeft: 16 }}
    >
      <Body>{title}</Body>
    </ListItem>
  );
};

export type RecurrenceRuleEditScreenProps = Readonly<{
  /**
   * RecurrenceRule to edit
   */
  recurrenceRule?: RecurrenceRule.Any;

  style?: StyleProp<ViewStyle>;
  /**
   * Page back button pressed
   */
  onBackPressed?: () => void;

  onSave?: (newRule: RecurrenceRule.Any | undefined) => void;
}>;

const RecurrenceRuleEditScreen: React.FC<RecurrenceRuleEditScreenProps> = ({
  recurrenceRule,
  style,
  onBackPressed,
  onSave,
}: RecurrenceRuleEditScreenProps) => {
  const isKeyboardOpen = useKeyboardIsOpen();
  const { t } = useTranslation('booking');

  const [repeatValue, setRepeatValue] = React.useState<PositiveInt>(recurrenceRule?.repeatEvery?.value ?? Numbers[1]);

  const oneTo100 = React.useMemo(
    (): SelectItem<PositiveInt>[] =>
      positiveIntRange(PositiveInt.unsafeFrom(1), PositiveInt.unsafeFrom(100)).map((n) => ({
        value: n,
        label: `${n}`,
      })),
    [],
  );

  const [repeatUnit, setRepeatUnit] = React.useState<RecurrenceTimeUnit.Any>(recurrenceRule?.repeatEvery.unit ?? 'day');
  const repeatUnits = React.useMemo(
    (): SelectItem<RecurrenceTimeUnit.Any>[] => [
      { value: 'day', label: t('day', { count: repeatValue }) },
      { value: 'week', label: t('week', { count: repeatValue }) },
      { value: 'month', label: t('month', { count: repeatValue }) },
    ],
    [repeatValue],
  );

  const timeZone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);
  const [endsOn, setEndsOn] = React.useState<RecurrenceEndCriteria.Any>(
    recurrenceRule?.endsOn ?? RecurrenceEndCriteria.INDEFINITE,
  );

  // Ends on date data
  const [showSelectDateScreen, setShowSelectDateScreen] = React.useState(false);
  const [now] = React.useState(new Date());
  const [lastEndsOnDateValue, setLastEndsOnDateValue] = React.useState<Date>(endsOn.date ?? now);

  const setEndsOnDateCallback = React.useCallback(
    (dd: Date) => {
      setShowSelectDateScreen(false);
      const date = DateTime.fromJSDate(dd, { zone: timeZone }).endOf('day').toJSDate();
      setLastEndsOnDateValue(date);
      setEndsOn({ date: date });
    },
    [setEndsOn, setShowSelectDateScreen, setLastEndsOnDateValue, setEndsOn],
  );

  // Last valid value for RecurrenceEndsWhenOcurrend.times
  const [lastEndsWhenOcurrendValue, setLastEndsWhenOcurrendValue] = React.useState<PositiveInt>(
    RecurrenceEndCriteria.WhenOccurred.is(endsOn) ? endsOn.times : Numbers[1],
  );
  const setEndsWhenOcurrendCallback = React.useCallback(
    (times: PositiveInt) => {
      setLastEndsWhenOcurrendValue(times);
      setEndsOn({ times });
    },
    [setLastEndsWhenOcurrendValue],
  );

  const saveCallback = React.useCallback(() => {
    if (onSave) {
      onSave({
        repeatEvery: {
          value: repeatValue,
          unit: repeatUnit,
        },
        endsOn: endsOn,
      });
    }
  }, [repeatValue, repeatUnit, endsOn, onSave]);

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const scrollRef = React.useRef<ScrollView>(null);

  const cancelRecurrenceCallback = React.useCallback(() => {
    if (onSave) {
      onSave(undefined);
    }
  }, [onSave]);

  const showSubmitButton = !(isKeyboardOpen || showSelectDateScreen);

  return (
    <SafeAreaView
      edges={['left', 'top', 'right']}
      style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, style)}
    >
      <MeroHeader title={t('repeatOptions')} canGoBack={onBackPressed !== undefined} onBack={onBackPressed} />
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView
          style={{ flex: 1 }}
          ref={scrollRef}
          contentContainerStyle={styles.formMargin}
          onScrollEndDrag={dismissKeyboardCallback}
        >
          <View style={styles.hrPadding}>
            <Spacer size="16" />
            <H1 style={meroStyles.text.alignCenter}>{t('repeatOptions')}</H1>
          </View>
          <Spacer size="32" />
          <FormCard dropShaddow rounded paddings="inputs">
            <InputWithLabel label={t('repeatsEvery')}>
              <Row>
                <Column flex={1}>
                  <Select items={oneTo100} value={repeatValue} onChange={setRepeatValue} />
                </Column>
                <HSpacer left={8} right={8} />
                <Column flex={1}>
                  <Select items={repeatUnits} value={repeatUnit} onChange={setRepeatUnit} />
                </Column>
              </Row>
            </InputWithLabel>
            <Spacer size="32" />
            <InputWithLabel label={t('repeatStop')}>
              {RecurrenceEndCriteria.Indefinite.is(endsOn) ? (
                <StopOption title={t('noEndDate')} checked />
              ) : (
                <TouchableOpacity onPress={() => setEndsOn(RecurrenceEndCriteria.INDEFINITE)}>
                  <StopOption title={t('noEndDate')} />
                </TouchableOpacity>
              )}

              <Line />

              {RecurrenceEndCriteria.OnDate.is(endsOn) ? (
                <>
                  <StopOption title={t('atCertainDate')} checked />
                  <SelectButton
                    text={capitalize(
                      DateTime.fromJSDate(endsOn.date, { zone: timeZone }).toFormat('EEE d LLLL', { locale: 'ro' }),
                    )}
                    onPress={() => {
                      setShowSelectDateScreen(true);
                    }}
                  />
                  <Spacer size="16" />
                </>
              ) : (
                <TouchableOpacity onPress={() => setEndsOn({ date: lastEndsOnDateValue })}>
                  <StopOption title={t('atCertainDate')} />
                </TouchableOpacity>
              )}

              <Line />

              {RecurrenceEndCriteria.WhenOccurred.is(endsOn) ? (
                <>
                  <StopOption title={t('after')} checked />
                  <Select items={oneTo100} value={lastEndsWhenOcurrendValue} onChange={setEndsWhenOcurrendCallback} />
                </>
              ) : (
                <TouchableOpacity onPress={() => setEndsWhenOcurrendCallback(lastEndsWhenOcurrendValue)}>
                  <StopOption title={t('after')} />
                </TouchableOpacity>
              )}
            </InputWithLabel>
          </FormCard>
          <Spacer size="32" />
          <Button
            size="large"
            text={t('stopRepeat')}
            backgroundColor="transparent"
            color={colors.RADICAL_RED}
            onClick={cancelRecurrenceCallback}
          />
          <Spacer size={64} />
        </ScrollView>
      </AvoidKeyboard>

      {/* Elements dropping shadows seems to draw over the ones with position absolute on Android */}
      {showSubmitButton ? (
        <FormCard dropShaddow paddings="button">
          <SafeAreaView edges={['bottom']}>
            <Button text={t('saveChanges')} onClick={saveCallback} />
          </SafeAreaView>
        </FormCard>
      ) : null}

      {showSelectDateScreen ? (
        <View style={styles.modalScreen}>
          <SelectDateScreen
            title={t('selectDate')}
            selected={lastEndsOnDateValue}
            minDate={now}
            onDateSelected={setEndsOnDateCallback}
            onClosePress={() => setShowSelectDateScreen(false)}
            timeZone={timeZone}
          />
        </View>
      ) : null}
    </SafeAreaView>
  );
};

export default RecurrenceRuleEditScreen;
