import { Portal } from '@gorhom/portal';
import { Icon, Body, colors, styles as meroStyles } from '@mero/components';
import { DateTime } from 'luxon';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import SelectInterval from './SelectInterval';

export type Interval = {
  label: string;
  id: string;
  value: {
    start: DateTime;
    end: DateTime;
  };
};

type Props = {
  intervals?: Interval[];
  activeInterval: Interval;
  setActiveInterval: (interval: Interval) => void;
  fullWidth?: boolean;
  showFutureDates?: boolean;
  withFullCurrentMonth?: boolean;
  withArrows?: boolean;
};

const now = DateTime.utc().setLocale('ro');

export const getIntervals = (
  payload: {
    custom?: Interval;
    withFirstDay?: boolean;
    withFullCurrentMonth?: boolean;
  } = {},
) => {
  const { custom, withFirstDay = false, withFullCurrentMonth = false } = payload;
  const LENGTH = 4;
  const isFirstDayOfMonth = withFirstDay ? now.day === 1 : false;

  return [
    ...Array.from({ length: LENGTH }, (_, i) => {
      const time = now.plus({ month: -(i + (isFirstDayOfMonth ? 1 : 0)) });
      const start = time.startOf('month');
      const end = i === 0 && !isFirstDayOfMonth ? time.endOf('day') : time.endOf('month');
      if (i === 0 && withFullCurrentMonth && end.day !== time.endOf('month').day) {
        const endOfMonth = time.endOf('month');
        return [
          {
            label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
            id: `${start.toISO()} - ${end.toISO()}`,
            value: { start, end },
          },
          {
            label: `${start.toFormat('dd MMM')} - ${endOfMonth.toFormat('dd MMM')}`,
            id: `${start.toISO()} - ${endOfMonth.toISO()}`,
            value: { start, end: endOfMonth },
          },
        ];
      }
      return {
        label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
        id: `${start.toISO()} - ${end.toISO()}`,
        value: { start, end },
      };
    }),
    (() => {
      const start = custom ? custom.value.start : now.startOf('day');
      const end = custom ? custom.value.end : now.endOf('day');

      return {
        label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
        id: 'custom',
        value: {
          start,
          end,
        },
      };
    })(),
  ]
    .flat()
    .filter((i): i is Interval => Boolean(i.id));
};

export const isSameDay = (a: DateTime, b: DateTime) => {
  return a.startOf('day').equals(b.startOf('day'));
};

const ActiveIntervalView: React.FC<Props> = ({
  intervals: _intervals,
  activeInterval,
  setActiveInterval,
  fullWidth = false,
  showFutureDates = false,
  withFullCurrentMonth,
  withArrows = true,
}) => {
  const now = React.useMemo(() => DateTime.utc().setLocale('ro'), []);
  const explicitCustom = React.useRef(false);

  const [intervals, setIntervals] = React.useState<Interval[]>(_intervals ?? getIntervals({ withFullCurrentMonth }));

  const [showSelector, setShowSelector] = React.useState(false);

  const changeInterval = (increase: boolean) => () => {
    let daysDiff = Math.round(activeInterval.value.end.diff(activeInterval.value.start, 'days').days);
    if (daysDiff === 0) {
      daysDiff = 1;
    }
    const start = activeInterval.value.start.plus(
      explicitCustom.current ? { days: increase ? daysDiff : -daysDiff } : { months: increase ? 1 : -1 },
    );
    let end = activeInterval.value.end.plus(
      explicitCustom.current ? { days: increase ? daysDiff : -daysDiff } : { months: increase ? 1 : -1 },
    );

    if (!explicitCustom.current) {
      if (end.month !== now.month) {
        end = start.endOf('month');
      } else {
        end = now;
      }
    }

    if (!showFutureDates) {
      if (start.diffNow('days').days > 0) {
        return;
      }

      if (end.diffNow('days').days > 0) {
        end = now;
      }
    }

    const existingInterval = intervals.find((i) => isSameDay(i.value.start, start) && isSameDay(i.value.end, end));

    if (existingInterval) {
      setActiveInterval(existingInterval);
    } else {
      setActiveInterval({
        label: `${start.toFormat('dd MMM')} - ${end.toFormat('dd MMM')}`,
        id: 'custom',
        value: {
          start,
          end,
        },
      });
    }
  };

  return (
    <>
      {withArrows && (
        <TouchableOpacity
          style={{
            width: 43,
            height: 43,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#DEE2E6',
            borderWidth: 1,
            borderRadius: 5,
            backgroundColor: colors.WHITE,
          }}
          onPress={changeInterval(false)}
        >
          <Icon type={'arrow-left'} color={'#000000'} />
        </TouchableOpacity>
      )}
      <TouchableOpacity
        style={{
          height: 43,
          flexGrow: fullWidth ? 1 : 0,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: '#DEE2E6',
          borderWidth: 1,
          borderRadius: 5,
          marginHorizontal: withArrows ? 8 : 0,
          paddingHorizontal: 12,
          backgroundColor: colors.WHITE,
        }}
        onPress={() => setShowSelector(true)}
      >
        <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
          <Body style={[{ alignItems: 'center' }, withArrows && meroStyles.text.semibold]}>
            {activeInterval.value.start.toFormat('dd MMM')} - {activeInterval.value.end.toFormat('dd MMM')}
          </Body>
          <View style={[{ paddingLeft: 10 }]}>
            <Icon type="dropdown" rotate={false} />
          </View>
        </View>
      </TouchableOpacity>
      {withArrows && (
        <TouchableOpacity
          style={{
            width: 43,
            height: 43,
            justifyContent: 'center',
            alignItems: 'center',
            borderColor: '#DEE2E6',
            borderWidth: 1,
            borderRadius: 5,
            backgroundColor: colors.WHITE,
          }}
          onPress={changeInterval(true)}
        >
          <Icon type={'arrow-right'} color={'#000000'} />
        </TouchableOpacity>
      )}
      {showSelector && (
        <Portal>
          <SelectInterval
            intervals={intervals}
            activeInterval={activeInterval ?? intervals[0].value}
            onSave={(interval) => {
              setActiveInterval(interval);
              if (interval.id === 'custom') {
                explicitCustom.current = true;
                setIntervals(getIntervals({ custom: interval, withFullCurrentMonth }));
              } else {
                explicitCustom.current = false;
              }
              setShowSelector(false);
            }}
            onCancel={() => setShowSelector(false)}
          />
        </Portal>
      )}
    </>
  );
};

export default ActiveIntervalView;
