import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import GiftCardsDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageGiftCardsSettingsScreen/GiftCardsDetailsScreen';
import PageBillingDetailsList from '../screens/Authorized/MenuScreen/screens/PageGiftCardsSettingsScreen/PageBillingDetailsList';
import PageBillingDetailsScreen from '../screens/Authorized/MenuScreen/screens/PageGiftCardsSettingsScreen/PageBillingDetailsScreen';
import PageGiftCardsSettingsScreen from '../screens/Authorized/MenuScreen/screens/PageGiftCardsSettingsScreen/PageGiftCardsSettingsScreen';
import StripeScreen from '../screens/Authorized/MenuScreen/screens/PageGiftCardsSettingsScreen/StripeScreen';

import { createStackNavigator } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { withBillingDetailsContextProvider } from '../contexts/CheckoutSettingsContext';
import { GiftCardsStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<GiftCardsStackParamList>();

function GiftCardsStackNavigator(): React.ReactElement {
  const { t } = useTranslation('giftCards');

  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCardPaddingLess
    : styles.tabletModalCardPaddingLess;
  return (
    <Stack.Navigator
      initialRouteName="GiftCards"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="GiftCards"
        component={PageGiftCardsSettingsScreen}
        options={{ title: t('giftCards'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="GiftCardDetails"
        component={GiftCardsDetailsScreen}
        options={{ title: t('detailsGiftCards'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetailsList"
        component={PageBillingDetailsList}
        options={{ title: t('billingDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen
        name="BillingDetails"
        component={PageBillingDetailsScreen}
        options={{ title: t('billingDetails'), cardStyle: modalStyle }}
      />
      <Stack.Screen name="Stripe" component={StripeScreen} options={{ title: t('stripe'), cardStyle: modalStyle }} />
    </Stack.Navigator>
  );
}

export default pipe(GiftCardsStackNavigator, withBillingDetailsContextProvider('Company'));
