import {
  CheckoutItemTotalScaledNumber,
  CheckoutTransactionItem,
  Firstname,
  FullnameFromString,
  MembershipTemplateId,
  OptionalLastname,
  StrictPhoneNumber,
  MeroCurrency,
  ProductId,
  InventoryId,
} from '@mero/api-sdk';
import { AppointmentId, CalendarId, CalendarEntry } from '@mero/api-sdk/dist/calendar';
import { CreateCheckoutTransactionItem } from '@mero/api-sdk/dist/checkout/checkoutApi/createCheckoutTransactionItem';
import { NONE } from '@mero/api-sdk/dist/checkout/checkoutTransactionCompany/checkoutTransactionCompany';
import { computeTransactionTotals } from '@mero/api-sdk/dist/checkout/checkoutTransactionDetails/utils';
import { CheckoutTransactionPayment } from '@mero/api-sdk/dist/checkout/checkoutTransactionPayment';
import { NO_VAT } from '@mero/api-sdk/dist/checkout/companies/companyVatStatus/noVat';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { MembershipPurchaseId } from '@mero/api-sdk/dist/memberships/membershipPurchaseId';
import { PurchasedMembershipDetails } from '@mero/api-sdk/dist/memberships/purchasedMembershipDetails';
import { UserId } from '@mero/api-sdk/dist/users';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import {
  AvoidKeyboard,
  Body,
  Button,
  colors,
  Column,
  formatDurationInMinutes,
  HSpacer,
  Icon,
  Label,
  Line,
  Row,
  SafeAreaView,
  sizes,
  SmallBody,
  styles as meroStyles,
  Switch,
  Title,
  useShowError,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { capitalize, PhoneNumber, StrictPhoneNumberParsed } from '@mero/shared-sdk/dist/common';
import { flow } from 'fp-ts/function';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import { omit } from 'lodash';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path, Circle } from 'react-native-svg';

import { MembershipItem, MembershipServices } from '../../../components/BookingDetailsScreen';
import ClientListItem from '../../../components/ClientListItem';
import { FlashyLabel } from '../../../components/FlashyLabel';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import SearchInputButton from '../../../components/SearchInputButton';
import AppointmentStatusView from '@mero/components/lib/components/AppointmentStatus';
import MeroHeader from '@mero/components/lib/components/MeroHeader';
import Spacer from '@mero/components/lib/components/Spacer';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import useGoBack from '../../../hooks/useGoBack';
import { useInputState } from '../../../hooks/useInputState';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { BookingClient } from '../../../contexts/BookingFormContext';
import {
  CheckoutFormContext,
  Client,
  EmptyDiscount,
  Item,
  ItemAmount,
  ItemBooking,
  ItemDiscount,
  ItemMembershipInstallment,
  ItemProduct,
  ItemService,
  Payment,
  withUiKey,
  WithUiKey,
} from '../../../contexts/CheckoutFormContext';
import { CurrentPageInfo } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutStackParamList, CombineCheckoutStackParamList } from '../../../types';
import log from '../../../utils/log';
import { roundToDecimals } from '../../../utils/number';
import { getMeasure, updateOrAdd } from '../../../utils/products';
import { scaledToString } from '../../../utils/scaled';
import { ProductAvailability } from './AddProceedScreen';
import ClientMenu from './ClientMenu';
import { CloseDraftMenuDesktop, CloseDraftMenuPhone } from './CloseDraftMenu';
import { getPercentage, getValue } from './DiscountComponent';
import EditAmount from './EditAmount';
import EditDiscount from './EditDiscount';
import EditProduct from './EditProduct';
import EditService from './EditService';
import MembershipPartialPayment from './MembershipPartialPayment';
import { checkoutItemConversion } from './PaymentScreen';

const WarningIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6844">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 55" />
      <Path
        fill="#fb6340"
        d="M12 6.531 6.034 17.468h11.932Zm-.5 3.977h.994v3.48h-.991Zm.5 5.593a.621.621 0 1 1 .621-.621.622.622 0 0 1-.621.621Z"
      />
    </G>
  </Svg>
);

export const PercentageIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G fill="none" data-name="Group 6969">
      <Path d="M0 0h24v24H0z" data-name="Rectangle 2669" />
      <G
        stroke="#080de0"
        strokeLinecap="round"
        strokeWidth={1.2}
        data-name="Orion_discount (2)"
        transform="translate(3.69 3.691)"
      >
        <Circle cx={1} cy={1} r={1} strokeLinejoin="round" strokeMiterlimit={10} transform="translate(5.31 5.311)" />
        <Circle
          cx={1}
          cy={1}
          r={1}
          strokeLinejoin="round"
          strokeMiterlimit={10}
          data-name="layer2"
          transform="translate(10.309 9.809)"
        />
        <Path strokeLinejoin="round" strokeMiterlimit={10} d="m11.763 5.555-6.208 6.208" data-name="layer2" />
        <Path d="M8.659-.34a9 9 0 1 1-9 9 9 9 0 0 1 9-9Z" />
      </G>
    </G>
  </Svg>
);

const ProtocolIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G fill="none" data-name="Group 6736">
      <G stroke="#080de0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.2}>
        <Path d="M18.462 12.854v7.787H5.112v-7.787M4 9.517h15.574v3.337H4Z" />
        <Path d="M9.562 9.517v11.125m4.45-11.125v11.125M10.174 4.789a2.058 2.058 0 0 0-1.725-.835 2.225 2.225 0 0 0-1.474 3.895c1.168 1.029 4.812 1.668 4.812 1.668a9.054 9.054 0 0 0-1.613-4.728Zm3.226 0a2.058 2.058 0 0 1 1.722-.835 2.225 2.225 0 0 1 1.474 3.894c-1.166 1.03-4.809 1.669-4.809 1.669A9.054 9.054 0 0 1 13.4 4.789Z" />
      </G>
      <Path d="M0 0h24v24H0z" data-name="Rectangle 2603" />
    </G>
  </Svg>
);

const MembershipIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8275" />
    <G
      fill="none"
      stroke="#2dce89"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.2}
      transform="translate(3 5.25)"
    >
      <Path d="M14.741 0v.295a1.474 1.474 0 0 1-2.948 0V0H5.9v.295a1.474 1.474 0 0 1-2.948 0V0H0v12.972h17.689V0ZM10.613 4.717h4.717m-4.717 2.358h4.717m-4.717 2.359h2.359" />
      <Circle cx={1.598} cy={1.598} r={1.598} data-name="layer1" transform="translate(3.732 3.834)" />
      <Path
        d="M5.307 7.075a2.978 2.978 0 0 0-2.947 3.037v.5h5.9v-.5a2.978 2.978 0 0 0-2.953-3.037Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

export const paymentConversion = (payment: Payment): CheckoutTransactionPayment.Any<ScaledNumber, MeroUnits.Any> => {
  switch (payment.type) {
    case 'Membership':
      return {
        type: 'Membership',
        membership: payment.membership,
        items: payment.items.map((item) => {
          switch (item.type) {
            case 'Service':
              return {
                ...item,
                transactionItemId: item.type,
                effectivePrice: {
                  amount: item.effectivePrice,
                  vatStatus: {
                    type: 'Included',
                    rate: undefined,
                  },
                },
              };
            default:
              return item;
          }
        }),
      };
    default:
      return payment;
  }
};

export type Props = Readonly<{
  page: CurrentPageInfo;
  defaultWorker?: WorkerId;
  client: Client;
  items: WithUiKey<Item>[];
  type: CreateCheckoutTransactionItem.Any<MeroUnits.Any>['type'];
  appointment?: {
    appointmentId: AppointmentId;
    calendarId: CalendarId;
    occurrenceIndex: number;
  };
  membership?: {
    clientId: ClientId;
    membershipTemplateId?: MembershipTemplateId;
    membershipPurchaseId?: MembershipPurchaseId;
  };
  isSavedTransaction: boolean;
  hasInvalidItems: boolean;
  addBooking: (appointment: CalendarEntry.Appointment) => void;
  addMembershipInstallment?: (membership: PurchasedMembershipDetails<MeroUnits.Any>) => void;
  addAmount: () => void;
  onUpdateItem: (item: WithUiKey<Item>) => void;
  onRemoveItem: (item: WithUiKey<Item>) => void;
  addDiscount: (discount: ItemDiscount) => void;
  updateDiscount: (discount: ItemDiscount) => void;
  removeDiscount: () => void;
  addProtocol: () => void;
  removeProtocol: () => void;
  onClientChanged: (client: Client) => void;
  onSelectClientPress?: () => void;
  onChangeClientPress?: () => void;
  onRemoveClientPress?: () => void;
  addNewItemPress: () => void;
  deleteProceed: () => void;
  toggleButton: () => void;
  productAvailability: Record<InventoryId, ProductAvailability[]>;
  setProductAvailability: (availability: Record<InventoryId, ProductAvailability[]>) => void;
}>;

const AddProceedComponent: React.FC<Props> = ({
  type,
  defaultWorker,
  client,
  items,
  page,
  appointment,
  membership,
  isSavedTransaction,
  hasInvalidItems,
  addBooking,
  addMembershipInstallment,
  addAmount,
  onUpdateItem,
  onRemoveItem,
  onClientChanged,
  addDiscount,
  addProtocol,
  removeProtocol,
  updateDiscount,
  removeDiscount,
  onChangeClientPress,
  onSelectClientPress,
  onRemoveClientPress,
  addNewItemPress,
  deleteProceed,
  toggleButton,
  productAvailability,
  setProductAvailability,
}) => {
  const { t } = useTranslation('checkout');

  const navigation =
    useNavigation<NavigationProp<AuthorizedStackParamList & CheckoutStackParamList & CombineCheckoutStackParamList>>();

  const goBack = useGoBack();
  const isKeyboardOpen = useKeyboardIsOpen();
  const { isPhone, isDesktop } = useMediaQueries();
  const showError = useShowError();

  const [formState, { saveDraft, reset }] = CheckoutFormContext.useContext();

  // display errors only after first submit
  const [showErrors, setShowErrors] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [membershipStatus, setMembershipStatus] = React.useState<{ debt: number; activeMemberships: number }>();
  const [usableMemberships, setUsableMemberships] = React.useState<MembershipServices[]>([]);
  const [selectedService, setSelectedService] = React.useState<WithUiKey<ItemService> | undefined>(undefined);
  const [selectedProduct, setSelectedProduct] = React.useState<WithUiKey<ItemProduct> | undefined>(undefined);
  const [selectedBooking, setSelectedBooking] = React.useState<WithUiKey<ItemBooking> | undefined>(undefined);
  const [selectedMembershipInstallment, setSelectedMembershipInstallment] = React.useState<
    (WithUiKey<ItemMembershipInstallment> & { debt: number }) | undefined
  >(undefined);

  const [selectedAmount, setSelectedAmount] = React.useState<WithUiKey<ItemAmount> | undefined>(undefined);
  const [selectedDiscount, setSelectedDiscount] = React.useState<ItemDiscount | undefined>(undefined);

  const [showEditService, setShowEditService] = React.useState(false);
  const [showEditProduct, setShowEditProduct] = React.useState(false);
  const [showEditAmount, setShowEditAmount] = React.useState(false);
  const [showEditDiscount, setShowEditDiscount] = React.useState(false);
  const [showCloseMenu, setShowCloseMenu] = React.useState(false);

  const [showEditMembershipInstallment, setShowEditMembershipInstallment] = React.useState(false);
  const [showClientMenu, setShowClientMenu] = React.useState(false);
  const [allowDiscount, setAllowDiscount] = React.useState(false);

  const [appointmentsIds, setAppointmentsIds] = React.useState<AppointmentId[]>([]);

  // ScrollView reference, for field auto scrolling
  const scrollRef = React.useRef<ScrollView>(null);
  // Target ScrollView scroll value (scroll to focused field to be visible)
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  /**
   * Client name (full name, must)
   */
  const [clientName, setClientName] = useInputState({
    input: client?.type === 'new' ? client.fullname ?? '' : '',
    decoded: FullnameFromString.decode(client?.type === 'new' ? client.fullname ?? '' : ''),
  });

  /**
   * Client phone number
   */
  const [clientPhoneNumber, setClientPhoneNumber] = useInputState({
    input: client?.type === 'new' ? client.phone ?? '' : '',
    decoded: StrictPhoneNumberParsed.decode(client?.type === 'new' ? client.phone ?? '' : ''),
  });

  const getUsableMemberships = async (client: { _id: ClientId }) => {
    try {
      const items = formState.draft.items
        .map(checkoutItemConversion)
        .flatMap((i) => (i.type === 'Service' ? i : i.type === 'Booking' ? i.items : []))
        .filter((i): i is CreateCheckoutTransactionItem.Service<MeroUnits.Any> => i.type === 'Service')
        .map((i) => omit(i, 'saleOwner')) as NonEmptyArray<CreateCheckoutTransactionItem.Service<MeroUnits.Any>>;

      const memberships = await meroApi.memberships.getPurchasedMembershipsAvailableForTransaction({
        page: page.details,
        client,
        items,
      });
      setUsableMemberships(
        memberships.map((m) => ({
          _id: m.membership._id,
          name: m.membership.name,
          items: m.items
            .map((i) =>
              i.type === 'Service'
                ? {
                    _id: i.service._id,
                    quantity: i.availableQuantity.type === 'Unlimited' ? Infinity : i.availableQuantity.remaining,
                    type: 'Service',
                  }
                : undefined,
            )
            .filter((i): i is MembershipItem => i !== undefined),
        })),
      );
    } catch (error) {
      log.error('Error getting client memberships', JSON.stringify(error));
    }
  };

  const getClientMembershipDetails = async (userId: UserId) => {
    setMembershipStatus(undefined);
    try {
      const { totalPayments, totalSellingPrice, totalCount } =
        await meroApi.memberships.getPagePurchasedMembershipsTotals({
          pageId: page.details._id,
          query: {
            userId,
            status: 'Active',
          },
        });
      const debt = ScaledNumber.sub(totalSellingPrice.amount, totalPayments.amount);
      if (totalCount > 0) {
        setMembershipStatus({
          activeMemberships: totalCount,
          debt: ScaledNumber.toNumber(debt),
        });
      }
    } catch (error) {
      log.error('Failed to get client membership details', error);
    }
  };

  const getProductsAvailability = async () => {
    try {
      const products = formState.draft.items
        .flatMap((i) => (i.type === 'Booking' ? i.items : [i]))
        .filter((i): i is WithUiKey<ItemProduct> => i.type === 'Product');

      if (products.length === 0) {
        setProductAvailability({});
        return;
      }

      const groupByInventory = Object.entries(
        products.reduce(
          (acc, product) => ({
            ...acc,
            [product.inventoryId]: [
              ...updateOrAdd(
                acc[product.inventoryId] ?? [],
                { _id: product.product._id, quantity: ScaledNumber.toNumber(product.quantity) },
                (i, n) => ({
                  ...i,
                  quantity: i.quantity + n.quantity,
                }),
              ),
            ],
          }),
          {} as Record<InventoryId, { _id: ProductId; quantity: number }[]>,
        ),
      ) as [InventoryId, { _id: ProductId; quantity: number }[]][];

      const productsAvailabilityByInventory = await Promise.all(
        groupByInventory.map(async ([inventoryId, inventory]) => {
          const stock = await meroApi.pro.inventories.searchProductsInventoryStock({
            pageId: page.details._id,
            inventoryId,
            productIds: inventory.map((p) => p._id),
          });

          return {
            inventoryId,
            products: inventory.map((p) => ({
              ...p,
              stock: stock[p._id] ? ScaledNumber.toNumber(stock[p._id].quantity) : 0,
            })),
          };
        }),
      );

      setProductAvailability(
        productsAvailabilityByInventory.reduce(
          (acc, { inventoryId, products }) => ({
            ...acc,
            [inventoryId]: products,
          }),
          {} as Record<InventoryId, { _id: ProductId; quantity: number; stock: number }[]>,
        ),
      );
    } catch (error) {
      log.error('Error getting products availability', JSON.stringify(error));
    }
  };

  const clientFormIsEmpty = clientName.input.trim() === '' && clientPhoneNumber.input.trim() === '';

  const hasClientAccess = React.useMemo(() => {
    return page.permissions.clients.canViewClientDetails();
  }, []);

  const clientDetails = React.useMemo(() => {
    if (client.type === 'existing') {
      getClientMembershipDetails(client.client.user._id);
    }
    if (hasClientAccess) {
      return client;
    }

    return client?.type === 'existing' && !hasClientAccess
      ? { ...client, client: { ...client.client, phone: undefined } }
      : client;
  }, [hasClientAccess, client]);

  const dismissKeyboardCallback = React.useCallback(() => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
  }, [isKeyboardOpen]);

  const setClientCallback = React.useCallback((client: BookingClient) => {
    if (onClientChanged) {
      onClientChanged(
        client.type === 'existing'
          ? {
              type: 'existing',
              client: {
                _id: client.client._id,
                pageId: page.details._id,
                user: {
                  _id: client.client.userId,
                  phone: client.client.phone ?? '',
                  profile: {
                    firstname: client.client.firstname,
                    lastname: client.client.lastname,
                    photo: client.client.photo,
                  },
                },
              },
            }
          : client,
      );
    }
  }, []);

  const addAppointment = React.useCallback(
    async (appointmentId: AppointmentId, calendarId: CalendarId, occurrenceIndex: number) => {
      try {
        const appointment = await meroApi.calendar.getCalendarEntryById({
          pageId: page.details._id,
          entryId: appointmentId,
          occurrenceIndex,
        });
        if (appointment && appointment.type === 0) {
          addBooking?.({
            ...appointment,
            occurrenceIndex,
          });
          const { client } = appointment.payload;
          if (client) {
            const clientDetails = await meroApi.clients.getClientById(client._id);
            if (clientDetails) {
              onClientChanged({
                type: 'existing',
                client: {
                  _id: client._id,
                  pageId: page.details._id,
                  user: {
                    _id: clientDetails.user._id,
                    phone: clientDetails.user.phone,
                    profile: {
                      firstname: client.firstname,
                      lastname: client.lastname,
                      photo: clientDetails.user.photo,
                    },
                  },
                },
              });
            }
          }
        }
      } catch (error) {
        log.error('Failed to add appointment', error);
      } finally {
      }
    },
    [],
  );

  const loadMembershipInstallment = React.useCallback(
    async (membershipPurchaseId: MembershipPurchaseId, clientId: ClientId) => {
      try {
        const membership = await meroApi.memberships.getPurchasedMembershipById({
          pageId: page.details._id,
          membershipPurchaseId,
        });
        if (membership) {
          const clientDetails = await meroApi.clients.getClientById(clientId);
          if (clientDetails) {
            onClientChanged({
              type: 'existing',
              client: {
                _id: clientId,
                pageId: page.details._id,
                user: {
                  _id: clientDetails.user._id,
                  phone: clientDetails.user.phone,
                  profile: {
                    firstname: membership.user.profile.firstname,
                    lastname: membership.user.profile.lastname,
                    photo: clientDetails.user.photo,
                  },
                },
              },
            });
            addMembershipInstallment?.(membership);
          }
        }
      } catch (error) {
        log.error('Failed to add membership installment', error);
      } finally {
      }
    },
    [],
  );

  const loadMembership = React.useCallback(
    async (
      membershipTemplateId: MembershipTemplateId,
      membershipPurchaseId: MembershipPurchaseId,
      clientId: ClientId,
    ) => {
      try {
        const clientDetails = await meroApi.clients.getClientById(clientId);
        if (clientDetails) {
          onClientChanged({
            type: 'existing',
            client: {
              _id: clientId,
              pageId: page.details._id,
              user: {
                _id: clientDetails.user._id,
                phone: clientDetails.user.phone,
                profile: {
                  firstname: clientDetails.user.firstname,
                  lastname: clientDetails.user.lastname,
                  photo: clientDetails.user.photo,
                },
              },
            },
          });
          navigation.navigate('CheckoutSubStack', {
            screen: 'EditMembershipScreen',
            params: {
              membershipTemplateId,
              membershipPurchaseId,
            },
          });
        }
      } catch (error) {
        log.error('Failed to add membership installment', error);
      } finally {
      }
    },
    [],
  );

  const loadClient = React.useCallback(async (clientId: ClientId) => {
    try {
      const clientDetails = await meroApi.clients.getClientById(clientId);
      if (clientDetails) {
        onClientChanged({
          type: 'existing',
          client: {
            _id: clientId,
            pageId: page.details._id,
            user: {
              _id: clientDetails.user._id,
              phone: clientDetails.user.phone,
              profile: {
                firstname: clientDetails.user.firstname,
                lastname: clientDetails.user.lastname,
                photo: clientDetails.user.photo,
              },
            },
          },
        });
        navigation.navigate('CheckoutSubStack', { screen: 'SelectMembershipScreen' });
      }
    } catch (error) {
      log.error('Failed to add membership installment', error);
    } finally {
    }
  }, []);

  const updateServiceBooking = React.useCallback(
    (service: WithUiKey<ItemService>) => {
      if (!selectedBooking) {
        return;
      }
      onUpdateItem({
        ...selectedBooking,
        items: selectedBooking.items.map((item) => (item.service._id === service.service._id ? service : item)),
      });
    },
    [selectedBooking],
  );

  const removeServiceBooking = React.useCallback(
    (service: WithUiKey<ItemService>) => {
      if (!selectedBooking) {
        return;
      }

      const newItems = selectedBooking.items.filter((item) => item.service._id !== service.service._id);

      if (newItems.length === 0) {
        return onRemoveItem(selectedBooking);
      }

      onUpdateItem({
        ...selectedBooking,
        items: newItems,
      });
    },
    [selectedBooking],
  );

  const closeEdit = React.useCallback(() => {
    setShowEditProduct(false);
    setShowEditService(false);
    setSelectedService(undefined);
    setSelectedBooking(undefined);
    setSelectedProduct(undefined);
  }, []);

  const changeMembershipInstallment = async (membershipInstallment: WithUiKey<ItemMembershipInstallment>) => {
    try {
      setIsLoading(true);
      const membership = await meroApi.memberships.getPurchasedMembershipById({
        pageId: page.details._id,
        membershipPurchaseId: membershipInstallment.membership._id,
      });
      const debt =
        ScaledNumber.toNumber(membership.template.sellingPrice.amount) - ScaledNumber.toNumber(membership.paid.amount);

      setSelectedMembershipInstallment({
        ...membershipInstallment,
        debt,
      });
      setShowEditMembershipInstallment(true);
    } catch (error) {
      log.error('Failed to change membership installment', error);
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMembershipInstallment = React.useCallback(
    (membershipInstallment: WithUiKey<ItemMembershipInstallment>, amount: number) => {
      onUpdateItem({
        ...membershipInstallment,
        total: {
          ...membershipInstallment.total,
          amount: {
            ...membershipInstallment.total.amount,
            amount: ScaledNumber.fromNumber(amount, membershipInstallment.total.amount.amount.scale ?? 2),
          },
        },
      });
    },
    [],
  );

  const closeEditMembershipInstallment = React.useCallback(() => {
    setShowEditMembershipInstallment(false);
    setSelectedMembershipInstallment(undefined);
  }, []);

  const closeEditAmount = React.useCallback(() => {
    setShowEditAmount(false);
    setSelectedAmount(undefined);
  }, []);

  const closeEditDiscount = React.useCallback(() => {
    setShowEditDiscount(false);
    setSelectedDiscount(undefined);
  }, []);

  const toggleCloseMenu = React.useCallback(() => {
    setShowCloseMenu((prev) => !prev);
  }, []);

  const toggleClientMenu = React.useCallback(() => {
    setShowClientMenu((prev) => !prev);
  }, []);

  const openClientDetails = React.useCallback(() => {
    if (clientDetails?.type === 'existing') {
      navigation.navigate('ClientDetails', {
        screen: 'DetailsScreen',
        params: {
          pageId: page.details._id,
          clientId: clientDetails.client._id,
        },
      });
    }
  }, [clientDetails]);

  const onSaveDraft = React.useCallback(async () => {
    try {
      await saveDraft();
      reset();
      goBack();
    } catch (error) {
      showError(error);
    }
  }, []);

  const checkAllowDiscount = React.useCallback((items: WithUiKey<Item>[]) => {
    if (items.some((item) => item.type === 'MembershipInstallment' || item.type === 'Membership')) {
      removeDiscount();
      return setAllowDiscount(false);
    }

    return setAllowDiscount(true);
  }, []);

  React.useEffect(() => {
    checkAllowDiscount(formState.draft.items);
  }, [formState.draft.items]);

  React.useEffect(() => {
    if (appointment) {
      addAppointment(appointment.appointmentId, appointment.calendarId, appointment.occurrenceIndex);
    } else if (membership) {
      if (type === 'Membership' && membership.membershipTemplateId && membership.membershipPurchaseId) {
        loadMembership(membership.membershipTemplateId, membership.membershipPurchaseId, membership.clientId);
      } else if (type === 'MembershipInstallment' && membership.membershipPurchaseId) {
        loadMembershipInstallment(membership.membershipPurchaseId, membership.clientId);
      } else if (type === 'Membership' && membership.clientId) {
        loadClient(membership.clientId);
      }
    } else if (!isSavedTransaction) {
      if (type === 'Booking') {
        navigation.navigate('CheckoutSubStack', {
          screen: 'SelectBookingScreen',
          params:
            formState.draft.client.type === 'existing' ? { clientId: formState.draft.client.client._id } : undefined,
        });
      } else if (type === 'Service') {
        navigation.navigate('CheckoutSubStack', {
          screen: 'SelectServiceScreen',
          params: defaultWorker ? { workerId: defaultWorker } : undefined,
        });
      } else if (type === 'Product') {
        navigation.navigate('CheckoutSubStack', {
          screen: 'SelectProductScreen',
          params: defaultWorker ? { workerId: defaultWorker } : undefined,
        });
      } else if (type === 'Membership') {
        navigation.navigate('CheckoutSubStack', { screen: 'SelectMembershipScreen' });
      } else if (type === 'MembershipInstallment') {
        navigation.navigate('CheckoutSubStack', { screen: 'SelectMembershipInstallmentScreen' });
      } else if (type === 'Amount') {
        addAmount();
      }
    }
  }, [appointment?.appointmentId, membership?.membershipPurchaseId]);

  const calculateTotals = React.useCallback(
    (discount?: ItemDiscount) => {
      return computeTransactionTotals(
        {
          unit: 'RON',
          payments: formState.draft.payments.map(paymentConversion),
          items: items,
          company: formState.draft.company
            ? {
                type: 'Company',
                company: formState.draft.company,
                emitReceipt: formState.draft.emitReceipt,
                receipt: formState.draft.receipt,
              }
            : NONE,
          discount,
        },
        2,
      );
    },
    [formState, items],
  );

  const totalValues = React.useMemo(() => {
    return calculateTotals(formState.draft.discount);
  }, [items, formState.draft.discount]);

  const isEditDiscount = React.useMemo(() => {
    if (!selectedDiscount) {
      return false;
    }

    return (
      (selectedDiscount.type === 'Value' && ScaledNumber.toNumber(selectedDiscount.value.amount) > 0) ||
      (selectedDiscount.type === 'Percent' && ScaledNumber.toNumber(selectedDiscount.percent) > 0)
    );
  }, [selectedDiscount]);

  const isClientFixed = React.useMemo(() => {
    return formState.draft.items.some((item) => item.type === 'MembershipInstallment');
  }, [formState.draft.items]);

  const { discountPercentage, discountValue } = React.useMemo(() => {
    if (!formState.draft.discount) {
      return { discountPercentage: 0, discountValue: 0 };
    }

    const totalsWithoutDiscount = calculateTotals();
    const subtotal = ScaledNumber.toNumber(totalsWithoutDiscount.subtotal.amount);

    if (formState.draft.discount.type === 'Value') {
      const discountValue = ScaledNumber.toNumber(formState.draft.discount.value.amount);
      return {
        discountPercentage: roundToDecimals(getPercentage(subtotal, discountValue)),
        discountValue,
      };
    }

    if (formState.draft.discount.type === 'Percent') {
      const discountPercentage = ScaledNumber.toNumber(formState.draft.discount.percent);
      return {
        discountPercentage,
        discountValue: roundToDecimals(getValue(subtotal, discountPercentage)),
      };
    }

    return {
      discountPercentage: 0,
      discountValue: 0,
    };
  }, [selectedDiscount, calculateTotals]);

  const getUnpaidAppointments = async (clientId: ClientId) => {
    try {
      const appointments = await meroApi.checkout.getUnpaidAppointments({
        pageId: page.details._id,
        clientId,
      });

      setAppointmentsIds(appointments.map((appointment) => appointment._id));
    } catch (error) {
      log.error('Failed to get unpaid appointments', error);
    }
  };

  React.useEffect(() => {
    if (client.type === 'new') {
      const fullname = clientName.isValid ? clientName.input : client.fullname;
      const phone = clientPhoneNumber.isValid ? clientPhoneNumber.input : client.phone;
      const splitName = (fullname ?? '').split(' ');
      const lastname = splitName.length > 1 ? splitName.at(-1) : undefined;
      const firstname = splitName.length > 1 ? splitName.slice(0, -1).join(' ') : splitName[0];

      if (
        clientName.isValid &&
        clientPhoneNumber.isValid &&
        fullname &&
        StrictPhoneNumber.is(phone) &&
        Firstname.is(firstname) &&
        OptionalLastname.is(lastname)
      ) {
        onClientChanged({
          type: 'new',
          isValid: true,
          isEmpty: false,
          fullname,
          phone,
          lastname,
          firstname,
        });
      } else if (firstname || lastname || phone) {
        onClientChanged({
          type: 'new',
          isValid: false,
          isEmpty: false,
          fullname: fullname ?? '',
          phone: phone ?? '',
        });
      } else {
        onClientChanged({
          type: 'new',
          isValid: false,
          isEmpty: false,
        });
      }
    }
  }, [client.type === 'new', clientName.input, clientPhoneNumber.input]);

  React.useEffect(() => {
    if (clientDetails.type === 'existing' && items.some((item) => item.type === 'Service' || item.type === 'Booking')) {
      getUsableMemberships({
        _id: clientDetails.client._id,
      });
    } else {
      setUsableMemberships([]);
    }
  }, [clientDetails, items]);

  React.useEffect(() => {
    getProductsAvailability();
  }, [items]);

  React.useEffect(() => {
    if (formState.draft.client.type === 'existing') {
      getUnpaidAppointments(formState.draft.client.client._id);
    }
  }, [formState.draft.client.type === 'existing' && formState.draft.client.client._id]);

  const getItemComponent = React.useCallback(
    (item: WithUiKey<Item>) => {
      switch (item.type) {
        case 'Booking': {
          return (
            <React.Fragment key={item.uiKey}>
              <Column
                key={item.appointmentId}
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
              >
                <Spacer size="12" />
                <Row>
                  <AppointmentStatusView status={item.status} inPast={true} />
                  <Label style={{ fontFamily: 'open-sans-semibold', color: colors.COMET }}>
                    {` `}- {capitalize(DateTime.fromJSDate(item.start).toFormat('EEEE, d LLL', { locale: 'ro' }))}
                  </Label>
                </Row>
                <Spacer size={6} />
                {item.items.map((service, index) => {
                  const hasDiscount =
                    service.total.discount &&
                    (service.total.discount.type === 'Value'
                      ? ScaledNumber.toNumber(service.total.discount.value.amount)
                      : service.total.discount.percent.value.toFixed()) !== 0;

                  const price = CheckoutItemTotalScaledNumber.getTotal(
                    service.total,
                    formState.draft.company?.vatStatus ?? NO_VAT,
                    2,
                  );
                  return (
                    <TouchableOpacity
                      key={service.service._id}
                      onPress={() => {
                        setSelectedService(withUiKey(service));
                        setSelectedBooking(item);
                        setShowEditService(true);
                      }}
                    >
                      <Row>
                        <Column style={{ flex: 1 }}>
                          <Title>{service.service.name}</Title>
                          <SmallBody style={{ color: colors.COMET }}>
                            {service.quantity} x {formatDurationInMinutes(service.service.durationInMinutes)} -{' '}
                            {service.saleOwner
                              ? `${service.saleOwner.profile.firstname} ${service.saleOwner.profile.lastname}`
                              : `${item.worker.user.profile.firstname} ${item.worker.user.profile.lastname}`}
                          </SmallBody>
                        </Column>
                        <Column>
                          {ScaledNumber.toNumber(price.amount) !== 0 || hasDiscount ? (
                            <Body>
                              {scaledToString(price.amount)} {t(service.total.amount.unit)}
                            </Body>
                          ) : (
                            <Label style={{ color: colors.COMET, fontFamily: 'open-sans-semibold' }}>
                              {t('noPrice')}
                            </Label>
                          )}
                          {hasDiscount ? (
                            <SmallBody
                              style={{
                                textDecorationLine: 'line-through',
                                color: colors.COMET,
                                textAlign: 'right',
                              }}
                            >
                              {scaledToString(
                                CheckoutItemTotalScaledNumber.getTotal(
                                  omit(service.total, 'discount'),
                                  formState.draft.company?.vatStatus ?? NO_VAT,
                                  2,
                                ).amount,
                              )}{' '}
                              {t(service.total.amount.unit)}
                            </SmallBody>
                          ) : null}
                        </Column>
                        <Column style={{ paddingLeft: 8 }}>
                          <Icon color={colors.DARK_BLUE} type="next" />
                        </Column>
                      </Row>
                      <Column>
                        {usableMemberships
                          .filter((m) =>
                            m.items.some((i) => i._id === service.service._id && i.quantity >= service.quantity),
                          )
                          .map((membership) => {
                            const membershipItem = membership.items.find((i) => i._id === service.service._id);
                            return (
                              <Row
                                key={membership._id}
                                style={{
                                  alignItems: 'center',
                                  paddingLeft: 4,
                                  paddingRight: 8,
                                  backgroundColor: '#E8F9F2',
                                  borderRadius: 4,
                                  marginRight: 8,
                                  marginTop: 8,
                                  alignSelf: 'flex-start',
                                }}
                              >
                                <MembershipIcon />
                                <HSpacer left={sizes[4]} />
                                <Label style={[meroStyles.text.semibold, { color: colors.SHAMROCK }]}>
                                  {membership.name}
                                  {membershipItem && membershipItem.quantity !== Infinity
                                    ? ` (${t('membershipLeft', { count: membershipItem.quantity })})`
                                    : ` (${t('membershipLeftUnlimited')})`}
                                </Label>
                              </Row>
                            );
                          })}
                      </Column>
                      {hasInvalidItems && ScaledNumber.toNumber(price.amount) === 0 && !hasDiscount && (
                        <>
                          <Spacer size="8" />
                          <SmallBody style={{ color: colors.RADICAL_RED }}>{t('invalidItemAmount')}</SmallBody>
                        </>
                      )}
                      <Spacer size="16" />
                      {index < item.items.length - 1 && (
                        <>
                          <Line />
                          <Spacer size="16" />
                        </>
                      )}
                    </TouchableOpacity>
                  );
                })}
              </Column>
              <Spacer size="16" />
            </React.Fragment>
          );
        }
        case 'Service': {
          const hasDiscount =
            item.total.discount &&
            (item.total.discount.type === 'Value'
              ? ScaledNumber.toNumber(item.total.discount.value.amount)
              : item.total.discount.percent.value.toFixed()) !== 0;

          const price = CheckoutItemTotalScaledNumber.getTotal(
            item.total,
            formState.draft.company?.vatStatus ?? NO_VAT,
            2,
          );

          return (
            <React.Fragment key={item.uiKey}>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
                onPress={() => {
                  setSelectedService(item);
                  setShowEditService(true);
                }}
              >
                <Row>
                  <Column style={{ flex: 1 }}>
                    <Title>{item.service.name}</Title>
                    <SmallBody style={{ color: colors.COMET }}>
                      {item.quantity} x {formatDurationInMinutes(item.service.durationInMinutes)}
                      {item.saleOwner ? (
                        <SmallBody style={{ color: colors.COMET }}>
                          {` - `}
                          {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                        </SmallBody>
                      ) : null}
                    </SmallBody>
                  </Column>
                  <Column>
                    {ScaledNumber.toNumber(price.amount) !== 0 || hasDiscount ? (
                      <Body>
                        {scaledToString(price.amount)} {t(item.total.amount.unit)}
                      </Body>
                    ) : (
                      <Label style={{ color: colors.COMET, fontFamily: 'open-sans-semibold' }}>{t('noPrice')}</Label>
                    )}
                    {hasDiscount ? (
                      <SmallBody
                        style={{
                          textDecorationLine: 'line-through',
                          color: colors.COMET,
                          textAlign: 'right',
                        }}
                      >
                        {scaledToString(
                          CheckoutItemTotalScaledNumber.getTotal(
                            omit(item.total, 'discount'),
                            formState.draft.company?.vatStatus ?? NO_VAT,
                            2,
                          ).amount,
                        )}{' '}
                        {t(item.total.amount.unit)}
                      </SmallBody>
                    ) : null}
                  </Column>
                  <Column style={{ paddingLeft: 8 }}>
                    <Icon color={colors.DARK_BLUE} type="next" />
                  </Column>
                </Row>
                <Column>
                  {usableMemberships
                    .filter((m) => m.items.some((i) => i._id === item.service._id && i.quantity >= item.quantity))
                    .map((membership) => {
                      const membershipItem = membership.items.find((i) => i._id === item.service._id);
                      return (
                        <Row
                          key={membership._id}
                          style={{
                            alignItems: 'center',
                            paddingLeft: 4,
                            paddingRight: 8,
                            backgroundColor: '#E8F9F2',
                            borderRadius: 4,
                            marginRight: 8,
                            marginTop: 8,
                            alignSelf: 'flex-start',
                          }}
                        >
                          <MembershipIcon />
                          <HSpacer left={sizes[4]} />
                          <Label style={[meroStyles.text.semibold, { color: colors.SHAMROCK }]}>
                            {membership.name}
                            {membershipItem && membershipItem.quantity !== Infinity
                              ? ` (${t('membershipLeft', { count: membershipItem.quantity })})`
                              : ` (${t('membershipLeftUnlimited')})`}
                          </Label>
                        </Row>
                      );
                    })}
                </Column>
                {hasInvalidItems && ScaledNumber.toNumber(price.amount) === 0 && !hasDiscount && (
                  <>
                    <Spacer size="8" />
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('invalidItemAmount')}</SmallBody>
                  </>
                )}
              </TouchableOpacity>
              <Spacer size="16" />
            </React.Fragment>
          );
        }
        case 'Product': {
          const hasDiscount =
            item.total.discount &&
            (item.total.discount.type === 'Value'
              ? ScaledNumber.toNumber(item.total.discount.value.amount)
              : item.total.discount.percent.value.toFixed()) !== 0;

          const price = CheckoutItemTotalScaledNumber.getTotal(
            item.total,
            formState.draft.company?.vatStatus ?? NO_VAT,
            2,
          );

          const measure = getMeasure(item.product.measure);

          const stock = productAvailability[item.inventoryId]?.find((p) => p._id === item.product._id);

          return (
            <React.Fragment key={item.uiKey}>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
                onPress={() => {
                  setSelectedProduct(item);
                  setShowEditProduct(true);
                }}
              >
                <Row>
                  <Column style={{ flex: 1 }}>
                    <Title>{item.product.name}</Title>
                    {measure || item.product.barcode ? (
                      <SmallBody>
                        {measure}
                        {measure && item.product.barcode ? ` - ` : ''}
                        {item.product.barcode?.value}
                      </SmallBody>
                    ) : null}
                    <SmallBody style={{ color: colors.COMET }}>
                      {scaledToString(item.quantity)} x 1 buc
                      {item.saleOwner ? (
                        <SmallBody style={{ color: colors.COMET }}>
                          {` - `}
                          {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                        </SmallBody>
                      ) : null}
                    </SmallBody>
                  </Column>
                  <Column>
                    {ScaledNumber.toNumber(price.amount) !== 0 || hasDiscount ? (
                      <Body>
                        {scaledToString(price.amount)} {t(item.total.amount.unit)}
                      </Body>
                    ) : (
                      <Label style={{ color: colors.COMET, fontFamily: 'open-sans-semibold' }}>{t('noPrice')}</Label>
                    )}
                    {hasDiscount ? (
                      <SmallBody
                        style={{
                          textDecorationLine: 'line-through',
                          color: colors.COMET,
                          textAlign: 'right',
                        }}
                      >
                        {scaledToString(
                          CheckoutItemTotalScaledNumber.getTotal(
                            omit(item.total, 'discount'),
                            formState.draft.company?.vatStatus ?? NO_VAT,
                            2,
                          ).amount,
                        )}{' '}
                        {t(item.total.amount.unit)}
                      </SmallBody>
                    ) : null}
                  </Column>
                  <Column style={{ paddingLeft: 8 }}>
                    <Icon color={colors.DARK_BLUE} type="next" />
                  </Column>
                </Row>
                {stock && stock.quantity > stock.stock && (
                  <>
                    <Spacer size="8" />
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('insufficientStock')}</SmallBody>
                  </>
                )}
              </TouchableOpacity>
              <Spacer size="16" />
            </React.Fragment>
          );
        }
        case 'Membership': {
          const totals = CheckoutTransactionItem.Membership.getTotals(
            item,
            item.total.amount.unit,
            formState.draft.company?.vatStatus ?? NO_VAT,
            MeroCurrency[item.total.amount.unit].exponent,
          );

          return (
            <React.Fragment key={item.uiKey}>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
                onPress={() => {
                  navigation.navigate('CheckoutSubStack', {
                    screen: 'EditMembershipScreen',
                    params: { uiKey: item.uiKey },
                  });
                  // setShowEditService(true);
                }}
              >
                <Row>
                  <Column style={{ flex: 1 }}>
                    <Title>{item.membershipTemplate.name}</Title>
                    <SmallBody style={{ color: colors.COMET }}>
                      {item.saleOwner ? (
                        <SmallBody style={{ color: colors.COMET }}>
                          {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                        </SmallBody>
                      ) : null}
                    </SmallBody>
                  </Column>
                  <Column>
                    <Body>
                      {scaledToString(totals.total.amount)} {t(item.total.amount.unit)}
                    </Body>
                  </Column>
                  <Column style={{ paddingLeft: 8 }}>
                    <Icon color={colors.DARK_BLUE} type="next" />
                  </Column>
                </Row>
                {hasInvalidItems && ScaledNumber.toNumber(totals.total.amount) === 0 && (
                  <>
                    <Spacer size="8" />
                    <SmallBody style={{ color: colors.RADICAL_RED }}>{t('invalidItemAmount')}</SmallBody>
                  </>
                )}
              </TouchableOpacity>
              <Spacer size="16" />
            </React.Fragment>
          );
        }
        case 'MembershipInstallment': {
          return (
            <React.Fragment key={item.uiKey}>
              <TouchableOpacity
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
                onPress={() => changeMembershipInstallment(item)}
              >
                <Row>
                  <Column style={{ flex: 1 }}>
                    <Title>{item.membership.name}</Title>
                    <SmallBody style={{ color: colors.COMET }}>
                      {item.saleOwner ? (
                        <SmallBody style={{ color: colors.COMET }}>
                          {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                        </SmallBody>
                      ) : null}
                    </SmallBody>
                  </Column>
                  <Column>
                    {ScaledNumber.toNumber(item.total.amount.amount) !== 0 ? (
                      <Body>
                        {scaledToString(item.total.amount.amount)} {t(item.total.amount.unit)}
                      </Body>
                    ) : (
                      <Label style={{ color: colors.COMET, fontFamily: 'open-sans-semibold' }}>{t('noPrice')}</Label>
                    )}
                  </Column>
                  <Column style={{ paddingLeft: 8 }}>
                    <Icon color={colors.DARK_BLUE} type="next" />
                  </Column>
                </Row>
              </TouchableOpacity>
              <Spacer size="16" />
            </React.Fragment>
          );
        }
        case 'Amount': {
          return (
            <React.Fragment key={item.uiKey}>
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.GEYSER,
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
                onPress={() => {
                  setSelectedAmount(item);
                  setShowEditAmount(true);
                }}
              >
                <Column style={{ flex: 1 }}>
                  <Title>{t('otherAmount')}</Title>
                  {item.saleOwner ? (
                    <SmallBody style={{ color: colors.COMET }}>
                      {item.saleOwner.profile.firstname} {item.saleOwner.profile.lastname}
                    </SmallBody>
                  ) : null}
                </Column>
                <Body>
                  {scaledToString(
                    CheckoutItemTotalScaledNumber.getTotal(item.total, formState.draft.company?.vatStatus ?? NO_VAT, 2)
                      .amount,
                  )}{' '}
                  {t(item.total.amount.unit)}
                </Body>
                <Column style={{ paddingLeft: 8 }}>
                  <Icon color={colors.DARK_BLUE} type="next" />
                </Column>
              </TouchableOpacity>
              <Spacer size="16" />
            </React.Fragment>
          );
        }
        default:
          return null;
      }
    },
    [items, hasInvalidItems, formState.draft.company, formState.draft.discount, usableMemberships, productAvailability],
  );

  const canUseProtocol = React.useMemo(
    () => !formState.draft.items.some((i) => i.type === 'MembershipInstallment'),
    [formState.draft.items],
  );

  const canUseMembership = React.useMemo(
    () => formState.draft.items.some((i) => i.type === 'Service' || i.type === 'Booking' || i.type === 'Product'),
    [formState.draft.items],
  );

  const totalUnpaidAppointments = React.useMemo(() => {
    if (formState.draft.client.type !== 'existing') {
      return 0;
    }
    const draftAppointments = formState.draft.items
      .filter((item): item is WithUiKey<ItemBooking> => item.type === 'Booking')
      .map((item) => item.appointmentId);

    return appointmentsIds.filter((id) => !draftAppointments.includes(id)).length;
  }, [appointmentsIds, formState.draft.items.length, formState.draft.client]);

  return (
    <>
      <ModalScreenContainer
        edges={['left', 'top', 'right']}
        style={isDesktop && { width: 500, borderRadius: 0, alignSelf: 'flex-end' }}
      >
        {/*{isLoading && <LoadingComponent />}*/}
        <Column style={{ zIndex: 1 }}>
          <MeroHeader
            title={t('newProceed')}
            RightComponent={
              <Column style={{ position: 'relative', zIndex: 9999 }}>
                <TouchableOpacity
                  onPress={items.length > 0 ? toggleCloseMenu : goBack}
                  style={{ justifyContent: 'center', height: 50 }}
                >
                  <Icon type="close" />
                </TouchableOpacity>
                {showCloseMenu && isDesktop ? (
                  <CloseDraftMenuDesktop
                    onSave={flow(toggleCloseMenu, onSaveDraft)}
                    onCancel={flow(reset, goBack)}
                    onClose={toggleCloseMenu}
                    onDelete={flow(toggleCloseMenu, deleteProceed)}
                  />
                ) : null}
              </Column>
            }
          />
        </Column>
        <SafeAreaView edges={['bottom']} style={{ flex: 1, flexDirection: 'column' }}>
          <AvoidKeyboard
            style={{
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <ScrollView style={{ flex: 1, flexGrow: 1 }} ref={scrollRef} onScrollEndDrag={dismissKeyboardCallback}>
              <Column style={{ paddingHorizontal: 24 }}>
                {clientDetails?.type === 'existing' ? (
                  <>
                    <Column style={{ borderWidth: 1, borderRadius: 6, overflow: 'hidden', borderColor: colors.GEYSER }}>
                      <ClientListItem
                        client={{
                          firstname: clientDetails.client.user.profile.firstname,
                          lastname: clientDetails.client.user.profile.lastname,
                          isBlocked: false,
                          isWarned: false,
                          isFavourite: false,
                          photo: clientDetails.client.user.profile.photo,
                          phone: clientDetails.client.user.phone as PhoneNumber,
                          isBoost: false,
                          hideBoostDetails: false,
                        }}
                        onPress={flow(toggleButton, toggleClientMenu)}
                        icon={onRemoveClientPress !== undefined ? 'next' : undefined}
                        onIconPress={flow(toggleButton, toggleClientMenu)}
                        disabled={isClientFixed}
                        style={{ paddingHorizontal: 16 }}
                      >
                        {membershipStatus && membershipStatus.activeMemberships > 0 && canUseMembership ? (
                          <>
                            <Spacer size={4} />
                            <FlashyLabel
                              text={t('usableMembership', {
                                count: membershipStatus.activeMemberships,
                                total: usableMemberships.length,
                              })}
                              type={usableMemberships.length > 0 ? 'success' : 'info'}
                            />
                          </>
                        ) : null}
                      </ClientListItem>
                      {membershipStatus && membershipStatus.debt > 0 ? (
                        <Row
                          style={{
                            backgroundColor: membershipStatus.debt > 0 ? colors.OUTRAGEOUS_ORANGE : colors.SHAMROCK,
                            paddingHorizontal: 12,
                            paddingVertical: 8,
                          }}
                        >
                          <Label style={[{ flex: 1, color: colors.WHITE }, meroStyles.text.semibold]}>
                            {t('clientMembershipDebt')}
                          </Label>
                          <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>
                            {scaledToString(ScaledNumber.fromNumber(membershipStatus.debt, 2))} {t('RON')}
                          </Label>
                        </Row>
                      ) : null}
                    </Column>
                    <Spacer size={16} />
                  </>
                ) : (
                  <>
                    <Switch
                      height={sizes[32]}
                      textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                      buttons={ClientTabsOptions.map((tab) => ({ ...tab, label: t(tab.label) }))}
                      defaultValue={client?.type === 'none' ? 'existing' : 'new'}
                      onChange={(value) => {
                        if (value === 'existing') {
                          setClientCallback({
                            type: 'none',
                          });
                        } else if (value === 'new') {
                          setClientCallback({
                            type: 'new',
                            isValid: true,
                            isEmpty: true,
                          });
                        }
                      }}
                    />
                    {client?.type === 'none' && (
                      <>
                        <Spacer size="16" />
                        <SearchInputButton placeholder={t('searchClient')} onPress={onSelectClientPress} />
                        <Spacer size="16" />
                      </>
                    )}

                    {client?.type === 'new' && (
                      <>
                        <Spacer size="16" />
                        <TypeSafeTextInput
                          codec={FullnameFromString}
                          value={clientName.input}
                          onChange={setClientName}
                          placeholder={t('clientName')}
                          showError={!clientFormIsEmpty && showErrors}
                          textContentType="name"
                        />
                        <Spacer size="8" />
                        <TypeSafeTextInput
                          codec={StrictPhoneNumberParsed}
                          value={clientPhoneNumber.input}
                          onChange={setClientPhoneNumber}
                          placeholder={t('addPhoneNumber')}
                          showError={!clientFormIsEmpty && showErrors}
                          keyboardType="phone-pad"
                          textContentType="telephoneNumber"
                        />
                        <Spacer size="16" />
                      </>
                    )}
                  </>
                )}
              </Column>
              <Column style={{ height: 16, backgroundColor: colors.ALABASTER }} />
              <Column style={{ paddingHorizontal: 24 }}>
                <Spacer size="24" />

                {items.map(getItemComponent)}

                {formState.draft.discount ? (
                  <>
                    <TouchableOpacity
                      style={{
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: colors.GEYSER,
                        paddingVertical: 16,
                        paddingLeft: 16,
                        paddingRight: 8,
                      }}
                      onPress={() => {
                        setSelectedDiscount(formState.draft.discount);
                        setShowEditDiscount(true);
                      }}
                    >
                      <Column style={{ flex: 1 }}>
                        <Title>{t('discountPercent', { value: discountPercentage })}</Title>
                      </Column>
                      <Body>
                        -{discountValue} {t('RON')}
                      </Body>
                      <Column style={{ paddingLeft: 8 }}>
                        <Icon color={colors.DARK_BLUE} type="next" />
                      </Column>
                    </TouchableOpacity>
                    <Spacer size="16" />
                  </>
                ) : null}
                {totalUnpaidAppointments > 0 && (
                  <>
                    <TouchableOpacity
                      style={{ flexDirection: 'row', flex: 1 }}
                      onPress={() =>
                        navigation.navigate('CheckoutSubStack', {
                          screen: 'SelectBookingScreen',
                          params:
                            formState.draft.client.type === 'existing'
                              ? { clientId: formState.draft.client.client._id }
                              : undefined,
                        })
                      }
                    >
                      <FlashyLabel
                        style={{ flex: 1, paddingVertical: 0 }}
                        CenterComponent={
                          <Row alignItems="center">
                            <WarningIcon />
                            <Label
                              style={[meroStyles.text.semibold, { marginLeft: 2, color: colors.OUTRAGEOUS_ORANGE }]}
                            >
                              {t('remainingAppointments', { count: totalUnpaidAppointments })}
                            </Label>
                          </Row>
                        }
                        // text={}
                        type={'warning'}
                      />
                    </TouchableOpacity>
                    <Spacer size={16} />
                  </>
                )}
                <Button
                  size="medium"
                  color={colors.DARK_BLUE}
                  backgroundColor={colors.SKY_BLUE}
                  text={t('addItem')}
                  onPress={addNewItemPress}
                />
                <Spacer size="24" />
              </Column>
              {formState.draft.items.length > 0 && (
                <>
                  <Column style={{ height: 16, backgroundColor: colors.ALABASTER }} />
                  <Column style={{ padding: 24 }}>
                    <Row>
                      <SmallBody>{t('subtotal')}</SmallBody>
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                        {scaledToString(totalValues.subtotal.amount)} {t(totalValues.subtotal.unit)}
                      </SmallBody>
                    </Row>
                    {totalValues.discount && ScaledNumber.toNumber(totalValues.discount.amount) !== 0 ? (
                      <Row style={{ paddingTop: 12 }}>
                        <SmallBody>{t('discount')}</SmallBody>
                        <SmallBody style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                          -{scaledToString(totalValues.discount.amount)} {t(totalValues.discount.unit)}
                        </SmallBody>
                      </Row>
                    ) : null}
                    {totalValues.vat ? (
                      <Row style={{ paddingTop: 12 }}>
                        <SmallBody>{t('vat')}</SmallBody>
                        <SmallBody style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                          {scaledToString(totalValues.vat.amount)} {t(totalValues.vat.unit)}
                        </SmallBody>
                      </Row>
                    ) : null}
                    <Spacer size={16} />
                    <Line />
                    <Spacer size={16} />
                    <Row>
                      <Body>{t('total')}</Body>
                      <Body style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                        {scaledToString(totalValues.total.amount)} {t(totalValues.total.unit)}
                      </Body>
                    </Row>
                    <Spacer size={24} />
                    <Row alignItems="center">
                      {formState.draft.discount || !allowDiscount ? null : (
                        <>
                          <TouchableOpacity
                            style={{ flexDirection: 'row', alignItems: 'center', paddingRight: 32 }}
                            onPress={() => {
                              setSelectedDiscount(EmptyDiscount);
                              setShowEditDiscount(true);
                            }}
                          >
                            <PercentageIcon />
                            <SmallBody
                              style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE, paddingLeft: 8 }}
                            >
                              {t('addDiscount')}
                            </SmallBody>
                          </TouchableOpacity>
                        </>
                      )}
                      {canUseProtocol && (
                        <TouchableOpacity
                          style={{ flexDirection: 'row', alignItems: 'center', paddingRight: 32 }}
                          onPress={addProtocol}
                        >
                          <ProtocolIcon />
                          <SmallBody
                            style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE, paddingLeft: 8 }}
                          >
                            {t('addProtocol')}
                          </SmallBody>
                        </TouchableOpacity>
                      )}
                    </Row>
                  </Column>
                </>
              )}
              {isSavedTransaction && (
                <>
                  <Spacer size={8} />
                  <Row style={{ paddingHorizontal: 24, justifyContent: 'center' }}>
                    <Button
                      padding={24}
                      expand={false}
                      size="medium"
                      backgroundColor={colors.WHITE}
                      color={colors.RADICAL_RED}
                      text={t('deleteProceed')}
                      onPress={deleteProceed}
                    />
                  </Row>
                </>
              )}
              <Spacer size={96} />
            </ScrollView>
          </AvoidKeyboard>
        </SafeAreaView>
      </ModalScreenContainer>
      {showEditService && selectedService && (
        <EditService
          service={selectedService}
          isBooking={!!selectedBooking}
          onSave={flow(selectedBooking ? updateServiceBooking : onUpdateItem, closeEdit)}
          onCancel={closeEdit}
          onDelete={flow(selectedBooking ? removeServiceBooking : onRemoveItem, closeEdit)}
        />
      )}
      {showEditProduct && selectedProduct && (
        <EditProduct
          product={selectedProduct}
          onSave={flow(onUpdateItem, closeEdit)}
          onCancel={closeEdit}
          onDelete={flow(onRemoveItem, closeEdit)}
        />
      )}
      {showEditAmount && selectedAmount && (
        <EditAmount
          isEdit={true}
          amount={selectedAmount}
          onSave={flow(onUpdateItem, closeEditAmount)}
          onDelete={flow(onRemoveItem, closeEditAmount)}
          onCancel={closeEditAmount}
        />
      )}
      {showEditDiscount && selectedDiscount && (
        <EditDiscount
          isEdit={isEditDiscount}
          discountItem={selectedDiscount}
          onSave={flow(isEditDiscount ? updateDiscount : addDiscount, closeEditDiscount)}
          onDelete={flow(removeDiscount, closeEditDiscount)}
          onCancel={closeEditDiscount}
          calculateTotals={calculateTotals}
        />
      )}
      {showEditMembershipInstallment && selectedMembershipInstallment && (
        <MembershipPartialPayment
          withDelete
          totalPrice={selectedMembershipInstallment.debt}
          partialPayment={ScaledNumber.toNumber(selectedMembershipInstallment.total.amount.amount)}
          onSave={flow(
            (amount) => updateMembershipInstallment(selectedMembershipInstallment, amount),
            closeEditMembershipInstallment,
          )}
          onCancel={closeEditMembershipInstallment}
          onDelete={flow(() => onRemoveItem(selectedMembershipInstallment), closeEditMembershipInstallment)}
        />
      )}
      {showCloseMenu && isPhone && (
        <CloseDraftMenuPhone
          onSave={flow(toggleCloseMenu, onSaveDraft)}
          onClose={toggleCloseMenu}
          onCancel={goBack}
          onDelete={flow(toggleCloseMenu, deleteProceed)}
        />
      )}
      {showClientMenu && onChangeClientPress && onRemoveClientPress ? (
        <ClientMenu
          onDetails={flow(toggleClientMenu, toggleButton, openClientDetails)}
          onChange={flow(toggleClientMenu, toggleButton, onChangeClientPress)}
          onRemove={flow(toggleClientMenu, toggleButton, onRemoveClientPress)}
          onDismiss={flow(toggleClientMenu, toggleButton)}
        />
      ) : null}
    </>
  );
};

const ClientTabs = {
  existing: { label: 'existingClient', value: 'existing' },
  new: { label: 'newClient', value: 'new' },
} as const;

export type ClientTabs = keyof typeof ClientTabs;

const ClientTabsOptions = [ClientTabs.existing, ClientTabs.new];

export default AddProceedComponent;
