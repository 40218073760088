import { MembershipPurchaseId } from '@mero/api-sdk';
import { SavedClient } from '@mero/api-sdk/dist/clients';
import { MembershipConsumptionPerTransaction } from '@mero/api-sdk/dist/memberships/membershipConsumptionPerTransaction';
import { MembershipPayment } from '@mero/api-sdk/dist/memberships/membershipPayment';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import { PurchasedMembershipDetails } from '@mero/api-sdk/dist/memberships/purchasedMembershipDetails';
import { AnyPurchasedMembershipHistoryRecord } from '@mero/api-sdk/dist/memberships/purchasedMembershipHistoryRecord';
import { PurchasedMembershipServiceItemDetails } from '@mero/api-sdk/dist/memberships/purchasedMembershipItemDetails';
import {
  Body,
  colors,
  Column,
  DismissKeyboard,
  H2s,
  Icon,
  Label,
  Line,
  MeroHeader,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
  useShowError,
  Button,
  Switch,
  H1,
  Avatar,
  HSpacer,
} from '@mero/components';
import { formatPhoneNumber } from '@mero/shared-components';
import { DefinedString, MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { styles } from '../MenuScreen/screens/ProsDashboardScreen/ProProfileDetailsScreen.styles';

import { FlashyLabel } from '../../../components/FlashyLabel';
import KeyboardAvoidingView from '../../../components/KeyboardAvoidingView';
import { LoadingComponent } from '../../../components/LoadingComponent';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import CancelMembershipDialog from '../ClientDetailsScreen/components/CancelMembershipDialog';
import EditMembershipNote from '../ClientDetailsScreen/components/EditMembershipNote';
import MembershipAppointmentListView from '../ClientDetailsScreen/components/MembershipAppointmentListView';
import MembershipHistoryListView from '../ClientDetailsScreen/components/MembershipHistoryListView';
import MembershipOptionsMenu from '../ClientDetailsScreen/components/MembershipOptionsMenu';
import FormCard from '@mero/components/lib/components/FormCard';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { meroApi } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import {
  AuthorizedStackParamList,
  CheckoutMembershipStackParamList,
  CheckoutSubStackParamList,
  CombineCheckoutStackParamList,
} from '../../../types';
import log from '../../../utils/log';
import { scaledToString } from '../../../utils/scaled';
import { nameGenerator } from '../../../utils/string';
import { BankTransferIcon, CardAndCashIcon, CardIcon, CashIcon, GiftCardIcon } from '../CheckoutScreen/FinishedPreview';

const ButtonIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6972">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 2671" />
    </G>
    <G fill="none" data-name="Group 7500">
      <G stroke="#080de0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.3}>
        <Path d="M7.813 12.748h4.789m-4.789 2.395h3.59m3.593-2.4h1.2m-1.2 2.4h1.2M4.826 4.5v15.3l1.79-1.193 1.815 1.2 1.789-1.2 1.8 1.193 1.789-1.193 1.8 1.193 1.79-1.193 1.795 1.193V4.5m-3.585 15.3h0Zm-3.585 0h0ZM3.75 4.5h16.5" />
        <Path d="m10.3 8.762 1.226 1.226 2.18-2.179" data-name="layer1" />
      </G>
      <Path d="M3 3h18v18H3z" data-name="Rectangle 2701" />
    </G>
  </Svg>
);

const UpIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 4275">
      <Path fill="#080de0" d="m17.743 14.593-1.385 1.385-4.489-4.569-4.485 4.569L6 14.593l5.869-5.954Z" />
      <Path fill="none" d="M0 24V0h24v24z" data-name="Rectangle 2" />
    </G>
  </Svg>
);
const ICON_MAP = {
  Card: <CardIcon />,
  Cash: <CashIcon />,
  BankTransfer: <BankTransferIcon />,
  Giftcard: <GiftCardIcon />,
  Mix: <CardAndCashIcon />,
  Online: <CardIcon />,
} as const;

type Props = CurrentBusinessProps &
  StackScreenProps<
    AuthorizedStackParamList &
      CombineCheckoutStackParamList &
      CheckoutSubStackParamList &
      CheckoutMembershipStackParamList,
    'ClientMembershipDetailsScreen'
  >;

const MembershipDetailsScreen: React.FC<Props> = ({ navigation, page, route }) => {
  const { t } = useTranslation('clients');
  const goBack = useGoBack();
  const showError = useShowError();
  const { isPhone } = useMediaQueries();
  const isFocused = useIsFocused();

  const { membershipPurchaseId, userId } = route.params;

  const [isLoading, setIsLoading] = React.useState(true);
  const [membership, setMembership] = React.useState<PurchasedMembershipDetails<MeroUnits.Any>>();
  const [payments, setPayments] = React.useState<MembershipPayment<MeroUnits.Any>[]>([]);
  const [consumptions, setConsumptions] = React.useState<MembershipConsumptionPerTransaction<MeroUnits.Any>[]>([]);
  const [history, setHistory] = React.useState<AnyPurchasedMembershipHistoryRecord[]>([]);
  const [showNoteDialog, setShowNoteDialog] = React.useState(false);
  const [note, setNotes] = React.useState('');
  const [client, setClient] = React.useState<SavedClient>();

  const [isPaymentsExpanded, setIsPaymentsExpanded] = React.useState(false);
  const [selected, setSelected] = React.useState('appointments');

  const [showOptions, setShowOptions] = React.useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = React.useState(false);

  const getMembershipDetails = async (membershipPurchaseId: MembershipPurchaseId) => {
    try {
      const [membership, payments, consumptions, history, clients] = await Promise.all([
        meroApi.memberships.getPurchasedMembershipById({
          pageId: page.details._id,
          membershipPurchaseId,
        }),
        meroApi.memberships.getPurchasedMembershipPayments({
          pageId: page.details._id,
          membershipPurchaseId,
        }),
        meroApi.memberships.getPurchasedMembershipConsumption({
          pageId: page.details._id,
          membershipPurchaseId,
        }),
        meroApi.memberships.getPurchasedMembershipHistory({
          pageId: page.details._id,
          membershipPurchaseId,
        }),
        meroApi.clients.search({
          pageId: page.details._id,
          userId: userId,
        }),
      ]);

      if (clients.length === 0) {
        throw new Error(t('clientNotFound'));
      }

      setMembership(membership);
      setNotes(membership.note ?? '');
      setPayments(payments);
      setConsumptions(consumptions);
      setHistory(history);
      setClient(clients[0]);
    } catch (error) {
      log.error('Error getting membership details', JSON.stringify(error));
      showError(error);
      goBack();
    } finally {
      setIsLoading(false);
    }
  };

  const { status, color } = React.useMemo(() => {
    if (!membership) {
      return { status: '', color: colors.COMET };
    }
    const status = PurchasedMembership.getMembershipStatus(membership);

    return {
      status: status,
      color:
        status === 'Cancelled' || status === 'Expired'
          ? colors.RADICAL_RED
          : status === 'Active'
          ? colors.SHAMROCK
          : colors.COMET,
    };
  }, [membership]);

  const hasDebt = React.useMemo(() => {
    if (!membership) {
      return false;
    }

    const debt =
      ScaledNumber.toNumber(membership.template.sellingPrice.amount) - ScaledNumber.toNumber(membership.paid.amount);

    return debt > 0;
  }, [membership?.paid]);

  const buttons = React.useMemo(
    () => [
      {
        label: t('appointments'),
        value: 'appointments',
      },
      {
        label: t('history'),
        value: 'history',
      },
    ],
    [],
  );
  const toggleShowNote = () => setShowNoteDialog(!showNoteDialog);

  const toggleOptions = () => setShowOptions(!showOptions);

  const addNewAppointment = () => {
    if (!client || !membership) {
      return;
    }
    const newStart = DateTime.now().setZone('Europe/Bucharest').startOf('hour').plus({ hours: 1 }).toJSDate();
    const serviceIds = membership.template.items
      .filter((item): item is PurchasedMembershipServiceItemDetails<MeroUnits.Any> => item.type === 'Service')
      .filter((item) => item.quantity.type === 'Unlimited' || item.quantity.value - item.consumed > 0)
      .map((item) => item.service._id);

    navigation.navigate('Booking', {
      screen: 'BookingCreateScreen',
      params: {
        clientId: client._id,
        serviceIds: serviceIds.length === 0 ? undefined : serviceIds.join(','),
        date: newStart.toISOString(),
      },
    });
  };

  const onSell = async () => {
    if (!membership || !client) {
      return;
    }
    navigation.navigate('CombineCheckout', {
      screen: 'CheckoutStack',
      params: {
        screen: 'AddProceedScreen',
        params: {
          type: 'Membership',
          membershipPurchaseId: membership._id,
          membershipTemplateId: membership.template._id,
          clientId: client._id,
        },
      },
    });
    toggleOptions();
  };

  const toggleConfirmCancel = () => setShowConfirmCancel(!showConfirmCancel);

  const onCancel = async () => {
    await getMembershipDetails(membershipPurchaseId);
    toggleOptions();
    toggleConfirmCancel();
  };

  const saveNote = async (note: string) => {
    await meroApi.memberships.updatePurchasedMembershipNote({
      pageId: page.details._id,
      membershipPurchaseId,
      note: note as DefinedString,
    });
    await getMembershipDetails(membershipPurchaseId);
    toggleShowNote();
  };

  React.useEffect(() => {
    if (isFocused) {
      getMembershipDetails(membershipPurchaseId);
    }
  }, [membershipPurchaseId, userId, isFocused]);

  const getSelected = React.useCallback(() => {
    switch (selected) {
      case 'appointments':
        return (
          <MembershipAppointmentListView
            data={consumptions}
            HeaderElement={
              consumptions.length ? (
                <>
                  <H1 style={{ paddingHorizontal: 16 }}>Ședințe efectuate</H1>
                  <Spacer size={24} />
                </>
              ) : undefined
            }
          />
        );
      case 'history':
        return (
          <MembershipHistoryListView
            data={history}
            HeaderElement={
              history.length ? (
                <>
                  <H1 style={{ paddingHorizontal: 16 }}>Istoric</H1>
                  <Spacer size={24} />
                </>
              ) : undefined
            }
          />
        );
      default:
        return null;
    }
  }, [selected, consumptions, history]);

  const addNewCharge = () => {
    if (!client) {
      return;
    }
    navigation.navigate('CheckoutStack', {
      screen: 'AddProceedScreen',
      params: {
        type: 'MembershipInstallment',
        membershipPurchaseId,
        clientId: client._id,
      },
    });
  };

  return (
    <>
      <ModalScreenContainer>
        {/*{isLoading && <LoadingComponent />}*/}
        <MeroHeader canGoBack onBack={goBack} title={t('membershipDetails')} />
        <DismissKeyboard style={{ paddingTop: 16, flex: 1, height: '100%' }}>
          <KeyboardAvoidingView style={{ flex: 1 }}>
            <ScrollView style={{ borderRadius: 6, flex: 1 }}>
              {membership && client ? (
                <>
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Label style={[{ color }, meroStyles.text.semibold]}>{t(status)}</Label>
                    <H2s>{membership.template.name}</H2s>
                    <Spacer size={6} />
                    <SmallBody>
                      {DateTime.fromJSDate(membership.template.validFor.from).setLocale('ro').toFormat('dd MMM yyyy')} -{' '}
                      {membership.template.validFor.type === 'Unlimited'
                        ? t('Unlimited')
                        : DateTime.fromJSDate(membership.template.validFor.to).setLocale('ro').toFormat('dd MMM yyyy')}
                    </SmallBody>
                    <Spacer size={16} />
                  </Column>
                  <Spacer size={16} color={colors.ALABASTER} />
                  <TouchableOpacity
                    style={{ padding: 16, flexDirection: 'row' }}
                    onPress={() =>
                      navigation.navigate('ClientDetails', {
                        screen: 'DetailsScreen',
                        params: { clientId: client._id, pageId: page.details._id },
                      })
                    }
                  >
                    <Column>
                      <Avatar
                        firstname={client.user.firstname ?? ''}
                        lastname={client.user.lastname ?? ''}
                        source={client.user.photo?.medium}
                      />
                    </Column>
                    <Column style={{ flex: 1, paddingLeft: 16 }}>
                      <Title>{nameGenerator(client.user, t('unknownUser'))}</Title>
                      <Spacer size={6} />
                      <SmallBody style={{ color: colors.COMET }}>{formatPhoneNumber(client.user.phone)}</SmallBody>
                    </Column>
                    <Column>
                      <Icon type="next" color={colors.DARK_BLUE} />
                    </Column>
                  </TouchableOpacity>
                  <Spacer size={16} color={colors.ALABASTER} />
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Spacer size="24" />
                    <Column
                      style={{
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: colors.GEYSER,
                        padding: 16,
                      }}
                    >
                      {membership.template.items.map((item, index) => {
                        if (item.type !== 'Service') {
                          return null;
                        }

                        return (
                          <React.Fragment key={item.service._id}>
                            <Column>
                              <Row>
                                <Column style={{ flex: 1 }}>
                                  <Title>{item.service.name}</Title>
                                  <SmallBody style={{ color: colors.COMET }}>
                                    {item.quantity.type === 'Unlimited'
                                      ? t('Unlimited')
                                      : t('statusItem', {
                                          consumed: item.quantity.value - item.consumed,
                                          total: item.quantity.value,
                                        })}
                                  </SmallBody>
                                </Column>
                                <Column>
                                  <Body>
                                    {scaledToString(item.sellingPrice.amount)} {t(item.sellingPrice.unit)}
                                  </Body>
                                </Column>
                              </Row>
                            </Column>
                            {index < membership.template.items.length - 1 && (
                              <>
                                <Spacer size={16} />
                                <Line />
                                <Spacer size={16} />
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Column>
                    <Spacer size="24" />
                  </Column>
                  <Spacer size={16} color={colors.ALABASTER} />
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Spacer size={16} />
                    {hasDebt || payments.length > 1 ? (
                      <>
                        <TouchableOpacity
                          style={{ flex: 1 }}
                          onPress={() => setIsPaymentsExpanded(!isPaymentsExpanded)}
                        >
                          <Row flex={1}>
                            <Column style={{ flex: 1 }}>
                              <FlashyLabel
                                text={hasDebt ? t('partialPayment') : t('fullPayment')}
                                type={hasDebt ? 'warning' : 'success'}
                              />
                              <Spacer size={4} />
                              <H2s>
                                {scaledToString(membership.paid.amount)} {t(membership.paid.unit)}
                                {hasDebt && (
                                  <SmallBody style={[{ color: colors.COMET }, meroStyles.text.semibold]}>
                                    {' '}
                                    / {scaledToString(membership.template.sellingPrice.amount)}{' '}
                                    {t(membership.template.sellingPrice.unit)}
                                  </SmallBody>
                                )}
                              </H2s>
                              <SmallBody>{nameGenerator(membership.user.profile, t('unknownUser'))}</SmallBody>
                            </Column>
                            <Column>
                              {isPaymentsExpanded ? <UpIcon /> : <Icon type="down" color={colors.DARK_BLUE} />}
                            </Column>
                          </Row>
                          {hasDebt && (
                            <Row
                              style={{
                                backgroundColor: colors.OUTRAGEOUS_ORANGE,
                                paddingHorizontal: 12,
                                paddingVertical: 4,
                                borderRadius: 4,
                                marginTop: 6,
                              }}
                            >
                              <Label style={[{ flex: 1, color: colors.WHITE }, meroStyles.text.semibold]}>
                                {t('debtAmount')}
                              </Label>
                              <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>
                                {scaledToString(
                                  ScaledNumber.sub(membership.template.sellingPrice.amount, membership.paid.amount),
                                )}{' '}
                              </Label>
                            </Row>
                          )}
                        </TouchableOpacity>
                        {isPaymentsExpanded && (
                          <Column>
                            <Spacer size={16} />
                            <Line />
                            <Spacer size={16} />
                            <Column>
                              {payments.map((payment, index) => (
                                <React.Fragment key={index}>
                                  <TouchableOpacity
                                    style={{ flexDirection: 'row' }}
                                    onPress={() =>
                                      navigation.navigate('CombineCheckout', {
                                        screen: 'CheckoutStack',
                                        params: {
                                          screen: 'ProceedDetailsScreen',
                                          params: {
                                            checkoutTransactionId: payment.checkoutTransaction._id,
                                            backMode: 'one',
                                          },
                                        },
                                      })
                                    }
                                  >
                                    <Column>{ICON_MAP[payment.checkoutTransaction.paymentMethod]}</Column>
                                    <Column style={{ flex: 1, paddingLeft: 16 }}>
                                      {payment.checkoutTransaction.status === 'Finished' ? (
                                        <FlashyLabel text={t('partialPaymentStatusFinalized')} type={'success'} />
                                      ) : (
                                        <FlashyLabel text={t('partialPaymentStatusCanceled')} type={'error'} />
                                      )}
                                      <Spacer size={4} />
                                      <H2s>
                                        {scaledToString(payment.amount.amount)} {t(payment.amount.unit)}
                                      </H2s>
                                      <SmallBody>{nameGenerator(membership.user.profile, t('unknownUser'))}</SmallBody>
                                      <Spacer size={6} />
                                      <SmallBody style={{ color: colors.COMET }}>
                                        {DateTime.fromJSDate(payment.checkoutTransaction.finishedAt)
                                          .setLocale('ro')
                                          .toFormat('dd MMM yyyy, HH:mm')}
                                      </SmallBody>
                                    </Column>
                                    <Column>
                                      <Icon type="next" color={colors.DARK_BLUE} />
                                    </Column>
                                  </TouchableOpacity>
                                  <Spacer size={16} />
                                  <Line />
                                  {index < payments.length - 1 && <Spacer size={16} />}
                                </React.Fragment>
                              ))}
                            </Column>
                          </Column>
                        )}
                        {hasDebt && (
                          <>
                            <Spacer size={16} />

                            <Button
                              LeftComponent={() => (
                                <Column style={{ paddingRight: 8 }}>
                                  <ButtonIcon />
                                </Column>
                              )}
                              backgroundColor={colors.SKY_BLUE}
                              color={colors.DARK_BLUE}
                              text={t('newCharge')}
                              onClick={addNewCharge}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <TouchableOpacity
                        style={{ flexDirection: 'row' }}
                        onPress={() =>
                          navigation.navigate('CheckoutStack', {
                            screen: 'ProceedDetailsScreen',
                            params: {
                              checkoutTransactionId: payments[0].checkoutTransaction._id,
                              backMode: 'one',
                            },
                          })
                        }
                      >
                        <Column style={{ flex: 1 }}>
                          <FlashyLabel text={t('fullPayment')} type={'success'} />
                          <Spacer size={4} />
                          <H2s>
                            {scaledToString(membership.template.sellingPrice.amount)}{' '}
                            {t(membership.template.sellingPrice.unit)}
                          </H2s>
                          <SmallBody>{nameGenerator(membership.user.profile, t('unknownUser'))}</SmallBody>
                          <Spacer size={6} />
                          <SmallBody style={{ color: colors.COMET }}>
                            {DateTime.fromJSDate(membership.purchasedOn).setLocale('ro').toFormat('dd MMM yyyy, HH:mm')}
                          </SmallBody>
                        </Column>
                        <Column>
                          <Icon type="next" color={colors.DARK_BLUE} />
                        </Column>
                      </TouchableOpacity>
                    )}
                    <Spacer size={16} />
                  </Column>
                  <Spacer size={16} color={colors.ALABASTER} />
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Spacer size={16} />
                    <Title>{t('observations')}</Title>
                    <Spacer size={6} />
                    <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleShowNote}>
                      <Body style={{ flex: 1, color: note ? colors.BLACK : colors.COMET }}>{note || t('addNote')}</Body>
                      <Icon type="next" color={colors.DARK_BLUE} />
                    </TouchableOpacity>
                    <Spacer size={16} />
                  </Column>
                  <Spacer size={16} color={colors.ALABASTER} />
                  <Column>
                    <Spacer size={24} />
                    <Column style={{ paddingHorizontal: 16 }}>
                      <Switch buttons={buttons} onChange={(v) => setSelected(v.toString())} />
                    </Column>
                    <Spacer size={32} />
                    {getSelected()}
                  </Column>
                </>
              ) : null}
              <Spacer size={96} />
            </ScrollView>
          </KeyboardAvoidingView>
        </DismissKeyboard>
        <FormCard
          dropShaddow
          paddings="button"
          style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
        >
          <SafeAreaView edges={['bottom']}>
            <Row flex={1}>
              <Column flex={1}>
                <Button
                  backgroundColor={colors.SKY_BLUE}
                  color={colors.DARK_BLUE}
                  text={t('membershipOptions')}
                  onClick={toggleOptions}
                />
              </Column>
              <HSpacer left={5} />
              <Column flex={1}>
                <Button text={t('addAppointment')} onClick={addNewAppointment} />
              </Column>
            </Row>
          </SafeAreaView>
        </FormCard>
      </ModalScreenContainer>
      {showNoteDialog && <EditMembershipNote note={note} onSave={saveNote} onCancel={toggleShowNote} />}
      {showOptions && (
        <MembershipOptionsMenu
          isActive={membership?.status === 'Active'}
          onSell={onSell}
          onCancel={onCancel}
          onDismiss={toggleOptions}
        />
      )}
      {showConfirmCancel && membership ? (
        <CancelMembershipDialog
          membershipPurchaseId={membership._id}
          onCancel={toggleConfirmCancel}
          onSuccess={onCancel}
        />
      ) : null}
    </>
  );
};

export default pipe(MembershipDetailsScreen, CurrentBusiness);
