import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import {
  Body,
  colors,
  Column,
  H1,
  H2s,
  HSpacer,
  Icon,
  Label,
  Line,
  MeroHeader,
  Row,
  SearchTextInput,
  SmallBody,
  Spacer,
  styles as meroStyles,
  useShowError,
  useToast,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import { LoadingComponent } from '../../../components/LoadingComponent';
import ModalScreenContainer from '../../../components/ModalScreenContainer';
import { MembershipIcon } from '../ClientDetailsScreen/components/ClientMembershipsListItemView';

import { useIsFocused } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutFormContext } from '../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, RootStackParamList, CheckoutSubStackParamList } from '../../../types';
import log from '../../../utils/log';
import { scaledToString } from '../../../utils/scaled';
import { membershipInstallmentToCheckoutItem } from './AddProceedScreen';

type Query = Parameters<typeof meroApi.memberships.getPagePurchasedMemberships>[0]['query'];

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<
    CheckoutSubStackParamList & RootStackParamList & AuthorizedStackParamList,
    'SelectMembershipInstallmentScreen'
  >;
const SelectMembershipInstallmentScreen: React.FC<Props> = ({ page, authorization, navigation }: Props) => {
  const { t } = useTranslation('checkout');
  const goBack = useGoBack();
  const toast = useToast();
  const isFocused = useIsFocused();
  const showError = useShowError();

  const [formState, { setItem, setClient }] = CheckoutFormContext.useContext();

  const [searchQuery, setSearchQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [purchaseMemberships, setPurchaseMemberships] = React.useState<PurchasedMembership<MeroUnits.Any>[]>([]);
  const [nextPage, setNextPage] = React.useState<string>();
  const loadingNextPage = React.useRef('');

  const loadMemberships = React.useCallback(
    async (query?: Query, next?: string) => {
      setIsLoading(true);
      try {
        const userId = formState.draft.client.type === 'existing' ? formState.draft.client.client.user._id : undefined;
        const { data: memberships, next: nextPage } = await meroApi.memberships
          .getPagePurchasedMemberships({
            pageId: page.details._id,
            query: {
              ...query,
              hasDebt: true,
              userId,
            },
            limit: 10,
            page: next,
          })
          .catch((error) => {
            log.error('Error fetching purchased memberships', JSON.stringify(error));
            throw error;
          });

        setNextPage(nextPage);
        setPurchaseMemberships(next ? [...purchaseMemberships, ...memberships] : memberships);
      } catch (error) {
        showError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [page.details._id],
  );

  const onSelect = React.useCallback(async (membership: PurchasedMembership<MeroUnits.Any>) => {
    setIsLoading(true);
    try {
      if (
        formState.draft.items.some(
          (item) => item.type === 'MembershipInstallment' && item.membership._id === membership._id,
        )
      ) {
        goBack();
        return;
      }
      const membershipInstallment = await meroApi.memberships.getPurchasedMembershipById({
        pageId: page.details._id,
        membershipPurchaseId: membership._id,
      });

      const member = page.members.find((m) => m.user._id === authorization.user._id) ?? page.members[0];

      const user = {
        _id: member.user._id,
        phone: member.user.phone ?? '074000000',
        profile: {
          firstname: member.user.profile.firstname,
          lastname: member.user.profile.lastname,
          photo: member.user.profile.photo,
        },
      };

      setItem({
        type: 'MembershipInstallment',
        ...membershipInstallmentToCheckoutItem(membershipInstallment, user),
      });

      const savedClients = await meroApi.clients.search({
        pageId: page.details._id,
        userId: membership.user._id,
      });

      if (savedClients.length > 0) {
        const clientId = savedClients[0]._id;
        const clientDetails = await meroApi.clients.getClientById(clientId);
        if (clientDetails) {
          setClient({
            type: 'existing',
            client: {
              _id: clientId,
              pageId: page.details._id,
              user: {
                _id: clientDetails.user._id,
                phone: clientDetails.user.phone,
                profile: {
                  firstname: membership.user.profile.firstname,
                  lastname: membership.user.profile.lastname,
                  photo: clientDetails.user.photo,
                },
              },
            },
          });
        }
      }

      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const loadMoreMemberships = () => {
    if (nextPage && nextPage !== loadingNextPage.current) {
      loadMemberships(
        searchQuery
          ? {
              search: searchQuery,
            }
          : undefined,
        nextPage,
      );
    }
  };

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      loadMemberships(
        searchQuery
          ? {
              search: searchQuery,
            }
          : undefined,
      );
    }, 500);

    return () => window.clearTimeout(timeout);
  }, [searchQuery, page.details._id, isFocused]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      {/*{isLoading && <LoadingComponent />}*/}
      <MeroHeader canGoBack onBack={goBack} title={t('partialMembershipTitle')} />
      <Column style={{ paddingTop: 16, flex: 1, height: '100%' }}>
        <InfiniteScrollView
          onEndReached={loadMoreMemberships}
          withLoading
          style={purchaseMemberships.length === 0 && !isLoading ? { borderRadius: 6, flex: 1 } : undefined}
          contentContainerStyle={purchaseMemberships.length === 0 && !isLoading ? { flex: 1 } : undefined}
          wrapperStyle={purchaseMemberships.length === 0 && !isLoading ? { flex: 1 } : undefined}
        >
          <Row style={{ paddingLeft: 24, paddingRight: 16, alignItems: 'center' }}>
            <H1 style={{ flex: 1 }}>{t('partialMembershipTitle')}</H1>
          </Row>
          <Spacer size={16} />
          <Row style={{ paddingHorizontal: 16, zIndex: 1 }}>
            <Column style={{ flex: 1 }}>
              <SearchTextInput
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder={t('searchMembershipPlaceholder')}
              />
            </Column>
            <HSpacer left={8} />
          </Row>
          <Spacer size={24} />
          {purchaseMemberships.map((membership, index) => {
            const total = membership.template.items.reduce(
              (acc, item) =>
                ScaledNumber.add(acc, item.type === 'Service' ? item.sellingPrice.amount : ScaledNumber.zero()),
              ScaledNumber.zero(),
            );

            const hasDebt = !ScaledNumber.equals(membership.debt.amount, ScaledNumber.zero());

            const { status, color } = (() => {
              if (!membership) {
                return { status: '', color: colors.COMET };
              }
              const status = PurchasedMembership.getMembershipStatus(membership);

              return {
                status: status,
                color:
                  status === 'Cancelled' || status === 'Expired'
                    ? colors.RADICAL_RED
                    : status === 'Active'
                    ? colors.SHAMROCK
                    : colors.COMET,
              };
            })();

            return (
              <React.Fragment key={membership._id}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 24,
                    paddingVertical: 16,
                  }}
                  onPress={() => onSelect(membership)}
                >
                  <Column>
                    <MembershipIcon />
                  </Column>
                  <Column style={{ flex: 1, paddingLeft: 16 }}>
                    <Row>
                      <Column style={{ flex: 1 }}>
                        <Label style={[{ color: color }, meroStyles.text.semibold]}>{t(status)}</Label>
                        <H2s>{membership.template.name}</H2s>
                        <Spacer size={6} />
                        <SmallBody>
                          {scaledToString(total)} {t('RON')} (
                          {DateTime.fromJSDate(membership.template.validFor.from)
                            .setLocale('ro')
                            .toFormat('dd MMM yyyy')}{' '}
                          -{' '}
                          {membership.template.validFor.type === 'Unlimited'
                            ? t('Unlimited')
                            : DateTime.fromJSDate(membership.template.validFor.to)
                                .setLocale('ro')
                                .toFormat('dd MMM yyyy')}
                          )
                        </SmallBody>
                      </Column>
                      <Column>
                        <Icon type="next" color={colors.DARK_BLUE} />
                      </Column>
                    </Row>
                    {hasDebt && (
                      <Row
                        style={{
                          backgroundColor: colors.OUTRAGEOUS_ORANGE,
                          paddingHorizontal: 12,
                          paddingVertical: 4,
                          borderRadius: 4,
                          marginTop: 16,
                        }}
                      >
                        <Label style={[{ flex: 1, color: colors.WHITE }, meroStyles.text.semibold]}>
                          {t('debtAmount')}
                        </Label>
                        <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>
                          {scaledToString(membership.debt.amount)} {t(membership.debt.unit)}
                        </Label>
                      </Row>
                    )}
                  </Column>
                </TouchableOpacity>
                {index < purchaseMemberships.length - 1 && <Line />}
              </React.Fragment>
            );
          })}
          {!isLoading && purchaseMemberships.length === 0 && (
            <>
              <Spacer size={24} />
              <Column
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 24,
                  paddingBottom: 32,
                }}
              >
                <H2s style={{ textAlign: 'center' }}>{t('noMembershipSearch')}</H2s>
                <Spacer size={8} />
                <Body style={{ textAlign: 'center' }}>{t('noMembershipSearchDescription')}</Body>
              </Column>
            </>
          )}
        </InfiniteScrollView>
      </Column>
    </ModalScreenContainer>
  );
};

export default pipe(SelectMembershipInstallmentScreen, CurrentBusiness, Authorized);
