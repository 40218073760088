import { AppointmentId, CalendarId } from '@mero/api-sdk/dist/calendar';
import {
  InvitePreview,
  InviteType,
  InviteTypeAppointmentRequest,
  InviteTypeClientRequest,
  InviteTypeWork,
} from '@mero/api-sdk/dist/invites';
import {
  AppointmentCancelledByAnotherProNotificationType,
  AppointmentCancelledByClientNotificationType,
  AppointmentMadeByAnotherProNotificationType,
  AppointmentModifiedByAnotherProNotificationType,
  AppointmentRequestedNotificationType,
  ClientAppointmentMadeNotificationType,
  ClientAppointmentPaymentReceivedNotificationType,
  GenericProNotificationType,
  WorkerActivityNotification,
  WorkerInviteFulfilledNotificationType,
  GenericAppointmentActionNotificationType,
  ClientAddedToWaintingListNotificationType,
} from '@mero/api-sdk/dist/notifications';
import {
  Body,
  colors,
  ConfirmBox,
  H1,
  H3s,
  Line,
  ModalOverlay,
  Spacer,
  useAppState,
  UserNotificationSkeleton,
  useShowError,
  Button,
  Row,
} from '@mero/components';
import { TFunction } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Linking, Platform, SectionList, View } from 'react-native';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { AppEventsContext } from '../../../../contexts/AppEvents';
import { Authorized, AuthorizedProps } from '../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps, CurrentBusinessContext } from '../../../../contexts/CurrentBusiness';
import { ProcessInviteContext, withProcessInviteContextProvider } from '../../../../contexts/ProcessInviteContext';
import { UserInvitesContext, withUserInvitesContextProvider } from '../../../../contexts/UserInvitesContext';
import {
  UserNotificationsContext,
  withUserNotificationsContextProvider,
} from '../../../../contexts/UserNotificationsContext';
import { UserNotificationsCountContext } from '../../../../contexts/UserNotificationsCountContext';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  NotificationsTabStackParamList,
  RootStackParamList,
} from '../../../../types';
import log from '../../../../utils/log';
import ActivatePushWidget from './ActivatePushWidget';
import EmptyNotificationsScreenView from './EmptyNotificationsScreenView';
import UserActivityNotificationItem from './UserActivityNotificationItem';
import InviteItemView from './invites/InviteItemView';
import { styles } from './styles';

type NotificationsScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<NotificationsTabStackParamList, 'NotificationsScreen'>,
  CompositeNavigationProp<
    BottomTabNavigationProp<HomeTabsParamsList, 'NotificationsTab'>,
    CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList>
      >
    >
  >
>;

type Props = CurrentBusinessProps &
  AuthorizedProps & {
    navigation: NotificationsScreenNavigationProp;
  };

const NotificationsScreenComponent: React.FC<Props> = ({ page, navigation }: Props) => {
  const { t, i18n } = useTranslation('notifications');

  // const [pushSubscriptionState, { trySubscribeDevice }] = PushClientSubscriptionContext.useContext();

  const [, { reloadUnseen, markAsSeen }] = UserNotificationsCountContext.useContext();
  const [
    activityState,
    { loadMore, reload: reloadNotifications, tryMarkAsSeenAt, tryResetError: tryResetActivityError },
  ] = UserNotificationsContext.useContext();
  const [invitesState, { removeProcessedInvite, reload: reloadInvites, tryResetError: tryResetInvitesError }] =
    UserInvitesContext.useContext();
  const [processInviteState, { process: processInvite, reset: resetProcessInviteState }] =
    ProcessInviteContext.useContext();
  const [, { reloadAsync: reloadManagedPages }] = CurrentBusinessContext.useContext();
  const [, { subscribe: subscribeAppEvents }] = AppEventsContext.useContext();
  const [confirmRejectInvite, setConfirmRejectInvite] = React.useState<InvitePreview | undefined>(undefined);

  const showError = useShowError();
  const isLoading = activityState.type === 'Loading' || invitesState.type === 'Loading';
  /**
   * View state loaded
   */
  const isLoaded = activityState.type === 'Loaded' && invitesState.type === 'Loaded';
  /**
   * State is loaded but empty
   */
  const isEmpty = isLoaded && activityState.activity.length === 0 && invitesState.invites.length === 0;
  const isFocused = useIsFocused();
  const appState = useAppState();

  type InviteByType = { [K in InviteType]: NotificationSectionItem[] };
  const inviteItems: InviteByType = React.useMemo(() => {
    const emptyAcc: InviteByType = {
      [InviteTypeWork.value]: [],
      [InviteTypeClientRequest.value]: [],
      [InviteTypeAppointmentRequest.value]: [],
    };
    if (invitesState.type === 'Loaded' || invitesState.type === 'Loading') {
      return invitesState.invites.reduce((acc: InviteByType, invite): InviteByType => {
        acc[invite.type].push(inviteToSectionItem(invite));
        return acc;
      }, emptyAcc);
    } else {
      return emptyAcc;
    }
  }, [invitesState]);

  const activityItems = React.useMemo(() => {
    if (activityState.type === 'Loaded' || activityState.type === 'Loading') {
      return activityState.activity.map(activityToSectionItem);
    } else {
      return [];
    }
  }, [activityState]);

  const sectionsData: { title: string; data: NotificationSectionItem[] }[] = React.useMemo(() => {
    return [
      ...(inviteItems[InviteTypeAppointmentRequest.value].length > 0
        ? [
            {
              title: t('appointmentRequest'),
              data: inviteItems[InviteTypeAppointmentRequest.value],
            },
          ]
        : []),
      ...(inviteItems[InviteTypeClientRequest.value].length > 0
        ? [
            {
              title: t('privateProfileRequest'),
              data: inviteItems[InviteTypeClientRequest.value],
            },
          ]
        : []),
      ...(inviteItems[InviteTypeWork.value].length > 0
        ? [
            {
              title: t('invitation'),
              data: inviteItems[InviteTypeWork.value],
            },
          ]
        : []),
      ...(activityItems.length > 0
        ? [
            {
              title: t('activity'),
              data: activityItems,
            },
          ]
        : []),
    ];
  }, [inviteItems, activityItems, i18n.language]);

  const reload = React.useCallback(() => {
    reloadNotifications();
    reloadInvites();
  }, [reloadNotifications, reloadInvites]);

  // Mark notifications as seen, reload unseen notifications count based on app state (active/background)
  React.useEffect(() => {
    if (appState === 'active') {
      if (isFocused) {
        log.debug('screen focused, reload unseen, dismiss notifications');

        try {
          markAsSeen();
        } catch (e) {
          log.error('Failed to mark notifications as seen', e);
        }
      } else {
        try {
          reload();
          reloadUnseen();
        } catch (e) {
          log.error('Failed to reload unseen notifications', e);
        }
      }
    }
  }, [page.details._id, appState, isFocused]);

  // Listen for app events, reload on appointment accepted event
  React.useEffect(
    () =>
      subscribeAppEvents((event) => {
        log.debug(`got AppEvent with type ${event.type} in NotificationsScreren`, event);
        if (event.type === 'AppointmentRequestAccepted' || event.type === 'AppointmentRequestRejected') {
          reload();
        }
      }),
    [subscribeAppEvents, reload],
  );

  // Relload requests when an invite wa sprocessed
  React.useEffect(() => {
    if (processInviteState.type === 'Processed') {
      removeProcessedInvite(processInviteState.inviteId);
      reload();
      if (processInviteState.resolution === 'accept') {
        reloadManagedPages();
      }
      resetProcessInviteState();
    } else if (processInviteState.type === 'Failed') {
      showError(processInviteState.error, t('errorUnknown'));
      reload();
      resetProcessInviteState();
    }
  }, [processInviteState, removeProcessedInvite, reload, reloadManagedPages, resetProcessInviteState, showError]);

  React.useEffect(() => {
    if (invitesState.type === 'Failed') {
      showError(invitesState.error);
      tryResetInvitesError();
    }
  }, [invitesState, showError, tryResetInvitesError]);

  React.useEffect(() => {
    if (activityState.type === 'Failed') {
      showError(activityState.error);
      tryResetActivityError();
    }
  }, [activityState, showError, tryResetActivityError]);

  const goAppointmentDetailsCallback = React.useCallback(
    (calendarId: CalendarId, calendarEntryId: AppointmentId, occurrenceIndex: number) => {
      navigation.navigate('Booking', {
        screen: 'BookingDetailsScreen',
        params: {
          calendarId: calendarId,
          calendarEntryId: calendarEntryId,
          occurrenceIndex: `${occurrenceIndex}`,
        },
      });
    },
    [navigation],
  );

  const navigatePagePendingClients = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PagePendingClientsScreen',
    });
  }, [navigation]);

  const notificationPressCallback = React.useCallback(
    (notification: WorkerActivityNotification, index: number) => {
      tryMarkAsSeenAt(index);

      switch (notification.type) {
        case WorkerInviteFulfilledNotificationType.value: {
          return;
        }
        case AppointmentCancelledByClientNotificationType.value: {
          return (
            //@TODO this should be fixed on the BE, the calendarId should not be undefined
            notification.payload.appointment.calendarId &&
            goAppointmentDetailsCallback(
              notification.payload.appointment.calendarId,
              notification.payload.appointment._id,
              notification.payload.appointment.occurrenceIndex ?? 0,
            )
          );
        }
        case ClientAppointmentMadeNotificationType.value:
        case GenericAppointmentActionNotificationType.value:
        case AppointmentMadeByAnotherProNotificationType.value: {
          return goAppointmentDetailsCallback(
            notification.payload.appointment.calendarId,
            notification.payload.appointment._id,
            0,
          );
        }
        case AppointmentModifiedByAnotherProNotificationType.value:
        case AppointmentCancelledByAnotherProNotificationType.value: {
          return goAppointmentDetailsCallback(
            notification.payload.appointment.calendarId,
            notification.payload.appointment._id,
            notification.payload.appointment.occurrenceIndex ?? 0,
          );
        }
        case ClientAppointmentPaymentReceivedNotificationType.value: {
          return goAppointmentDetailsCallback(
            notification.payload.appointment.calendarId,
            notification.payload.appointment._id,
            0,
          );
        }
        case AppointmentRequestedNotificationType.value: {
          if (notification.payload.appointment.status === 'accepted') {
            goAppointmentDetailsCallback(
              notification.payload.appointment.calendarId,
              notification.payload.appointment._id,
              0,
            );
          }

          return;
        }
        case ClientAddedToWaintingListNotificationType.value: {
          return navigation.navigate('PendingList');
        }
        case GenericProNotificationType.value: {
          if (Platform.OS === 'web' && notification.payload.webLinkUrl) {
            return Linking.openURL(notification.payload.webLinkUrl).catch((e) =>
              log.error('Failed to load web page', e),
            );
          }

          if (Platform.OS !== 'web' && notification.payload.appLinkUrl) {
            return Linking.openURL(notification.payload.appLinkUrl).catch((e) =>
              log.error('Failed to load app page', e),
            );
          }

          return;
        }
        case ClientAddedToWaintingListNotificationType.value: {
          return navigatePagePendingClients();
        }
      }
    },
    [navigation, tryMarkAsSeenAt],
  );

  const NotificationsPressableByType: { [K in WorkerActivityNotification['type']]: boolean } = {
    [WorkerInviteFulfilledNotificationType.value]: false,
    [AppointmentCancelledByClientNotificationType.value]: true,
    [ClientAppointmentMadeNotificationType.value]: true,
    [ClientAppointmentPaymentReceivedNotificationType.value]: true,
    [AppointmentRequestedNotificationType.value]: true,
    [GenericProNotificationType.value]: false,
    [AppointmentMadeByAnotherProNotificationType.value]: true,
    [AppointmentCancelledByAnotherProNotificationType.value]: true,
    [AppointmentModifiedByAnotherProNotificationType.value]: true,
    [GenericAppointmentActionNotificationType.value]: true,
    [ClientAddedToWaintingListNotificationType.value]: true,
  };

  const isNotificationPressableByPayload = (notification: WorkerActivityNotification) => {
    if (
      notification.type === GenericProNotificationType.value &&
      ((Platform.OS === 'web' && notification.payload.webLinkUrl) ||
        (Platform.OS !== 'web' && notification.payload.appLinkUrl))
    ) {
      return true;
    }
    return false;
  };

  const isNotificationPressable = React.useCallback(
    (notification: WorkerActivityNotification) => NotificationsPressableByType[notification.type],
    [],
  );

  const processInviteCallback = React.useCallback(
    (invite: InvitePreview, resolution: 'accept' | 'reject') => {
      log.debug(`${resolution} invite ${invite._id}`);
      if (resolution === 'reject') {
        setConfirmRejectInvite(invite);
      } else {
        switch (invite.type) {
          case InviteTypeAppointmentRequest.value: {
            navigation.navigate('Booking', {
              screen: 'BookingDetailsScreen',
              params: {
                calendarEntryId: invite.appointment.appointmentId,
                calendarId: invite.appointment.calendarId,
                occurrenceIndex: '0', // no recurring appointments from client-side
              },
            });
            return;
          }
          case InviteTypeClientRequest.value: {
            processInvite({ invite, resolution });
            return;
          }
          case InviteTypeWork.value: {
            processInvite({ invite, resolution });
            return;
          }
        }
      }
    },
    [reload, navigation, setConfirmRejectInvite],
  );
  const acceptInviteCallback = React.useCallback(
    (invite: InvitePreview) => {
      processInviteCallback(invite, 'accept');
    },
    [processInviteCallback],
  );
  const rejecctInviteCallback = React.useCallback(
    (invite: InvitePreview) => {
      processInviteCallback(invite, 'reject');
    },
    [processInviteCallback],
  );

  const navigateNotificationsOptions = React.useCallback(() => {
    navigation.navigate('NotificationsOptionsScreen');
  }, [navigation]);

  const navigateAppSettingsCallback = React.useCallback(() => {
    if (Platform.OS === 'ios') {
      Linking.openURL('app-settings:').catch(log.error);
    } else {
      Linking.openSettings().catch(log.error);
    }
  }, [Linking]);

  const HeaderElement = (
    <View>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 6,
          paddingLeft: 24,
          paddingRight: 8,
        }}
      >
        <H1 style={{ marginBottom: 0 }}>{t('notifications')}</H1>
        <Button
          text={t('options')}
          onClick={navigateNotificationsOptions}
          backgroundColor={colors.WHITE}
          color={colors.DARK_BLUE}
          size="large"
          expand={false}
          containerStyle={{ padding: 0 }}
        />
      </Row>
      <ActivatePushWidget onNavigatePermissionDenied={navigateAppSettingsCallback} />
    </View>
  );

  return (
    <View style={styles.container}>
      {isLoading && activityState.activity.length === 0 ? (
        <FlatList
          style={{ flex: 1, width: '100%' }}
          data={[1, 2, 3, 4, 5]}
          keyExtractor={(_, idx) => `${idx}`}
          keyboardShouldPersistTaps="handled"
          renderItem={() => <UserNotificationSkeleton />}
          ItemSeparatorComponent={Line}
          ListHeaderComponent={HeaderElement}
        />
      ) : isEmpty ? (
        <EmptyNotificationsScreenView onNavigatePermissionDenied={navigateAppSettingsCallback} />
      ) : (
        <SectionList
          style={{ flex: 1, width: '100%' }}
          ListHeaderComponent={HeaderElement}
          sections={sectionsData}
          keyExtractor={notificationSectionItemKeyExtractor}
          renderSectionHeader={({ section: { title } }) => (
            <View style={[styles.hrPadding, { backgroundColor: colors.WHITE }]}>
              <Spacer size="16" />
              <H3s>{title}</H3s>
              <Spacer size="8" />
            </View>
          )}
          renderItem={({ item, index }) => {
            switch (item.type) {
              case 'Invite': {
                return (
                  <InviteItemView
                    invite={item.invite}
                    onApprove={acceptInviteCallback}
                    onReject={rejecctInviteCallback}
                  />
                );
              }
              case 'Activity': {
                return (
                  <UserActivityNotificationItem
                    item={item.notification}
                    index={index}
                    onPress={notificationPressCallback}
                    disabled={
                      !isNotificationPressable(item.notification) &&
                      !isNotificationPressableByPayload(item.notification)
                    }
                  />
                );
              }
            }
          }}
          keyboardShouldPersistTaps="handled"
          onEndReached={loadMore}
          onEndReachedThreshold={0.8}
          windowSize={11}
          refreshing={isLoading}
          onRefresh={reload}
        />
      )}
      {confirmRejectInvite ? (
        <InviteRejectConfirmModal
          invite={confirmRejectInvite}
          onCancel={() => {
            setConfirmRejectInvite(undefined);
          }}
          onConfirm={async (invite) => {
            setConfirmRejectInvite(undefined);
            processInvite({ invite, resolution: 'reject' });
          }}
        />
      ) : null}
    </View>
  );
};

type NotificationSectionItem =
  | {
      readonly type: 'Invite';
      readonly invite: InvitePreview;
    }
  | {
      readonly type: 'Activity';
      readonly notification: WorkerActivityNotification;
    };

const activityToSectionItem = (notification: WorkerActivityNotification): NotificationSectionItem => ({
  type: 'Activity',
  notification: notification,
});

const inviteToSectionItem = (invite: InvitePreview): NotificationSectionItem => ({
  type: 'Invite',
  invite: invite,
});

const notificationSectionItemKeyExtractor = (item: NotificationSectionItem): string => {
  switch (item.type) {
    case 'Invite': {
      return `invite-${item.invite._id}`;
    }
    case 'Activity': {
      return `activity-${item.notification._id}`;
    }
  }
};

type InviteRejectConfirmModalTexts = {
  readonly title: string;
  readonly subtitle: string;
};

const getInviteRejectConfirmModalTexts = (
  invite: InvitePreview,
  t: TFunction<'notifications', undefined>,
): InviteRejectConfirmModalTexts => {
  switch (invite.type) {
    case InviteTypeAppointmentRequest.value: {
      return {
        title: t('cancelAppointmentRequestTitle'),
        subtitle: t('cancelAppointmentRequestSubtitle'),
      };
    }
    case InviteTypeClientRequest.value: {
      return {
        title: t('cancelClientRequestTitle'),
        subtitle: t('cancelClientRequestSubtitle'),
      };
    }
    case InviteTypeWork.value: {
      return {
        title: t('cancelInviteWorkTitle'),
        subtitle: t('cancelInviteWorkSubtitle'),
      };
    }
  }
};

type InviteRejectConfirmModalProps = {
  readonly invite: InvitePreview;
  readonly onConfirm: (invite: InvitePreview) => void;
  readonly onCancel: () => void;
};

const InviteRejectConfirmModal: React.FC<InviteRejectConfirmModalProps> = ({ invite, onConfirm, onCancel }) => {
  const { t } = useTranslation('notifications');

  const { title, subtitle } = getInviteRejectConfirmModalTexts(invite, t);

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <ConfirmBox
        type="error"
        icon="info"
        headerTitle={t('canNotBeUndoneAction')}
        canClose={true}
        onClose={onCancel}
        leftAction={{
          text: t('cancel'),
          onPress: onCancel,
        }}
        rightAction={{
          text: t('reject'),
          onPress: () => {
            onConfirm(invite);
          },
        }}
      >
        <H1>{title}</H1>
        <Spacer size="8" />
        <Body>{subtitle}</Body>
      </ConfirmBox>
    </ModalOverlay>
  );
};

export default CurrentBusiness(
  Authorized(
    withUserInvitesContextProvider(
      withUserNotificationsContextProvider(withProcessInviteContextProvider(NotificationsScreenComponent)),
    ),
  ),
);
