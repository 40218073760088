import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import {
  Body,
  colors,
  H2s,
  Icon,
  Row,
  SearchTextInput,
  SmallBody,
  Title,
  useShowError,
  styles as meroStyles,
  HSpacer,
} from '@mero/components';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import InfiniteScrollView from '../../../components/InfiniteScrollView';
import FormCard from '@mero/components/lib/components/FormCard';
import Column from '@mero/components/lib/components/Layout/Column';
import Line from '@mero/components/lib/components/Line';
import Spacer from '@mero/components/lib/components/Spacer';

import { NavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';

import { meroApi } from '../../../contexts/AuthContext';
import { CheckoutsContext, StateLoaded } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, CheckoutTabStackParamList } from '../../../types';
import { scaledToString } from '../../../utils/scaled';
import { nameGenerator } from '../../../utils/string';
import AddProceedButton from './AddProceedButton';
import AddProceedMenu from './AddProceedMenu';
import MembershipSearchFilter from './MembershipSearchFilter';

type Props = CurrentBusinessProps & Pick<StateLoaded, 'interval'>;

export type Filters =
  | Parameters<typeof meroApi.memberships.getPagePurchasedMemberships>[0]['query']['status']
  | 'HasDebt';

type Query = Parameters<typeof meroApi.memberships.getPagePurchasedMemberships>[0]['query'];

const MembershipsTabWeb: React.FC<Props> = ({ page, interval }) => {
  const { t } = useTranslation('checkout');
  const navigation = useNavigation<NavigationProp<CheckoutTabStackParamList & AuthorizedStackParamList>>();

  const showError = useShowError();
  const isFocused = useIsFocused();

  const [checkoutsState, { loadMoreFinished, updateSearch }] = CheckoutsContext.useContext();
  const [isLoading, setIsLoading] = React.useState(true);

  const [showAddProceedMenu, setShowAddProceedMenu] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filter, setFilter] = React.useState<Filters>();
  const [purchaseMemberships, setPurchaseMemberships] = React.useState<PurchasedMembership<MeroUnits.Any>[]>([]);
  const [totalPayments, setTotalPayments] = React.useState<Money<ScaledNumber, MeroUnits.Any>>();
  const [totalSellingPrice, setTotalSellingPrice] = React.useState<Money<ScaledNumber, MeroUnits.Any>>();
  const [nextPage, setNextPage] = React.useState<string>();
  const loadingNextPage = React.useRef('');

  const loadMemberships = async (query: Query, shouldAppend: boolean, next?: string) => {
    setIsLoading(true);
    try {
      loadingNextPage.current = next ?? '';
      const [{ data: memberships, next: nextPage }, { totalPayments, totalSellingPrice }] = await Promise.all([
        meroApi.memberships.getPagePurchasedMemberships({
          limit: 10,
          pageId: page.details._id,
          query,
          page: next,
        }),
        meroApi.memberships.getPagePurchasedMembershipsTotals({
          pageId: page.details._id,
          query,
        }),
      ]);

      setNextPage(nextPage);
      setTotalPayments(totalPayments);
      setTotalSellingPrice(totalSellingPrice);
      setPurchaseMemberships(shouldAppend ? [...purchaseMemberships, ...memberships] : memberships);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const initialRender = React.useRef(true);

  const loadMoreMemberships = () => {
    if (checkoutsState.type === 'Loaded' && nextPage && nextPage !== loadingNextPage.current) {
      loadMemberships(
        {
          purchasedAfter: checkoutsState.interval.from,
          purchasedBefore: checkoutsState.interval.to,
          search: searchQuery,
          ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
        },
        true,
        nextPage,
      );
    }
  };

  React.useEffect(() => {
    let timeout: number;
    if (checkoutsState.type === 'Loaded' && isFocused) {
      if (initialRender.current) {
        loadMemberships(
          {
            purchasedAfter: checkoutsState.interval.from,
            purchasedBefore: checkoutsState.interval.to,
            search: searchQuery,
            ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
          },
          false,
        );
        initialRender.current = false;
      } else {
        timeout = window.setTimeout(() => {
          loadMemberships(
            {
              purchasedAfter: checkoutsState.interval.from,
              purchasedBefore: checkoutsState.interval.to,
              search: searchQuery,
              ...(filter === 'HasDebt' ? { hasDebt: true } : { status: filter }),
            },
            false,
          );
        }, 500);
      }
    } else if (!isFocused) {
      initialRender.current = true;
    }

    return () => window.clearTimeout(timeout);
  }, [
    searchQuery,
    checkoutsState.type,
    checkoutsState.type === 'Loaded' ? checkoutsState.interval : undefined,
    page.details._id,
    filter,
    isFocused,
  ]);

  if (checkoutsState.type !== 'Loaded') {
    return null;
  }

  return (
    <>
      {/*{isLoading && <LoadingComponent />}*/}
      <InfiniteScrollView onEndReached={loadMoreMemberships} withLoading>
        <FormCard dropShaddow paddings="none" rounded style={{ marginHorizontal: 24 }}>
          <Row
            style={{ flex: 1, paddingLeft: 24, paddingRight: 16, paddingVertical: 16, zIndex: 1 }}
            alignItems="center"
          >
            <Column style={{ flex: 1 }}>
              <H2s>{t('memberships')}</H2s>
              {totalPayments && totalSellingPrice ? (
                <SmallBody style={{ fontSize: 12 }}>
                  {t('membershipCharged', {
                    value: scaledToString(totalPayments.amount),
                    total: scaledToString(totalSellingPrice.amount),
                    currency: t(totalPayments.unit),
                  })}
                </SmallBody>
              ) : null}
            </Column>
            <Column style={{ minWidth: 327 }}>
              <SearchTextInput
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder={t('searchCheckoutPlaceholder')}
              />
            </Column>
            <HSpacer left={8} />
            <MembershipSearchFilter activeFilter={filter} onChange={setFilter} />
          </Row>
          <Line />
          {/* Table Header */}
          <Row style={{ paddingHorizontal: 24, paddingTop: 16, paddingBottom: 12 }}>
            <Column style={{ width: '10%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('status')}</Title>
            </Column>
            <Column style={{ width: '25%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('membershipName')}</Title>
            </Column>
            <Column style={{ width: '25%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('client')}</Title>
            </Column>
            <Column style={{ width: '20%' }}>
              <Title style={{ fontSize: 12, paddingRight: 4 }}>{t('membershipAvailability')}</Title>
            </Column>
            <Column style={{ width: '20%' }} alignItems="flex-end">
              <Title style={{ fontSize: 12, textAlign: 'right', paddingRight: 40 }}>{t('membershipPaid')}</Title>
            </Column>
          </Row>
          <Line />
          {/* Table Body - Finished */}
          {purchaseMemberships.map((membership, index) => {
            const amountPaid = ScaledNumber.sub(membership.template.sellingPrice.amount, membership.debt.amount);
            const hasDebt = !ScaledNumber.equals(membership.debt.amount, ScaledNumber.zero());

            const { status, color } = (() => {
              if (!membership) {
                return { status: '', color: colors.COMET };
              }
              const status = PurchasedMembership.getMembershipStatus(membership);

              return {
                status: status,
                color:
                  status === 'Cancelled' || status === 'Expired'
                    ? colors.RADICAL_RED
                    : status === 'Active'
                    ? colors.SHAMROCK
                    : colors.COMET,
              };
            })();

            return (
              <React.Fragment key={membership._id}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 24,
                    alignItems: 'center',
                    paddingVertical: 16,
                  }}
                  onPress={() => {
                    navigation.navigate('CombineCheckout', {
                      screen: 'CheckoutMembershipStack',
                      params: {
                        screen: 'ClientMembershipDetailsScreen',
                        params: { membershipPurchaseId: membership._id, userId: membership.user._id },
                      },
                    });
                  }}
                >
                  <Column style={{ width: '10%' }}>
                    <SmallBody style={[{ color: color, fontSize: 12 }, meroStyles.text.semibold]}>
                      {t(status).toUpperCase()}
                    </SmallBody>
                  </Column>
                  <Column style={{ width: '25%' }}>
                    <SmallBody style={{ paddingRight: 4 }}>{membership.template.name}</SmallBody>
                  </Column>
                  <Column style={{ width: '25%' }}>
                    <SmallBody style={{ paddingRight: 4 }}>
                      {nameGenerator(membership.user.profile, t('unknownUser'))}
                    </SmallBody>
                  </Column>
                  <Column style={{ width: '20%' }}>
                    <SmallBody style={{ flex: 1 }}>
                      {DateTime.fromJSDate(membership.template.validFor.from).toFormat('dd MMM. yyyy').toLowerCase()} -{' '}
                      {membership.template.validFor.type === 'Unlimited'
                        ? t('unlimited')
                        : DateTime.fromJSDate(membership.template.validFor.to).toFormat('dd MMM. yyyy').toLowerCase()}
                    </SmallBody>
                  </Column>
                  <Row style={{ width: '20%', paddingRight: 4 }} alignItems="flex-end">
                    <Column style={{ flex: 1, paddingRight: 16 }} justifyContent="flex-end">
                      <SmallBody
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        {hasDebt && `${scaledToString(amountPaid)} / `}
                        {scaledToString(membership.template.sellingPrice.amount)}{' '}
                        {t(membership.template.sellingPrice.unit)}
                      </SmallBody>
                      {hasDebt && (
                        <SmallBody
                          style={{
                            textAlign: 'right',
                            fontSize: 12,
                            color: colors.OUTRAGEOUS_ORANGE,
                          }}
                        >
                          {t('membershipDebt', {
                            value: `${scaledToString(membership.debt.amount)} ${t(membership.debt.unit)}`,
                          })}
                        </SmallBody>
                      )}
                    </Column>
                    <Icon type="next" color={colors.DARK_BLUE} />
                  </Row>
                </TouchableOpacity>
                {index < checkoutsState.finished.data.length - 1 && <Line />}
              </React.Fragment>
            );
          })}
          {!isLoading && purchaseMemberships.length === 0 && (
            <>
              <Spacer size={24} />
              <Column
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 24,
                  paddingBottom: 32,
                }}
              >
                <H2s>{t('noMembershipSearch')}</H2s>
                <Spacer size={8} />
                <Body style={{ textAlign: 'center' }}>{t('noMembershipSearchDescription')}</Body>
              </Column>
            </>
          )}
        </FormCard>
        <Column style={{ zIndex: -1 }}>
          <Spacer size={64} />
        </Column>
      </InfiniteScrollView>
      <AddProceedButton onPress={() => setShowAddProceedMenu(true)} />
      {showAddProceedMenu && (
        <AddProceedMenu
          onDismiss={() => setShowAddProceedMenu(false)}
          onSelect={flow(
            (type) =>
              navigation.navigate('CombineCheckout', {
                screen: 'CheckoutStack',
                params: { screen: 'AddProceedScreen', params: { type } },
              }),
            () => setShowAddProceedMenu(false),
          )}
        />
      )}
    </>
  );
};

export default pipe(MembershipsTabWeb, CurrentBusiness);
