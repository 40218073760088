import { SubscriptionTier } from '@mero/api-sdk';
import { BottomNotification, Text } from '@mero/components';
import * as WebBrowser from 'expo-web-browser';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import CalendarFilterScreen from '../screens/Authorized/CalendarFilterScreen';
import SubscriptionExpiredScreen from '../screens/Authorized/SubscriptionExpiredScreen';

import AppReviewRequestScreen from '../components/AppReviewRequestScreen';
import ModalScreenContainer from '../components/ModalScreenContainer';

import { createDrawerNavigator, DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import config from '../config';
import { Authorized, AuthorizedProps } from '../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../contexts/CurrentBusiness';
import { AuthorizedStackParamList, HomeDrawerParamsList, RootStackParamList } from '../types';
import log from '../utils/log';
import BottomTabNavigator from './BottomTabNavigator';

const HomeTabs = CurrentBusiness(BottomTabNavigator, RedirectOnboarding);

type RedirectOnboardingProps = {
  navigation: CompositeNavigationProp<
    DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
    CompositeNavigationProp<
      StackNavigationProp<AuthorizedStackParamList, 'Home'>,
      StackNavigationProp<RootStackParamList>
    >
  >;
};

function RedirectOnboarding({ navigation }: RedirectOnboardingProps) {
  React.useEffect(() => {
    navigation.replace('Onboarding', { screen: 'OnboardingScreen' });
  });

  return (
    <ModalScreenContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Text>Redirecting ...</Text>
    </ModalScreenContainer>
  );
}

function NoAccessScreen({ navigation }: RedirectOnboardingProps) {
  React.useEffect(() => {
    navigation.replace('NoAccess');
  }, []);
  return (
    <ModalScreenContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Text>Redirecting ...</Text>
    </ModalScreenContainer>
  );
}

const SubscriptionExpiryNotification: React.FC<CurrentBusinessProps> = Authorized(
  ({ page }: CurrentBusinessProps & AuthorizedProps) => {
    const { t } = useTranslation('notifications');
    const insets = useSafeAreaInsets();
    const navigation = useNavigation<StackNavigationProp<AuthorizedStackParamList>>();

    const [, { saveSubscriptionExpiryNotificationSeen }] = CurrentBusinessContext.useContext();

    const now = DateTime.now().setZone('Europe/Bucharest');
    const expiresIn =
      page.subscription !== undefined
        ? Math.floor(DateTime.fromJSDate(page.subscription.expires).diffNow('days').days)
        : undefined;
    const isAutocharge = page.subscription?.autoCharge ?? false;
    const wasNotifiedToday =
      page.expiryNotifiedAt !== undefined && DateTime.fromJSDate(page.expiryNotifiedAt) > now.startOf('day');

    const notifyInDays = 2;
    const isVisible = expiresIn !== undefined && expiresIn >= 0 && expiresIn <= notifyInDays && !wasNotifiedToday;

    const dismiss = (): void => {
      saveSubscriptionExpiryNotificationSeen();
    };

    const leftAction = {
      text: t('notNow'),
      onPress: dismiss,
    };

    const rightAction = {
      text: t('checkPaymentDetails'),
      onPress: () => {
        dismiss();
        navigation.navigate('Menu', {
          screen: 'PageSubscriptionSettingsScreen',
        });
      },
    };

    return isVisible ? (
      <View style={{ position: 'absolute', left: 0, right: 0, bottom: 0, alignItems: 'center' }}>
        <BottomNotification
          type="info"
          text={
            page.subscription?.tier === SubscriptionTier.TRIAL
              ? expiresIn !== undefined && expiresIn > 0
                ? t('freeTrialDays', { days: expiresIn })
                : t('freeTrialToday')
              : isAutocharge
              ? t('subscriptionWillExpireWithAutocharge')
              : t('subscriptionWillExpireWithoutAutocharge')
          }
          style={{ paddingBottom: insets.bottom + 16, maxWidth: 500 }}
          leftAction={leftAction}
          rightAction={rightAction}
        />
      </View>
    ) : null;
  },
);

const TrialStartedNotification: React.FC = Authorized(() => {
  const { t } = useTranslation('notifications');
  const insets = useSafeAreaInsets();

  const [state, { saveTrialStartedNotificationSeen }] = CurrentBusinessContext.useContext();
  const isVisible = React.useMemo(() => {
    if (state.type === 'Loaded') {
      const page = state.page;
      const expiresIn =
        page.subscription !== undefined
          ? Math.floor(DateTime.fromJSDate(page.subscription.expires).diffNow('days').days)
          : undefined;
      const isTrialStarted =
        page.subscription?.tier === SubscriptionTier.TRIAL && (page.subscription?.trialStarted ?? false);
      // There may not be 14 full days left, 13 or 14 is ok
      const isFirstTrialDay = expiresIn === 14 || expiresIn === 13;
      const wasNotified = page.trialStartedNotifiedAt !== undefined;
      log.debug(
        `TrialStartedNotification: pageId=${page.details._id}, expiresIn=${expiresIn}, isTrialStarted=${isTrialStarted}, wasNotified=${wasNotified}`,
      );

      const isVisible = isFirstTrialDay && isTrialStarted && !wasNotified;

      return isVisible;
    }

    return false;
  }, [state]);

  const dismiss = (): void => {
    saveTrialStartedNotificationSeen();
  };

  const leftAction = {
    text: t('close'),
    onPress: dismiss,
    flex: 1,
  };

  const rightAction = {
    text: t('selectWhenWeShouldCall'),
    onPress: () => {
      dismiss();
      WebBrowser.openBrowserAsync(config.startTrialCallLink);
    },
    flex: 3,
  };

  return isVisible ? (
    <View style={{ position: 'absolute', left: 0, right: 0, bottom: 0, alignItems: 'center' }}>
      <BottomNotification
        type="info"
        text={t('trialPeriodExtended')}
        style={{ paddingBottom: insets.bottom + 16, maxWidth: 500 }}
        leftAction={leftAction}
        rightAction={rightAction}
      />
    </View>
  ) : null;
});

const Drawer = createDrawerNavigator<HomeDrawerParamsList>();

type Props = CurrentBusinessProps & {
  navigation: CompositeNavigationProp<
    DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
    CompositeNavigationProp<
      StackNavigationProp<AuthorizedStackParamList, 'Home'>,
      StackNavigationProp<RootStackParamList, 'Authorized'>
    >
  >;
};

/**
 * Account grace period in milliseconds.
 */
const GracePeriodMs = 0; // 3 * 24 * 3600 * 1000;

const HomeTabsNavigator: React.FC<Props> = ({ navigation, page }: Props): React.ReactElement => {
  if (
    page.subscription?.expires !== undefined &&
    page.subscription.validUntil.getTime() < new Date().getTime() - GracePeriodMs
  ) {
    return <SubscriptionExpiredScreen navigation={navigation} />;
  } else {
    return (
      <View style={{ flex: 1 }}>
        <AppReviewRequestScreen
          isPaid={!!page.subscription?.tier && page.subscription.tier !== SubscriptionTier.TRIAL}
        />
        <Drawer.Navigator
          initialRouteName="HomeTabs"
          screenOptions={{
            gestureHandlerProps: {
              enabled: Platform.OS !== 'web',
            },
            swipeEnabled: false,
            headerShown: false,
            drawerType: 'front',
            //@TODO On web drawer is created on the right, and this adds a page scroll
            drawerPosition: Platform.OS === 'web' ? 'left' : 'right',
            swipeEdgeWidth: Platform.OS === 'web' ? 0 : undefined,
            drawerStyle: {
              maxWidth: 300,
            },
          }}
          drawerContent={({ navigation: drawerNavigation }) => (
            <CalendarFilterScreen drawerNavigation={drawerNavigation} navigation={navigation} />
          )}
        >
          <Drawer.Screen name="HomeTabs" component={HomeTabs} />
        </Drawer.Navigator>
        <SubscriptionExpiryNotification page={page} />
        {/*<TrialStartedNotification />*/}
      </View>
    );
  }
};

export default CurrentBusiness(HomeTabsNavigator, RedirectOnboarding, NoAccessScreen);
