import { ClientId } from '@mero/api-sdk';
import { PurchasedMembership } from '@mero/api-sdk/dist/memberships/purchasedMembership';
import {
  colors,
  Column,
  FormCard,
  sizes,
  SmallBody,
  styles as meroStyles,
  Label,
  Icon,
  H2s,
  Spacer,
  Row,
} from '@mero/components';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Circle, Path } from 'react-native-svg';

import { NavigationProp, useNavigation } from '@react-navigation/native';

import { CurrentBusiness, CurrentBusinessProps } from '../../../../contexts/CurrentBusiness';
import { ClientStackParamList } from '../../../../types';
import { scaledToString } from '../../../../utils/scaled';

export const MembershipIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" transform="translate(0 .5)" />
      <G fill="none">
        <Path d="M5.5 6h21v21h-21Z" data-name="Path 8275" />
        <G
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          transform="translate(8.125 10.594)"
        >
          <Path d="M12.9 0v.258a1.29 1.29 0 1 1-2.58 0V0H5.159v.258a1.29 1.29 0 0 1-2.58 0V0H0v11.35h15.478V0ZM9.287 4.127h4.127M9.287 6.191h4.127M9.287 8.254h2.064" />
          <Circle cx={1.398} cy={1.398} r={1.398} data-name="layer1" transform="translate(3.265 3.355)" />
          <Path
            d="M4.643 6.191a2.605 2.605 0 0 0-2.578 2.657v.443h5.159v-.439a2.605 2.605 0 0 0-2.581-2.661Z"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

type Props = CurrentBusinessProps & {
  readonly clientId: ClientId;
  readonly item: PurchasedMembership<MeroUnits.Any>;
};

const ClientMembershipsListItemView: React.FC<Props> = ({ page, clientId, item }) => {
  const { t } = useTranslation('clients');
  const navigation = useNavigation<NavigationProp<ClientStackParamList>>();

  const { status, color } = React.useMemo(() => {
    if (!item) {
      return { status: '', color: colors.COMET };
    }
    const status = PurchasedMembership.getMembershipStatus(item);

    return {
      status: status,
      color:
        status === 'Cancelled' || status === 'Expired'
          ? colors.RADICAL_RED
          : status === 'Active'
          ? colors.SHAMROCK
          : colors.COMET,
    };
  }, [item]);
  const hasDebt = React.useMemo(() => ScaledNumber.toNumber(item.debt.amount) > 0, [item.debt]);

  return (
    <TouchableOpacity
      style={{ flexDirection: 'row', paddingHorizontal: 16 }}
      onPress={() =>
        navigation.navigate('MembershipDetailsScreen', {
          pageId: page.details._id,
          clientId,
          membershipPurchaseId: item._id,
        })
      }
    >
      <Column>
        <MembershipIcon />
      </Column>
      <Column style={{ flex: 1, paddingLeft: 16 }}>
        <Row>
          <Column style={{ flex: 1 }}>
            <Label style={[{ color }, meroStyles.text.semibold]}>{t(status)}</Label>
            <H2s>{item.template.name}</H2s>
            <Spacer size={6} />
            <SmallBody>
              {DateTime.fromJSDate(item.template.validFor.from).setLocale('ro').toFormat('dd MMM yyyy')} -{' '}
              {item.template.validFor.type === 'Unlimited'
                ? t('Unlimited')
                : DateTime.fromJSDate(item.template.validFor.to).setLocale('ro').toFormat('dd MMM yyyy')}
            </SmallBody>
          </Column>
          <Column>
            <Icon type="next" color={colors.DARK_BLUE} />
          </Column>
        </Row>
        {hasDebt && (
          <Row
            style={{
              backgroundColor: colors.OUTRAGEOUS_ORANGE,
              paddingHorizontal: 12,
              paddingVertical: 4,
              borderRadius: 4,
              marginTop: 16,
            }}
          >
            <Label style={[{ flex: 1, color: colors.WHITE }, meroStyles.text.semibold]}>{t('debtAmount')}</Label>
            <Label style={[{ color: colors.WHITE }, meroStyles.text.semibold]}>
              {scaledToString(item.debt.amount)} {t(item.debt.unit)}
            </Label>
          </Row>
        )}
      </Column>
    </TouchableOpacity>
  );
};

export default React.memo(pipe(ClientMembershipsListItemView, CurrentBusiness));
