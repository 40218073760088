import { MeroHeader, SafeAreaView } from '@mero/components';
import * as React from 'react';
import WebView from 'react-native-webview';

import { styles } from '../../../../../components/AddBookingScreen/styles';
import { WHITE } from '@mero/components/lib/styles/colors';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { AppStorage } from '../../../../../app-storage';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { GiftCardsStackParamList } from '../../../../../types';

export type Props = StackScreenProps<GiftCardsStackParamList, 'Stripe'>;

const CardPaymentScreen: React.FC<Props> = ({ navigation, route }) => {
  const { oauthUrl, title } = route.params;

  const [, { reload }] = CurrentBusinessContext.useContext();

  const { isPhone } = useMediaQueries();
  const goBack = useGoBack();

  const activationSuccess = async (url: string) => {
    const [, query] = url.split('?');
    const props = query.split('&').reduce((acc: Record<string, string>, curr) => {
      const [key, value] = curr.split('=');
      return { ...acc, [key]: value };
    }, {});

    if (props.code) {
      await AppStorage.setStripeCode(props.code);
    }

    reload();
    navigation.navigate('GiftCards');
  };

  return (
    <SafeAreaView style={[{ flex: 1, backgroundColor: WHITE }, !isPhone && styles.modalBorder]}>
      <MeroHeader title={title} canGoBack onBack={goBack} />
      <WebView
        style={{ flex: 1, opacity: 0.99, overflow: 'hidden' }}
        sharedCookiesEnabled={true}
        pullToRefreshEnabled={false}
        domStorageEnabled={true}
        containerStyle={{ flex: 1, backgroundColor: WHITE }}
        source={{ uri: oauthUrl }}
        onNavigationStateChange={(state) => {
          if (state.url.includes('mero.ro') && !state.url.includes('connect.stripe.com')) {
            activationSuccess(state.url);
          }
        }}
      />
    </SafeAreaView>
  );
};

export default CardPaymentScreen;
