import { PageMemberPreview, SavedWorker, UserId, WorkerId } from '@mero/api-sdk';
import {
  colors,
  Row,
  Icon,
  HSpacer,
  H1,
  Spacer,
  Body,
  styles as meroStyles,
  Column,
  Line,
  SmallBody,
  Title,
  FormCard,
  H3s,
  Avatar,
} from '@mero/components';
import { indexOf, sortBy, words } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, Platform, ScrollView } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useClickOutsideWeb } from '../../../../../hooks/useClickOutsideWeb';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { MenuItem } from '../..';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  ProsDashboardStackParamList,
  RootStackParamList,
} from '../../../../../types';
import { styles } from './ProsWebScreen.styles';

type Props = {
  readonly navigation: CompositeNavigationProp<
    StackNavigationProp<ProsDashboardStackParamList, 'ProsWeb'>,
    CompositeNavigationProp<
      BottomTabNavigationProp<HomeTabsParamsList, 'ProsTab'>,
      CompositeNavigationProp<
        DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
        CompositeNavigationProp<
          StackNavigationProp<AuthorizedStackParamList, 'Home'>,
          StackNavigationProp<RootStackParamList>
        >
      >
    >
  >;
};

const ProsWebScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('pros');
  const { isDesktop } = useMediaQueries();

  const [authState] = AuthContext.useContext();
  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [showAddMenu, setShowAddMenu] = React.useState(false);
  const addMenuRef = React.useRef<View>(null);
  const [selectedPageMember, setSelectedPageMember] = React.useState<
    | {
        worker: SavedWorker | undefined;
        member: PageMemberPreview;
      }
    | undefined
  >();

  const isOwnProfile = (userId: UserId) => {
    if (authState.type === 'Authorized') {
      return authState.user._id === userId;
    }

    return true;
  };

  useClickOutsideWeb({
    ref: addMenuRef,
    isVisible: showAddMenu,
    onClickOutside() {
      setShowAddMenu(false);
    },
    condition: isDesktop && Platform.OS === 'web',
  });

  type Members = {
    workers: {
      worker: SavedWorker;
      member: PageMemberPreview;
    }[];
    administrative: PageMemberPreview[];
  };
  const members: Members = React.useMemo(() => {
    if (pageState.type === 'Loaded') {
      const workersByUserId: Record<UserId, SavedWorker> = pageState.page.workers.reduce(
        (acc: Record<UserId, SavedWorker>, worker) => ({ ...acc, [worker.user._id]: worker }),
        {},
      );

      const workersIds = pageState.page.workers.map((worker) => worker._id);

      const workers = pageState.page.members
        .filter((member) => workersByUserId[member.user._id])
        .map((member) => ({
          worker: workersByUserId[member.user._id],
          member,
        }))
        .filter((member) => member.worker);

      const administrative = pageState.page.members.filter((member) => !workersByUserId[member.user._id]);

      return { workers: sortBy(workers, (item) => indexOf(workersIds, item.worker._id)), administrative };
    }

    return { workers: [], administrative: [] };
  }, [pageState]);

  React.useEffect(() => {
    if (selectedPageMember) {
      const administrativeMembers = members.administrative.map((member) => ({ member: member, worker: undefined }));
      const allPageMembers = [...members.workers, ...administrativeMembers];
      const foundMember = allPageMembers.find((w) => w.member.user._id === selectedPageMember?.member.user._id);
      if (foundMember) {
        setSelectedPageMember(foundMember);
      } else {
        setSelectedPageMember(members.workers[0]);
      }
    }
  }, [members]);

  React.useEffect(() => {
    if (members.workers.length > 0) {
      setSelectedPageMember(members.workers[0]);
    }
  }, []);

  const addProsCallback = React.useCallback(() => {
    navigation.navigate('ProsEdit', {
      screen: 'ProAddNewStack',
    });
    setShowAddMenu(false);
  }, [navigation]);

  const inviteProsCallback = React.useCallback(() => {
    navigation.navigate('ProsEdit', {
      screen: 'ProInviteStack',
    });
    setShowAddMenu(false);
  }, [navigation]);

  const navigateSortingCallback = React.useCallback(() => {
    navigation.navigate('ProsEdit', { screen: 'SortPros' });
  }, [navigation]);

  const navigateProProfileCallback = React.useCallback(
    (params: { workerId: WorkerId | undefined; userId: UserId }) => {
      navigation.navigate('ProsEdit', {
        screen: 'ProProfile',
        params: params,
      });
    },
    [navigation],
  );

  const navigateProCalendarCallback = React.useCallback(
    (params: { workerId: WorkerId | undefined; userId: UserId }) => {
      navigation.navigate('ProsEdit', {
        screen: 'ProCalendar',
        params: params,
      });
    },
    [navigation],
  );

  const navigateProScheduleCallback = React.useCallback(
    (workerId: WorkerId | undefined) => {
      workerId &&
        navigation.navigate('ProsEdit', {
          screen: 'ProSchedule',
          params: { workerId: workerId },
        });
    },
    [navigation],
  );

  const navigateProServicesCallback = React.useCallback(
    (workerId: WorkerId | undefined) => {
      workerId && navigation.navigate('ProsEdit', { screen: 'ProServices', params: { workerId: workerId } });
    },
    [navigation],
  );

  const navigateProPermissionsCallback = React.useCallback(
    (userId: UserId) => {
      navigation.navigate('ProsEdit', { screen: 'ProPermissions', params: { userId } });
    },
    [navigation],
  );

  const navigateMenu = React.useCallback(() => {
    navigation.navigate('MenuTab', { screen: 'MenuScreen' });
  }, [navigation]);

  const PageMemberCard: React.FC<{ member: PageMemberPreview; worker: SavedWorker | undefined }> = ({
    member,
    worker,
  }) => (
    <TouchableOpacity onPress={() => setSelectedPageMember({ member, worker })}>
      <Row
        style={[
          selectedPageMember?.member.user._id === member.user._id && {
            backgroundColor: colors.ATHENS_GRAY,
          },
          { paddingHorizontal: 16, paddingVertical: 12, justifyContent: 'space-between' },
        ]}
      >
        <Row>
          <Avatar
            size={32}
            firstname={member.user.profile.firstname ?? ''}
            lastname={member.user.profile.lastname ?? ''}
            source={member.user.profile.photo?.small}
          />
          <HSpacer left={12} />
          <Column style={{ maxWidth: 160 }}>
            <Body style={[meroStyles.text.semibold]}>
              {member.user.profile.firstname} {member.user.profile.lastname}
            </Body>
            <SmallBody style={{ fontSize: 12 }}>{member.roles.map((role) => role.name).join(', ')}</SmallBody>
            <SmallBody style={[{ fontSize: 12, marginTop: 4 }, meroStyles.text.hint]}>{member.user.phone}</SmallBody>
          </Column>
        </Row>

        {selectedPageMember?.member.user._id === member.user._id && <Icon type="arrow-right" size={24}></Icon>}
      </Row>
    </TouchableOpacity>
  );

  if (pageState.type !== 'Loaded' || authState.type !== 'Authorized') {
    return;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Row
        alignItems="center"
        justifyContent="space-between"
        style={[styles.headerWrapperWithShadow, { paddingHorizontal: 24, paddingVertical: 30 }]}
      >
        <Row>
          <TouchableOpacity onPress={navigateMenu}>
            <Icon type="back" disabled={true} size={32} color={colors.BLACK} />
          </TouchableOpacity>
          <HSpacer left={16} />
          <H1>{t('pros')}</H1>
        </Row>
      </Row>

      <View style={{ padding: 24 }}>
        <Row alignItems="center" justifyContent="space-between">
          <TouchableOpacity
            style={{
              height: 26,
              width: 132,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: colors.DARK_BLUE,
              borderRadius: 25,
            }}
          >
            <Body style={[{ color: colors.WHITE, fontSize: 13 }, meroStyles.text.semibold]}>{t('pros')}</Body>
          </TouchableOpacity>

          <Row>
            {members.workers.length > 1 && (
              <>
                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={navigateSortingCallback}>
                  <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('sort')}</Body>
                </TouchableOpacity>
                <HSpacer left={32} />
              </>
            )}
            <View ref={addMenuRef} style={{ flexDirection: 'column', display: 'flex' }}>
              <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => setShowAddMenu((prev) => !prev)}>
                <Body style={[meroStyles.text.semibold, { color: colors.DARK_BLUE }]}>{t('add')}</Body>
                <View
                  style={{
                    paddingLeft: 8,
                    justifyContent: 'center',
                  }}
                >
                  <Icon type="dropdown" color={colors.DARK_BLUE} />
                </View>
              </TouchableOpacity>

              <View style={{ position: 'relative' }}>
                {showAddMenu && (
                  <Column style={styles.webAddProsActions}>
                    <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={addProsCallback}>
                      <Title>{t('addPro')}</Title>
                    </TouchableOpacity>
                    <Line />
                    <TouchableOpacity style={styles.optionsMenuItem} delayPressIn={0} onPress={inviteProsCallback}>
                      <Title>{t('invitePro')}</Title>
                      <Spacer size={2} />
                      <SmallBody>{t('inviteProSubtitle')}</SmallBody>
                    </TouchableOpacity>
                  </Column>
                )}
              </View>
            </View>
          </Row>
        </Row>
      </View>

      <FormCard
        dropShaddow
        paddings="none"
        style={{ flex: 1, marginHorizontal: 24, marginBottom: 24, zIndex: -1 }}
        rounded
      >
        <Row style={{ flex: 1 }}>
          {/* Left Menu */}
          <ScrollView style={{ marginVertical: 24, maxWidth: 313 }}>
            <FormCard paddings="none" rounded dropShaddow style={{ marginHorizontal: 24 }}>
              <H3s style={{ flex: 1, padding: 16 }}>{t('acceptsAppointments')}</H3s>
              {members.workers.map(({ member, worker }, index) => (
                <View key={member.user._id}>
                  {index !== 0 && <Line />}
                  <PageMemberCard member={member} worker={worker} />
                </View>
              ))}

              {members.administrative.length > 0 && (
                <H3s style={{ flex: 1, padding: 16 }}>{t('doesntAcceptAppointments')}</H3s>
              )}
              {members.administrative.map((member, index) => (
                <View key={member.user._id}>
                  {index !== 0 && <Line />}
                  <PageMemberCard member={member} worker={undefined} />
                </View>
              ))}
            </FormCard>
          </ScrollView>

          {/* Member Right Details */}
          {selectedPageMember && (
            <Column style={{ flex: 1, paddingVertical: 24, paddingRight: 24 }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Column>
                  <H1 style={{ fontFamily: 'open-sans-bold', fontSize: 20 }}>
                    {selectedPageMember.member.user.profile.firstname} {selectedPageMember.member.user.profile.lastname}
                  </H1>
                  <SmallBody style={[meroStyles.text.hint, { fontSize: 12, marginTop: 4 }]}>
                    {selectedPageMember.member.user.phone}
                  </SmallBody>
                </Column>

                <Avatar
                  size={48}
                  firstname={selectedPageMember.member.user.profile.firstname ?? ''}
                  lastname={selectedPageMember.member.user.profile.lastname ?? ''}
                  source={selectedPageMember.member.user.profile.photo?.small}
                />
              </Row>

              <Spacer size={16} />
              <Column style={{ backgroundColor: colors.WHITE, flex: 1 }}>
                <MenuItem
                  title={t('profileDetails')}
                  icon="pro-menu-profile-details"
                  onPress={() =>
                    navigateProProfileCallback({
                      userId: selectedPageMember.member.user._id,
                      workerId: selectedPageMember.worker?._id,
                    })
                  }
                />
                <Line />
                <MenuItem
                  title={t('calendarSettings')}
                  subtitle={`${t(
                    selectedPageMember.worker
                      ? 'calendarSettingsDescriptionPositive'
                      : 'calendarSettingsDescriptionNegative',
                  )}${
                    selectedPageMember.worker?.calendar.settings.isPrivate
                      ? ` - ${t('calendarSettingsDescription2')}`
                      : ''
                  }`}
                  icon="pro-menu-calendar-settings"
                  onPress={() =>
                    navigateProCalendarCallback({
                      userId: selectedPageMember.member.user._id,
                      workerId: selectedPageMember.worker?._id,
                    })
                  }
                />
                <Line />
                {selectedPageMember.worker && (
                  <>
                    <MenuItem
                      title={t('schedule')}
                      icon="pro-menu-pending-clients"
                      onPress={() => navigateProScheduleCallback(selectedPageMember.worker?._id)}
                    />
                    <Line />
                    <MenuItem
                      title={t('servicesCustom')}
                      subtitle={t('activeServices', {
                        count: selectedPageMember.worker.services.filter((s) => s.pageId === pageState.page.details._id)
                          .length,
                      })}
                      icon="pro-menu-services"
                      onPress={() => navigateProServicesCallback(selectedPageMember.worker?._id)}
                    />
                    <Line />
                  </>
                )}
                <MenuItem
                  disabled={
                    !(
                      pageState.type === 'Loaded' &&
                      pageState.page.permissions.account.canManagePermissions() &&
                      !isOwnProfile(selectedPageMember.member.user._id)
                    )
                  }
                  title={t('permissions')}
                  subtitle={selectedPageMember.member.roles[0]?.name}
                  icon="pro-menu-permissions"
                  onPress={() => navigateProPermissionsCallback(selectedPageMember.member.user._id)}
                />
              </Column>
            </Column>
          )}
        </Row>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default ProsWebScreen;
