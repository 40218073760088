import * as React from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

import config from '../../config';
import log from '../../utils/log';
import { AuthContext } from '../AuthContext';
import { IntercomContext } from './context';
import { IntercomHandle, Registration } from './types';

const InitIntercom: React.FC<
  React.PropsWithChildren<{
    readonly unreadCount: number;
  }>
> = ({ unreadCount, children }) => {
  const { boot, update, show, hide } = useIntercom();

  const unreadCountRef = React.useRef(unreadCount);
  unreadCountRef.current = unreadCount;

  const intercomHandle = React.useMemo((): IntercomHandle => {
    return {
      init: async (registration: Registration | undefined): Promise<void> => {
        if (registration) {
          boot({
            userId: registration.userId,
            email: registration.email,
            hideDefaultLauncher: true,
          });
        } else {
          boot({
            hideDefaultLauncher: true,
          });
        }
      },
      updateRegistration: async (registration: Registration | undefined): Promise<void> => {
        if (registration) {
          update({
            userId: registration.userId,
            email: registration.email,
          });
        } else {
          update({
            userId: undefined,
            email: undefined,
          });
        }
      },
      getUnreadConversationCount: async (): Promise<number> => {
        return unreadCountRef.current;
      },
      showLauncher: () => {
        update({
          verticalPadding: 64,
          hideDefaultLauncher: false,
        });
      },
      hideLauncher: () => {
        update({
          hideDefaultLauncher: true,
        });
        hide();
      },
      showChat: show,
      hideChat: hide,
    };
  }, [boot, update, show, hide, unreadCountRef]);

  const [authState] = AuthContext.useContext();
  const [, { init, updateRegistration }] = IntercomContext.useContext();

  React.useEffect(() => {
    if (authState.type === 'Authorized') {
      // Init will only work first time
      init({
        registration: {
          userId: authState.user._id,
          email: authState.user.email,
        },
        handle: intercomHandle,
      });
      // Update will work on consequent calls
      updateRegistration({
        userId: authState.user._id,
        email: authState.user.email,
      });
    } else if (authState.type === 'Anonymous' || authState.type === 'Failed') {
      // Init will only work first time
      init({
        registration: undefined,
        handle: intercomHandle,
      });
      // Update will work on consequent calls
      updateRegistration(undefined);
    }
  }, [authState, intercomHandle]);

  return <>{children}</>;
};

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  log.debug('Init intercom for web!');
  const [unreadCount, setUnreadCount] = React.useState(0);

  return (
    <IntercomProvider appId={config.intercom.appId} onUnreadCountChange={setUnreadCount}>
      <InitIntercom unreadCount={unreadCount}>{children}</InitIntercom>
    </IntercomProvider>
  );
};

export default ContextInit;
