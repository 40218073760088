import { PositiveInt, StrictPhoneNumberParsed } from '@mero/api-sdk';
import {
  AvoidKeyboard,
  Body,
  Checkbox,
  colors,
  Column,
  DismissKeyboard,
  H3s,
  Icon,
  MeroHeader,
  Row,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Button,
  useToast,
  useShowError,
} from '@mero/components';
import { ScaledNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/function';
import { NumberFromString } from 'io-ts-types';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import SelectDateTimeModal from '../../../../../components/SelectDateTimeModal';
import FormCard from '@mero/components/lib/components/FormCard';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import H1 from '@mero/components/lib/components/Text/H1';
import TypeSafeTextInput from '@mero/components/lib/components/TypeSafeTextInput';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useInputState } from '../../../../../hooks/useInputState';

import { Authorized, AuthorizedProps, meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { MarketingContext } from '../../../../../contexts/MarketingContext';
import { MarketingSettingsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { scaledToString } from '../../../../../utils/scaled';
import { sequence } from '../../../../../utils/sequence';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<MarketingSettingsStackParamList, 'ConfigureBoost'>;

const PositiveString = sequence(NumberFromString, PositiveInt.JSON);

const DURATION = 14; // days
const DEFAULT_BUDGET = '100';

const ConfigureBoostScreen: React.FC<Props> = ({
  navigation,
  route,
  page,
  authorization,
}): React.ReactElement | null => {
  const { t } = useTranslation('marketing');
  const goBack = useGoBack();
  const toast = useToast();
  const showError = useShowError();

  const [{ pageBoostSettings }, { getPageBoostSettings }] = MarketingContext.useContext();
  const [minBudget, setMinBudget] = React.useState(DEFAULT_BUDGET);
  const timezone = React.useMemo(() => IANAZone.create('UTC'), []);

  const [showErrors, setShowErrors] = React.useState(false);
  const [showIntervalSelector, setShowIntervalSelector] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [budget, setBudget] = useInputState({
    input: '',
    decoded: PositiveString.decode(''),
  });

  const isValidBudget = budget.isValid && Number(budget.value) >= Number(minBudget);

  const [startDate, setStartDate] = React.useState<DateTime>(DateTime.now());
  const [endDate, setEndDate] = React.useState<DateTime>(startDate.plus({ days: DURATION }));

  const [acceptConditions, setAcceptConditions] = React.useState(false);

  const toggleAcceptConditions = React.useCallback(() => {
    setAcceptConditions((prev) => !prev);
  }, []);

  const save = async () => {
    setShowErrors(true);
    if (!isValidBudget) {
      return;
    }

    if (!acceptConditions) {
      toast.show({
        text: t('boostConditionError'),
        type: 'error',
      });
      return;
    }

    setIsLoading(true);
    try {
      await meroApi.boost.enableAutomaticBoost({
        pageId: page.details._id,
        budget: {
          amount: ScaledNumber.fromNumber(budget.value, 2),
          unit: 'RON',
        },
        interval: {
          from: startDate.toJSDate(),
        },
        acceptedTerms: {
          commissionPercent: pageBoostSettings.defaultValues.commissionPercent,
          minCommissionValue: pageBoostSettings.defaultValues.minCommissionValue,
        },
      });

      goBack();
    } catch (e) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const value = String(ScaledNumber.toNumber(pageBoostSettings.defaultValues.minBudget.amount));
    setMinBudget(value);
  }, [pageBoostSettings.defaultValues.minBudget]);

  React.useEffect(() => {
    getPageBoostSettings(page.details._id).catch((e) => {
      log.error('Failed to get page boost settings', e);
      showError(e);
    });
  }, []);

  return (
    <>
      <ModalScreenContainer edges={['left', 'top', 'right']}>
        <MeroHeader title={t('configureBoost')} canGoBack onBack={goBack} />
        <AvoidKeyboard style={{ flex: 1 }}>
          <DismissKeyboard style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1, paddingHorizontal: 16 }} showsVerticalScrollIndicator={false}>
              <Spacer size={16} />
              <H1 style={{ paddingHorizontal: 8 }}>{t('configureBoost')}</H1>
              <Spacer size={24} />
              <FormCard rounded dropShaddow paddings="none" style={{ padding: 16 }}>
                <H3s>{t('budget')}</H3s>
                <Spacer size={8} />
                <SmallBody>{t('budgetDescription')}</SmallBody>
                <Spacer size={24} />
                <InputWithLabel
                  label={t('budgetInputLabel')}
                  isError={showErrors && !isValidBudget}
                  errorText={t('budgetInputError', { value: minBudget, currency: t('RON') })}
                >
                  <TypeSafeTextInput
                    codec={PositiveString}
                    value={budget.input}
                    onChange={setBudget}
                    placeholder={t('budgetInputPlaceholder')}
                  />
                </InputWithLabel>
                <Spacer size={8} />
                <SmallBody style={{ color: colors.COMET, fontSize: 12 }}>{t('budgetWarning')}</SmallBody>
              </FormCard>
              <Spacer size={16} />
              <FormCard rounded dropShaddow paddings="none" style={{ padding: 16 }}>
                <H3s>{t('period')}</H3s>
                <Spacer size={8} />
                <SmallBody>
                  <Trans ns="marketing" t={t} i18nKey="periodDescription">
                    0<SmallBody style={meroStyles.text.semibold}>1</SmallBody>
                  </Trans>
                </SmallBody>
                <Spacer size={24} />
                <Row alignItems="center">
                  <Column style={{ flex: 1 }}>
                    <Body style={meroStyles.text.semibold}>{t('from')}</Body>
                    <Spacer size={8} />
                    <TouchableOpacity
                      style={{
                        paddingLeft: 14,
                        paddingRight: 12,
                        paddingVertical: 12,
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: colors.GEYSER,
                        borderRadius: 4,
                        alignItems: 'center',
                      }}
                      onPress={() => setShowIntervalSelector(true)}
                    >
                      <Body style={{ flex: 1 }}>{startDate.setLocale('ro').toFormat('dd MMM yyyy')}</Body>
                      <Icon type="dropdown" />
                    </TouchableOpacity>
                  </Column>
                  <Column style={{ paddingHorizontal: 8, paddingTop: 30 }}>
                    <Body>-</Body>
                  </Column>
                  <Column style={{ flex: 1 }}>
                    <Body style={meroStyles.text.semibold}>{t('to')}</Body>
                    <Spacer size={8} />
                    <TouchableOpacity
                      style={{
                        paddingLeft: 14,
                        paddingRight: 12,
                        paddingVertical: 12,
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: colors.GEYSER,
                        borderRadius: 4,
                        alignItems: 'center',
                        backgroundColor: colors.ATHENS_GRAY,
                      }}
                      disabled
                    >
                      <Body style={{ flex: 1 }}>{endDate.setLocale('ro').toFormat('dd MMM yyyy')}</Body>
                      <Icon type="dropdown" />
                    </TouchableOpacity>
                  </Column>
                </Row>
              </FormCard>
              <Spacer size={32} />
              <TouchableOpacity style={{ flexDirection: 'row' }} onPress={toggleAcceptConditions}>
                <Checkbox value={acceptConditions} onValueChange={(v) => setAcceptConditions(v)} />
                <Column style={{ paddingLeft: 12, flex: 1 }}>
                  <Body>
                    <Trans
                      ns="marketing"
                      t={t}
                      i18nKey="boostConditions"
                      values={{
                        percentage: pageBoostSettings.defaultValues.commissionPercent,
                        value: scaledToString(pageBoostSettings.defaultValues.minCommissionValue.amount),
                        currency: t(pageBoostSettings.defaultValues.minCommissionValue.unit),
                      }}
                    >
                      0<Body style={meroStyles.text.semibold}>1</Body>2
                    </Trans>
                  </Body>
                  <Spacer size={8} />
                  <SmallBody style={[{ fontSize: 12, textTransform: 'uppercase' }, meroStyles.text.semibold]}>
                    <Trans ns="marketing" t={t} i18nKey="boostConditions2">
                      0
                      <SmallBody
                        style={[
                          { fontSize: 12, textDecorationLine: 'underline', textTransform: 'uppercase' },
                          meroStyles.text.semibold,
                        ]}
                      >
                        1
                      </SmallBody>
                      2
                    </Trans>
                  </SmallBody>
                </Column>
              </TouchableOpacity>
              <Spacer size={32} />
              <Column style={{ paddingHorizontal: 8 }}>
                <Button text={t('enableBoost')} onClick={save} disabled={isLoading} />
              </Column>
              <Spacer size={96} />
            </ScrollView>
          </DismissKeyboard>
        </AvoidKeyboard>
      </ModalScreenContainer>
      {showIntervalSelector && (
        <Column style={{ flex: 1, position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
          <SelectDateTimeModal
            mode="date"
            selected={startDate.toJSDate()}
            onDateSelected={(date) => {
              setStartDate(DateTime.fromJSDate(date));
              setEndDate(DateTime.fromJSDate(date).plus({ days: DURATION }));
              setShowIntervalSelector(false);
            }}
            onClosePress={() => {
              setShowIntervalSelector(false);
            }}
            minDate={new Date()}
            timeZone={timezone}
          />
        </Column>
      )}
    </>
  );
};

export default pipe(ConfigureBoostScreen, CurrentBusiness, Authorized);
