import { ApiError, DefinedString, apiError, StrictPhoneNumber, RecurrenceRule } from '@mero/api-sdk';
import { AppointmentId, CalendarId } from '@mero/api-sdk/dist/calendar';
import { WaitingListId } from '@mero/api-sdk/dist/calendar/waiting-list-id';
import { ClientId } from '@mero/api-sdk/dist/clients';
import { Firstname, Lastname } from '@mero/api-sdk/dist/common';
import { PageId } from '@mero/api-sdk/dist/pages';
import { ServiceId } from '@mero/api-sdk/dist/services';
import { UserId } from '@mero/api-sdk/dist/users';
import { WorkerId } from '@mero/api-sdk/dist/workers';
import { createModelContext } from '@mero/components';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import * as t from 'io-ts';
import * as React from 'react';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';

type BookingCreateContextState =
  | {
      type: 'Ready';
    }
  | {
      type: 'Creating';
    }
  | {
      type: 'Created';
      calendarId: CalendarId;
      appointmentId: AppointmentId;
    }
  | {
      type: 'Failed';
      error?: ApiError<unknown>;
      isOverride: boolean;
    };

type NewBookingClient =
  | {
      readonly type: 'existing';
      readonly userId: UserId;
    }
  | {
      readonly type: 'new';
      readonly firstname: Firstname;
      readonly lastname?: Lastname;
      readonly phone: StrictPhoneNumber;
    };

const defaultState = (): BookingCreateContextState => ({ type: 'Ready' });

export const BookingCreateContext = createModelContext(
  defaultState(),
  {
    setCreating: () => ({
      type: 'Creating',
    }),
    setCreated: (_, payload: { calendarId: CalendarId; appointmentId: AppointmentId }) => ({
      type: 'Created',
      ...payload,
    }),
    setFailed: (_, payload: { error?: ApiError<unknown>; isOverride: boolean }) => ({
      type: 'Failed',
      ...payload,
    }),
    reset: () => ({
      type: 'Ready',
    }),
  },
  (dispatch) => {
    return {
      createBooking: (payload: {
        calendarId: CalendarId;
        pageId: PageId;
        start: Date;
        end: Date;
        workerId: WorkerId;
        bookedServiceIds: NonEmptyArray<ServiceId>;
        note?: DefinedString;
        client?: NewBookingClient;
        recurrenceRule?: RecurrenceRule.Any;
        override: boolean;
        waitingListId?: WaitingListId;
      }): void => {
        const create = async () => {
          try {
            dispatch.setCreating();
            let userId: UserId | undefined = undefined;
            let clientId: ClientId | undefined = undefined;

            if (payload.client?.type === 'existing') {
              userId = payload.client.userId;
            } else if (payload.client?.type === 'new') {
              const findOrCreateClient = async (client: {
                phone: StrictPhoneNumber;
                firstname: Firstname;
                lastname?: Lastname;
              }): Promise<ClientId> => {
                log.debug(`Find or create new client: ${JSON.stringify(client)}`);

                // Try find user by phone
                const clients = await meroApi.clients.search({ pageId: payload.pageId, search: client.phone });

                if (clients.length === 0) {
                  log.debug(`No users found with phone number ${client.phone}, goint to create new client`);
                  return await meroApi.clients.createClientByPhone({
                    pageId: payload.pageId,
                    phone: client.phone,
                    firstname: client.firstname,
                    lastname: client.lastname,
                  });
                } else {
                  log.debug(`There is a client with phone ${client.phone} (userId: ${userId})`);
                  return clients[0]._id;
                }
              };

              clientId = await findOrCreateClient({
                phone: payload.client.phone,
                firstname: payload.client.firstname,
                lastname: payload.client.lastname,
              });
            }

            const appointmentId = await meroApi.calendar.createOwnAppointment({
              calendarId: payload.calendarId,
              start: payload.start,
              end: payload.end,
              recurrenceRule: payload.recurrenceRule,
              pageId: payload.pageId,
              workerId: payload.workerId,
              userId: userId,
              clientId: clientId,
              bookedServiceIds: payload.bookedServiceIds,
              note: payload.note,
              override: payload.override,
            });
            dispatch.setCreated({
              calendarId: payload.calendarId,
              appointmentId: appointmentId,
            });
            log.debug(`New appointment with id ${appointmentId} successfully created`);
            if (payload.waitingListId) {
              await meroApi.calendar
                .deleteAppointmentWaitingList({
                  workerId: payload.workerId,
                  waitingListId: payload.waitingListId,
                })
                .catch(log.error);
            }
          } catch (e) {
            if (apiError(t.unknown).is(e)) {
              if (e.code === 14) {
                // booking override
                dispatch.setFailed({
                  error: e,
                  isOverride: true,
                });
              } else {
                log.exception(e);
                dispatch.setFailed({
                  error: e,
                  isOverride: false,
                });
              }
            } else {
              log.exception(e);
            }
          }
        };

        create();
      },
      reset: dispatch.reset,
    };
  },
);

export const withBookingCreateContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithBookingCreateContextProvider(props: P) {
    return (
      <BookingCreateContext.Provider>
        <Content {...props} />
      </BookingCreateContext.Provider>
    );
  };
};
