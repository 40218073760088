import { PageMemberPreview } from '@mero/api-sdk/dist/pages';
import { SavedWorker } from '@mero/api-sdk/dist/workers';
import {
  Avatar,
  colors,
  H1,
  HSpacer,
  Icon,
  Line,
  SmallBody,
  Spacer,
  styles as meroStyles,
  Title,
  sizes,
  Label,
  SafeAreaView,
  Row,
  Body,
} from '@mero/components';
import Constants from 'expo-constants';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, ScrollView, Platform, Linking, View } from 'react-native';
import Svg, { Circle, G, Path, SvgProps } from 'react-native-svg';

import { HelpCenterScreenUrl } from './screens/HelpCenterScreen';
import ShopIcon from './screens/Shop/ShopIcon';

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { AppStorage } from '../../../app-storage';
import { AppContext } from '../../../contexts/AppContext';
import { AuthContext, Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessContext, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { IntercomContext } from '../../../contexts/IntercomContext';
import { PushServerSubscriptionContext } from '../../../contexts/PushServerSubscriptionContext';
import { backNavigationContext } from '../../../models/back-navigation';
import {
  AuthorizedStackParamList,
  HomeDrawerParamsList,
  HomeTabsParamsList,
  MenuTabStackParamList,
  RootStackParamList,
} from '../../../types';
import log from '../../../utils/log';
import { nameGenerator } from '../../../utils/string';
import { styles } from './styles';

const CloseIcon = () => (
  <Svg data-name="Group 5822" width={24} height={24}>
    <Path fill="none" d="M0 0h24v24H0z" />
    <Path
      d="m6.376 16.68 4.62-4.619-4.62-4.62a.754.754 0 1 1 1.066-1.066l4.62 4.62 4.62-4.62a.754.754 0 0 1 1.065 1.066l-4.62 4.62 4.62 4.619a.754.754 0 0 1-1.066 1.066l-4.62-4.619-4.619 4.62a.754.754 0 1 1-1.065-1.066Z"
      fill="#fff"
    />
  </Svg>
);

const ProCheckoutMenuIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" data-name="Group 5932">
      <Path d="M0 0h32v32H0z" data-name="Rectangle 59" />
      <G data-name="Group 7613">
        <Path d="M4 4h24v24H4z" data-name="Rectangle 2767" />
        <G data-name="Group 7995">
          <G stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.5}>
            <Path d="M12.416 16.441h4.1m-4.1 3.189h3.075m3.076-3.194h1.024m-1.026 3.194h1.024M8.174 8v16.4l1.953-1.279 1.98 1.283 1.952-1.283 1.959 1.279 1.952-1.279 1.959 1.279 1.953-1.279L23.84 24.4V8m-3.911 16.4h0Zm-3.911 0h0ZM7 8h18" />
            <Path d="m13.732 12.676 1.634 1.634 2.909-2.906" data-name="layer1" />
          </G>
          <Path d="M6 6h20v20H6z" data-name="Rectangle 2701" />
        </G>
      </G>
    </G>
  </Svg>
);

const ProMembershipMenuIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G fill="none" transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} transform="translate(0 .5)" />
      <Path d="M4 4.5h24v24H4Z" data-name="Path 8275" />
      <G
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.7}
        transform="translate(6 9.167)"
      >
        <Path d="M16.667 0v.333a1.667 1.667 0 1 1-3.333 0V0H6.667v.333a1.667 1.667 0 0 1-3.333 0V0H0v14.667h20V0ZM12 5.333h5.333M12 8h5.333M12 10.666h2.667" />
        <Circle cx={1.807} cy={1.807} r={1.807} data-name="layer1" transform="translate(4.219 4.335)" />
        <Path d="M6 8a3.367 3.367 0 0 0-3.332 3.433V12h6.667v-.567A3.367 3.367 0 0 0 6 8Z" data-name="layer1" />
      </G>
    </G>
  </Svg>
);

const ProductsMenuIcon = (props: SvgProps) => (
  <Svg width={32} height={32} {...props}>
    <G transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} fill="none" transform="translate(0 .5)" />
      <G data-name="Group 7272">
        <Path fill="none" d="M0 .5h32v32H0z" data-name="Rectangle 59" />
        <G data-name="Group 5923">
          <Path fill="none" d="M4 4.5h24v24H4z" data-name="Rectangle 62" />
        </G>
        <G
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.7}
        >
          <Path d="m26.001 10.934-10-4.433-10 4.433v11.132l10 4.435 10-4.433Z" />
          <Path d="m6.001 10.934 10 4.433 10-4.433M11.467 8.501l10 4.433m-5.466 2.433v11.134" data-name="layer2" />
          <Path d="m23.333 16.367-4 1.766v3.766l4-1.766Z" />
        </G>
      </G>
    </G>
  </Svg>
);

type MenuScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<MenuTabStackParamList, 'MenuScreen'>,
  CompositeNavigationProp<
    BottomTabNavigationProp<HomeTabsParamsList, 'MenuTab'>,
    CompositeNavigationProp<
      DrawerNavigationProp<HomeDrawerParamsList, 'HomeTabs'>,
      CompositeNavigationProp<
        StackNavigationProp<AuthorizedStackParamList, 'Home'>,
        StackNavigationProp<RootStackParamList, 'Authorized'>
      >
    >
  >
>;

type Props = {
  navigation: MenuScreenNavigationProp;
} & CurrentBusinessProps &
  AuthorizedProps;

const MenuScreenComponent: React.FC<Props> = ({ navigation, page, authorization }: Props) => {
  const { t } = useTranslation('settings');
  const [, { signOut }] = AuthContext.useContext();
  const [intercomState, { openChat }] = IntercomContext.useContext();
  const [pageState] = CurrentBusinessContext.useContext();
  const unreadIntercomCount = intercomState.type === 'Ready' ? intercomState.unreadConversationCount : 0;
  const [, { tryUnsubscribe: tryUnsubscribePush }] = PushServerSubscriptionContext.useContext();
  const [appState] = AppContext.useContext();
  const { isPhone, isDesktop } = useMediaQueries();

  const [isPendingDelete, setIsPendingDelete] = React.useState(false);
  const isFocused = useIsFocused();

  React.useEffect(() => {
    if (isFocused) {
      AppStorage.getPagesToDelete()
        .then((pages) => {
          setIsPendingDelete(pages.includes(page.details._id));
        })
        .catch((e) => {
          log.error('Failed to get pages pending deletion', e);
        });
    }
  }, [isFocused]);

  const user = authorization.user;

  const selfWorker: SavedWorker | undefined = React.useMemo(
    () => page.workers.find((w) => w.user._id === user._id),
    [user._id, page.workers],
  );

  const selfMember: PageMemberPreview | undefined = React.useMemo(
    () => page.members.find((w) => w.user._id === user._id),
    [user._id, page.workers],
  );

  const profilePhoto = selfMember?.user.profile.photo; // DO NOT fallback to user profile photo as it may confuse PROs (?)

  const selfWorkerId = selfWorker?._id;
  const selfUserId = selfWorker?.user._id;

  // User is an worker can manage only own worker profile
  const isWorker = !page.permissions.proProfiles.canManageAllProfiles() && selfWorker !== undefined;

  const onSignOut = React.useCallback(() => {
    const doit = async (): Promise<void> => {
      try {
        log.debug('Going to remove user notification device...');
        await tryUnsubscribePush();
      } catch (e) {
        log.error('Failed to unregister notification device', e);
      }
      try {
        log.debug('Going to signOut');
        await signOut();
      } catch (e) {
        log.error('Failed to sign out', e);
      }
    };

    doit();
  }, [signOut]);

  const clearPendingDelete = React.useCallback(() => {
    AppStorage.removePageToDelete(page.details._id)
      .then(() => {
        setIsPendingDelete(false);
      })
      .catch((e) => {
        log.error('Failed to remove page from pending deletion', e);
      });
  }, [pageState]);

  const navigateMarketingSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'MarketingSettingsScreen',
    });
  }, [navigation]);

  const navigatePageProfileSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'LocationProfileScreen',
    });
  }, [navigation]);

  const navigateWorkerProfileSettings = React.useCallback(() => {
    if (selfWorkerId && selfUserId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerProfileSettingsScreen',
          params: {
            workerId: selfWorkerId,
            userId: selfUserId,
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigateWorkerNotificationsSettings = React.useCallback(() => {
    if (selfWorkerId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerNotificationsSettingsScreen',
          params: {
            workerId: selfWorkerId,
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigateWorkerServicesSettings = React.useCallback(() => {
    if (selfWorkerId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerServicesSettingsScreen',
          params: {
            screen: 'WorkerServices',
            params: {
              workerId: selfWorkerId,
            },
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigateWorkerScheduleSettings = React.useCallback(() => {
    if (selfWorkerId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerScheduleSettingsScreen',
          params: {
            workerId: selfWorkerId,
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigateWorkerCalendarSettings = React.useCallback(() => {
    if (selfWorkerId && selfUserId) {
      navigation.navigate('Authorized', {
        screen: 'Menu',
        params: {
          screen: 'WorkerCalendarSettingsScreen',
          params: {
            workerId: selfWorkerId,
            userId: selfUserId,
          },
        },
      });
    }
  }, [navigation, selfWorkerId]);

  const navigatePageServicesSettings = React.useCallback(() => {
    if (isDesktop) {
      navigation.navigate('ServicesTab', {
        screen: 'ServicesWeb',
      });
    } else {
      navigation.navigate('ServicesTab', {
        screen: 'ServicesMobile',
      });
    }
  }, [navigation]);

  const navigateProsDashboard = React.useCallback(() => {
    if (isDesktop) {
      navigation.navigate('ProsTab', { screen: 'ProsWeb' });
    } else {
      navigation.navigate('ProsTab', { screen: 'ProsMobile' });
    }
  }, [navigation]);

  const navigatePageSubscriptionSettings = React.useCallback(() => {
    // if (Platform.OS === 'web') {
    //   // payu form submit doesn't work inside IOS iframe
    //   // so we need to open this route in a separate tab in web build
    //   // https://github.com/react-native-webview/react-native-webview/issues/666
    //   // https://github.com/react-native-webview/react-native-webview/issues/957
    //   // https://github.com/react-native-webview/react-native-webview/pull/2258
    //   Linking.openURL(
    //     `${config.proUrl}/drawer/page/subscription?pageId=${page.details._id}&hideLayout=true&nvb=${authorization.grant.accessToken}`,
    //   );
    // } else {
    navigation.navigate('Menu', {
      screen: 'PageSubscriptionSettingsScreen',
    });
    // }
  }, [navigation, page, authorization]);

  const navigatePageCheckoutSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageCheckoutSettingsScreen',
    });
  }, [navigation]);

  const navigatePageMembershipSettingsScreen = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageMembershipSettingsScreen',
    });
  }, [navigation]);

  const navigatePageReports = React.useCallback(() => {
    if (isPhone) {
      navigation.navigate('Menu', {
        screen: 'PageReportsMobileScreen',
      });
    } else {
      navigation.navigate('PageReportsScreen');
    }
  }, [navigation]);

  const navigatePagePendingClients = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PagePendingClientsScreen',
    });
  }, [navigation]);

  const navigatePageReviews = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageReviewsScreen',
      params: {
        screen: 'Reviews',
      },
    });
  }, [navigation]);

  const navigatePageOnlinePaymentsSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageOnlinePaymentsSettingsScreen',
    });
  }, [navigation]);

  const navigatePageGiftcardsSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageGiftCardsSettingsScreen',
    });
  }, [navigation]);

  const navigatePageShop = React.useCallback(() => {
    navigation.navigate('Menu', { screen: 'ShopScreen' });
  }, [navigation]);

  const navigatePageNotificationsSettings = React.useCallback(() => {
    navigation.navigate('Menu', {
      screen: 'PageNotificationsSettingsScreen',
    });
  }, [navigation]);

  const navigateSelectPage = React.useCallback(() => {
    navigation.navigate('Authorized', {
      screen: 'SelectAccount',
    });
  }, [navigation]);

  const navigateProductDashboard = React.useCallback(() => {
    navigation.navigate('ProductsTab', {
      screen: 'ProductsDashboardScreen',
    });
  }, [navigation]);

  const navigateProductMenu = React.useCallback(() => {
    navigation.navigate('ProductsTab', {
      screen: 'ProductsMenuScreen',
    });
  }, [navigation]);

  const navigateHelpCenter = React.useCallback(() => {
    if (Platform.OS === 'web') {
      Linking.openURL(HelpCenterScreenUrl).catch(log.exception);
    } else {
      navigation.navigate('Menu', {
        screen: 'HelpCenterScreen',
      });
    }
  }, [navigation]);

  const navigateContactSupport = React.useCallback(() => {
    openChat();
  }, [openChat]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: colors.ALABASTER }} edges={['top']}>
      <ScrollView style={styles.container}>
        <View style={styles.profileContainer}>
          <View style={styles.avatarContainer}>
            <Avatar
              source={profilePhoto?.medium}
              firstname={selfMember?.user.profile.firstname ?? ''}
              lastname={selfMember?.user.profile.lastname ?? ''}
              size={119}
            />
            <View style={styles.pagePhotoContainer}>
              <Avatar source={page.details.profilePhoto.medium} firstname={page.details.name} lastname="" size={46} />
            </View>
          </View>
          <Spacer size="16" />
          <H1 style={meroStyles.text.bold} numberOfLines={1}>
            {nameGenerator(selfMember?.user.profile ?? {}, 'Fără nume')}
          </H1>
          <Spacer size="8" />
          <TouchableOpacity onPress={navigateSelectPage} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Title style={{ color: '#32325D' }} numberOfLines={1}>
              {page.details.name}
            </Title>
            <HSpacer left={8} />
            <Icon type="dropdown" backgroundColor="blue" />
          </TouchableOpacity>
        </View>
        {isPendingDelete && (
          <>
            <Row style={{ padding: 8, backgroundColor: '#F05F7C', marginHorizontal: 16, borderRadius: 4 }}>
              <Body style={{ color: colors.WHITE, flex: 1 }}>{t('pendingDeletion')}</Body>
              <TouchableOpacity style={{ paddingLeft: 4 }} onPress={clearPendingDelete}>
                <CloseIcon />
              </TouchableOpacity>
            </Row>
            <Spacer size={16} />
          </>
        )}
        <View style={styles.hrPadding}>
          {page.permissions.page.canManageDetails() ? (
            <>
              <MenuItem title={t('pageDetails')} icon="pro-menu-page-details" onPress={navigatePageProfileSettings} />
              <Line />
            </>
          ) : null}

          {isWorker && page.permissions.proProfiles.canEditOwnProfileDetails() ? (
            <>
              <MenuItem
                title={t('profileDetails')}
                icon="pro-menu-profile-details"
                onPress={navigateWorkerProfileSettings}
              />
              <Line />
            </>
          ) : null}

          {page.permissions.page.canManageServices() ? (
            <>
              <MenuItem title={t('services')} icon="pro-menu-services" onPress={navigatePageServicesSettings} />
              <Line />
            </>
          ) : null}

          {isWorker && page.permissions.proProfiles.canEditOwnProfile() ? (
            <>
              <MenuItem
                title={t('calendarSettings')}
                icon="pro-menu-calendar-settings"
                onPress={navigateWorkerCalendarSettings}
              />
              <Line />
            </>
          ) : null}

          {isWorker && page.permissions.proProfiles.canEditOwnProfile() ? (
            <>
              <MenuItem
                title={t('schedule')}
                icon="pro-menu-pending-clients"
                onPress={navigateWorkerScheduleSettings}
              />
              <Line />
            </>
          ) : null}

          {isWorker && page.permissions.proProfiles.canEditOwnProfile() ? (
            <>
              <MenuItem
                title={t('personalServices')}
                icon="pro-menu-services"
                onPress={navigateWorkerServicesSettings}
              />
              <Line />
            </>
          ) : null}

          {page.permissions.proProfiles.canManageAllProfiles() ? (
            <>
              <MenuItem title={t('pros')} icon="pro-menu-team" onPress={navigateProsDashboard} />
              <Line />
            </>
          ) : null}

          {page.permissions.reviews.canReadOwnReviews() || page.permissions.reviews.canReadAllReviews() ? (
            <>
              <MenuItem title={t('reviews')} icon="pro-menu-reviews" onPress={navigatePageReviews} />
              <Line />
            </>
          ) : null}

          {page.permissions.page.canManageNotificationSettings() ? (
            <>
              <MenuItem
                title={t('notificationsSettings')}
                icon="pro-menu-client-notifications"
                onPress={navigatePageNotificationsSettings}
              />
              <Line />
            </>
          ) : null}

          {page.permissions.clients.canWriteOwnInvites() ? (
            <>
              <MenuItem
                title={t('pendingClients')}
                icon="pro-menu-pending-clients"
                onPress={navigatePagePendingClients}
              />
              <Line />
            </>
          ) : null}

          {(isWorker && page.permissions.statistics.canReadOwnStatistics()) ||
          page.permissions.statistics.canReadAllStatistics() ? (
            <>
              <MenuItem title={t('reports')} icon="pro-menu-reports" onPress={navigatePageReports} />
              <Line />
            </>
          ) : null}
        </View>

        <Spacer size="48" color={colors.ALABASTER} />

        <View style={styles.hrPadding}>
          {page.permissions.page.canManageOnlinePayments() ? (
            <>
              <MenuItem
                title={t('onlinePayments')}
                icon="pro-menu-payments"
                onPress={navigatePageOnlinePaymentsSettings}
              />
              <Line />
            </>
          ) : null}

          {page.details.published && page.permissions.marketing.canManageAll() ? (
            <>
              <MenuItem title={t('marketing')} icon="pro-menu-marketing" onPress={navigateMarketingSettings} />
              <Line />
            </>
          ) : null}

          {page.permissions.page.canManageCheckout() && (
            <>
              <MenuItem
                title={t('checkout')}
                icon={<ProCheckoutMenuIcon />}
                onPress={navigatePageCheckoutSettings}
                titleLabel="NOU"
              />
              <Line />
            </>
          )}

          {page.permissions.page.canManageMemberships() && (
            <>
              <MenuItem
                title={t('memberships')}
                icon={<ProMembershipMenuIcon />}
                onPress={navigatePageMembershipSettingsScreen}
                titleLabel="NOU"
              />
              <Line />
            </>
          )}

          {page.permissions.products.canManageAll() ? (
            <>
              <MenuItem
                title={t('products:productsAndInventory')}
                icon={<ProductsMenuIcon />}
                onPress={isPhone ? navigateProductMenu : navigateProductDashboard}
              />
              <Line />
            </>
          ) : null}

          {page.permissions.page.canManageGiftcards() ? (
            <>
              <MenuItem title={t('giftCards')} icon="pro-menu-giftcards" onPress={navigatePageGiftcardsSettings} />
              <Line />
            </>
          ) : null}

          {appState.type === 'Loaded' && appState.featureFlags.barberStore?.enabled ? (
            <>
              <MenuItem title="Cumpără produse" icon={<ShopIcon color={colors.BLACK} />} onPress={navigatePageShop} />
              <Line />
            </>
          ) : null}
        </View>

        <Spacer size="48" color={colors.ALABASTER} />

        <View style={styles.hrPadding}>
          {page.permissions.account.canManageSubscription() && !(Platform.OS === 'ios' && Constants.isDevice) ? (
            <>
              <MenuItem title={t('subscriptions')} onPress={navigatePageSubscriptionSettings} />
              <Line />
            </>
          ) : null}
          <MenuItem title="Centru de ajutor" onPress={navigateHelpCenter} />
          <Line />
          <MenuItem
            title={`Contact MERO Asistență`}
            badge={unreadIntercomCount > 0 ? `${unreadIntercomCount}` : undefined}
            onPress={navigateContactSupport}
          />
          <Line />
          <MenuItem title="Ieși din cont" onPress={onSignOut} />
          <Line />
        </View>
        <Spacer size="48" color={colors.ALABASTER} />
      </ScrollView>
    </SafeAreaView>
  );
};

type MenuIcon =
  | 'pro-menu-marketing'
  | 'pro-menu-payments'
  | 'pro-menu-places'
  | 'pro-menu-reports'
  | 'pro-menu-reviews'
  | 'pro-menu-services'
  | 'pro-menu-subscription'
  | 'pro-menu-team'
  | 'pro-menu-page-details'
  | 'pro-menu-profile-details'
  | 'pro-menu-photo-gallery'
  | 'pro-menu-permissions'
  | 'pro-menu-client-notifications'
  | 'pro-menu-pending-clients'
  | 'pro-menu-calendar-settings'
  | 'pro-menu-giftcards';

export type MenuItemProps = {
  readonly icon?: MenuIcon | React.ReactElement;
  readonly title: string;
  readonly titleLabel?: string;
  readonly subtitle?: string;
  readonly subtitleColor?: string;
  readonly badge?: string;
  readonly onPress?: () => void;
  readonly disabled?: boolean;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  title,
  titleLabel,
  subtitle,
  subtitleColor,
  icon,
  badge,
  onPress,
  disabled,
}: MenuItemProps) => {
  const getIcon = React.useCallback(() => {
    if (!icon) {
      return null;
    }

    if (typeof icon === 'string') {
      return <Icon type={icon} disabled />;
    }

    return icon;
  }, [icon]);

  const MenuIcon = getIcon();

  return (
    <TouchableOpacity style={styles.menuItem} onPress={onPress} disabled={disabled}>
      {icon !== undefined ? (
        <>
          {MenuIcon}
          <HSpacer left={16} />
        </>
      ) : undefined}
      <View style={styles.menuTextContainer}>
        <View style={styles.menuTitleContainer}>
          <Title style={styles.menuTitle}>{title}</Title>
          {titleLabel ? (
            <View style={styles.menuTitleLabelContainer}>
              <Label style={styles.menuTitleLabel}>{titleLabel}</Label>
            </View>
          ) : null}
        </View>
        {subtitle ? (
          <View>
            <SmallBody style={{ color: subtitleColor }}>{subtitle}</SmallBody>
          </View>
        ) : null}
      </View>
      {badge !== undefined ? (
        <View style={styles.menuItemBadge}>
          <SmallBody style={styles.menuItemBadgeText}>{badge}</SmallBody>
        </View>
      ) : null}
      {!disabled && <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />}
    </TouchableOpacity>
  );
};

export const MenuScreenBackNavigationContext = backNavigationContext();

export default pipe(MenuScreenComponent, Authorized, CurrentBusiness);
