import { DefinedTrimedString, Lastname, PageBillingDetails, PageBillingDetailsId } from '@mero/api-sdk';
import { CityName, DistrictName } from '@mero/api-sdk/dist/business';
import { PageId } from '@mero/api-sdk/dist/pages';
import { Button, colors, FormCard, Select, Spacer, TextInput, useShowError, useToast } from '@mero/components';
import { Email, Firstname, StrictPhoneNumber } from '@mero/shared-sdk';
import { flow } from 'fp-ts/function';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';

import KeyboardAvoidingView from '../../../../../components/KeyboardAvoidingView';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../../../hooks/useMediaQueries';
import { useSafeInput } from '../../../../../hooks/useSafeInput';

import { meroApi } from '../../../../../contexts/AuthContext';
import log from '../../../../../utils/log';
import { styles } from './CompanyBillingDetails.style';
import DeleteBillingDetailsDialog from './DeleteBillingDetailsDialog';

const INPUT_POSITIONS = {
  firstname: 250,
  lastname: 300,
  phone: 350,
  email: 450,
  address: 550,
} as const;

type Props = {
  pageId: PageId;
  billingDetailsId?: PageBillingDetailsId;
  details?: PageBillingDetails.Person;
  onSave: () => void;
  onDelete: () => void;
  canDelete: boolean;
};
const PersonComponent: React.FC<React.PropsWithChildren<Props>> = ({
  pageId,
  billingDetailsId,
  details,
  onSave,
  onDelete,
  canDelete,
  children,
}) => {
  const { t } = useTranslation('subscription');
  const { isPhone } = useMediaQueries();

  const showError = useShowError();
  const toast = useToast();

  const block = React.useRef(false);

  const [showErrors, setShowErrors] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const [firstname, setFirstname] = useSafeInput(NonEmptyString)(details?.firstname);
  const [lastname, setLastname] = useSafeInput(NonEmptyString)(details?.lastname);
  const [phone, setPhone] = useSafeInput(StrictPhoneNumber)(details?.phone);
  const [email, setEmail] = useSafeInput(Email.JSON)(details?.email);

  const [address, setAddress] = useSafeInput(NonEmptyString)(details?.address.address);

  const [district, setDistrict] = React.useState<NonEmptyString | undefined>(details?.address.county);
  const [city, setCity] = React.useState<NonEmptyString | undefined>(details?.address.city);

  const [districts, setDistricts] = React.useState<{ label: DistrictName; value: DistrictName }[]>([]);
  const [cities, setCities] = React.useState<{ label: CityName; value: CityName }[]>([]);

  const getInitialData = React.useCallback(async () => {
    const districts = await meroApi.business.getDistricts();
    setDistricts(districts.map((d) => ({ label: d, value: d })));
  }, []);

  const getCities = async (county: string) => {
    const cities = await meroApi.business.getCities({ district: county });
    setCities(cities.map((c) => ({ label: c, value: c })));
    if (cities.length === 1) {
      setCity(cities[0] as DefinedTrimedString);
    }
  };

  React.useEffect(() => {
    if (district) {
      getCities(district).catch((error) => log.error('Failed to get cities', error));
    }
  }, [district]);

  React.useEffect(() => {
    getInitialData().catch((error) => showError(error, t('failedToLoadDistricts')));
  }, []);

  const scrollTo = (inputName: keyof typeof INPUT_POSITIONS): void => {
    setScrollToY(INPUT_POSITIONS[inputName]);
  };

  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const save = async () => {
    if (
      !address.isValid ||
      !city ||
      !district ||
      !firstname.isValid ||
      !lastname.isValid ||
      !phone.isValid ||
      !email.isValid
    ) {
      setShowErrors(true);
      return;
    }
    try {
      setIsLoading(true);

      const billing = {
        type: 'Person',
        address: {
          address: address.value as DefinedTrimedString,
          city: city as DefinedTrimedString,
          county: district as DefinedTrimedString,
        },
        firstname: firstname.value as Firstname,
        lastname: lastname.value as Lastname,
        phone: phone.value,
        email: email.value,
      } as const;

      if (billingDetailsId) {
        await meroApi.pro.pageBillingDetails.updatePageBillingDetails({
          pageId,
          billingDetailsId,
          billing,
        });
        toast.show({
          type: 'success',
          text: t('billingDetailsUpdated'),
        });
      } else {
        await meroApi.pro.pageBillingDetails.addPageBillingDetails({
          pageId,
          billing,
        });
        toast.show({
          type: 'success',
          text: t('billingDetailsSaved'),
        });
      }

      onSave();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <KeyboardAvoidingView style={{ flex: 1 }}>
        <ScrollView>
          {children}
          <FormCard paddings={'none'} style={{ padding: 16, marginHorizontal: 16 }} dropShaddow rounded>
            <InputWithLabel
              label={t('firstname')}
              isError={showErrors && !firstname.isValid}
              errorText={t('firstnameError')}
            >
              <TextInput
                value={firstname.value}
                onChange={setFirstname}
                placeholder={t('firstnamePlaceholder')}
                onFocus={() => scrollTo('firstname')}
                isError={showErrors && !firstname.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel
              label={t('lastname')}
              isError={showErrors && !lastname.isValid}
              errorText={t('lastnameError')}
            >
              <TextInput
                value={lastname.value}
                onChange={setLastname}
                placeholder={t('lastnamePlaceholder')}
                onFocus={() => scrollTo('lastname')}
                isError={showErrors && !lastname.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('phone')} isError={showErrors && !phone.isValid} errorText={t('phoneError')}>
              <TextInput
                value={phone.value}
                onChange={setPhone}
                placeholder={t('phonePlaceholder')}
                onFocus={() => scrollTo('phone')}
                isError={showErrors && !phone.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('email')} isError={showErrors && !email.isValid} errorText={t('emailError')}>
              <TextInput
                value={email.value}
                onChange={setEmail}
                placeholder={t('emailPlaceholder')}
                onFocus={() => scrollTo('email')}
                isError={showErrors && !email.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('address')} isError={showErrors && !address.isValid} errorText={t('addressError')}>
              <TextInput
                value={address.value}
                onChange={setAddress}
                placeholder={t('addressPlaceholder')}
                onFocus={() => scrollTo('address')}
                isError={showErrors && !address.isValid}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('county')} isError={showErrors && !district} errorText={t('countyError')}>
              <Select
                placeholder={t('countyPlaceholder')}
                items={districts}
                value={district}
                onChange={(value) =>
                  setDistrict((prev) => {
                    if (prev !== value) {
                      setCity(undefined);
                    }

                    return value as DefinedTrimedString;
                  })
                }
                isError={showErrors && !district}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('city')} isError={showErrors && !city} errorText={t('cityError')}>
              <Select
                placeholder={district ? t('cityPlaceholder2') : t('cityPlaceholder1')}
                items={cities}
                value={city}
                onChange={(city) => setCity(city as DefinedTrimedString)}
                isError={showErrors && !city}
                editable={cities.length > 0 && district !== undefined}
              />
            </InputWithLabel>
            <Spacer size="16" />
          </FormCard>
          <Spacer size={16} />
          <Spacer size={32} />
          {canDelete ? (
            <Button
              backgroundColor={colors.ALABASTER}
              disabledBackgroundColor={colors.ALABASTER}
              color={colors.RADICAL_RED}
              text={t('deleteCompany')}
              onPress={() => setShowDeleteDialog(true)}
              disabled={isLoading}
            />
          ) : null}
          <Spacer size={117} />
        </ScrollView>
      </KeyboardAvoidingView>
      <FormCard
        dropShaddow
        paddings="button"
        style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }, !isPhone && styles.modalBorderBottom]}
      >
        <SafeAreaView edges={['bottom']}>
          <Button text={details ? t('saveChanges') : t('savePerson')} onPress={save} disabled={isLoading} />
        </SafeAreaView>
      </FormCard>
      {showDeleteDialog && details && billingDetailsId ? (
        <DeleteBillingDetailsDialog
          billingDetailsId={billingDetailsId}
          type={details.type}
          onSuccess={flow(onCloseDeleteDialog, onDelete)}
          onCancel={onCloseDeleteDialog}
        />
      ) : null}
    </>
  );
};

export default PersonComponent;
