import {
  Body,
  Button,
  Checkbox,
  colors,
  Column,
  FormCard,
  H1,
  H3s,
  HSpacer,
  Label,
  Line,
  Row,
  SmallBody,
  Spacer,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import * as Clipboard from 'expo-clipboard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform, ScrollView, Share, TouchableOpacity } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { AppStorage } from '../../../../../app-storage';
import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';
import ConfirmUnlistingDialog from './ConfirmUnlistingDialog';
import DeleteLocationDialog from './DeleteLocationDialog';
import EnableOnlineProfile from './EnableOnlineProfile';

const SHARE_QUOTE = `Niciodată nu a fost mai simplu să îţi faci o programare!
Intră acum pe profilul meu de MERO și convinge-te!`;
const SHARE_HASHTAG = '#MERO';

enum Status {
  INCOMPLETE,
  INACTIVE,
  PENDING_PUBLISH,
  PUBLISHED,
  PENDING_UNPUBLISH,
  PENDING_LISTING,
  LISTED,
  // PENDING_UNLISTING,
  ACTIVE,
}

const STATUS_LABELS = {
  [Status.INCOMPLETE]: 'incomplete',
  [Status.INACTIVE]: 'inactive',
  [Status.PENDING_PUBLISH]: 'inactive',
  [Status.PUBLISHED]: 'private',
  [Status.PENDING_UNPUBLISH]: 'private',
  [Status.PENDING_LISTING]: 'private',
  [Status.LISTED]: 'public',
  // [Status.PENDING_UNLISTING]: 'public',
  [Status.ACTIVE]: 'public',
} as const;

const STATUS_BG_COLOR = {
  [Status.INCOMPLETE]: '#A8AABE',
  [Status.INACTIVE]: '#A8AABE',
  [Status.PENDING_PUBLISH]: '#A8AABE',
  [Status.PUBLISHED]: '#FFEFEB',
  [Status.PENDING_UNPUBLISH]: '#FFEFEB',
  [Status.PENDING_LISTING]: '#FFEFEB',
  [Status.LISTED]: '#E9FAF3',
  // [Status.PENDING_UNLISTING]: '#E9FAF3',
  [Status.ACTIVE]: '#E9FAF3',
} as const;

const STATUS_COLOR = {
  [Status.INCOMPLETE]: '#A8AABE',
  [Status.INACTIVE]: '#A8AABE',
  [Status.PENDING_PUBLISH]: '#A8AABE',
  [Status.PUBLISHED]: '#FF6B6B',
  [Status.PENDING_UNPUBLISH]: '#FB6340',
  [Status.PENDING_LISTING]: '#FB6340',
  [Status.LISTED]: '#2DCE89',
  // [Status.PENDING_UNLISTING]: '#2DCE89',
  [Status.ACTIVE]: '#2DCE89',
} as const;

const STATUS_BUTTON = {
  [Status.INCOMPLETE]: true,
  [Status.INACTIVE]: false,
  [Status.PENDING_PUBLISH]: false,
  [Status.PUBLISHED]: false,
  [Status.PENDING_UNPUBLISH]: false,
  [Status.PENDING_LISTING]: true,
  [Status.LISTED]: false,
  // [Status.PENDING_UNLISTING]: true,
  [Status.ACTIVE]: false,
};

const ClipboardIcon = () => (
  <Svg width={32} height={32}>
    <G data-name="Group 5926">
      <Path data-name="Rectangle 59" fill="none" d="M0 0h32v32H0z" />
      <Path data-name="Path 8335" d="M7 7h18v18H7Z" fill="none" />
      <Path
        data-name="Path 8336"
        d="M19 7.75h-9a1.5 1.5 0 0 0-1.5 1.5v10.5H10V9.25h9Zm2.25 3H13a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h8.25a1.5 1.5 0 0 0 1.5-1.5v-10.5a1.5 1.5 0 0 0-1.5-1.5Zm0 12H13v-10.5h8.25Z"
        fill="#080de0"
      />
    </G>
  </Svg>
);

const OnlineLink: React.FC = () => {
  const { t } = useTranslation('location');

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [isCompleted, setIsCompleted] = React.useState(false);

  const [isPublishing, setIsPublishing] = React.useState(false);
  const [showConfirmUnlistingDialog, setShowConfirmUnlistingDialog] = React.useState(false);

  const showError = useShowError();
  const toast = useToast();

  const copyToClipboard = (value: string) => async () => {
    if (value) {
      await Clipboard.setStringAsync(value);
    }
  };

  const share = (value: string) => async () => {
    if (Platform.OS === 'web' && !navigator.share) {
      const pageUrl = encodeURIComponent(value);
      const shareQuote = encodeURIComponent(SHARE_QUOTE);
      const shareTag = encodeURIComponent(SHARE_HASHTAG);
      const shareUrl = `https://www.facebook.com/dialog/share?app_id=${config.facebookAppId}&display=popup&href=${pageUrl}&quote=${shareQuote}&hashtag=${shareTag}`;
      window.open(shareUrl, '', 'height=300,width=600');
    } else {
      try {
        await Share.share({
          message: value,
        });
      } catch {}
    }
  };

  const open = (value: string) => async () => {
    try {
      await Linking.openURL(value);
    } catch {}
  };

  if (pageState.type !== 'Loaded') {
    return null;
  }

  const getStatus = () => {
    if (pageState.page.details.isSearchable) {
      return Status.LISTED;
    }

    if (!isCompleted) {
      return Status.INCOMPLETE;
    }

    if (!pageState.page.details.published) {
      return Status.INACTIVE;
    }

    if (pageState.page.details.searchableRequested) {
      return Status.PENDING_LISTING;
    }

    if (pageState.page.details.published) {
      return Status.PUBLISHED;
    }

    return Status.INCOMPLETE;
  };

  const toggleListingDialog = async () => {
    setShowConfirmUnlistingDialog(!showConfirmUnlistingDialog);
  };

  const toggleListing = async () => {
    if (pageState.page.details.isSearchable) {
      return toggleListingDialog();
    }
    try {
      await meroApi.pages.requestPageSearchable({ pageId: pageState.page.details._id });

      await reloadAsync();
      toast.show({
        type: 'success',
        text: t('listingToggleSuccess'),
      });
    } catch (err) {
      showError(err);
    }
  };

  const publishPage = async () => {
    setIsPublishing(true);
    try {
      await meroApi.pages.publishPage({ pageId: pageState.page.details._id });
      AppStorage.deletePublishBannerHideState(pageState.page.details._id).catch((error) =>
        log.error('Error deleting publish banner hide state', error),
      );
      await reloadAsync();
    } catch (err) {
      showError(err);
    } finally {
      setIsPublishing(false);
    }
  };

  const status = getStatus();
  const pageUrl = `${config.marketPlaceUrl}/p/${pageState.page.details.slug}`;

  return (
    <>
      <ScrollView style={{ paddingTop: 16, flex: 1 }}>
        <H1 style={{ paddingHorizontal: 16 }}>{t('onlineLink')}</H1>
        {status <= Status.INACTIVE && <EnableOnlineProfile page={pageState.page} onChange={setIsCompleted} />}
        {status < Status.PUBLISHED && status > Status.INCOMPLETE ? (
          <>
            <Spacer size={24} />
            <FormCard rounded paddings="none" dropShaddow style={{ marginHorizontal: 16, padding: 16 }}>
              <Title>{t('linkTitle')}</Title>
              <Spacer size={4} />
              <SmallBody>{t('linkPreDescription')}</SmallBody>
              <Spacer size={16} />
              <Button text={t('createLink')} onClick={publishPage} disabled={isPublishing} />
            </FormCard>
          </>
        ) : null}
        {status >= Status.PUBLISHED ? (
          <>
            <Spacer size={32} />
            <FormCard rounded paddings="none" dropShaddow style={{ marginHorizontal: 16, padding: 16 }}>
              <Row>
                <Column
                  style={{
                    borderRadius: 4,
                    backgroundColor: STATUS_BG_COLOR[status],
                    paddingVertical: 4,
                    paddingHorizontal: 8,
                    flexGrow: 0,
                  }}
                >
                  <Label style={{ color: STATUS_COLOR[status], fontFamily: 'open-sans-semibold' }}>
                    {t(STATUS_LABELS[status])}
                  </Label>
                </Column>
                <Column style={{ flex: 1 }} />
              </Row>
              <Spacer size={16} />
              <Title>{t('linkTitle')}</Title>
              <Spacer size={4} />
              <SmallBody style={{ color: '#525F7F' }}>{t('linkDescription')}</SmallBody>
              <Spacer size={12} />
              <TouchableOpacity
                style={{ alignItems: 'center', flexDirection: 'row' }}
                onPress={copyToClipboard(pageUrl)}
              >
                <Body style={{ flex: 1 }}>{pageUrl.replace(/^http(s)?\:\/\//, '')}</Body>
                <ClipboardIcon />
              </TouchableOpacity>
              <Spacer size={16} />
              <Row>
                <Column style={{ flex: 2, paddingRight: 6 }}>
                  <Button text={t('share')} size="small" onClick={share(pageUrl)} />
                </Column>
                <Column style={{ flex: 2, paddingLeft: 6 }}>
                  <Button
                    text={t('viewProfile')}
                    backgroundColor="#F2F2FE"
                    color={colors.DARK_BLUE}
                    size="small"
                    onClick={open(pageUrl)}
                  />
                </Column>
              </Row>
            </FormCard>
            <Spacer size={24} />

            <FormCard rounded paddings="none" dropShaddow style={{ marginHorizontal: 16 }}>
              <Row style={{ padding: 16, alignItems: 'center' }}>
                <H3s>{t('publishOnlineProfile')}</H3s>
              </Row>
              <Line />
              {status === Status.PENDING_LISTING && (
                <>
                  <Spacer size={16} />
                  <Column
                    style={{
                      paddingHorizontal: 12,
                      paddingVertical: 8,
                      backgroundColor: '#2DCE89',
                      borderRadius: 4,
                      marginHorizontal: 16,
                      flex: 1,
                    }}
                  >
                    <Body style={{ color: colors.WHITE }}>
                      {status === Status.PENDING_LISTING ? t('pendingListing') : t('pendingUnlisting')}
                    </Body>
                  </Column>
                </>
              )}
              <TouchableOpacity
                style={{ padding: 16, flexDirection: 'row' }}
                disabled={STATUS_BUTTON[status]}
                onPress={toggleListing}
              >
                <Checkbox
                  value={Boolean(pageState.page.details.isSearchable || pageState.page.details.searchableRequested)}
                  disabled={STATUS_BUTTON[status]}
                  onValueChange={toggleListing}
                />
                <HSpacer left={12} />
                <Column style={{ flex: 1 }}>
                  <Title>{t('enableTitle')}</Title>
                  <Spacer size={6} />
                  <SmallBody style={{ color: '#525F7F' }}>{t('enableDescription')}</SmallBody>
                </Column>
              </TouchableOpacity>
            </FormCard>
          </>
        ) : null}
        <Spacer size={48} />
      </ScrollView>
      {showConfirmUnlistingDialog && (
        <ConfirmUnlistingDialog onSuccess={toggleListingDialog} onCancel={toggleListingDialog} />
      )}
    </>
  );
};

export default OnlineLink;
