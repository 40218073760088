import { Email, PhoneNumber } from '@mero/api-sdk';
import { BillingInfo } from '@mero/api-sdk/dist/payments/billing-info';
import {
  AvoidKeyboard,
  Button,
  colors,
  Column,
  FormCard,
  H1,
  InputWithLabel,
  MeroHeader,
  SafeAreaView,
  Select,
  Spacer,
  TypeSafeTextInput,
  useShowError,
} from '@mero/components';
import * as E from 'fp-ts/Either';
import { NonEmptyString } from 'io-ts-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import { useKeyboardIsOpen } from '@mero/components/lib/hooks';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import countiesAndCities from '../../../../../constants/county-cities.json';

import config from '../../../../../config';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext';
import { PageOnlinePaymentsSettingsStackParamList } from '../../../../../types';
import log from '../../../../../utils/log';
import { strictLength } from '../PageSubscriptionSettingsScreen/BillingDetails';
import { styles } from '../PageSubscriptionSettingsScreen/BillingDetails.styles';

export type Props = StackScreenProps<PageOnlinePaymentsSettingsStackParamList, 'PageOnlinePaymentsBillingDetails'>;

const PageOnlinePaymentsBillingDetailsScreen: React.FC<Props> = ({ navigation, route }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('onlinePayments');
  const isKeyboardOpen = useKeyboardIsOpen();
  const { isPhone } = useMediaQueries();
  const showError = useShowError();

  const [pageState] = CurrentBusinessContext.useContext();
  const [{ subscriptionInfo }, { reload }] = SubscriptionContext.useContext();
  const { mode } = route.params;

  const billingDetails = subscriptionInfo?.billing;

  const block = React.useRef(false);

  const scrollRef = React.useRef<ScrollView>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const [scrollToY, setScrollToY] = React.useState<number | undefined>(undefined);

  const [companyName, setCompanyName] = React.useState({
    input: billingDetails?.company?.name ?? '',
    decoded: NonEmptyString.decode(billingDetails?.company?.name ?? ''),
  });
  const companyNameValid = E.isRight(companyName.decoded);

  const [fiscalCode, setFiscalCode] = React.useState({
    input: billingDetails?.company?.fiscalCode ?? '',
    decoded: NonEmptyString.decode(billingDetails?.company?.fiscalCode ?? ''),
  });
  const fiscalCodeValid = E.isRight(fiscalCode.decoded);

  const [registrationNumber, setRegistrationNumber] = React.useState({
    input: billingDetails?.company?.registrationNumber ?? '',
    decoded: NonEmptyString.decode(billingDetails?.company?.registrationNumber ?? ''),
  });
  const registrationNumberValid = E.isRight(registrationNumber.decoded);

  const [iban, setIban] = React.useState({
    input: billingDetails?.company?.iban ?? '',
    decoded: NonEmptyString.decode(billingDetails?.company?.iban ?? ''),
  });
  const ibanValid = E.isRight(iban.decoded);

  const [firstname, setFirstname] = React.useState({
    input: billingDetails?.firstname ?? '',
    decoded: strictLength(3).decode(billingDetails?.firstname ?? ''),
  });
  const firstnameValid = E.isRight(firstname.decoded);

  const [lastname, setLastname] = React.useState({
    input: billingDetails?.lastname ?? '',
    decoded: strictLength(3).decode(billingDetails?.lastname ?? ''),
  });
  const lastnameValid = E.isRight(lastname.decoded);

  const [phone, setPhone] = React.useState({
    input: billingDetails?.phone ?? '',
    decoded: PhoneNumber.decode(billingDetails?.phone ?? ''),
  });
  const phoneValid = E.isRight(phone.decoded);

  const [email, setEmail] = React.useState({
    input: billingDetails?.email ?? '',
    decoded: Email.JSON.decode(billingDetails?.email ?? ''),
  });
  const emailValid = E.isRight(email.decoded);

  const [address, setAddress] = React.useState({
    input: billingDetails?.address ?? '',
    decoded: NonEmptyString.decode(billingDetails?.address ?? ''),
  });
  const addressValid = E.isRight(address.decoded);

  const [county, setCounty] = React.useState(billingDetails?.county ?? '');
  const [city, setCity] = React.useState(billingDetails?.city ?? '');

  const scrollTo = (y: number): void => {
    setScrollToY(y);
  };

  const saveChanges = async () => {
    if (subscriptionInfo && pageState.type === 'Loaded') {
      const payload = BillingInfo.decode({
        company: {
          name: companyName.input,
          fiscalCode: fiscalCode.input,
          registrationNumber: registrationNumber.input,
          iban: iban.input,
        },
        firstname: firstname.input,
        lastname: lastname.input,
        phone: phone.input,
        email: email.input,
        address: address.input,
        county,
        city,
      });

      if (E.isRight(payload) && firstnameValid && lastnameValid) {
        setIsLoading(true);
        try {
          await meroApi.payments.setSubscriptionBilling(subscriptionInfo._id, payload.right);

          reload({ pageId: subscriptionInfo.pageId });

          if (mode === 'create') {
            const redirectUri =
              Platform.OS === 'web'
                ? `${window.location.origin}/page/current/online-payments`
                : config.stripeRedirectUri.onlinePayments;

            const oauth = await meroApi.payments.getStripeConnectOauthLink({
              pageId: pageState.page.details._id,
              redirectUri,
            });

            if (Platform.OS === 'web') {
              window.location.href = oauth.result;
            } else {
              navigation.navigate('PageOnlinePaymentsStripe', { oauthUrl: oauth.result, title: t('stripe') });
            }
          } else {
            goBack();
          }
        } catch (error) {
          showError(error, t('saveBillingDetailsError'));
          setShowErrors(true);
        } finally {
          setIsLoading(false);
        }
      } else {
        setShowErrors(true);
      }
    }
  };

  React.useEffect(() => {
    try {
      if (isKeyboardOpen && scrollToY !== undefined) {
        setScrollToY(undefined);
        scrollRef?.current?.scrollTo({ y: scrollToY, animated: true });
      }
    } catch (e: unknown) {
      log.exception(e);
    }
  }, [scrollToY, isKeyboardOpen, setScrollToY, scrollRef, scrollRef?.current]);

  const counties = React.useMemo(
    () => countiesAndCities.map(({ name }) => ({ label: name, value: name })),
    [countiesAndCities],
  );

  const cities = React.useMemo(
    () =>
      (countiesAndCities.find(({ name }) => name === county)?.cities ?? []).map((city) => ({
        label: city,
        value: city,
      })),
    [county, countiesAndCities],
  );

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={t('billingInfo')} />
      <H1 style={{ paddingHorizontal: 24, paddingTop: 16 }}>{t('billingInfo')}</H1>
      <AvoidKeyboard style={{ flex: 1 }}>
        <ScrollView style={{ marginTop: 32, borderRadius: 6 }} ref={scrollRef}>
          <Column
            style={{
              marginHorizontal: 16,
              paddingHorizontal: 16,
              paddingTop: 32,
              paddingBottom: 134,
              backgroundColor: colors.WHITE,
              borderRadius: 6,
            }}
          >
            <InputWithLabel
              label={t('companyName')}
              isError={showErrors && !companyNameValid}
              errorText={t('companyNameError')}
            >
              <TypeSafeTextInput
                codec={NonEmptyString}
                value={companyName.input}
                showError={showErrors}
                onChange={setCompanyName}
                placeholder={t('companyNamePlaceholder')}
                onFocus={() => scrollTo(50)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('cui')} isError={showErrors && !fiscalCodeValid} errorText={t('cuiError')}>
              <TypeSafeTextInput
                codec={NonEmptyString}
                value={fiscalCode.input}
                showError={showErrors}
                onChange={setFiscalCode}
                placeholder={t('cuiPlaceholder')}
                onFocus={() => scrollTo(150)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel
              label={t('registrationNumber')}
              isError={showErrors && !registrationNumberValid}
              errorText={t('registrationNumberError')}
            >
              <TypeSafeTextInput
                codec={NonEmptyString}
                value={registrationNumber.input}
                showError={showErrors}
                onChange={setRegistrationNumber}
                placeholder={t('registrationNumberPlaceholder')}
                onFocus={() => scrollTo(150)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('iban')} isError={showErrors && !ibanValid} errorText={t('ibanError')}>
              <TypeSafeTextInput
                codec={NonEmptyString}
                value={iban.input}
                showError={showErrors}
                onChange={setIban}
                placeholder={t('ibanPlaceholder')}
                onFocus={() => scrollTo(250)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel
              label={t('firstName')}
              isError={showErrors && !firstnameValid}
              errorText={t('firstNameError')}
            >
              <TypeSafeTextInput
                codec={strictLength(3)}
                value={firstname.input}
                showError={showErrors}
                onChange={setFirstname}
                placeholder={t('firstNamePlaceholder')}
                onFocus={() => scrollTo(350)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('lastName')} isError={showErrors && !lastnameValid} errorText={t('lastNameError')}>
              <TypeSafeTextInput
                codec={strictLength(3)}
                value={lastname.input}
                showError={showErrors}
                onChange={setLastname}
                placeholder={t('lastNamePlaceholder')}
                onFocus={() => scrollTo(450)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('phone')} isError={showErrors && !phoneValid} errorText={t('phoneError')}>
              <TypeSafeTextInput
                codec={PhoneNumber}
                value={phone.input}
                showError={showErrors}
                onChange={setPhone}
                placeholder={t('phonePlaceholder')}
                onFocus={() => scrollTo(450)}
                keyboardType={'phone-pad'}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('email')} isError={showErrors && !emailValid} errorText={t('emailError')}>
              <TypeSafeTextInput
                codec={Email.JSON}
                value={email.input}
                showError={showErrors}
                onChange={setEmail}
                placeholder={t('emailPlaceholder')}
                onFocus={() => scrollTo(550)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('address')} isError={showErrors && !addressValid} errorText={t('addressError')}>
              <TypeSafeTextInput
                codec={NonEmptyString}
                value={address.input}
                showError={showErrors}
                onChange={setAddress}
                placeholder={t('addressPlaceholder')}
                onFocus={() => scrollTo(350)}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('county')} isError={showErrors && !county} errorText={t('countyError')}>
              <Select
                placeholder={t('countyPlaceholder')}
                items={counties}
                value={county}
                onChange={setCounty}
                isError={showErrors && !county}
              />
            </InputWithLabel>
            <Spacer size="16" />
            <InputWithLabel label={t('city')} isError={showErrors && !city} errorText={t('cityError')}>
              <Select
                placeholder={county ? t('cityPlaceholder2') : t('cityPlaceholder1')}
                items={cities}
                value={city}
                onChange={setCity}
                isError={showErrors && !city}
              />
            </InputWithLabel>
          </Column>
        </ScrollView>
      </AvoidKeyboard>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? (
            <Button
              disabled={block.current || isLoading}
              text={mode === 'create' ? t('continue') : t('saveChanges')}
              onClick={saveChanges}
            />
          ) : (
            <Button
              disabled={block.current || isLoading}
              expand={false}
              containerStyle={{ alignSelf: 'center' }}
              text={mode === 'create' ? t('continue') : t('saveChanges')}
              onClick={saveChanges}
            />
          )}
        </SafeAreaView>
      </FormCard>
    </ModalScreenContainer>
  );
};

export default PageOnlinePaymentsBillingDetailsScreen;
