import { CalendarEntry, CalendarEntryDetails, DefinedTrimedString, OccurrenceIndex, optionull } from '@mero/api-sdk';
import { AppointmentId, CalendarId } from '@mero/api-sdk/dist/calendar';
import { ClientPreview } from '@mero/api-sdk/dist/clients';
import { Body, ConfirmBox, H1, ModalOverlay, Spacer, useShowError, useToast } from '@mero/components';
import * as Ap from 'fp-ts/lib/Apply';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as O from 'fp-ts/lib/Option';
import { identity, pipe } from 'fp-ts/lib/function';
import { DateFromISOString, NumberFromString } from 'io-ts-types';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Dimensions, Linking, Platform, View } from 'react-native';

import AddBookingScreenView from '../../../components/AddBookingScreen';
import { BookedServiceWithWorkerItem } from '../../../components/BookedServiceWithWorkerListItem';

import { CompositeNavigationProp, RouteProp } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';

import { AppEventsContext } from '../../../contexts/AppEvents';
import { meroApi } from '../../../contexts/AuthContext';
import { addDefaultEventDuration, BookingFormContext, ValidBookedService } from '../../../contexts/BookingFormContext';
import { BookingUpdateContext, withBookingUpdateContextProvider } from '../../../contexts/BookingUpdateContext';
import { CalendarContext } from '../../../contexts/CalendarContext';
import {
  CalendarEntryContext,
  HasCalendarEntryState,
  withCalendarEntryContext,
} from '../../../contexts/CalendarEntryContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { SelectBookingPerformerContext } from '../../../contexts/SelectBookingPerformerContext';
import { SelectBookingServiceContext } from '../../../contexts/SelectBookingServiceContext';
import { AuthorizedStackParamList, BookingStackParamList, RootStackParamList } from '../../../types';
import log from '../../../utils/log';
import ConfirmUpdateAndAcceptBooking from './ConfirmUpdateAndAcceptBooking';
import ConfirmUpdateRecurrentBooking from './ConfirmUpdateRecurrentBooking';

const isSame = (a: Date, b: Date) =>
  DateTime.fromJSDate(a).toFormat('yyyy-MM-dd HH:mm') === DateTime.fromJSDate(b).toFormat('yyyy-MM-dd HH:mm');

export const getAppointmentClient = (a: CalendarEntry.Appointment): ClientPreview | undefined => {
  if (a.payload.client && a.payload.user) {
    const { firstname, lastname } =
      a.payload.client.firstname || a.payload.client.lastname ? a.payload.client : a.payload.user?.profile ?? {};

    const isBoost = a.payload.clientBoostStatus?.isBoostClient ?? false;

    return {
      _id: a.payload.client._id,
      userId: a.payload.user._id,
      firstname: firstname,
      lastname: lastname,
      phone: a.payload.user.phone,
      photo: a.payload.user.profile.photo,
      isBlocked: a.payload.client?.isBlocked,
      isWarned: a.payload.client?.isWarned,
      isFavourite: a.payload.client?.isFavourite,
      isBoost: isBoost,
      hideBoostDetails: isBoost && (a.payload.hideBoostClientDetails ?? false),
    };
  } else {
    return undefined;
  }
};

export const getAppointmentNotes = (a: CalendarEntryDetails.Appointment): DefinedTrimedString | undefined =>
  pipe(
    a.payload.note,
    DefinedTrimedString.decode,
    E.fold(() => undefined, identity),
  );

type EditoBookingScreenNavigationProp = CompositeNavigationProp<
  StackNavigationProp<BookingStackParamList, 'BookingEditScreen'>,
  CompositeNavigationProp<
    StackNavigationProp<AuthorizedStackParamList, 'Booking'>,
    StackNavigationProp<RootStackParamList, 'Authorized'>
  >
>;

type Props = CurrentBusinessProps & {
  navigation: EditoBookingScreenNavigationProp;
  route: RouteProp<BookingStackParamList, 'BookingEditScreen'>;
};

type ComponentProps = Props & HasCalendarEntryState;

const EditBookingComponent = withCalendarEntryContext(
  ({ calendarEntryState: state, route, page, navigation }: ComponentProps) => {
    const { calendarId, calendarEntryId, occurrenceIndex } = route.params;
    type Params = Partial<{
      calendarId: CalendarId;
      calendarEntryId: AppointmentId;
      start: Date;
    }>;
    const params: Params = pipe(
      Ap.sequenceS(E.either)({
        calendarId: CalendarId.decode(route.params.calendarId),
        calendarEntryId: AppointmentId.decode(route.params.calendarEntryId),
        start: optionull(DateFromISOString).decode(route.params.start),
      }),
      E.fold(() => ({}), identity),
    );

    const showError = useShowError();
    const toast = useToast();
    const [, { pushEvent, subscribe: subscribeAppEvents }] = AppEventsContext.useContext();

    const appointment: CalendarEntryDetails.Appointment | undefined =
      state.type === 'Loaded' && state.entry.type === CalendarEntry.Type.Appointment.VALUE ? state.entry : undefined;

    const [
      bookingFormState,
      {
        reset: resetBookingForm,
        setStart,
        setEnd,
        setPerformer,
        setRecurrenceRule,
        setNotes,
        addService,
        deleteServiceAt,
      },
    ] = BookingFormContext.useContext();
    const {
      start: formStart,
      end: formEnd,
      recurrenceRule: formRecurrenceRule,
      notes: formNotes,
      client: formClient,
      services: formServices,
      performer: performer,
    } = bookingFormState;
    const [formInitialized, setFormInitialized] = React.useState(false);

    const [updateBookingState, { updateBooking, reset: resetUpdateBooking }] = BookingUpdateContext.useContext();

    const appointmentClient = appointment ? getAppointmentClient(appointment) : undefined;
    const { startDate, endDate, client, recurrenceRule, notes, services, editableStatus } = formInitialized
      ? // Use form data when form is initialilzed
        {
          startDate: formStart ?? new Date(),
          endDate: formEnd ?? addDefaultEventDuration(formStart ?? new Date()),
          client: formClient,
          recurrenceRule: formRecurrenceRule,
          notes: formNotes,
          services: formServices,
          editableStatus: appointment?.editableStatus ?? CalendarEntryDetails.AppointmentEditableStatus.NON_EDITABLE,
        }
      : // While form not initialized - use data from loaded entry or params
        {
          startDate: appointment?.start ?? params.start ?? new Date(),
          endDate: appointment?.end ?? addDefaultEventDuration(formStart ?? new Date()),
          client: appointmentClient ? ({ type: 'existing', client: appointmentClient } as const) : undefined,
          recurrenceRule: appointment?.recurrent ? appointment?.recurrenceRule : undefined,
          notes: appointment ? getAppointmentNotes(appointment) : undefined,
          services: (appointment ? appointment.payload.bookedServices : []).map(
            (s): ValidBookedService => ({ type: 'valid', service: s }),
          ),
          editableStatus: appointment?.editableStatus ?? CalendarEntryDetails.AppointmentEditableStatus.NON_EDITABLE,
        };

    const servicesWithWorker = React.useMemo((): BookedServiceWithWorkerItem[] => {
      return pipe(
        services,
        A.map(
          (s): BookedServiceWithWorkerItem => ({
            type: s.type,
            service: s.service,
            worker: performer?.user,
            workerServices: page.workers.filter((w) => w._id === performer?._id).flatMap((w) => w.services),
          }),
        ),
      );
    }, [services, performer]);

    React.useEffect(() => {
      // Initialize once
      if (!formInitialized && appointment) {
        setFormInitialized(true);

        const performer = pipe(
          page.workers,
          A.findFirst((w) => w.calendar._id === appointment.calendarId),
          O.getOrElseW(() => undefined),
        );

        resetBookingForm({
          start: appointment.start,
          end: appointment.end,
          client: getAppointmentClient(appointment),
          recurrenceRule: appointment.recurrent ? appointment.recurrenceRule : undefined,
          notes: getAppointmentNotes(appointment),
          services: appointment.payload.bookedServices,
          performer,
        });

        if (performer) setSelectedPerformer(performer);
      }
    }, [formInitialized, state, page.workers, setFormInitialized, setStart, setRecurrenceRule]);

    const [selectPerformerState, { setSelectedPerformer }] = SelectBookingPerformerContext.useContext();
    const [selectServiceState, { reset: resetServiceSelect, setServicesFilter }] =
      SelectBookingServiceContext.useContext();

    /**
     * New service was selected, add to the list
     */
    React.useEffect(() => {
      if (selectServiceState.type === 'some') {
        addService(selectServiceState.service);
        reset: resetServiceSelect();
      }
    }, [selectServiceState, addService, resetServiceSelect]);

    // Update services filter to exclude services already selected or not provided by current worker
    React.useEffect(() => {
      setServicesFilter(
        (w) => performer === undefined || performer._id === w._id, // Show only services performed by current worker
      );
    }, [services, performer, setServicesFilter]);

    // Handle performer selection result
    React.useEffect(() => {
      if (selectPerformerState.type === 'selected') {
        // do not reset the context bc it is also used by select services screen
        // resetPerformerSelect();
        setPerformer(selectPerformerState.selected);
      }
    }, [selectPerformerState, setPerformer]);

    const addServiceCallback = () => {
      // FIXME: init services filter here
      navigation.push('SelectBookingService', {
        screen: 'SelectBookingServiceScreen',
        params: { calendarId, calendarEntryId, occurrenceIndex, bookingPageId: appointment?.payload.page._id },
      });
    };

    const removeServiceCallback = React.useCallback(
      (s: unknown, index: number) => {
        log.debug('REMOVE SERVICE', index), deleteServiceAt(index);
      },
      [deleteServiceAt],
    );

    // const hasUnsavedChanges = () => text !== '' && !saved;
    const navigateCalendar = React.useCallback(() => {
      navigation.navigate('Home', {
        screen: 'HomeTabs',
        params: { screen: 'CalendarTab', params: { screen: 'CalendarScreen' } },
      });
    }, [navigation]);

    const goBack = useGoBack(navigateCalendar);

    // Go back if not an appointment
    React.useEffect(() => {
      if (state.type == 'Loaded' && state.entry.type !== CalendarEntry.Type.Appointment.VALUE) {
        goBack();
      }
    }, [state, goBack]);

    const shouldConfirmRecurrentUpdate = appointment && appointment.recurrent;
    const [showRecurrentUpdateOptions, setShowRecurrentUpdateOptions] = React.useState(false);
    const [recurrentUpdateOption, setRecurrentUpdateOption] = React.useState<'undetermined' | 'once' | 'all'>(
      'undetermined',
    );

    const shouldConfirmUpdateAndAccept =
      !!appointment &&
      appointment.type === CalendarEntry.Type.Appointment.VALUE &&
      appointment.payload.status === 'pending';
    const [statusUpdateOption, setStatusUpdateOption] = React.useState<'undetermined' | 'update' | 'update_and_accept'>(
      'undetermined',
    );
    const [showConfirmUpdateAndAcceptOptions, setShowConfirmUpdateAndAcceptOptions] = React.useState(false);

    const selectWorkerCallback = React.useCallback(() => {
      navigation.push('SelectBookingPerformerScreen');
    }, [navigation]);

    const saveBooking = ({
      override = false,
      onlyOnce,
      markAccepted,
    }: {
      override?: boolean;
      onlyOnce: boolean;
      markAccepted?: boolean;
    }): void => {
      if (
        bookingFormState.type === 'Valid' &&
        (updateBookingState.type === 'Ready' || updateBookingState.type === 'Failed') &&
        appointment &&
        performer
      ) {
        updateBooking({
          calendarId: appointment.calendarId,
          appointmentId: appointment._id,
          occurrenceIndex: appointment.occurrenceIndex,
          start: bookingFormState.start,
          end: bookingFormState.end,
          recurrenceRule: bookingFormState.recurrenceRule,
          bookedServiceIds: bookingFormState.bookedServiceIds,
          note: bookingFormState.notes,
          override: override,
          onlyOnce: onlyOnce,
          status: markAccepted ? 'accepted' : undefined,
          pageId: page.details._id,
          workerId: performer._id,
        }).then(() => {
          if (markAccepted) {
            log.debug(`Booking ${appointment._id} was marked as accepted, issue AppointmentRequestAccepted event`);
            pushEvent({
              type: 'AppointmentRequestAccepted',
            });
          }
        });
        setStatusUpdateOption('undetermined');
      } else {
        const invalidService = bookingFormState.services.find((s) => s.type === 'invalid');
        if (invalidService) {
          showError(new Error(`Alege un serviciu oferit de profesionistul selectat pentru a putea salva programarea.`));
        }
      }
    };

    type SaveBookingArgs = {
      readonly recurrentUpdateOption: 'undetermined' | 'once' | 'all';
      readonly statusUpdateOption: 'undetermined' | 'update' | 'update_and_accept';
    };

    const saveBookingCallback = ({ recurrentUpdateOption, statusUpdateOption }: SaveBookingArgs) => {
      if (shouldConfirmRecurrentUpdate && recurrentUpdateOption === 'undetermined') {
        setShowRecurrentUpdateOptions(true);
      } else if (shouldConfirmUpdateAndAccept && statusUpdateOption === 'undetermined') {
        setShowConfirmUpdateAndAcceptOptions(true);
      } else {
        saveBooking({
          onlyOnce: recurrentUpdateOption !== 'all',
          markAccepted: shouldConfirmUpdateAndAccept && statusUpdateOption === 'update_and_accept',
        });
      }
    };

    const sendNotification = React.useCallback(async (appointmentId: AppointmentId, phoneNumber: string) => {
      try {
        if (Platform.OS === 'web' && Dimensions.get('window').width > 768) {
          return;
        }

        const notificationSettings = await meroApi.pages.getPageNotificationSettings({
          pageId: page.details._id,
        });

        if (!notificationSettings.appointmentModified) {
          return;
        }

        const message = await meroApi.notifications.getRenderedTextForAppointmentUpdated({
          appointmentId,
          fromStart: appointment?.start || new Date(),
          fromEnd: appointment?.end || new Date(),
          occurrenceIndex: appointment?.occurrenceIndex as OccurrenceIndex,
        });

        const separator = Platform.OS === 'ios' ? '&' : '?';
        const url = `sms:${phoneNumber}${separator}body=${encodeURIComponent(message.text)}`;

        const supported = await Linking.canOpenURL(url);

        if (supported) {
          await Linking.openURL(url);
        }
      } catch (error) {
        log.error('Failed to get page notification settings', error);
      }
    }, []);

    const [, { reload: reloadCalendarEntry }] = CalendarEntryContext.useContext();

    const [showConfirmOverride, setShowConfirmOverride] = React.useState(false);

    const [, { reload: reloadCalendar }] = CalendarContext.useContext();

    // React to update booking action
    React.useEffect(() => {
      if (updateBookingState.type === 'Updated') {
        const phone =
          client?.type === 'new'
            ? client.phone
            : client?.type === 'existing' && !client.client.hideBoostDetails
            ? client.client.phone
            : undefined;
        if (
          updateBookingState.appointmentId &&
          phone &&
          appointment &&
          bookingFormState?.start &&
          !isSame(bookingFormState.start, appointment.start)
        ) {
          sendNotification(updateBookingState.appointmentId, phone);
        }
        resetBookingForm({});
        resetUpdateBooking();
        reloadCalendarEntry();
        setRecurrentUpdateOption('undetermined');
        reloadCalendar();
        if (appointment) {
          pushEvent({
            type: 'AppointmentUpdated',
            calendarId: appointment.calendarId,
            appointmentId: appointment._id,
          });
        }
        goBack();
        toast.show({
          type: 'success',
          text: 'Programarea a fost modificată',
        });
      } else if (updateBookingState.type === 'Failed') {
        resetUpdateBooking();
        if (updateBookingState.isOverride) {
          setShowConfirmOverride(true);
        } else if (updateBookingState.error) {
          showError(updateBookingState.error);
          setRecurrentUpdateOption('undetermined');
        } else {
          toast.show({
            type: 'error',
            text: 'Încercarea de a modifica programarea a eșuat',
          });
          setRecurrentUpdateOption('undetermined');
        }
      }
    }, [updateBookingState]);

    const cancelConfirmOverrideCallback = () => {
      setShowConfirmOverride(false);
      setRecurrentUpdateOption('undetermined');
    };

    const confirmBookingOverrideCallback = () => {
      setShowConfirmOverride(false);
      saveBooking({ onlyOnce: recurrentUpdateOption !== 'all', override: true });
    };

    React.useEffect(
      () =>
        subscribeAppEvents((event) => {
          switch (event.type) {
            case 'AppointmentDeleted': {
              if (
                state.type === 'Loaded' &&
                state.entry.calendarId === event.calendarId &&
                state.entry._id === event.appointmentId
              ) {
                goBack();
              }
              return;
            }
          }
        }),
      [subscribeAppEvents, state],
    );

    if (state.type !== 'Loaded' || state.entry.type === CalendarEntry.Type.Appointment.VALUE) {
      return (
        <>
          <AddBookingScreenView
            mode="edit"
            start={startDate}
            onStartChanged={setStart}
            end={endDate}
            onEndChanged={setEnd}
            onBackPress={goBack}
            client={client}
            services={servicesWithWorker}
            performer={performer}
            onSelectWorkerPress={selectWorkerCallback}
            onAddServicePress={addServiceCallback}
            onRemoveServicePress={removeServiceCallback}
            onRemoveBookingPress={undefined}
            recurrenceRule={recurrenceRule}
            onRecurrenceRuleChanged={setRecurrenceRule}
            notes={notes}
            onNotesChanged={setNotes}
            onSaveBooking={
              updateBookingState.type === 'Updating'
                ? undefined
                : () =>
                    saveBookingCallback({
                      recurrentUpdateOption,
                      statusUpdateOption,
                    })
            }
            editableStatus={editableStatus}
            fromWaitingList={false}
          />

          {showConfirmOverride ? (
            <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
              <ConfirmBox
                type="warn"
                icon="info"
                headerTitle="Actiune Importantă"
                canClose={true}
                onClose={cancelConfirmOverrideCallback}
                leftAction={{
                  text: 'Anulare',
                  onPress: cancelConfirmOverrideCallback,
                }}
                rightAction={{
                  text: 'Confirmă',
                  onPress: confirmBookingOverrideCallback,
                }}
              >
                <H1>Confirmă suprapunere programare</H1>
                <Spacer size="8" />
                <Body>Aceasta programare se va suprapune cu altele. Eşti sigur că vrei să continui?</Body>
              </ConfirmBox>
            </ModalOverlay>
          ) : null}

          {showRecurrentUpdateOptions ? (
            <ConfirmUpdateRecurrentBooking
              onUpdateAll={() => {
                setShowRecurrentUpdateOptions(false);
                // Save user choice, to avoid infinite loop with confirm override
                setRecurrentUpdateOption('all');
                saveBookingCallback({
                  recurrentUpdateOption: 'all',
                  statusUpdateOption,
                });
              }}
              onUpdateOnlyOne={() => {
                setShowRecurrentUpdateOptions(false);
                // Save user choice, to avoid infinite loop with confirm override
                setRecurrentUpdateOption('once');
                saveBookingCallback({
                  recurrentUpdateOption: 'once',
                  statusUpdateOption,
                });
              }}
              onDismiss={() => {
                setShowRecurrentUpdateOptions(false);
              }}
            />
          ) : null}

          {showConfirmUpdateAndAcceptOptions ? (
            <ConfirmUpdateAndAcceptBooking
              onUpdate={() => {
                setShowConfirmUpdateAndAcceptOptions(false);
                setStatusUpdateOption('update');
                saveBookingCallback({
                  recurrentUpdateOption,
                  statusUpdateOption: 'update',
                });
              }}
              onUpdateAndAccept={() => {
                setShowConfirmUpdateAndAcceptOptions(false);
                setStatusUpdateOption('update_and_accept');
                saveBookingCallback({
                  recurrentUpdateOption,
                  statusUpdateOption: 'update_and_accept',
                });
              }}
              onDismiss={() => {
                setShowConfirmUpdateAndAcceptOptions(false);
              }}
            />
          ) : null}
        </>
      );
    } else {
      return (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Body>Not an appointment</Body>
        </View>
      );
    }
  },
);

const BookingEditScreen: React.FC<Props> = ({ page, route, navigation }) => {
  return (
    <EditBookingComponent
      pageId={page.details._id}
      calendarEntryId={route.params.calendarEntryId}
      occurrenceIndex={pipe(
        route.params.occurrenceIndex,
        NumberFromString.decode,
        E.getOrElse(() => 0),
      )}
      page={page}
      route={route}
      navigation={navigation}
    />
  );
};

export default pipe(BookingEditScreen, withBookingUpdateContextProvider, CurrentBusiness);
