import { SubscriptionInfo, SubscriptionTier } from '@mero/api-sdk/dist/payments';
import { Order, ProductScopeEnum } from '@mero/api-sdk/dist/payments/order';
import {
  colors,
  Column,
  H2s,
  Label,
  Line,
  Row,
  SmallBody,
  Spacer,
  Title,
  styles as meroStyles,
} from '@mero/components';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Body from '@mero/components/lib/components/Text/Body';

import { roundToDecimals } from '../../../../../utils/number';

const ProductName = {
  [ProductScopeEnum.base]: 'subscriptionBase',
  [ProductScopeEnum.workerSeat]: 'subscriptionWorkerSeat',
  [ProductScopeEnum.workerSeatProRata]: 'subscriptionWorkerSeatProRata',
  [ProductScopeEnum.smsNotifications]: 'subscriptionSmsNotifications',
  [ProductScopeEnum.outstandingBalance]: 'subscriptionOutstandingBalance',
  [ProductScopeEnum.boost]: 'subscriptionBoostCommission',
  // [ProductScopeEnum.boostDiscount]: 'subscriptionBoostCommissionDiscount',
} as const;

const ProductSubtitle = {
  [ProductScopeEnum.base]: 'subscriptionBaseSubtitle',
  [ProductScopeEnum.workerSeat]: 'subscriptionWorkerSeatSubtitle',
  [ProductScopeEnum.workerSeatProRata]: 'subscriptionWorkerSeatProRataSubtitle',
  [ProductScopeEnum.smsNotifications]: 'subscriptionSmsNotificationsSubtitle',
  [ProductScopeEnum.outstandingBalance]: 'subscriptionOutstandingBalanceSubtitle',
  [ProductScopeEnum.boost]: 'subscriptionBoostCommissionSubtitle',
  // [ProductScopeEnum.boostDiscount]: 'subscriptionBoostCommissionDiscountSubtitle',
} as const;

type Props = {
  readonly order: Order;
  readonly subscriptionInfo: Pick<
    SubscriptionInfo,
    'tier' | 'autoCharge' | 'trialStarted' | 'billingCycle' | 'paymentExpires'
  >;
  readonly now: Date;
};

const ProformaDetails: React.FC<Props> = ({ order, subscriptionInfo, now }) => {
  const { t } = useTranslation('subscription');
  const tier = subscriptionInfo.tier;
  const trialStarted = subscriptionInfo.trialStarted;
  const paymentExpires = subscriptionInfo.paymentExpires;
  const isPreTrial = tier === SubscriptionTier.TRIAL && !trialStarted;
  const isExpired = paymentExpires.getTime() < now.getTime();
  const isAutoChargeOn = subscriptionInfo.autoCharge ?? false;

  const expires: DateTime = React.useMemo(() => DateTime.fromJSDate(paymentExpires), [paymentExpires]);
  const expirationDate: string = React.useMemo(() => expires.setLocale('ro').toFormat('dd.MM.yyyy'), [expires]);
  const isAboutToExpire = React.useMemo(
    () => !isExpired && paymentExpires.getTime() - now.getTime() < 48 * 3600 * 1000,
    [isExpired, paymentExpires, now],
  );
  const isPositiveTotal = order.total > 0;

  const currency = order.currency === 'EUR' ? '€' : 'RON';
  const billingCycleValueMonths = React.useMemo((): number => {
    switch (subscriptionInfo.billingCycle) {
      case 'annually':
        return 12;
      case 'halfYear':
        return 6;
      case 'quarterly':
        return 3;
      case 'monthly':
        return 1;
    }
  }, [subscriptionInfo.billingCycle]);

  const cycleLabel = React.useCallback((scope: ProductScopeEnum) => {
    if (scope === ProductScopeEnum.base || scope === ProductScopeEnum.workerSeat) {
      return t(subscriptionInfo.billingCycle);
    }

    return '';
  }, []);

  return (
    <>
      <Label
        style={{
          color:
            isPositiveTotal && isExpired
              ? colors.RADICAL_RED
              : isPositiveTotal && isAboutToExpire && !isAutoChargeOn
              ? colors.OUTRAGEOUS_ORANGE
              : colors.COMET,
          paddingHorizontal: 16,
        }}
      >
        {isPreTrial ? (
          t('toPay')
        ) : (
          <Trans ns={'subscription'} t={t} i18nKey="toPayBy" values={{ date: expirationDate }}>
            0
          </Trans>
        )}
      </Label>
      <H2s style={{ paddingTop: 6, paddingHorizontal: 16 }}>
        {order.total} {currency}{' '}
        {order.totalRON !== undefined && (
          <Body style={{ fontSize: 20 }}>
            ({order.totalRON.amount} {t(order.totalRON.unit)})
          </Body>
        )}
      </H2s>
      <Spacer size={16} />
      <Line />
      <Spacer size={16} />
      <Column style={{ paddingHorizontal: 16 }}>
        {order.items.map((item, index) => (
          <Column style={{ paddingTop: 16 }} key={item.product.scope}>
            <Row>
              <Title style={{ flex: 1 }}>
                {t(ProductName[item.product.scope])}
                {cycleLabel(item.product.scope)}
              </Title>
              <Title>
                {roundToDecimals(item.product.price * item.quantity)} {currency}
              </Title>
            </Row>
            <SmallBody style={{ paddingTop: 4, color: colors.COMET, paddingBottom: 16 }}>
              {t(ProductSubtitle[item.product.scope], {
                price: item.product.price,
                count:
                  billingCycleValueMonths > 1 && item.product.scope === ProductScopeEnum.workerSeat
                    ? item.quantity / billingCycleValueMonths
                    : item.quantity,
                currency,
              })}
            </SmallBody>
            {index < order.items.length - 1 && <Line />}
          </Column>
        ))}
      </Column>
      <Spacer size={16} />
      <Line />
      {order.subtotalDiscount ? (
        <>
          <Spacer size={16} />
          <Row style={{ paddingHorizontal: 16 }}>
            <SmallBody style={[{ flex: 1 }, meroStyles.text.semibold]}>
              {order.discountPercent > 0 ? t('discountPercentage', { value: order.discountPercent }) : t('discount')}
            </SmallBody>
            <SmallBody style={meroStyles.text.semibold}>
              -{roundToDecimals(order.subtotalDiscount)} {currency}
            </SmallBody>
          </Row>
        </>
      ) : null}
      {order.subtotal ? (
        <>
          <Spacer size={16} />
          <Row style={{ paddingHorizontal: 16 }}>
            <SmallBody style={[{ flex: 1 }, meroStyles.text.semibold]}>{t('totalWithoutVAT')}</SmallBody>
            <SmallBody style={meroStyles.text.semibold}>
              {roundToDecimals(order.subtotal - (order.subtotalDiscount ?? 0))} {currency}
            </SmallBody>
          </Row>
        </>
      ) : null}
      <Spacer size={16} />
      <Row style={{ paddingHorizontal: 16 }}>
        <Title style={[{ flex: 1 }, meroStyles.text.bold]}>{t('totalWithVAT')}</Title>
        <Title style={meroStyles.text.bold}>
          {order.total} {currency}
        </Title>
      </Row>
      <Spacer size={24} />
    </>
  );
};

export default ProformaDetails;
