import { ProductMeasure } from '@mero/api-sdk';

import { scaledToString } from './scaled';

export const getMeasure = (measure: ProductMeasure.Any) => {
  if (measure.unit === 'buc') {
    return `${measure.unit}.`;
  }

  return `${scaledToString(measure.value)} ${measure.unit}`;
};

export const updateOrAdd = <K, T extends { _id: K }>(array: T[], item: T, onFound: (i: T, n: T) => T) => {
  const index = array.findIndex((i) => i._id === item._id);

  if (index === -1) {
    return [...array, item];
  }

  return array.map((i) => (i._id === item._id ? onFound(i, item) : i));
};
