import * as React from 'react';
import { Platform } from 'react-native';

import NativeOnboardingScreen from '../screens/Authorized/OnboardingScreen/NativeOnboardingScreen';
import NativeOnboardingSuccessScreen from '../screens/Authorized/OnboardingScreen/NativeOnboardingSuccessScreen';
import OnboardingQuestion1Screen from '../screens/Authorized/OnboardingScreen/OnboardingQuestion1Screen';
import OnboardingQuestion2Screen from '../screens/Authorized/OnboardingScreen/OnboardingQuestion2Screen';
import OnboardingQuestion3Screen from '../screens/Authorized/OnboardingScreen/OnboardingQuestion3Screen';

import ContainerWithBackground from '../components/ContainerWithBackground';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { useMediaQueries } from '../hooks/useMediaQueries';

import { OnboardingStackParamList } from '../types';
import { styles } from './styles';

const Stack = createStackNavigator<OnboardingStackParamList>();

export default function OnboardingStackNavigator(): React.ReactElement {
  const { isPhone } = useMediaQueries();

  const modalStyle = isPhone
    ? styles.phoneModalCard
    : Platform.OS === 'web'
    ? styles.desktopModalCard
    : styles.tabletModalCard;

  return (
    <ContainerWithBackground>
      <Stack.Navigator
        initialRouteName="OnboardingScreen"
        screenOptions={{ headerShown: false, cardStyle: modalStyle, cardOverlayEnabled: false }}
      >
        <Stack.Group
          screenOptions={{
            ...(isPhone ? TransitionPresets.ModalSlideFromBottomIOS : TransitionPresets.ModalFadeTransition),
          }}
        >
          <Stack.Screen name="OnboardingScreen" component={NativeOnboardingScreen} />
        </Stack.Group>
        <Stack.Screen name="OnboardingQuestion1Screen" component={OnboardingQuestion1Screen} />
        <Stack.Screen name="OnboardingQuestion2Screen" component={OnboardingQuestion2Screen} />
        <Stack.Screen name="OnboardingQuestion3Screen" component={OnboardingQuestion3Screen} />
        <Stack.Screen name="OnboardingSuccessScreen" component={NativeOnboardingSuccessScreen} />
      </Stack.Navigator>
    </ContainerWithBackground>
  );
}
