import { ProductBrandId, ProductCategoryId, ProductId } from '@mero/api-sdk';
import {
  colors,
  MeroHeader,
  Spacer,
  H1,
  Row,
  styles as meroStyles,
  SearchTextInput,
  Title,
  Column,
  Switch,
  HSpacer,
} from '@mero/components';
import { pipe } from 'fp-ts/lib/function';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import ModalScreenContainer from '../../../components/ModalScreenContainer';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { AppStorage } from '../../../app-storage';
import { Authorized, AuthorizedProps } from '../../../contexts/AuthContext';
import { DefaultCategory, ProductsContext } from '../../../contexts/ProductsContext';
import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductsTabContext, SelectedProductsTabState } from '../../../contexts/SelectedProductsTabContext';
import { ProductsDashboardScreenNavigationProp } from '../../../types';
import BrandAddButton from './BrandAddButton';
import BrandsContent from './BrandsContent';
import CategoriesContent from './CategoriesContent';
import ProductAddButton from './ProductAddButton';
import { styles } from './styles';

type Props = AuthorizedProps & {
  navigation: ProductsDashboardScreenNavigationProp;
};

const ProductsDashboardScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('products');
  const { isPhone, isDesktop } = useMediaQueries();

  const goBack = useGoBack();

  const [selectedTab, { setSelectedTab, update: updateSelectedTab }] = SelectedProductsTabContext.useContext();
  const [searchState, { debounceSearch }] = SearchProductsContext.useContext();
  const [state, { update }] = ProductsContext.useContext();

  const navigateProductsMenuCallback = React.useCallback(() => {
    navigation.navigate('ProductsMenuScreen');
  }, [navigation]);

  const navigateImportProductsCallback = React.useCallback(() => {
    navigation.navigate('ImportProductsScreen');
  }, [navigation]);

  const navigateProductCallback = React.useCallback(
    (productId?: ProductId) => {
      if (productId) {
        navigation.navigate('ProductEdit', { screen: 'ProductMenu', params: { productId } });
      } else {
        navigation.navigate('ProductEdit', { screen: 'ProductScreen' });
      }
    },
    [navigation],
  );

  const navigateProductSearchCallback = React.useCallback(() => {
    navigation.navigate('ProductsTab', { screen: 'ProductSearchScreen' });
  }, [navigation]);

  const navigateCategoryCallback = React.useCallback(
    (categoryId?: ProductCategoryId) => {
      navigation.navigate('ProductCategoryScreen', categoryId ? { categoryId } : {});
    },
    [navigation],
  );

  const navigateBrandCallback = React.useCallback(
    (brandId?: ProductBrandId) => {
      navigation.navigate('ProductBrandScreen', brandId ? { brandId } : {});
    },
    [navigation],
  );

  const navigateDeleteCategoryCallback = React.useCallback(
    (categoryId: ProductCategoryId) => {
      navigation.navigate('DeleteCategoryScreen', { categoryId });
    },
    [navigation],
  );

  const navigateDeleteBrandCallback = React.useCallback(
    (brandId: ProductBrandId) => {
      navigation.navigate('DeleteBrandScreen', { brandId });
    },
    [navigation],
  );

  const navigateBrandProductsListCallback = React.useCallback(
    (brandId: ProductBrandId) => {
      navigation.navigate('BrandProductsListScreen', { brandId });
    },
    [navigation],
  );

  const navigateCategoryProductsListCallback = React.useCallback(
    (category: ProductCategoryId | 'noCategory' | 'inactive') => {
      navigation.navigate('CategoryProductsListScreen', { category });
    },
    [navigation],
  );

  React.useEffect(() => {
    const init = async () => {
      const selectedTab = await AppStorage.getSelectedProductsTab();
      if (selectedTab) {
        updateSelectedTab(selectedTab);
      }
    };
    init();
  }, []);

  const getContent = () => {
    switch (selectedTab) {
      case 'products':
        return (
          <CategoriesContent
            navigateSearchProduct={navigateProductSearchCallback}
            navigateProduct={navigateProductCallback}
            navigateDeleteCategory={navigateDeleteCategoryCallback}
            navigateCategory={navigateCategoryCallback}
            navigateImportProducts={navigateImportProductsCallback}
            navigateCategoryProducts={navigateCategoryProductsListCallback}
          />
        );
      case 'brands':
        return (
          <BrandsContent
            navigateProduct={navigateProductCallback}
            navigateBrand={navigateBrandCallback}
            navigateDeleteBrand={navigateDeleteBrandCallback}
            navigateBrandProducts={navigateBrandProductsListCallback}
          />
        );
      case 'suppliers':
        return <></>;
      case 'documents':
        return <></>;
      case 'inventories':
        return <></>;
    }
  };

  const getDesktopActions = () => {
    switch (selectedTab) {
      case 'products':
        return (
          <Row>
            {/* <ProductFilter hasDropdownIcon={true} hasPadding={false} /> */}
            <HSpacer left={16} />
            <ProductAddButton
              hasBackground={false}
              navigateImportProductsCallback={navigateImportProductsCallback}
              navigateAddProductCallback={navigateProductCallback}
              navigateAddCategoryCallback={navigateCategoryCallback}
            />
          </Row>
        );
      case 'brands':
        return <BrandAddButton isPhone={false} navigateBrand={navigateBrandCallback} />;
      case 'suppliers':
        return <></>;
      case 'documents':
        return <></>;
      case 'inventories':
        return <></>;
    }
  };

  const getMobileActions = () => {
    if (state.type !== 'Loaded') return;

    switch (selectedTab) {
      case 'products':
        return <HSpacer left={48} />;
      case 'brands':
        return state.brands.length > 0 ? (
          <BrandAddButton isPhone={true} navigateBrand={navigateBrandCallback} />
        ) : (
          <HSpacer left={48} />
        );
      case 'suppliers':
        return <></>;
      case 'documents':
        return <></>;
      case 'inventories':
        return <></>;
    }
  };

  const onChangeTab = (newTab: SelectedProductsTabState) => {
    if (state.type !== 'Loaded') {
      return;
    }

    setSelectedTab(newTab);
    switch (newTab) {
      case 'products': {
        update({ selectedCategory: DefaultCategory.active });
        break;
      }
      case 'brands': {
        if (state.brands.length) {
          update({ selectedBrand: state.brands[0] });
        }
      }
    }
  };

  if (state.type !== 'Loaded') {
    return;
  }

  return (
    <>
      <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
        {isPhone && (
          <>
            <MeroHeader
              canGoBack
              onBack={goBack}
              TitleComponent={() => <Title style={{ color: colors.BLACK }}>{t(`${selectedTab}Title`)}</Title>}
              titleComponentStyle={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              RightComponent={getMobileActions()}
            />

            <Spacer size={16} />
            <Column style={[{ flex: 1 }]}>
              <Column style={[isPhone && { marginHorizontal: 16 }]}>
                <H1>{t(`${selectedTab}Title`)}</H1>
              </Column>
              {getContent()}
            </Column>
          </>
        )}

        {isDesktop && (
          <>
            <Row
              alignItems="center"
              justifyContent="space-between"
              style={[styles.headerWrapperWithShadow, { paddingHorizontal: 24, paddingVertical: 30 }]}
            >
              <H1>{t('productsAndInventory')}</H1>
              <Row style={{ width: 340 }}>
                <SearchTextInput
                  placeholder={t('searchProducts')}
                  value={searchState.query.search}
                  onChange={(keyword) => debounceSearch({ query: { search: keyword } })}
                />
              </Row>
            </Row>

            <View style={{ paddingHorizontal: 24, flex: 1 }}>
              <Spacer size="24" />

              <Row alignItems="center" justifyContent="space-between">
                <Column style={{ flex: 1, maxWidth: 270 }}>
                  <Switch
                    height={32}
                    textProps={[meroStyles.text.semibold, { fontSize: 13 }]}
                    buttons={[
                      { label: t('productsTitle'), value: 'products' },
                      { label: t('brandsTitle'), value: 'brands' },
                    ]}
                    defaultValue={selectedTab}
                    onChange={(newTab) => {
                      onChangeTab(newTab as SelectedProductsTabState);
                    }}
                  />
                </Column>

                {getDesktopActions()}
              </Row>

              <Column style={{ flex: 1, zIndex: -1 }}>{getContent()}</Column>

              <Spacer size="24" />
            </View>
          </>
        )}
      </ModalScreenContainer>
    </>
  );
};

export default pipe(ProductsDashboardScreen, Authorized);
