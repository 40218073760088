import { Avatar, Button, colors, Column, FormCard, H1, Header, Icon, Line, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import { LocationProfileStackParamList } from '../../../../../types';
import { MenuItem } from '../ProsDashboardScreen/ProDetailsScreen';

export type Props = StackScreenProps<LocationProfileStackParamList, 'LocationMenu'>;

const LocationProfileScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation('location');

  const [pageState] = CurrentBusinessContext.useContext();

  const goBack = useGoBack();

  if (pageState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('title')}
        RightComponent={() => (
          <Column style={{ paddingRight: 24 }}>
            <Avatar
              size={32}
              source={pageState.page.details.profilePhoto.thumbnail}
              firstname={pageState.page.details.name}
              lastname={''}
            />
          </Column>
        )}
      />

      <ScrollView style={{ paddingHorizontal: 16, paddingTop: 16, flex: 1 }}>
        <H1 style={{ paddingHorizontal: 8 }}>{t('title')}</H1>
        <Spacer size={32} />
        <FormCard paddings={'none'} style={{ padding: 16 }} dropShaddow rounded>
          <MenuItem
            title={t('details')}
            icon="pro-menu-page-details"
            onPress={() => navigation.navigate('LocationProfile')}
          />
          <Line />

          {pageState.page.permissions.page.canManageGallery() ? (
            <>
              <MenuItem
                title={t('gallery')}
                icon="pro-menu-photo-gallery"
                onPress={() => navigation.navigate('LocationGallerySettingsScreen')}
              />
              <Line />
            </>
          ) : null}

          <MenuItem
            title={t('address')}
            icon="pro-menu-location"
            onPress={() => navigation.navigate('LocationAddress')}
          />
          <Line />
          <MenuItem
            title={t('schedule')}
            icon="pro-menu-pending-clients"
            onPress={() => navigation.navigate('LocationSchedule')}
          />
          <Line />
          <MenuItem
            title={t('onlineLink')}
            icon="pro-menu-marketing"
            onPress={() => navigation.navigate('OnlineLink')}
          />
        </FormCard>
        <Spacer size={32} />
        <Button
          text={t('delete')}
          backgroundColor={colors.ALABASTER}
          color={colors.RADICAL_RED}
          onClick={() => navigation.navigate('DeleteLocation')}
        />
        <Spacer size={48} />
      </ScrollView>
    </ModalScreenContainer>
  );
};

export default LocationProfileScreen;
