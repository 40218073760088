import * as MeroApi from '@mero/api-sdk';
import { PhoneNumber } from '@mero/api-sdk';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import { meroApi } from '../../contexts/AuthContext';
import log from '../../utils/log';

type NovaAuth = {
  readonly token: string;
  readonly phone: PhoneNumber;
};

type FacebookToken = { fbAccessToken: string } | { fbAuthenticationToken: string };

type SigningState =
  | {
      readonly type: 'Ready';
      readonly googleToken?: string;
      readonly facebook?: FacebookToken;
      readonly appleToken?: string;
      readonly novaToken?: NovaAuth;
    }
  | {
      readonly type: 'NotRegistered';
      readonly googleToken?: string;
      readonly facebook?: FacebookToken;
      readonly appleToken?: string;
      readonly novaToken?: NovaAuth;
    }
  | {
      readonly type: 'SignInProgress';
      readonly googleToken?: string;
      readonly facebook?: FacebookToken;
      readonly appleToken?: string;
      readonly novaToken?: NovaAuth;
    }
  | {
      readonly type: 'Success';
      readonly user: MeroApi.users.Authentication['user'];
    };

const defaultState = (): SigningState => ({
  type: 'Ready',
});

const GrantType = 'implicit';

export const SigningContext = createModelContext(
  defaultState(),
  {
    setSignInProgress(s) {
      if (s.type === 'Ready' || s.type === 'NotRegistered') {
        return {
          ...s,
          type: 'SignInProgress',
        };
      } else {
        return s;
      }
    },
    setNotRegistered(s) {
      if (s.type === 'Ready' || s.type === 'SignInProgress') {
        return {
          ...s,
          type: 'NotRegistered',
        };
      } else {
        return s;
      }
    },
    stopNotRegistered(s) {
      if (s.type === 'NotRegistered') {
        return {
          ...s,
          type: 'Ready',
        };
      } else {
        return s;
      }
    },
    stopSignInProgress(s) {
      if (s.type === 'SignInProgress') {
        return {
          ...s,
          type: 'Ready',
        };
      } else {
        return s;
      }
    },
    setGoogleToken(s, googleToken: string) {
      if (s.type === 'Ready' || s.type === 'NotRegistered' || s.type === 'SignInProgress') {
        return {
          ...s,
          type: 'Ready',
          googleToken,
        };
      } else {
        return s;
      }
    },
    setFacebookToken(s, facebook: FacebookToken) {
      if (s.type === 'Ready' || s.type === 'NotRegistered' || s.type === 'SignInProgress') {
        return {
          ...s,
          type: 'Ready',
          facebook,
        };
      } else {
        return s;
      }
    },
    setAppleToken(s, appleToken: string) {
      if (s.type === 'Ready' || s.type === 'NotRegistered' || s.type === 'SignInProgress') {
        return {
          ...s,
          type: 'Ready',
          appleToken,
        };
      } else {
        return s;
      }
    },
    setNovaToken(s, novaToken: NovaAuth) {
      if (s.type === 'Ready' || s.type === 'NotRegistered' || s.type === 'SignInProgress') {
        return {
          ...s,
          type: 'Ready',
          novaToken,
        };
      } else {
        return s;
      }
    },
    setSuccess(_, user: MeroApi.users.Authentication['user']) {
      return {
        type: 'Success',
        user: user,
      };
    },
    mutate: (s, fn: (s: SigningState) => SigningState): SigningState => fn(s),
  },
  (dispatch) => {
    const tryAttachAccounts = async (s: SigningState) => {
      if (s.type === 'SignInProgress' || s.type === 'NotRegistered' || s.type === 'Ready') {
        if (s.googleToken) {
          try {
            await meroApi.users.linkGoogle({ googleIdToken: s.googleToken });
          } catch (e) {
            log.error('Failed to attach google account', e);
          }
        }

        if (s.appleToken) {
          try {
            await meroApi.users.linkAppleId({ idToken: s.appleToken });
          } catch (e) {
            log.error('Failed to attach apple account', e);
          }
        }

        if (s.facebook) {
          try {
            await meroApi.users.linkFacebook(s.facebook);
          } catch (e) {
            log.error('Failed to attach facebook account', e);
          }
        }
      }
    };

    return {
      loginGoogle: async (idToken: string): Promise<void> => {
        try {
          dispatch.setSignInProgress();
          const user = await meroApi.users.loginGoogle({
            googleIdToken: idToken,
            grantType: GrantType,
          });
          dispatch.setSuccess(user);
        } catch (e: any) {
          dispatch.setGoogleToken(idToken);
          // Instanceof doesn't work cause api package is compiled with "target": "es5" (?)
          if ('code' in e && e['code'] === 4) {
            dispatch.setNotRegistered();
          } else {
            dispatch.stopSignInProgress();
            // TODO: add error UI
            log.error('Failed to sign in with google', e);
          }
        }
      },
      loginApple: async (payload: { authorizationCode: string; identityToken?: string }): Promise<void> => {
        // pass
        try {
          dispatch.setSignInProgress();
          const user = await meroApi.users.loginAppleId({
            appleAuthCode: payload.authorizationCode,
            grantType: GrantType,
          });
          dispatch.setSuccess(user);
        } catch (e: any) {
          log.error('Apple login failed', payload, e);
          if (payload.identityToken) {
            dispatch.setAppleToken(payload.identityToken);
          }

          // Instanceof doesn't work cause api package is compiled with "target": "es5" (?)
          if ('code' in e && e['code'] === 4) {
            dispatch.setNotRegistered();
          } else {
            dispatch.stopSignInProgress();
            // TODO: add error UI
            log.error('Failed to sign in with Apple', e);
          }
        }
      },
      loginFacebook: async (payload: { fbAccessToken: string } | { fbAuthenticationToken: string }): Promise<void> => {
        try {
          dispatch.setSignInProgress();
          const user = await meroApi.users.loginFacebook({
            ...payload,
            grantType: GrantType,
          });
          dispatch.setSuccess(user);
        } catch (e: any) {
          dispatch.setFacebookToken(payload);
          // Instanceof doesn't work cause api package is compiled with "target": "es5" (?)
          if ('code' in e && e['code'] === 4) {
            dispatch.setNotRegistered();
          } else {
            dispatch.stopSignInProgress();
            // TODO: add error UI
            log.error('Failed to sign in with facebook', JSON.stringify(e));
          }
        }
      },
      loginNovaAuth: async (novaToken: NovaAuth): Promise<void> => {
        // Try login with nova token, if "not registered" - set token but redirect to register screen
        try {
          dispatch.setSignInProgress();
          const user = await meroApi.users.loginNovaAuth({
            token: novaToken.token,
            grantType: GrantType,
          });
          dispatch.setSuccess(user);
          dispatch.mutate((s) => {
            tryAttachAccounts(s);

            return s;
          });
        } catch (e: any) {
          dispatch.setNovaToken(novaToken);
          // Instanceof doesn't work cause api package is compiled with "target": "es5" (?)
          if ('code' in e && e['code'] === 3) {
            dispatch.setNotRegistered();
          } else {
            // TODO: add error UI
            log.error('Failed to sign in with phone number', e, JSON.stringify(e));
          }
        }
      },
      registerNovaAuth: (payload: { novaToken: string; firstName: string; lastName: string }): void => {
        dispatch.mutate((s) => {
          if (s.type === 'Ready' || s.type === 'NotRegistered') {
            log.debug(`SigningContext state is ${s.type}, proceed with registration`);
            const register = async (): Promise<void> => {
              try {
                log.debug(`registerNovaAuth started`);
                const user = await meroApi.users.registerNovaAuth({
                  token: payload.novaToken,
                  firstname: payload.firstName,
                  lastname: payload.lastName,
                  grantType: GrantType,
                  tags: ['business'],
                });

                log.debug(`registerNovaAuth user registered`);

                dispatch.mutate((s) => {
                  tryAttachAccounts(s);

                  return s;
                });
                dispatch.setSuccess(user);
              } catch (e: any) {
                log.error(`registerNovaAuth registration failed`, e);
                if ('code' in e && e['code'] === 1) {
                  // already registered
                  try {
                    const user = await meroApi.users.loginNovaAuth({
                      token: payload.novaToken,
                      grantType: GrantType,
                    });
                    dispatch.mutate((s) => {
                      tryAttachAccounts(s);

                      return s;
                    });
                    dispatch.setSuccess(user);
                  } catch (e) {
                    log.error('Failed to sign in', e);
                  }
                } else {
                  log.error('Failed to sign in with phone number', e, JSON.stringify(e));
                }
              } finally {
                dispatch.stopSignInProgress();
              }
            };

            register().catch(log.exception);

            return {
              ...s,
              type: 'SignInProgress',
            };
          } else {
            return s;
          }
        });
      },
      stopNotRegistered: dispatch.stopNotRegistered,
      stopSignInProgress: dispatch.stopSignInProgress,
      setSignInProgress: dispatch.setSignInProgress,
    };
  },
);

export const withSigningContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSigningContextProvider(props: P) {
    return (
      <SigningContext.Provider>
        <Content {...props} />
      </SigningContext.Provider>
    );
  };
};
