import { colors, Column, H1, Header, Icon, Spacer, useShowError } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import useGoBack from '../../../../../hooks/useGoBack';

import { AppStorage } from '../../../../../app-storage';
import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';
import log from '../../../../../utils/log';
import ActivateOnlinePayment from './ActivateOnlinePayment';
import ManageOnlinePayment from './ManageOnlinePayment';

const PageOnlinePaymentsSettingsScreen: React.FC = ({}) => {
  const goBack = useGoBack();
  const { t } = useTranslation('onlinePayments');
  const showError = useShowError();

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();
  const stripeConnectionInProgress = React.useRef(false);

  const connectStripeWeb = async (code: string) => {
    if (pageState.type !== 'Loaded') {
      return;
    }
    try {
      await meroApi.payments.connectStripeAccount({
        pageId: pageState.page.details._id,
        code,
      });

      await meroApi.pages.updateOnlinePaymentsStatus({ pageId: pageState.page.details._id, enabled: true });
      await reloadAsync();
    } catch (error) {
      log.error('Failed to connect stripe', error);
      showError(error, t('connectStripeError'));
    }
  };

  const connectStripeApp = async () => {
    if (pageState.type !== 'Loaded' || stripeConnectionInProgress.current) {
      return;
    }
    try {
      const code = await AppStorage.getStripeCode();
      if (!code) {
        return;
      }
      stripeConnectionInProgress.current = true;
      await meroApi.payments.connectStripeAccount({
        pageId: pageState.page.details._id,
        code,
      });

      await meroApi.pages.updateOnlinePaymentsStatus({ pageId: pageState.page.details._id, enabled: true });
      await reloadAsync();

      await AppStorage.deleteStripeCode();
    } catch (error) {
      log.error('Failed to connect stripe', error);
      showError(error, t('connectStripeError'));
    } finally {
      stripeConnectionInProgress.current = false;
    }
  };

  React.useEffect(() => {
    if (pageState.type === 'Loaded') {
      if (Platform.OS === 'web') {
        const params = new URLSearchParams(window.location.search);
        if (params.has('code')) {
          const code = params.get('code');
          if (code) {
            connectStripeWeb(code);
          }
        }
      } else {
        connectStripeApp();
      }
    }
  }, [pageState.type]);

  if (pageState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <Header
        LeftComponent={() => (
          <TouchableOpacity onPress={goBack} style={{ paddingLeft: 16 }}>
            <Icon type="back" />
          </TouchableOpacity>
        )}
        text={t('title')}
      />
      <Column style={{ flex: 1 }}>
        <Spacer size={16} />
        <H1 style={{ paddingHorizontal: 24 }}>{t('title')}</H1>
        {pageState.page.details.hasOnlinePaymentsEnabled ? <ManageOnlinePayment /> : <ActivateOnlinePayment />}
      </Column>
    </ModalScreenContainer>
  );
};

export default PageOnlinePaymentsSettingsScreen;
