import { ProductId, ScaledNumber, SortField } from '@mero/api-sdk';
import { Body, Column, H2s, HSpacer, Line, Row, SmallBody, Spacer, colors } from '@mero/components';
import { SortDirection } from '@mero/shared-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, DimensionValue, FlatList, TouchableOpacity } from 'react-native';

import { AscSortedIcon, DescSortedIcon, UnsortedIcon } from '../MenuScreen/screens/PageReportsScreen/components/Table';

import { SearchProductsContext } from '../../../contexts/ProductsSearchContext';
import { SelectedProductsTabContext } from '../../../contexts/SelectedProductsTabContext';
import { getMeasure } from '../../../utils/products';
import { scaledToString } from '../../../utils/scaled';
import ProductSummaryCard from './ProductSummaryCard';

export type ProductRow = {
  _id: ProductId;
  name: string;
  brand: string;
  measure: string;
  barcode: string;
  imageUrl?: string;
  vat: string;
  price: string;
  discountedPrice?: string;
  index: number;
  stockTotal: string;
};

export type Primitives = string | number | boolean;

export type Column<T extends Record<string, Primitives>> = {
  field: keyof T;
  headerName: string;
  width: DimensionValue;
  hidden?: boolean;
  sortField?: SortField;
  style: {
    header: React.ComponentProps<typeof SmallBody>['style'];
    cell: React.ComponentProps<typeof SmallBody>['style'];
  };
};

type Props = {
  navigateProduct: (productId: ProductId) => void;
};

const ProductsTable: React.FC<Props> = ({ navigateProduct }) => {
  const [searchState, { loadMore, search }] = SearchProductsContext.useContext();
  const [selectedTab] = SelectedProductsTabContext.useContext();
  const tableRef = React.useRef<FlatList<ProductRow>>(null);

  const { t } = useTranslation('products');

  const { columns, data } = React.useMemo(() => {
    let data: ProductRow[] = [];

    if (searchState.type === 'Loaded' || searchState.type === 'Loading') {
      data = searchState.result.products.map((product, index) => {
        const price = `${scaledToString(product.price.retailPrice.amount)} ${t(product.price.retailPrice.unit)}`;
        const discountedPrice = `${scaledToString(product.price.discountedPrice.amount)} ${t(
          product.price.retailPrice.unit,
        )}`;

        return {
          _id: product._id,
          barcode: product.barcode?.value ?? '',
          name: product.name.toString(),
          brand: product.brandName ?? '',
          measureUnit: product.measure.unit.toString(),
          measure: getMeasure(product.measure),
          imageUrl: product.image?.thumbnail,
          vat: `${scaledToString(product.price.vatRate)}%`,
          price,
          discountedPrice: discountedPrice === price ? undefined : discountedPrice,
          stockTotal: scaledToString(product.stockDescription.quantity),
          index,
        };
      });
    } else {
      return {
        columns: [],
        data: [],
      };
    }

    const columns: Column<ProductRow>[] = [
      {
        field: 'name',
        headerName: t('nameColumn'),
        width: '40%',
        sortField: 'name',
        style: {
          header: {
            justifyContent: 'flex-start',
          },
          cell: {
            textAlign: 'left',
            paddingLeft: 0,
          },
        },
      },
      {
        field: 'brand',
        headerName: t('brandColumn'),
        width: '20%',
        style: {
          header: {
            justifyContent: 'flex-start',
          },
          cell: {
            textAlign: 'left',
            paddingLeft: 0,
          },
        },
      },
      {
        field: 'stockTotal',
        headerName: t(`stockTotalColumn`),
        width: `15%`,
        style: {
          header: {
            justifyContent: 'flex-end',
          },
          cell: {
            textAlign: 'right',
          },
        },
      },
      {
        field: 'vat',
        headerName: t(`vatColumn`),
        width: `12%`,
        sortField: 'vatRate',
        style: {
          header: {
            justifyContent: 'flex-end',
          },
          cell: {
            textAlign: 'right',
          },
        },
      },
      {
        field: 'price',
        headerName: t(`priceColumn`),
        width: `13%`,
        sortField: 'discountedPrice',
        style: {
          header: {
            justifyContent: 'flex-end',
          },
          cell: {
            textAlign: 'right',
          },
        },
      },
    ];

    return {
      columns: columns,
      data: data,
    };
  }, [searchState]);

  const onChangeSort = (field: SortField) => {
    let newSortField: SortField | undefined = field;
    let newSortDirection: SortDirection | undefined = 'ASC';

    if (field === searchState.query.sortBy) {
      if (searchState.query.sortDirection === undefined) {
        newSortDirection = 'ASC';
      }
      if (searchState.query.sortDirection === 'ASC') {
        newSortDirection = 'DESC';
      }
      if (searchState.query.sortDirection === 'DESC') {
        newSortDirection = undefined;
        newSortField = undefined;
      }
    }

    search({
      query: {
        ...searchState.query,
        sortBy: newSortField,
        sortDirection: newSortDirection,
      },
    });
  };

  const renderHeader = () => (
    <>
      <Row style={[{ paddingVertical: 12, paddingRight: 12, backgroundColor: colors.WHITE }]}>
        {columns
          .filter((c) => !c.hidden)
          .map((column, index) => (
            <TouchableOpacity
              key={column.field.toString()}
              style={[
                column.style.header,
                {
                  width: column.width,
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingRight: index !== columns.length - 1 ? 16 : 0,
                },
              ]}
              onPress={() => column.sortField && onChangeSort(column.sortField)}
            >
              <SmallBody style={[{ fontFamily: 'open-sans-semibold' }, column.style?.header]}>
                {column.headerName}
              </SmallBody>
              {column.sortField && (
                <>
                  <HSpacer left={4} />
                  {searchState.query.sortBy &&
                    searchState.query.sortBy === column.sortField &&
                    searchState.query.sortDirection === 'ASC' && <AscSortedIcon />}
                  {searchState.query.sortBy &&
                    searchState.query.sortBy === column.sortField &&
                    searchState.query.sortDirection === 'DESC' && <DescSortedIcon />}
                  {(!searchState.query.sortBy || searchState.query.sortBy !== column.sortField) && <UnsortedIcon />}
                </>
              )}
            </TouchableOpacity>
          ))}
      </Row>
      <Line color={colors.ATHENS_GRAY} />
    </>
  );

  const renderItem = ({ item }: { item: ProductRow; index: number }) => (
    <Row style={{ padding: 12 }}>
      {columns
        .filter((c) => !c.hidden)
        .map((column, index) => (
          <Column
            key={column.field.toString()}
            style={{ width: column.width, paddingRight: index !== columns.length - 1 ? 16 : 0 }}
          >
            {column.field === 'name' && (
              <ProductSummaryCard
                product={item}
                openProductDetails={navigateProduct}
                showLine={false}
                showPrice={false}
                hasPadding={false}
              />
            )}

            {(column.field === 'vat' || column.field === 'brand' || column.field === 'stockTotal') && (
              <SmallBody style={[column.style?.cell]}>{item[column.field]}</SmallBody>
            )}

            {column.field === 'price' && (
              <Column>
                {item.discountedPrice && <SmallBody style={[column.style?.cell]}>{item.discountedPrice}</SmallBody>}
                <SmallBody
                  style={[
                    !!item.discountedPrice && { textDecorationLine: 'line-through', fontSize: 12, color: colors.COMET },
                    column.style?.cell,
                  ]}
                >
                  {item[column.field]}
                </SmallBody>
              </Column>
            )}
          </Column>
        ))}
    </Row>
  );

  React.useEffect(() => {
    tableRef.current?.scrollToOffset({ animated: false, offset: 0 });
  }, [searchState.query.search, searchState.query.brandId, searchState.query.categoryId]);

  return (
    <FlatList
      ref={tableRef}
      data={data}
      ListHeaderComponent={renderHeader}
      renderItem={renderItem}
      onEndReached={loadMore}
      onEndReachedThreshold={0.8}
      stickyHeaderIndices={[0]}
      ListFooterComponent={searchState.type === 'Loading' ? <ActivityIndicator size="large" /> : null}
      ListEmptyComponent={() =>
        searchState.type === 'Loaded' && (
          <>
            <Spacer size={24} />
            <Column
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 24,
                paddingBottom: 32,
              }}
            >
              <H2s style={{ textAlign: 'center' }}>{t('noProducts')}</H2s>
              <Spacer size={8} />
              <Body style={{ textAlign: 'center' }}>
                {selectedTab === 'brands' ? t('noBrandProductsDescription') : t('noCategoryProductsDescription')}
              </Body>
            </Column>
          </>
        )
      }
      ItemSeparatorComponent={() => <Line color={colors.ATHENS_GRAY} />}
    />
  );
};

export default ProductsTable;
