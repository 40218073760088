import { SafeAreaView, useShowError } from '@mero/components';
import { pipe } from 'fp-ts/function';
import * as React from 'react';

import MeroHeader from '@mero/components/lib/components/MeroHeader';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../hooks/useGoBack';
import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { Authorized, AuthorizedProps, meroApi } from '../../../contexts/AuthContext';
import { CheckoutsContext } from '../../../contexts/CheckoutsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../contexts/CurrentBusiness';
import { AuthorizedStackParamList, ProductsTabStackParamList } from '../../../types';
import EnableCheckoutDesktop from '../CheckoutScreen/EnableCheckoutDesktop';
import EnableCheckoutMobile from '../CheckoutScreen/EnableCheckoutMobile';

type Props = AuthorizedProps &
  CurrentBusinessProps &
  StackScreenProps<
    ProductsTabStackParamList & AuthorizedStackParamList,
    'ProductsDashboardScreen' | 'ProductsMenuScreen'
  >;

const EnableProductsScreen: React.FC<Props> = ({ navigation, page }) => {
  const { isPhone } = useMediaQueries();
  const [checkoutState, { reloadSettings, reloadCompanies }] = CheckoutsContext.useContext();
  const showError = useShowError();
  const goBack = useGoBack();

  const [isLoading, setIsLoading] = React.useState(false);

  const changeCheckoutSettings = async () => {
    setIsLoading(true);
    try {
      await meroApi.checkout.savePageSettings({
        pageId: page.details._id,
        settings: {
          checkoutEnabled: true,
        },
      });

      reloadSettings(page.details._id);
      reloadCompanies(page.details._id);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onEnableCheckout = React.useCallback(() => {
    if (checkoutState.type === 'Loaded') {
      changeCheckoutSettings();
      if (checkoutState.companies.length === 0) {
        navigation.navigate('Menu', { screen: 'PageCheckoutSettingsScreen', params: { screen: 'BillingDetailsList' } });
      }
    }
  }, [checkoutState]);

  return (
    <>
      {/*{isLoading && <LoadingComponent />}*/}
      {checkoutState.type === 'Loaded' ? (
        isPhone ? (
          <>
            <SafeAreaView edges={['top']} />
            <MeroHeader
              canGoBack
              onBack={() =>
                navigation.navigate('Home', {
                  screen: 'HomeTabs',
                  params: {
                    screen: 'MenuTab',
                    params: {
                      screen: 'MenuScreen',
                    },
                  },
                })
              }
            />
            <EnableCheckoutMobile onEnableCheckout={onEnableCheckout} />
          </>
        ) : (
          <EnableCheckoutDesktop onEnableCheckout={onEnableCheckout} />
        )
      ) : null}
    </>
  );
};

export default pipe(EnableProductsScreen, CurrentBusiness, Authorized);
