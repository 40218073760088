import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

const ShopIcon: React.FC<{ color?: string }> = ({ color = '#52577f' }) => (
  <Svg width={32} height={32}>
    <G data-name="Group 5928">
      <Path data-name="Rectangle 59" fill="none" d="M0 0h32v32H0z" />
      <G data-name="Group 6204">
        <Path data-name="Rectangle 2477" fill="none" d="M4 4h24v24H4z" />
      </G>
      <G fill={color}>
        <Path d="M23.244 26.056H8.755a1 1 0 0 1-1-1.045l.6-13.282a1 1 0 0 1 1-.955H22.64a1 1 0 0 1 1 .955l.6 13.282a1 1 0 0 1-1 1.045Zm-13.443-2h12.4l-.516-11.282H10.314Z" />
        <Path d="M19.018 15.189a1 1 0 0 1-1-1V9.963a2.019 2.019 0 0 0-4.037 0v4.226a1 1 0 0 1-2 0V9.963A4.019 4.019 0 0 1 16 5.944a4.023 4.023 0 0 1 4.019 4.019v4.226a1 1 0 0 1-1.001 1Z" />
      </G>
    </G>
  </Svg>
);

export default ShopIcon;
