export const DEFAULT_TIMEZONE = 'Europe/Bucharest';

export const preZero = (num: number) => (num < 10 ? `0${num}` : num);

type Minute = {
  key: string;
  value: number;
  label: string;
  type: 'string';
};

export const DURATION_MIN = 1;

const insertCustomMinute = (list: Minute[], customMinute: number) => {
  const position = list.findIndex((m) => customMinute < m.value);
  const newItem = {
    key: `minute-${position}`,
    value: customMinute,
    label: `${preZero(customMinute)}m`,
    type: 'string' as const,
  };
  return position === -1 ? [...list, newItem] : [...list.slice(0, position), newItem, ...list.slice(position)];
};

export const getMinutes = (step = 5, customMinutes?: number[]) => {
  const list = [...Array(60 / step).keys()].map((i) => {
    const min = i * step;
    return {
      key: `minute-${i}`,
      value: min,
      label: `${preZero(min)}m`,
      type: 'string' as const,
    };
  });

  if (customMinutes) {
    return customMinutes.reduce(insertCustomMinute, list);
  }

  return list;
};
