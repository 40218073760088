import { Firstname, Lastname, PageBillingDetails } from '@mero/api-sdk';
import { colors, H1, MeroHeader, Spacer, useToast } from '@mero/components';
import { StrictPhoneNumber } from '@mero/shared-sdk';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ModalScreenContainer from '../../../../../components/ModalScreenContainer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';

import { Authorized, AuthorizedProps } from '../../../../../contexts/AuthContext';
import {
  BillingDetailsContext,
  BillingDetailsProps,
  withBillingDetails,
} from '../../../../../contexts/CheckoutSettingsContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { PageOnlinePaymentsSettingsStackParamList } from '../../../../../types';
import CompanyComponent from '../PageSubscriptionSettingsScreen/CompanyBillingDetails';
import PersonComponent from '../PageSubscriptionSettingsScreen/PersonBillingDetails';

export type Props = CurrentBusinessProps &
  AuthorizedProps &
  BillingDetailsProps &
  StackScreenProps<PageOnlinePaymentsSettingsStackParamList, 'BillingDetails'>;

const PageBillingDetailsScreen: React.FC<Props> = ({ page, billingDetails, authorization, route, navigation }) => {
  const goBack = useGoBack();
  const { t } = useTranslation('subscription');
  const toast = useToast();

  const [, { reloadAsync }] = BillingDetailsContext.useContext();

  const { billingId, type, ...rest } = route.params ?? {};

  const onSave = () => {
    reloadAsync({ pageId: page.details._id });
    goBack();
  };

  const details = React.useMemo(() => {
    if (billingId) {
      const currentBilling = billingDetails.find((b) => b._id === billingId);
      if (!currentBilling) {
        toast.show({
          type: 'error',
          text: t('billingNotFound'),
        });
        return goBack();
      }
      return currentBilling;
    }
  }, [billingId]);

  const title = React.useMemo(() => {
    if (type === 'Company') {
      return details ? t('editCompany') : t('addCompany');
    }
    return details ? t('editPerson') : t('addPerson');
  }, [details, type]);

  const defaultContact: Partial<PageBillingDetails.Company['contact']> | undefined = React.useMemo(() => {
    const admins = page.members.filter((m) => m.roles.some((r) => r.isOwnerRole));
    if (admins.length > 0) {
      const admin = admins.find((m) => m.user._id === authorization.user._id) ?? admins[0];

      return {
        firstname: admin.user.profile.firstname as Firstname,
        lastname: admin.user.profile.lastname as Lastname,
        email: page.details.email,
        phone: page.details.phoneNo as StrictPhoneNumber,
      };
    }

    return undefined;
  }, [page.details._id, page.members]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.ALABASTER }}>
      <MeroHeader canGoBack onBack={goBack} title={title} />
      {type === 'Company' && (
        <CompanyComponent
          pageId={page.details._id}
          defaultContact={defaultContact}
          billingDetailsId={details?._id}
          details={details?.billing.type === 'Company' ? details.billing : undefined}
          onSave={onSave}
          onDelete={() => null}
          canDelete={!!details?._id}
        >
          <Spacer size={16} />
          <H1 style={{ paddingHorizontal: 24 }}>{title}</H1>
          <Spacer size={32} />
        </CompanyComponent>
      )}
      {type === 'Person' && (
        <PersonComponent
          pageId={page.details._id}
          billingDetailsId={details?._id}
          details={details?.billing.type === 'Person' ? details.billing : undefined}
          onSave={onSave}
          onDelete={() => null}
          canDelete={!!(details?.usage && details.usage.length > 0)}
        >
          <Spacer size={16} />
          <H1 style={{ paddingHorizontal: 24 }}>{title}</H1>
          <Spacer size={32} />
        </PersonComponent>
      )}
    </ModalScreenContainer>
  );
};

export default pipe(PageBillingDetailsScreen, withBillingDetails, CurrentBusiness, Authorized);
