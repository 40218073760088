import { Body, colors, ConfirmBox, H1, ModalOverlay, SimpleListItem, Spacer } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import MobileWebModalWrapper from '../../../../../components/MobileWebModalWrapper';

import { meroApi } from '../../../../../contexts/AuthContext';
import { CurrentBusinessContext } from '../../../../../contexts/CurrentBusiness';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

const DisableOnlinePayment: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const { t } = useTranslation('onlinePayments');

  const [pageState, { reloadAsync }] = CurrentBusinessContext.useContext();

  const [inProgress, setInProgress] = React.useState(false);
  const [checks, setChecks] = React.useState({
    check1: false,
  });

  const cancel = () => {
    onCancel();
  };

  const confirm = async () => {
    try {
      if (pageState.type === 'Loaded') {
        setInProgress(true);
        await meroApi.pages.updateOnlinePaymentsStatus({ pageId: pageState.page.details._id, enabled: false });
        await reloadAsync();
        onSuccess();
      }
    } catch (error) {
    } finally {
      setInProgress(false);
    }
  };

  const addCategoryLeftAction = {
    text: t('cancel'),
    onPress: cancel,
    flex: 10,
  };

  const addCategoryRightAction = {
    text: t('deactivate'),
    onPress: Object.values(checks).some((v) => !v) || inProgress ? undefined : confirm,
    flex: 15,
  };

  const toggleCheck = (checkName: keyof typeof checks) => () => {
    setChecks({
      ...checks,
      [checkName]: !checks[checkName],
    });
  };

  return (
    <ModalOverlay style={{ justifyContent: 'center', alignItems: 'center' }}>
      <MobileWebModalWrapper position="center">
        <ConfirmBox
          type="error"
          headerTitle={t('importantAction')}
          icon="info"
          canClose={!inProgress}
          onClose={cancel}
          leftAction={addCategoryLeftAction}
          rightAction={addCategoryRightAction}
        >
          <H1>{t('disableTitle')}</H1>
          <Spacer size="8" />
          <Body>{t('disableDescription')}</Body>
          <Spacer size="16" />
          <TouchableOpacity onPress={toggleCheck('check1')} disabled={inProgress}>
            <SimpleListItem
              icon={checks.check1 ? 'checked' : 'unchecked'}
              iconColor={inProgress ? colors.SANTAS_GRAY : colors.RADICAL_RED}
              paddingTop={0}
              paddingBottom={0}
            >
              <Body>{t('disableCheck1')}</Body>
            </SimpleListItem>
          </TouchableOpacity>
        </ConfirmBox>
      </MobileWebModalWrapper>
    </ModalOverlay>
  );
};

export default DisableOnlinePayment;
