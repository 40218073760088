import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { flow, reverse } from 'lodash';

const isNotNull = (t: unknown) => t !== null;

type NonEmptyArray<T> = [T, ...T[]];

type LastType<T> = T extends [...unknown[], infer Last] ? (Last extends t.Mixed ? Last : never) : never;
export const sequence = <T extends NonEmptyArray<t.Mixed>>(...types: [...T]) => {
  const decodeChain = flow(
    ...types.map((type) =>
      flow(
        type.decode,
        E.getOrElseW(() => null),
      ),
    ),
  );

  return new t.Type<t.TypeOf<LastType<T>>, T[0]['_O'], unknown>(
    'sequence',
    (u): u is t.TypeOf<LastType<T>> => pipe(u, decodeChain, isNotNull),
    (u, c) => pipe(u, decodeChain, (v) => (isNotNull(v) ? t.success(v) : t.failure(u, c))),
    (v) => pipe(v, flow(reverse(types).map((v) => v.encode))),
  );
};
