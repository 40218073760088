import { PageOnlinePaymentsSettings } from '@mero/api-sdk';
import { PageId, ServicesPercentItem } from '@mero/api-sdk/dist/pages';
import { createModelContext } from '@mero/components';
import * as React from 'react';

import SplashScreen from '../../screens/SplashScreen';

import log from '../../utils/log';
import { meroApi } from '../AuthContext';
import { CurrentBusinessContext } from '../CurrentBusiness';

export type OnlinePaymentsContextState =
  | {
      readonly type: 'New';
      readonly settings?: PageOnlinePaymentsSettings;
      readonly selectedServices: ServicesPercentItem[];
    }
  | {
      readonly type: 'Loading';
      readonly settings?: PageOnlinePaymentsSettings;
      readonly selectedServices: ServicesPercentItem[];
    }
  | {
      readonly type: 'Loaded';
      readonly settings: PageOnlinePaymentsSettings;
      readonly selectedServices: ServicesPercentItem[];
    }
  | {
      readonly type: 'Failed';
      readonly settings?: PageOnlinePaymentsSettings;
      readonly selectedServices: ServicesPercentItem[];
      readonly error: unknown;
    };

const defaultState = (): OnlinePaymentsContextState => ({
  type: 'New',
  selectedServices: [],
});

export const OnlinePaymentsContext = createModelContext(
  defaultState(),
  {
    trySetResult: (
      state,
      result: {
        settings: PageOnlinePaymentsSettings;
        selectedServices?: ServicesPercentItem[];
      },
    ) => {
      if (state.type === 'Loading') {
        return {
          type: 'Loaded',
          settings: result.settings,
          selectedServices: result.selectedServices ?? state.selectedServices,
        };
      } else {
        // pass, result is for different query
        return state;
      }
    },
    setFailed: (
      state,
      payload: {
        error: unknown;
        settings?: PageOnlinePaymentsSettings;
      },
    ) => {
      return {
        type: 'Failed',
        settings: payload.settings ?? state.settings,
        selectedServices: state.selectedServices,
        error: payload.error,
      };
    },
    updateSelectedServices: (s, selectedServices: ServicesPercentItem[]) => ({ ...s, selectedServices }),
    mutate: (s, fn: (s: OnlinePaymentsContextState) => OnlinePaymentsContextState): OnlinePaymentsContextState => fn(s),
  },
  (dispatch) => {
    const reload = async ({ pageId }: { pageId: PageId; silent?: boolean }) => {
      try {
        log.debug('Start reloading payment settings');

        const settings = await meroApi.pages.getPageOnlinePaymentsSettings({ pageId });
        const selectedServices =
          settings.requireAdvancePayment.type === 'SpecificServices' ? settings.requireAdvancePayment.servicesMap : [];

        dispatch.trySetResult({
          settings,
          selectedServices,
        });
      } catch (error) {
        dispatch.setFailed({
          error: error,
        });
        log.exception(error);
      }
    };

    return {
      reloadAsync: async (payload: { pageId: PageId }): Promise<void> => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload({ pageId: payload.pageId }).catch(log.exception);

            return {
              ...state,
              type: 'Loading',
            };
          }

          return state;
        });
      },

      reload: (payload: { pageId: PageId }): void => {
        dispatch.mutate((state) => {
          if (state.type !== 'Loading') {
            reload(payload).catch(log.exception);

            return {
              type: 'Loading',
              settings: state.settings,
              selectedServices: state.selectedServices,
            };
          }

          return state;
        });
      },
      updateSelectedServices: dispatch.updateSelectedServices,
    };
  },
);

export type OnlinePaymentsProps = {
  settings: PageOnlinePaymentsSettings;
};

type PropsWithOnlinePayments<P> = P & OnlinePaymentsProps;

export function withOnlinePayments<P extends OnlinePaymentsProps>(
  Component: React.ComponentType<P>,
  FailedComponent: React.ComponentType<P> = () => null,
): React.FunctionComponent<Omit<P, keyof OnlinePaymentsProps>> {
  return function OnlinePaymentsContextComponent(props) {
    const [state] = OnlinePaymentsContext.useContext();

    switch (state.type) {
      case 'New': {
        return <SplashScreen />;
      }
      case 'Loading': {
        return <SplashScreen />;
      }
      case 'Loaded': {
        const settings = state.settings;

        // @ts-expect-error
        const allProps: PropsWithOnlinePayments<P> = {
          ...props,
          settings,
        };

        return <Component {...allProps} />;
      }
      case 'Failed': {
        // @ts-expect-error
        const allProps: P = { ...props };

        return <FailedComponent {...allProps} />;
      }
    }
  };
}

const ContextInit: React.FC<
  React.PropsWithChildren<{
    // pass
  }>
> = ({ children }) => {
  const [currentBusinessState] = CurrentBusinessContext.useContext();
  const [, { reload }] = OnlinePaymentsContext.useContext();

  const pageId = currentBusinessState.type === 'Loaded' ? currentBusinessState.page.details._id : undefined;

  React.useEffect(() => {
    if (pageId) {
      reload({ pageId });
    }
  }, [pageId]);

  return <>{children}</>;
};

export const withOnlinePaymentsContextProvider = <P extends object>(Content: React.ComponentType<P>): React.FC<P> => {
  return function WithSubscriptionContextProvider(props: P) {
    return (
      <OnlinePaymentsContext.Provider>
        <ContextInit>
          <Content {...props} />
        </ContextInit>
      </OnlinePaymentsContext.Provider>
    );
  };
};
