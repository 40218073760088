import { CheckoutTransactionServicePrice } from '@mero/api-sdk';
import { ServiceItemDetails } from '@mero/api-sdk/dist/memberships/membershipItemDetails';
import {
  Column,
  H1,
  MeroHeader,
  colors,
  useShowError,
  useToast,
  Button,
  DismissKeyboard,
  Spacer,
  Body,
  Row,
  Title,
  SmallBody,
  formatDurationInMinutes,
  Icon,
  Line,
  InputWithLabel,
  HSpacer,
} from '@mero/components';
import { MeroUnits, PositiveInt, ScaledNumber } from '@mero/shared-sdk';
import { DefinedString } from '@mero/shared-sdk/dist/common/string';
import { DiscountPercent } from '@mero/shared-sdk/dist/numbers/discountPercent';
import { flow } from 'fp-ts/function';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';

import KeyboardAvoidingView from '../../../../../components/KeyboardAvoidingView';
import ModalScreenContainer from '../../../../../components/ModalScreenContainer';
import { TextInputWithInternalState } from '../../../../../components/TextInputWithInternalState/TextInputWithInternalState';
import FormCard from '@mero/components/lib/components/FormCard';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Select from '@mero/components/lib/components/Select';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../hooks/useGoBack';
import { useMediaQueries } from '../../../../../hooks/useMediaQueries';

import { meroApi } from '../../../../../contexts/AuthContext';
import { WithUiKey } from '../../../../../contexts/CheckoutFormContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../contexts/CurrentBusiness';
import { Item, MembershipTemplateContext } from '../../../../../contexts/MembershipTemplateContext';
import { MembershipSettingsStackParamList } from '../../../../../types';
import { roundToDecimals } from '../../../../../utils/number';
import {
  localeNumberValidator,
  localeStringToNumber,
  replaceDecimalSeparator,
  scaledToString,
  stripLocalThousandsSeparators,
} from '../../../../../utils/scaled';
import { PercentageIcon } from '../../../CheckoutScreen/AddProceedComponent';
import DiscountComponent, { MinusIcon, PlusIcon } from '../../../CheckoutScreen/DiscountComponent';
import { styles } from '../ProsDashboardScreen/ProProfileDetailsScreen.styles';
import ItemMenu from './ItemMenu';

const SESSION_TYPE = [
  {
    value: 'Limited',
    label: 'limited',
  } as const,
  {
    value: 'Unlimited',
    label: 'unlimited',
  } as const,
];

const numberValidator = (prev: string) => (next: string) => {
  const parsed = replaceDecimalSeparator(next);
  return localeNumberValidator(parsed) ? localeStringToNumber(parsed).toString() : prev;
};

export const getItemPrice = (price: CheckoutTransactionServicePrice<ScaledNumber, MeroUnits.Any>) =>
  ScaledNumber.toNumber(price.type === 'Fixed' ? price.fixed.amount : ScaledNumber.fromNumber(0, 2));

export type CalculateMembershipItem = Omit<ServiceItemDetails<MeroUnits.Any>, 'discount'>;
export const calculateItemsDiscount = (items: CalculateMembershipItem[]) => {
  return items.reduce((acc, item) => {
    const priceNumber = getItemPrice(item.service.price);

    const quantity = item.quantity.type === 'Limited' ? item.quantity.value : 1;

    const actualPriceNumber = ScaledNumber.toNumber(item.sellingPrice.amount) / quantity;
    const hasDiscount = priceNumber > actualPriceNumber;
    const discount = hasDiscount
      ? roundToDecimals(priceNumber * quantity - ScaledNumber.toNumber(item.sellingPrice.amount))
      : 0;

    return acc + discount;
  }, 0);
};

type Props = StackScreenProps<MembershipSettingsStackParamList, 'MembershipItems'> & CurrentBusinessProps;

const MembershipItemsScreen: React.FC<Props> = ({ page, navigation, route }: Props) => {
  const { t } = useTranslation('membership');
  const goBack = useGoBack();
  const toast = useToast();
  const showError = useShowError();
  const { isPhone } = useMediaQueries();

  const { membershipId } = route.params ?? {};

  const [membershipState, { load, updateItem, removeItem, updateDiscount, sync, resetEdit }] =
    MembershipTemplateContext.useContext();

  const [isLoading, setIsLoading] = React.useState(true);
  const [showEditDiscount, setShowEditDiscount] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState<WithUiKey<Item>>();
  const [showErrors, setShowErrors] = React.useState(false);

  const onUpdateDiscount = (value: number, percentage: number, type: 'percentage' | 'value') => {
    if (type === 'value') {
      updateDiscount({
        type: 'Value',
        value: {
          unit: 'RON',
          amount: ScaledNumber.fromNumber(value, 2),
        },
      });
    }

    if (type === 'percentage') {
      updateDiscount({
        type: 'Percent',
        percent: ScaledNumber.fromNumber(percentage, 2) as DiscountPercent<ScaledNumber>,
      });
    }
  };
  const save = async () => {
    if (!membershipState.name) {
      return toast.show({ type: 'error', text: t('nameRequired') });
    }

    if (!membershipState.editItems.length) {
      return toast.show({ type: 'error', text: t('itemsRequired') });
    }

    if (!membershipState.validFor) {
      return toast.show({ type: 'error', text: t('validForRequired') });
    }

    if (
      membershipState.editItems.some((item) => {
        if (item.type !== 'Service' || item.quantity.type === 'Unlimited') {
          return false;
        }
        const priceNumber = getItemPrice(item.service.price);

        const actualPriceNumber = ScaledNumber.toNumber(item.sellingPrice.amount) / item.quantity.value;

        return priceNumber > 0 && priceNumber < actualPriceNumber;
      })
    ) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    try {
      if (!membershipId) {
        await meroApi.memberships.createMembershipTemplate({
          pageId: page.details._id,
          template: {
            ...membershipState,
            name: membershipState.name as DefinedString,
            termsAndConditions: membershipState.termsAndConditions as DefinedString,
            validFor: membershipState.validFor,
            unit: 'RON',
            description: membershipState.description,
            discount: membershipState.editDiscount,
            items: membershipState.editItems,
          },
        });

        toast.show({
          type: 'success',
          text: t('membershipTypeSaved'),
        });

        navigation.popToTop();
        navigation.replace('MembershipSettings');
      } else {
        sync();
        navigation.replace('MembershipDetails', { membershipId });
      }
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (membershipId && !membershipState.name) {
      load(page.details._id, membershipId)
        .catch((error) => {
          showError(error);
          goBack();
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const totalPrice = React.useMemo(() => {
    return (membershipState?.editItems ?? []).reduce((acc, item) => {
      if (item.type !== 'Service') {
        return acc;
      }
      const priceNumber =
        getItemPrice(item.service.price) * (item.quantity.type === 'Unlimited' ? 1 : item.quantity.value);
      const sellingPrice = ScaledNumber.toNumber(item.sellingPrice.amount);

      if (item.quantity.type === 'Unlimited' && priceNumber > sellingPrice) {
        return acc + priceNumber;
      }

      if (item.quantity.type !== 'Unlimited' && priceNumber > 0 && priceNumber > sellingPrice) {
        return acc + priceNumber;
      }

      return acc + sellingPrice;
    }, 0);
  }, [membershipState?.editItems]);

  const itemsDiscount = React.useMemo(
    () =>
      calculateItemsDiscount(
        membershipState.editItems.filter((item) => item.type === 'Service') as ServiceItemDetails<MeroUnits.Any>[],
      ),
    [membershipState.editItems],
  );

  const { maxPayValue, generalDiscount } = React.useMemo(() => {
    const tempTotal = totalPrice - itemsDiscount;

    if (membershipState.editDiscount) {
      if (membershipState.editDiscount.type === 'Percent') {
        const general = (tempTotal * ScaledNumber.toNumber(membershipState.editDiscount.percent)) / 100;
        return {
          maxPayValue: tempTotal - general,
          generalDiscount: general,
        };
      }
      const general = ScaledNumber.toNumber(membershipState.editDiscount.value.amount);
      return {
        maxPayValue: tempTotal - general,
        generalDiscount: general,
      };
    }

    return {
      maxPayValue: tempTotal,
      generalDiscount: 0,
    };
  }, [totalPrice, itemsDiscount, membershipState.editDiscount]);

  const { type, percentage, value } = React.useMemo(() => {
    if (!membershipState.editDiscount) {
      return {
        type: 'value' as const,
        percentage: 0,
        value: 0,
      };
    }

    if (membershipState.editDiscount.type === 'Percent') {
      const percentage = ScaledNumber.toNumber(membershipState.editDiscount.percent);
      return {
        type: 'percentage' as const,
        percentage,
        value: roundToDecimals((percentage / 100) * (maxPayValue + generalDiscount)),
      };
    }

    const value = ScaledNumber.toNumber(membershipState.editDiscount.value.amount);

    return {
      type: 'value' as const,
      percentage: Math.ceil((value / (maxPayValue + generalDiscount)) * 100),
      value,
    };
  }, [membershipState.editDiscount, maxPayValue, generalDiscount]);

  return (
    <ModalScreenContainer style={{ backgroundColor: colors.WHITE }}>
      {/*{isLoading && <LoadingComponent />}*/}
      <MeroHeader
        canGoBack
        onBack={flow(goBack, () => membershipId && resetEdit())}
        title={t('selectMembershipItems')}
      />
      <Column style={{ paddingTop: 16, flex: 1, height: '100%' }}>
        <DismissKeyboard style={{ paddingTop: 16, flex: 1, height: '100%' }}>
          <KeyboardAvoidingView style={{ flex: 1 }}>
            <ScrollView style={{ borderRadius: 6, flex: 1 }}>
              <H1 style={{ paddingHorizontal: 24 }}>{t('selectMembershipItems')}</H1>
              <Spacer size="16" />
              {membershipState.editItems.map((item, index) => {
                if (item.type !== 'Service') {
                  return null;
                }

                const priceNumber = getItemPrice(item.service.price);
                const price =
                  priceNumber > 0 ? scaledToString(ScaledNumber.fromNumber(priceNumber, 2)) : t('variablePrice');

                const actualPriceNumber =
                  ScaledNumber.toNumber(item.sellingPrice.amount) /
                  (item.quantity.type === 'Limited' ? item.quantity.value : 1);
                const hasDiscount = priceNumber > actualPriceNumber && item.quantity.type !== 'Unlimited';
                const discount = hasDiscount
                  ? roundToDecimals(((priceNumber - actualPriceNumber) * 100) / priceNumber)
                  : 0;

                const isPriceBigger = priceNumber > 0 && priceNumber < actualPriceNumber;

                return (
                  <React.Fragment key={item.service._id}>
                    <Spacer size="16" />
                    <Column
                      style={{
                        borderWidth: 1,
                        borderRadius: 6,
                        borderColor: colors.GEYSER,
                        paddingVertical: 16,
                        paddingLeft: 16,
                        paddingRight: 8,
                        marginHorizontal: 24,
                      }}
                    >
                      <TouchableOpacity
                        key={index}
                        onPress={() => {
                          setSelectedItem(item);
                        }}
                      >
                        <Row>
                          <Column style={{ flex: 1 }}>
                            <Title>{item.service.name}</Title>
                            <SmallBody style={{ color: colors.COMET }}>
                              {formatDurationInMinutes(item.service.durationInMinutes)}
                            </SmallBody>
                          </Column>
                          <Column>
                            <Body>{price}</Body>
                            {hasDiscount ? (
                              <SmallBody
                                style={{
                                  color: colors.RADICAL_RED,
                                  textAlign: 'right',
                                }}
                              >
                                -{discount}%
                              </SmallBody>
                            ) : null}
                          </Column>
                          <Column style={{ paddingLeft: 8 }}>
                            <Icon color={colors.DARK_BLUE} type="next" />
                          </Column>
                        </Row>
                      </TouchableOpacity>
                      <Spacer size="16" />
                      <Line />
                      <Spacer size="16" />
                      <Row>
                        <Column style={{ flex: 1 }}>
                          <InputWithLabel label={t('sessions')}>
                            <Select
                              placeholder={t('membershipDurationSelectPlaceholder')}
                              items={SESSION_TYPE.map((item) => ({
                                label: t(item.label),
                                value: item.value,
                              }))}
                              value={item.quantity.type}
                              onChange={(value) => {
                                if (value === 'Unlimited') {
                                  updateItem({
                                    ...item,
                                    quantity: {
                                      type: 'Unlimited',
                                    },
                                  });
                                } else {
                                  updateItem({
                                    ...item,
                                    quantity: {
                                      type: 'Limited',
                                      value: 1 as PositiveInt,
                                    },
                                    discount: undefined,
                                  });
                                }
                              }}
                            />
                          </InputWithLabel>
                        </Column>
                        {item.quantity.type === 'Limited' && (
                          <>
                            <HSpacer left={16} />
                            <Column style={{ flex: 1 }} justifyContent="space-between">
                              <Body style={{ fontFamily: 'open-sans-semibold' }}>{t('quantity')}</Body>
                              <Spacer size={8} />
                              <Row
                                style={{
                                  padding: 8,
                                  borderWidth: 1,
                                  borderRadius: 4,
                                  borderColor: colors.GEYSER,
                                  alignItems: 'center',
                                  width: '100%',
                                  height: 43,
                                }}
                              >
                                <TouchableOpacity
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 12,
                                    backgroundColor: colors.SKY_BLUE,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  onPress={() => {
                                    if (item.quantity.type === 'Unlimited' || item.quantity.value < 2) {
                                      return;
                                    }
                                    updateItem({
                                      ...item,
                                      quantity: {
                                        type: 'Limited',
                                        value: (item.quantity.value - 1) as PositiveInt,
                                      },
                                      discount: undefined,
                                    });
                                  }}
                                >
                                  <MinusIcon />
                                </TouchableOpacity>
                                <Column style={{ flex: 1, paddingHorizontal: 4 }}>
                                  <TextInputWithInternalState
                                    style={{
                                      flex: 1,
                                      textAlign: 'center',
                                      fontSize: 16,
                                      lineHeight: 22,
                                      fontFamily: 'open-sans',
                                    }}
                                    value={item.quantity.value.toString()}
                                    onBlur={(value) => {
                                      if (item.quantity.type !== 'Limited') {
                                        return;
                                      }
                                      pipe(value, numberValidator(item.quantity.value.toString()), (v) => {
                                        const value = (isNaN(parseInt(v)) ? 1 : parseInt(v)) as PositiveInt;
                                        updateItem({
                                          ...item,
                                          quantity: {
                                            type: 'Limited',
                                            value,
                                          },
                                          discount: undefined,
                                        });
                                      });
                                    }}
                                    keyboardType={'numeric'}
                                  />
                                </Column>
                                <TouchableOpacity
                                  style={{
                                    width: 24,
                                    height: 24,
                                    borderRadius: 12,
                                    backgroundColor: colors.SKY_BLUE,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  onPress={() => {
                                    if (item.quantity.type !== 'Limited') {
                                      return;
                                    }
                                    updateItem({
                                      ...item,
                                      quantity: {
                                        type: 'Limited',
                                        value: (item.quantity.value + 1) as PositiveInt,
                                      },
                                      discount: undefined,
                                    });
                                  }}
                                >
                                  <PlusIcon />
                                </TouchableOpacity>
                              </Row>
                            </Column>
                          </>
                        )}
                      </Row>
                      <Spacer size="16" />
                      <Row style={{ flex: 1 }}>
                        <Column style={{ flex: 1 }}>
                          <InputWithLabel
                            label={
                              item.quantity.type === 'Limited'
                                ? t('priceSale', { count: item.quantity.value })
                                : t('priceSaleUnlimited')
                            }
                          >
                            <TextInputWithInternalState
                              style={{
                                padding: 8,
                                borderWidth: 1,
                                borderRadius: 4,
                                borderColor: colors.GEYSER,
                                justifyContent: 'center',
                                height: 42,
                              }}
                              value={scaledToString(item.sellingPrice.amount)}
                              onFocus={() =>
                                stripLocalThousandsSeparators(
                                  ScaledNumber.toNumber(item.sellingPrice.amount).toLocaleString(),
                                )
                              }
                              onBlur={flow(
                                numberValidator(ScaledNumber.toNumber(item.sellingPrice.amount).toString()),
                                (v) => {
                                  const value = ScaledNumber.fromNumber(parseFloat(v), 2);
                                  updateItem({
                                    ...item,
                                    sellingPrice: {
                                      ...item.sellingPrice,
                                      amount: value,
                                    },
                                  });
                                },
                              )}
                              keyboardType={'numeric'}
                            />
                          </InputWithLabel>
                          {item.quantity.type === 'Limited' && !(showErrors && isPriceBigger) && priceNumber > 0 && (
                            <>
                              <Spacer size={8} />
                              <SmallBody style={{ color: colors.COMET }}>
                                {t('priceSaleInitial', {
                                  count: item.quantity.value,
                                  sum: priceNumber * item.quantity.value,
                                })}
                              </SmallBody>
                            </>
                          )}
                          {showErrors && isPriceBigger && (
                            <>
                              <Spacer size={8} />
                              <SmallBody style={{ color: colors.RADICAL_RED }}>{t('errorPriceTooBig')}</SmallBody>
                            </>
                          )}
                        </Column>
                      </Row>
                    </Column>
                  </React.Fragment>
                );
              })}

              {membershipState.editItems.length > 0 && (
                <>
                  <Spacer size={16} />
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Button
                      text={t('addItems')}
                      color={colors.DARK_BLUE}
                      backgroundColor={colors.SKY_BLUE}
                      onPress={() => navigation.navigate('SelectMembershipService')}
                    />
                  </Column>
                  <Spacer size={16} />
                  <Column style={{ height: 16, backgroundColor: colors.ALABASTER }} />
                  <Spacer size={24} />
                  {showEditDiscount || membershipState.editDiscount ? (
                    <Column style={{ paddingHorizontal: 24 }}>
                      <DiscountComponent
                        price={maxPayValue + generalDiscount}
                        value={value}
                        percentage={percentage}
                        onUpdate={onUpdateDiscount}
                        type={type}
                      />
                    </Column>
                  ) : (
                    <TouchableOpacity
                      style={{ flexDirection: 'row', alignItems: 'center', paddingRight: 32, paddingHorizontal: 24 }}
                      onPress={() => {
                        setShowEditDiscount(true);
                      }}
                    >
                      <PercentageIcon />
                      <SmallBody style={{ fontFamily: 'open-sans-semibold', color: colors.DARK_BLUE, paddingLeft: 8 }}>
                        {t('addDiscount')}
                      </SmallBody>
                    </TouchableOpacity>
                  )}
                  <Spacer size={24} />
                  <>
                    <Column style={{ paddingHorizontal: 24 }}>
                      <Row>
                        <SmallBody>{t('totalPrice')}</SmallBody>
                        <SmallBody style={{ fontFamily: 'open-sans-semibold', textAlign: 'right', flex: 1 }}>
                          {scaledToString(ScaledNumber.fromNumber(totalPrice, 2))} {t('RON')}
                        </SmallBody>
                      </Row>
                      {(membershipState.editDiscount && value > 0) || itemsDiscount > 0 ? (
                        <Row style={{ paddingTop: 12 }}>
                          <SmallBody>{t('discount')}</SmallBody>
                          <SmallBody style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                            -{scaledToString(ScaledNumber.fromNumber(generalDiscount + itemsDiscount, 2))} {t('RON')}
                          </SmallBody>
                        </Row>
                      ) : null}
                      <Spacer size={16} />
                      <Line />
                      <Spacer size={16} />
                      <Row>
                        <Body>{t('total')}</Body>
                        <Body style={{ fontFamily: 'open-sans-semibold', flex: 1, textAlign: 'right' }}>
                          {scaledToString(ScaledNumber.fromNumber(maxPayValue, 2))} {t('RON')}
                        </Body>
                      </Row>
                    </Column>
                  </>
                  <Spacer size={64} />
                  <Spacer size={96} />
                </>
              )}

              {membershipState.editItems.length === 0 && (
                <>
                  <Spacer size={16} />
                  <Column style={{ paddingHorizontal: 24 }}>
                    <Body style={{ textAlign: 'center', color: '#2A2E43' }}>{t('noItems')}</Body>
                    <Spacer size={16} />
                    <Button
                      text={t('addItems')}
                      color={colors.DARK_BLUE}
                      backgroundColor={colors.SKY_BLUE}
                      onPress={() => navigation.navigate('SelectMembershipService')}
                    />
                  </Column>
                </>
              )}
            </ScrollView>
          </KeyboardAvoidingView>
        </DismissKeyboard>
      </Column>
      <FormCard
        dropShaddow
        paddings="button"
        style={[!isPhone && styles.modalBorderBottom, { position: 'absolute', left: 0, right: 0, bottom: 0 }]}
      >
        <SafeAreaView edges={['bottom']}>
          {isPhone ? <Button text={t('save')} onPress={save} /> : <Button text={t('save')} onPress={save} />}
        </SafeAreaView>
      </FormCard>
      {selectedItem ? (
        <ItemMenu
          onSelect={flow(
            () => removeItem(selectedItem),
            () => setSelectedItem(undefined),
          )}
          onDismiss={() => setSelectedItem(undefined)}
        />
      ) : null}
    </ModalScreenContainer>
  );
};

export default pipe(MembershipItemsScreen, CurrentBusiness);
