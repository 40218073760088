import { Line, Title } from '@mero/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, TouchableOpacity, View } from 'react-native';

import ModalOverlay from '@mero/components/lib/components/ModalOverlay';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';

import { useMediaQueries } from '../../../hooks/useMediaQueries';

import { CheckoutFormContext, Payment } from '../../../contexts/CheckoutFormContext';
import { styles } from './AddProceedMenu.styles';
import { PaymentMethods } from './SelectPaymentScreen';

export type Props = {
  onSelect: (paymentType: Payment['type']) => void;
  onDismiss: () => void;
};

const PaymentsMenu: React.FC<Props> = ({ onDismiss, onSelect }) => {
  const { isPhone, isDesktop } = useMediaQueries();
  const { t } = useTranslation('checkout');

  const [formState] = CheckoutFormContext.useContext();

  const activePayments = React.useMemo(() => formState.draft.payments.map((p) => p.type), formState.draft.payments);
  const availablePayments = React.useMemo(() => {
    if (
      (activePayments.includes('Card') && activePayments.includes('Cash')) ||
      activePayments.includes('BankTransfer')
    ) {
      return [];
    }

    if (activePayments.includes('Card')) {
      return PaymentMethods.filter((p) => p.label === 'Cash');
    }

    if (activePayments.includes('Cash')) {
      return PaymentMethods.filter((p) => p.label === 'Card');
    }

    return PaymentMethods.filter((p) => p.label === 'BankTransfer' || p.label === 'Cash' || p.label === 'Card');
  }, [activePayments]);

  return (
    <ModalOverlay style={isDesktop && { alignItems: 'flex-end' }}>
      <Pressable style={styles.optionsStretchContainer} onPress={onDismiss} />
      <View style={[styles.optionsListContainer, !isPhone && styles.modalBorderBottom, isDesktop && { width: 500 }]}>
        <SafeAreaView edges={['bottom']}>
          {availablePayments.map((payment) => (
            <React.Fragment key={payment.label}>
              <TouchableOpacity
                style={styles.optionsMenuItem}
                delayPressIn={0}
                onPress={() => onSelect(payment.label === 'CardAndCash' ? 'Card' : payment.label)}
              >
                <Title>{t(payment.label)}</Title>
              </TouchableOpacity>
              <Line />
            </React.Fragment>
          ))}
        </SafeAreaView>
      </View>
    </ModalOverlay>
  );
};

export default PaymentsMenu;
